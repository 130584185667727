import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IntegrationIdentifier } from '../models/integration-identifier';
import { FastFetchDetails } from '../models/fast-fetch-details';
import { protectedResources } from '../auth-config';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { IntegrationInformation } from '../models/integration-information';

@Injectable()
export class FastFetchService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- FastFetch Operations ------------------------------------------//

  getintegrationId(integrationId: string, destination: string): Observable<IntegrationIdentifier> {
    this.actionUrl = "IntegrationIdentifier/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId, "/", destination);

    return this.http
      .get<IntegrationIdentifier>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET ids By integration id
  getintegrationIdentifiers(integrationId: string): Observable<IntegrationIdentifier[]> {
    this.actionUrl = "IntegrationIdentifiers/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

    return this.http
      .get<IntegrationIdentifier[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  postFastFetchLoans(details: FastFetchDetails): Observable<any> {
    this.actionUrl = "FastFetch/";
    this.Url = this.baseUrl.concat(this.actionUrl, details.application);

    return this.http
      .post<any>(this.Url, details, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
  
  //--------------------------- Integration Information ------------------------------------------//

  //GET FastFetch Integration Information
  getAllFastFetchIntegrationInformation(endpointName?: string): Observable<IntegrationInformation[]> {
    this.actionUrl = "IntegrationInformations/FastFetch";
    this.Url = this.baseUrl.concat(this.actionUrl);

    if (endpointName != undefined) {
      this.Url = this.Url.concat("/" + endpointName);
    }

    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationInformation[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
