<div class="container d-flex flex-column">
  <h1 class="title-center my-2">Default Config Placeholders</h1>
  <div>
    <button mat-raised-button class="my-2" style="float: right" (click)="goToAddPage()">Add Default Config</button>
  </div>
  <div class="content">
    <kendo-grid [data]="gridView"
                [pageSize]="state.take"
                [skip]="state.skip"
                [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
                [sortable]="{mode: 'single',allowUnsort: false}"
                [sort]="state.sort"
                [selectable]="true"
                [scrollable]="'scrollable'"
                [filter]="filter"
                [filterable]="true"
                (selectionChange)="selectionChangeHandler($event)"
                (sortChange)="sortChange($event)"
                (filterChange)="filterChange($event)"
                (pageChange)="pageChange($event)">

      <kendo-grid-column field="name" title="Name"></kendo-grid-column>
      <kendo-grid-column field="endpointName" title="Destination">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.endpointName != null">{{dataItem.endpointName | uppercase}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dataSourceTypeId" title="DataSource">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.dataSourceTypeId != null">{{dataItem.dataSourceTypeId}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="typeDescription" title="Import Type">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.typeDescription != null">{{dataItem.typeDescription}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="isGeneric" title="Is Generic" width="90">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.isGeneric != null">{{dataItem.isGeneric.toString() | uppercase}}</span>
          <!-- <span *ngIf="dataItem.isGeneric == null">NULL</span> -->
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="comments" title="Comments"></kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading && gridView">No records found.</div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridView">Loading failed.</div>
        </div>
      </ng-template>
    </kendo-grid>
  </div>

  <div>
    <button mat-raised-button class="my-2" *ngIf="!showFullMapping" (click)="initShowFullMapping()">See Full Mapping All Together</button>
    <button mat-raised-button class="my-2" *ngIf="showFullMapping" (click)="initHideFullMapping()">Hide Full Mapping</button>
  </div>
  <ng-container *ngIf="showFullMapping">
    <app-monaco-editor #monaco
                      (textChange)="monacoData = $event"
                      [editorData]="monacoData"
                      [lang]="'xml'"
                      [editAccess]="checkAccess(['LOSTalker.Admin'])"></app-monaco-editor>
  </ng-container>
</div>
