<div class="container d-flex flex-column">
    <h2 class="title-center my-2">Field Paths</h2>

    <div class="content">
        <kendo-grid [data]="gridView"
                    [pageSize]="state.take"
                    [skip]="state.skip"
                    [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
                    [sortable]="{mode: 'single',allowUnsort: false}"
                    [sort]="state.sort"
                    [scrollable]="'scrollable'"
                    [filter]="state.filter"
                    [filterable]="true"
                    (sortChange)="sortChange($event)"
                    (filterChange)="filterChange($event)"
                    (pageChange)="pageChange($event)"
                    (edit)="editHandler($event)"
                    (add)="addHandler()">
          <ng-template kendoGridToolbarTemplate>
              <button kendoGridAddCommand>Add new</button>
          </ng-template>
          <kendo-grid-column field="fieldId" title="Field Id" [width]="220">
          </kendo-grid-column>
          <kendo-grid-column field="apiFieldPath" title="API Field Path"></kendo-grid-column>
          <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200" [filterable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.updatedDate | date:'yyyy-MM-dd hh:mm:ss a'}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-command-column title="Edit Field" [width]="100">
            <ng-template kendoGridCellTemplate>
                <button kendoGridEditCommand [primary]="true" [disabled]="!checkAccess(['LOSTalker.Admin'])">Edit</button>
            </ng-template>
          </kendo-grid-command-column>
          <ng-template kendoGridNoRecordsTemplate>
            <div class="empty_spacing_for_norecords">
              <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
              <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
            </div>
          </ng-template>

          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
          </ng-template>
        </kendo-grid>
      </div>

</div>
<div class="title-center">
    <button type="button" class="k-button m-2" (click)="saveData(excelexport)">
        Export To Excel
    </button>
</div>
<kendo-excelexport [data]="filteredGroup" [collapsible]="true" fileName="EncompassAPIFieldPaths.xlsx" #excelexport>
    <kendo-excelexport-column field="encompassAPIFieldPathId" title="EncompassAPIFieldPathId"></kendo-excelexport-column>
    <kendo-excelexport-column field="fieldId" title="FieldId"></kendo-excelexport-column>
    <kendo-excelexport-column field="apiFieldPath" title="APIFieldPath"></kendo-excelexport-column>
    <kendo-excelexport-column field="updatedDate" title="UpdatedDate"></kendo-excelexport-column>
</kendo-excelexport>

<ng-template #addUpdateFieldForm>
  <h2 matDialogTitle>{{ isNew ? "Add New Encompass API Field Path" : "Edit Encompass Field Path" }}</h2>
  <mat-dialog-content>
    <div class="field-path-form-content">
      <ng-container>
        <mat-form-field appearance="fill" class="field-path-form-field">
          <mat-label>Field ID</mat-label>
          <input matInput [formControl]="fieldIdFormControl" placeholder="Ex. 364">
            <mat-error *ngIf="fieldIdFormControl.hasError('checkNotInList')">
              FieldId must not already exist.
            </mat-error>
            <mat-error *ngIf="fieldIdFormControl.hasError('required')">
              FieldId is required.
            </mat-error>
            <mat-error *ngIf="fieldIdFormControl.hasError('maxLength')">
              FieldId must be 100 characters or less.
            </mat-error>
        </mat-form-field>
      </ng-container>
    
      <ng-container>
        <mat-form-field appearance="fill" class="field-path-form-field">
          <mat-label>API Field Path</mat-label>
          <input matInput [formControl]="apiFieldPathFormControl" placeholder="Ex. $.loanNumber" >
            <mat-hint *ngIf="!isNew">Original Value: {{selectedItem.apiFieldPath}}</mat-hint>
            <mat-error *ngIf="apiFieldPathFormControl.hasError('required')">
              API Field Path is required.
            </mat-error>
            <mat-error *ngIf="apiFieldPathFormControl.hasError('maxLength')">
              API Field Path must 500 characters or less.
            </mat-error>
        </mat-form-field>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Close</button>
    <button mat-button (click)="onSave()">Save</button>
  </mat-dialog-actions>
</ng-template>
