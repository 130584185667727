import { Component, OnInit, QueryList, ViewChild } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {

  }

  public ngOnDestroy(): void {

  }


}
