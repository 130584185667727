import { Injectable } from '@angular/core';
import { Toast, ToastType } from '../models/toast';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public toastInstance: Toast;
  private subject = new Subject<Toast>();
  private defaultId = 'default-toast';

  constructor() { }

  onStart(id = this.defaultId): Observable<Toast> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  toast(toast: Toast) {
    toast.id = toast.id || this.defaultId;
    this.subject.next(toast);
  }

  clear(id = this.defaultId) {
    this.subject.next(new Toast({ id }));
  }

  toastCreate(message: string, type: string, options?: any) {
      this.toast(new Toast({ ...options, toastType: ToastType[type], message }));
  }

  toastCreateOrig(message: string, type: ToastType, options?: any) {
      this.toast(new Toast({ ...options, toastType: type, message }));
  }

}
