<h1 class="title-center">Add Base Mapping</h1>
<br>
<br>

<div *ngIf="!baseMappingsLoading && surefire3BaseMappingList">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Base Mapping Type</mat-label>
        <mat-select [(ngModel)]="baseMappingType">
            <mat-option *ngFor="let type of baseMappingTypes" [value]="type">{{type}}</mat-option>
        </mat-select>
    </mat-form-field>
    <br>

    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="baseMappingName">
    </mat-form-field>
    <br>

    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Import Type</mat-label>
        <mat-select [(ngModel)]="importType">
            <mat-option *ngFor="let type of importTypes" [value]="type">{{type.typeDescription}}</mat-option>
        </mat-select>
    </mat-form-field>
    <br>

    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Destination</mat-label>
        <mat-select [(ngModel)]="destination">
            <mat-option *ngFor="let type of destinations" [value]="type">{{type.endpointName}}</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    
    <kendo-switch
        [(ngModel)]="checked"
        onLabel="XML"
        offLabel="JS"
        (valueChange)="toggleDataXMLJS($event)"
    ></kendo-switch>

    <br />
    <app-monaco-editor #monaco
        (textChange)="monacoData = $event"
        [editorData]="monacoData"
        [lang]="'xml'"
        [editAccess]="true"
    ></app-monaco-editor>

    <div class="submit">
        <button id="btnSaveIntegration"
          mat-raised-button (click)="addBaseMapping()" [disabled]="savingBaseMapping">
          Add Base Mapping
        </button>
        <div class="k-i-loading" *ngIf="savingBaseMapping"></div>
    </div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="baseMappingsLoading">
    <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!baseMappingsLoading && !surefire3BaseMappingList">
    <div class="no-records-message">Loading failed.</div>
</div>
