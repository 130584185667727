<div class="container" *ngIf="pageReady">

  <!-- date period switch -->
  <div class="row" *ngIf="showDatePeriodSwitch == true">
    <div class="match-form-field title-center">
      <mat-button-toggle-group #group="matButtonToggleGroup" [multiple]="false" [hideSingleSelectionIndicator]="true"
        (change)="onToggleShowPeriodSelectViewChange($event.value)">
        <mat-button-toggle [checked]="showPeriodSelectView == true" value="true" aria-label="By Invoice Period">
          By Invoice Period
        </mat-button-toggle>
        <mat-button-toggle [checked]="showPeriodSelectView == false" value="false" aria-label="By Date Range">
          By Date Range
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- invoice period selection -->
  <div class="row" *ngIf="showPeriodSelectView == true">
    <app-invoice-period-view
        [selectedContract]="selectedContract"
        [invoicePeriods]="allInvoicePeriods"
        (itemSelected)="getInvoicePeriodFromView($event)"
        (completedLoansSelected)="getCompletedLoansInvoicePeriodFromView($event)"
        [overrideInvoicePeriod]="overrideInvoicePeriod"
        (itemSelectedForOverride)="getInvoicePeriodFromOverrideView($event)"
        (returnAllInvoicePeriods)="returnInvoicePeriodsList($event)">
      </app-invoice-period-view>
  </div>

  <div class="row" *ngIf="showPeriodSelectView == true && selectedInvoicePeriod">
    <h5 class="title-center my-2">
      Selected Period:
      {{selectedInvoicePeriod.dateEnd | date:'LLLL'}} 1st -
      {{formatDateEnd(selectedInvoicePeriod.dateEnd.getUTCDate())}}, {{selectedInvoicePeriod.dateEnd.getFullYear()}}
    </h5>
  </div>

  <!-- date range selection -->
  <div class="row" *ngIf="showPeriodSelectView == false">
    <app-date-range-view
        (updatedRange)="submitDateRange($event)"
        [monthYearOnly]="useMonthYearOnly"
        [dateStringPrefix]="dateStringPrefix"
        [showThisMonthButton]="useMonthYearOnly"
        [initDateRange]="overrideDateRange">
      </app-date-range-view>
  </div>

</div>
