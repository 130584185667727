<div class="container px-3 py-4">
  <div class="grid-container">

    <kendo-grid
        [data]="gridView"
        [loading]="gridViewIsLoading"
        [reorderable]="true"
        [resizable]="true"
        [pageable]="true"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sortable]="true"
        [filterable]="false"
        (sortChange)="sortChange($event)"
        (pageChange)="pageChange($event)"
        >

        <ng-template kendoGridToolbarTemplate>
          <button mat-stroked-button (click)="startAdd()">Add Mapping</button>
          <button mat-stroked-button (click)="startDeleteAll()">Delete All Mapping and Token</button>
        </ng-template>
        <kendo-grid-column field="clientId" title="ClientId" [width]="100"></kendo-grid-column>
        <kendo-grid-column field="integrationId" title="IntegrationId"></kendo-grid-column>
        <kendo-grid-column field="integrationName" title="IntegrationName"></kendo-grid-column>

        <kendo-grid-column field="losTokenMappingId" title="Delete">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button mat-stroked-button (click)="confirmDelete(dataItem)">Delete Mapping</button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoGridViewLoadingTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="k-i-loading"></div>
          </div>
        </ng-template>

        <ng-template kendoGridNoRecordsTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
          </div>
        </ng-template>

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-input></kendo-pager-input>
          <kendo-pager-info></kendo-pager-info>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
        </ng-template>

    </kendo-grid>
  </div>

  <ng-template #confirmDeleteDialog>
    <h3 mat-dialog-title>Do you wish to delete LOS Token Mapping?</h3>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="closeDialog()">No</button>
      <button mat-stroked-button (click)="checkDeleteMapping()" cdkFocusInitial>Yes</button>
    </div>
  </ng-template>

  <ng-template #confirmDeleteTokenDialog>
    <h3 mat-dialog-title>Are you sure wish to delete the LOS Token?</h3>
    <div mat-dialog-content>
      LOS Token Mapping being deleted is the only LOS Token Mapping for LOS Token.<br />
      If you delete the LOS Token Mapping, the LOS Token will be deleted too.<br />
      Do you wish to delete the LOS Token Mapping and LOS Token?
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="closeDialog()">No</button>
      <button mat-stroked-button (click)="deleteToken()" cdkFocusInitial>Yes</button>
    </div>
  </ng-template>

  <ng-template #confirmDeleteAllDialog>
    <h3 mat-dialog-title>Are you sure wish to delete all the LOS Token Mappings and LOS Token?</h3>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="closeDialog()">No</button>
      <button mat-stroked-button (click)="deleteToken()" cdkFocusInitial>Yes</button>
    </div>
  </ng-template>

  <ng-template #addMappingDialog>
    <h3 mat-dialog-title>Add Mapping for LOS Token</h3>
    <div mat-dialog-content>
      <ng-container *ngIf="availableIntegrations.length > 0">

        <kendo-multicolumncombobox #list
          [size]="'large'"
          [(value)]="selectedIntegration"
          [data]="availableIntegrations"
          [listHeight]="500"
          placeholder="Select an integration..."
          textField="name"
          valueField="integrationId"
          [kendoDropDownFilter]="{
            operator: 'contains',
            fields: ['dataSourceTypeId', 'name', 'integrationId']
          }">
          <kendo-combobox-column field="dataSourceTypeId" title="DataSourceTypeId" [width]="150">
            <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
              <span class="integration-combo-dropdown">{{dataItem.dataSourceTypeId}}</span>
            </ng-template>
          </kendo-combobox-column>
          <kendo-combobox-column field="name" title="Name" [width]="150" class="integration-combo-dropdown">
            <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
              <span class="integration-combo-dropdown">{{dataItem.name}}</span>
            </ng-template>
          </kendo-combobox-column>
          <kendo-combobox-column field="integrationId" title="IntegrationId" [width]="250" class="integration-combo-dropdown">
            <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
              <span class="integration-combo-dropdown">{{dataItem.integrationId}}</span>
            </ng-template>
          </kendo-combobox-column>
        </kendo-multicolumncombobox>
      </ng-container>
      <ng-container *ngIf="availableIntegrations.length == 0">
        There are no available integrations to add a token to
      </ng-container>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="closeDialog()">Cancel</button>
      <button mat-stroked-button (click)="addNewMapping()" cdkFocusInitial [disabled]="!selectedIntegration">Submit</button>
    </div>
  </ng-template>
</div>
