import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Location } from '@angular/common';
import { Observable, Subscription, Subject, forkJoin } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { MonacoPageComponent } from '../monaco-editor/monaco-editor.component';

import { User } from '../../models/user';
import { ImportType } from '../../models/import-type';
import { DataSourceType } from '../../models/data-source-type';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { DefaultConfigurationPlaceholder, DefaultConfigUtils } from '../../models/default-config-placeholder';

import { DefaultConfigPlaceholderService } from '../../services/default-config-placeholder.service';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { ToastService } from '../../services/toast.service';
import { ThisReceiver } from '@angular/compiler';
import { TypeConstantService } from 'src/app/services/type-constant.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-edit-default-configs',
  templateUrl: './edit-default-configs.component.html',
  styleUrls: ['./edit-default-configs.component.scss'],
  providers: [DefaultConfigPlaceholderService, ClientIntegrationService]
})
export class EditDefaultConfigsComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild('monaco') public child: MonacoPageComponent;
  monacoData: string = '<xml></xml>';
  sub: any;
  tmpStr = "";

  nameFormControl = new UntypedFormControl('', Validators.required);
  matcher = new MyErrorStateMatcher();

  selectedConfig: DefaultConfigurationPlaceholder;
  selectedParentConfig: DefaultConfigurationPlaceholder;
  allConfigs: DefaultConfigurationPlaceholder[];
  filteredConfigs: DefaultConfigurationPlaceholder[];

  importTypes: ImportType[] = [];
  selectedImportType: ImportType;

  destinationEndpoints: DestinationEndpoint[] = [];
  selectedDestinationEndpoint: DestinationEndpoint;

  dataSourceTypes: DataSourceType[] = [];
  selectedDataSourceType: DataSourceType;

  isGenericIntegration: boolean;

  inEditMode = true;

  constructor(
    protected configService: DefaultConfigPlaceholderService,
    protected clientService: ClientIntegrationService,
    private toastService: ToastService,
    private typeConstantService: TypeConstantService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private user: User
  ) { }

  ngOnInit(): void {

    this.sub = this.route.params
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(params => {
      forkJoin({
        data1: this.typeConstantService.getAllDataSourceTypes(),
        data2: this.typeConstantService.getAllDestinationEndpoints(),
        data3: this.typeConstantService.getAllImportTypes()
      })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data1, data2, data3 }) => {
        this.dataSourceTypes = data1;
        this.destinationEndpoints = data2;
        this.importTypes = data3;

        this.configService.getAllDefaultConfigPlaceholders()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data) => {
          this.allConfigs = data;


          this.filteredConfigs = data.filter(x =>
            x.name != params['name']
          );

          this.selectedConfig = data.find(x =>
            x.name === params['name']
          );

          this.monacoData = this.selectedConfig.xmlElementConfig;

          this.nameFormControl.setValue(this.selectedConfig.name);

          if (this.selectedConfig.parentId != null) {
            this.selectedParentConfig = this.allConfigs.find(x =>
              x.defaultConfigurationPlaceholderId === this.selectedConfig.parentId
            );
          }

          this.selectedDataSourceType = this.dataSourceTypes.find(x =>
            x.dataSourceTypeId === this.selectedConfig.dataSourceTypeId
          );

          this.selectedDestinationEndpoint = this.destinationEndpoints.find(x =>
            x.destinationEndpointId === this.selectedConfig.destinationEndpointId
          );

          this.selectedImportType = this.importTypes.find(x =>
            x.importTypeId === this.selectedConfig.importTypeId
          );

          this.isGenericIntegration = this.selectedConfig.isGeneric;

        });

      });

    });
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  goBack(): void {
    this.location.back();
  }

  canDeactivate(): boolean {
    if (this.child.hasBeenEdited()) {
      return false;
    }
    return true;
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  updateConfig(): void {
    this.selectedConfig.xmlElementConfig = this.monacoData;

    this.configService.updateDefaultConfigPlaceholder(this.selectedConfig)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      () => {
        // on success
        this.toastService.toastCreate("Successfully updated default config: " + this.selectedConfig.name, "Success", {
          autoClose: false
        });
      });
  }

  parentConfigChange(event: any): void {
    if (event.value == null) {
      this.selectedParentConfig = null;
      this.selectedConfig.parentId = null;
    }
    else {
      this.selectedParentConfig = event.value;
      this.selectedConfig.parentId = event.value.defaultConfigurationPlaceholderId;
    }
  }

  dataSourceTypeChange(event: any): void {
    this.selectedDataSourceType = event.value;
    this.selectedConfig.dataSourceTypeId = event.value.dataSourceTypeId;
  }

  destinationEndpointChange(event: any): void {
    this.selectedDestinationEndpoint = event.value;
    this.selectedConfig.destinationEndpointId = event.value.destinationEndpointId;
    this.selectedConfig.endpointName = event.value.endpointName;
  }

  importTypeChange(event: any): void {
    this.selectedImportType = event.value;
    this.selectedConfig.importTypeId = event.value.importTypeId;
    this.selectedConfig.typeDescription = event.value.typeDescription;
  }

  onToggleIsGenericChange(value: any): void {

    if (value === 'true') {
      this.isGenericIntegration = true;
      this.selectedConfig.isGeneric = true;
    } else if (value === 'false') {
      this.isGenericIntegration = false;
      this.selectedConfig.isGeneric = false;
    } else {
      this.isGenericIntegration = null;
      this.selectedConfig.isGeneric = null;
    }
  }



}
