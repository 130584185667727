export class ColumnSettings {
    field: string;
    prettyField?: string;
    title?: string;
    format?: string;
    pipeFormat?: string;
    usePipeFormat?: boolean = false;
    type?: "text" | "numeric" | "boolean" | "date" = "text";
    public hidden?: boolean = false;
    filterable?: boolean = true;
    hideInExport?: boolean = false;
    sticky?: boolean = false;
    hasSubColumns?: boolean = false;
    subColumns?: ColumnSettings[] = [];
    isSubColumn?: boolean = false;
    parentColumnTitle?: string;
    fullField?: string;

    width?: number;

    constructor(init?: Partial<ColumnSettings>){
        this.field = init.field ?? "";
        this.prettyField = init.prettyField ?? "";
        this.title = init.title ?? "";
        this.format = init.format ?? "";
        this.pipeFormat = init.pipeFormat ?? "";
        this.type = init.type ?? "text";
        this.hidden = init.hidden ?? false;
        this.filterable = init.filterable ?? true;
        this.hideInExport = init.hideInExport ?? false;
        this.sticky = init.sticky ?? false;
        this.usePipeFormat = init.usePipeFormat ?? false;

        this.fullField = init.fullField ?? "";

        this.width = init.width ?? null;

        this.hasSubColumns = init.hasSubColumns ?? false;
        this.isSubColumn = init.isSubColumn ?? false;
        if (init.subColumns != null && init.subColumns?.length > 0) {
            this.subColumns = init.subColumns;
        }
        else {
            this.subColumns = [];
        }

        this.parentColumnTitle = init.parentColumnTitle ?? "";
    }
}