<h1 *ngIf="!serverListLoading && serverList" style="text-align:center;">Select a Server To View</h1>
<br/>
<kendo-dialog
  title="Update Server Details"
  *ngIf="opened"
  (close)="close()"
  [minWidth]="250"
  [width]="450">
    <p id=kendoDialogOriginalValue>Original Value: {{serverSelected.encompassVersion}}</p>
    <label class="k-form" for="textbox">New Version:</label>
    <input type="text" name="Name" id="textbox" #textbox [(ngModel)]="serverSelected.encompassVersion">
    <div class="wrap">
      <label class="k-checkbox-label" for="isWhitelisted">White Listed:</label>
      <input type="checkbox" name="WhiteListed" id="isWhitelisted" kendoCheckBox [(ngModel)]="this.serverSelected.isWhitelisted">
    </div>
    <div class="wrap">
      <label class="k-checkbox-label" for="testServer">Test Server:</label>
      <input type="checkbox" name="TestServer" id="testServer" kendoCheckBox [(ngModel)]="this.serverSelected.testServer">
    </div>
    <kendo-dialog-actions>
      <button kendoButton (click)="close()" >Cancel</button>
      <button kendoButton (click)="saveServer()" >Submit</button>
    </kendo-dialog-actions>
</kendo-dialog>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="serverListLoading">
  <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!serverListLoading && !serverList">
  <div class="no-records-message">Loading failed.</div>
</div>

<div *ngIf="!serverListLoading && serverList" class="row row-cols-1 row-cols-lg-2">
  <div *ngFor="let s of serverList" class="col mb-4" (click)="selectServer(s.serverId)">
    <div class="card">
      <div class="card-header bg-secondary text-white" style="display: flex; align-items: center; vertical-align: middle; justify-content: space-between;">

        <p class="server-title">{{s.description}}</p>
        <div>
          <button *ngIf="serverStopped.get(s.serverId) && !s.testServer" matTooltip="Last data sent is outside of allowed range"
                class="btn" style="float: right; width:20px; color: white;">
            <i style="color: red; " class="bi bi-exclamation-circle-fill"></i>
          </button>
          <button class="btn" style="float: right; color: white;" (click)="servButton($event, s.serverId)" *ngIf="s.serverId !== '0'"><i class="bi bi-pencil"></i></button>
        </div>

      </div>
      <div class="card-body w-75" style="width:100%!important">
        <div class="row">
          <div class="col-6">
            <div class="row" *ngIf="s.serverId !== '0'">
              <div class="col">Encompass Version</div>
              <div class="col">{{s.encompassVersion}}</div>
            </div>
            <div class="row">
              <div class="col">Integration Count</div>
              <div class="col">{{s.integrationCount}}</div>
            </div>
            <div class="row" *ngIf="s.serverId !== '0'">
              <div class="col">Is Whitelisted</div>
              <div class="col">{{s.isWhitelisted}}</div>
            </div>
            <div class="row" *ngIf="s.serverId !== '0'">
              <div class="col">TestServer</div>
              <div class="col">{{s.testServer}}</div>
            </div>
          </div>
          <div class="col-6" *ngIf="s.serverId !== '0' && s.integrationCount != 0">
            Last Data Sent: {{ s.lastDataSentServer | date: 'short'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
