export class CustomSQLQuery {
  customSQLQueryId: string;
  integrationId: string;
  integrationName: string;
  integrationSourceTypeId: string;
  sqlString: string;
  queryTypeId: number;
  queryName: string;
  queryTypeName: string;
}
