import { Component, OnInit, OnChanges, OnDestroy, Input, Output, ViewChild, EventEmitter, SimpleChanges, AfterViewInit, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from "rxjs/operators";
import { ToastService } from '../../services/toast.service';
import { State, DataSourceRequestState, orderBy, CompositeFilterDescriptor, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ColumnSettings } from '../../models/column-settings';
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { TooltipDirective } from "@progress/kendo-angular-tooltip";

const flatten = (filter) => {
  const filters = filter.filters;
  if (filters) {
      return filters.reduce(
          (acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]),
          []
      );
  }
  return [];
};

@Component({
  selector: 'app-invoicing-loans-grid',
  templateUrl: './invoicing-loans-grid.component.html',
  styleUrls: ['./invoicing-loans-grid.component.scss']
})
export class InvoicingLoansGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('genericGrid') public kendoGenericGrid: GridComponent;
  @ViewChild('excelExportGrid') public excelExportGrid: ExcelExportComponent;

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() data: any[];
  @Input() loading: boolean;
  @Input() customExport: boolean = false;
  @Input() exportFileName: string = "Data";
  @Input() columns: ColumnSettings[] = [];
  @Input() showExportToolbar: boolean = true;
  @Input() sortDescriptions: SortDescriptor[] = [];

  @Input() columnFieldInfoMap: Map<string,string> = new Map<string,string>();

  @Output() getUpdated = new EventEmitter<string>(); // ask parent for updated data

  public cols: ColumnSettings[] = [];

  hasData: boolean = false;

  public gridViewIsLoading: boolean = true;

  public allGroup: any[];
  public filteredGroup: any[];

  type: 'numeric' | 'input' = 'input';
  public pageSizes: number[] = [5, 10, 25, 50, 100];
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor(private toastService: ToastService, private ngZone: NgZone) { }

  ngOnInit(): void {
    if (this.data != null) {
      if (this.data.length > 0) {
        this.hasData = true;
        this.allGroup = this.data;
      }
      else {
        this.hasData = false;
        this.gridViewIsLoading = false;
      }
    }

    if (this.columns != null && this.columns.length > 0) {
      this.cols = [];
      this.columns.forEach(c => this.cols.push(new ColumnSettings(c)));
    }
    else {
      this.buildColumnSettings();
    }

    if (this.sortDescriptions != null && this.sortDescriptions.length > 0) {
      this.state.sort = this.sortDescriptions;
    }

    this.allData = this.allData.bind(this);
  }

  public ngAfterViewInit(): void {
    if (this.columns.filter(x => x.hidden == false).length > 10) {
      this.fitColumns();
    }
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.kendoGenericGrid.autoFitColumns();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data?.currentValue != null && changes?.data?.firstChange != true) {
      if (this.data.length > 0) {
        this.gridViewIsLoading = true;
        this.allGroup = this.data;
        this.gridViewIsLoading = false;
      }
    }
    if (changes?.columns?.currentValue != null && changes?.columns?.firstChange != true){
      if (this.columns.length > 0) {
        this.gridViewIsLoading = true;
        this.cols = [];
        this.columns.forEach(c => this.cols.push(new ColumnSettings(c)));
        this.gridViewIsLoading = false;
      }
    }
  }

  public saveData(component: any): void {
    component.save();
  }

  buildColumnSettings(): void {
    if (this.data != null && this.data.length > 0) {
      var fields: string[] = Object.getOwnPropertyNames(this.data[0]);
      if (fields != null && fields.length > 0) {
        this.cols = [];
        fields.forEach(c => this.cols.push(new ColumnSettings({ field: c, title: c})));
      }
    }
  }

  public getCurrentGridData(): any[] {
    let orderedData = orderBy(this.allGroup, this.kendoGenericGrid.sort);
    this.filteredGroup = filterBy(orderedData, this.kendoGenericGrid.filter);
    return this.filteredGroup;
  }

  public allData(): ExcelExportData {
    let orderedData = orderBy(this.allGroup, this.kendoGenericGrid.sort);
    this.filteredGroup = filterBy(orderedData, this.kendoGenericGrid.filter);
    const result: ExcelExportData = {
      data: this.filteredGroup
    };
    return result;
  }

  public getHeaderTooltipText(t: string, field: string = null) {
    var rc = `${t}`
    if (field != null && field != '') {
      rc = `${t} | Field: ${field}`;
    }
    return rc;
  }

}
