import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { Observable, Subscription, Subject, forkJoin } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { Integration } from '../../models/integration';
import { Client } from 'src/app/models/client';
import { IntegrationErrorMessage } from '../../models/integration-error-message';
import { IntegrationErrorMessagesResponse } from '../../models/integration-error-messages-response';
import { ClientIntegrationService } from "../../services/client-integration.service";
import { ErrorLoggingService } from '../../services/logging.service';
import { ErrorListGridComponent } from '../error-list-grid/error-list-grid.component';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ToastService } from '../../services/toast.service';
import { Server } from 'src/app/models/server';
import { ServerService } from 'src/app/services/server.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { IntegrationType } from 'src/app/models/integration-type';
import { ServiceErrorListGridComponent } from '../service-error-list-grid/service-error-list-grid.component';
import { ServiceError } from 'src/app/models/service-error';
@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
  providers: [ClientIntegrationService, ErrorLoggingService]
})
export class ErrorListComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() integration: Integration;
  isInTab: boolean;

  @ViewChild("tabstrip") public tabstrip: TabStripComponent;
  @ViewChild("stoppedListComponent") public stoppedListComponent: ErrorListGridComponent;
  @ViewChild("maintenanceListComponent") public maintenanceListComponent: ErrorListGridComponent;
  @ViewChild("serviceErrorListComponent") public serviceErrorListComponent: ServiceErrorListGridComponent;
  @ViewChild("otherListSection") public otherListSection: ErrorListGridComponent;
  tabIndex: number = 0;
  dataLoading: boolean = true;
  stoppedPopulated: boolean = false;
  maintenancePopulated: boolean = false;
  otherPopulated: boolean = false;
  serviceErrorsPopulated: boolean = false;

  allErrorsList: IntegrationErrorMessagesResponse;
  otherErrorsList: IntegrationErrorMessage[];
  stoppedErrorsList: IntegrationErrorMessage[];
  maintenanceErrorsList: IntegrationErrorMessage[];
  serviceErrorList: ServiceError[];
  public allDestinations: DestinationEndpoint[];
  public allIntegrationTypes: IntegrationType[];

  integrations: Integration[];
  clients: Client[];
  servers: Server[];
  integrationsMap: Map<string, Integration>;
  destinationsMap: Map<string, DestinationEndpoint>;

  hasDestinations: boolean = false;
  hasIntegrationTypes: boolean = false;

  subIntegrationErrorMessage: Subscription;

  constructor(
    protected typeService: TypeConstantService,
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientIntegrationService,
    private serverService: ServerService,
    private toastService: ToastService,
    private errorLoggingService: ErrorLoggingService) {
    this.stoppedErrorsList = [];
    this.maintenanceErrorsList = [];
    this.otherErrorsList = [];
    this.integrations = [];
    this.allDestinations = [];
    this.allIntegrationTypes = [];
  }

  ngOnInit() {
    this.getTypesAndDestinations();
    if (this.integration == null) {
      this.getIntegrations();
    }

    this.isInTab = (this.integration !== null);
    this.getErrors();
  }

  ngOnDestroy(): void {
    this.subIntegrationErrorMessage.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getErrors(forceRefresh: Boolean = false) {
    if (this.tabIndex == 0 && (!this.stoppedPopulated || forceRefresh)) {
      this.dataLoading = true;
      this.getStoppedErrors();
    }
    else if (this.tabIndex == 1 && (!this.maintenancePopulated || forceRefresh)) {
      this.dataLoading = true;
      this.getMaintenanceErrors();
    }
    else if (this.tabIndex == 2 && (!this.serviceErrorsPopulated || forceRefresh)) {
      this.dataLoading = true;
      this.getServiceErrors();
    }
    else if (!this.otherPopulated || forceRefresh) {
      this.dataLoading = true;
      this.getOtherErrors();
    }
    else {
      this.dataLoading = false;
    }
  }

  getErrorsInBackground() {
    if (this.clients == null) {
      this.getClients();
    }

    if(this.servers == null){
      this.servers = [];
      this.checkAllServersLastDataSent();
    }

    if (this.tabIndex !== 0 && !this.stoppedPopulated) {
      this.getStoppedErrors();
    }

    if (this.tabIndex !== 1 && !this.maintenancePopulated) {
      this.getMaintenanceErrors();
    }

    if (this.tabIndex !== 2 && !this.serviceErrorsPopulated) {
      this.getServiceErrors();
    }

    if (this.tabIndex !== 3 && !this.otherPopulated) {
      this.getOtherErrors();
    }
  }

  sendUpdatedDataToTable(e: any) {
    this.getErrors(true);
  }

  onTabSelect(event: any) {
    this.tabIndex = event.index;
    this.getErrors();
  }

  getIntegrations(): void {
    this.clientService.getAllIntegrations()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (g) => {
          this.integrationsMap = new Map(g.map(k => [k.integrationId, k] as [string, Integration]));
          this.integrations = g;
        });
  }


  getClients(): void {
    this.clientService.getAllClients()
      .subscribe(clients => this.clients = clients);
  }

  getTypesAndDestinations(): void {
    this.typeService.getAllDestinationEndpoints()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.allDestinations = r;
        this.hasDestinations = true;
        this.typeService.getAllIntegrationTypes()
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(i => {
            this.allIntegrationTypes = i;
            this.hasIntegrationTypes = true;
            this.destinationsMap = new Map(i.map(k => [k.integrationTypeId, r.find(d => d.destinationEndpointId == k.destinationId)] as [string, DestinationEndpoint]));
          },
            (error) => {
              this.hasIntegrationTypes = false;
            });
      },
        (error) => {
          this.hasDestinations = false;
        });
  }

  getOtherErrors(): void {
    if (this.integration == null) {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllOtherErrors()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.otherErrorsList = g;
            this.dataLoading = false;
            this.otherPopulated = true;
            this.otherListSection?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.otherPopulated = true;
            this.toastService.toastCreate("Loading Other Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
    else {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllOtherErrorsById(this.integration.integrationId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.otherErrorsList = g;
            this.dataLoading = false;
            this.otherPopulated = true;
            this.otherListSection?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.otherPopulated = true;
            this.toastService.toastCreate("Loading Other Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
  }

  getMaintenanceErrors(): void {
    if (this.integration == null) {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllMaintenanceErrors()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.maintenanceErrorsList = g;
            this.dataLoading = false;
            this.maintenancePopulated = true;
            this.maintenanceListComponent?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.maintenancePopulated = true;
            this.toastService.toastCreate("Loading Maintenance Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
    else {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllMaintenanceErrorsById(this.integration.integrationId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.maintenanceErrorsList = g;
            this.dataLoading = false;
            this.maintenancePopulated = true;
            this.maintenanceListComponent?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.maintenancePopulated = true;
            this.toastService.toastCreate("Loading Maintenance Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
  }

  getStoppedErrors(): void {
    if (this.integration == null) {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllStoppedErrors()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.stoppedErrorsList = g;
            this.dataLoading = false;
            this.stoppedPopulated = true;
            this.stoppedListComponent?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.stoppedPopulated = true;
            this.toastService.toastCreate("Loading Stopped Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
    else {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllStoppedErrorsById(this.integration.integrationId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.stoppedErrorsList = g;
            this.dataLoading = false;
            this.stoppedPopulated = true;
            this.stoppedListComponent?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.stoppedPopulated = true;
            this.toastService.toastCreate("Loading Stopped Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
  }

  getServiceErrors(): void {
    if (this.integration == null) {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllServiceErrors()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.serviceErrorList = g;
            this.dataLoading = false;
            this.serviceErrorsPopulated = true;
            this.serviceErrorListComponent?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.serviceErrorsPopulated = true;
            this.toastService.toastCreate("Loading Service Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
    else {
      this.subIntegrationErrorMessage = this.errorLoggingService.getAllServiceErrorsByServerId(this.integration.serverId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (g) => {
            this.serviceErrorList = g;
            this.dataLoading = false;
            this.serviceErrorsPopulated = true;
            this.serviceErrorListComponent?.refresh();
            this.getErrorsInBackground();
          },
          (error) => {
            this.dataLoading = false;
            this.serviceErrorsPopulated = true;
            this.toastService.toastCreate("Loading Service Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
  }

  public checkAllServersLastDataSent() {
    this.serverService.getAllServersInformation()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (s) => {
        this.servers = s;
        s.forEach(server => {
          if (server.serverId !== '0' && server.testServer == false) {
            if (server.lastDataSentServer !== null) {
              let lds = new Date(server.lastDataSentServer);

              if (lds !== null) {
                if (lds.getTime() < Date.now() - (server.serverAlertTimespan * 60 * 1000)) {
                  this.toastService.toastCreate("Last Data Sent Error. Server " + server.serverId + ": " + lds.toLocaleString(), "Warning", { autoClose: true, keepAfterRouteChange: false, ignoreInDevEnvironment: true });
                }
              }
            }
            else if (server.integrationCount > 0) {
              this.toastService.toastCreate("Last Data Sent not populated for Server " + server.serverId, "Warning", { autoClose: true, keepAfterRouteChange: false, ignoreInDevEnvironment: true });
            }
          }
        });
      },
      (error) => {
        this.dataLoading = false;
      }
    );
  }
}
 