import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { CompletedLoan } from '../models/completed-loan';
import { InvoicedLoan } from '../models/invoiced-loan';
import { protectedResources } from '../auth-config';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { InvoicePeriod } from '../models/invoice-period';
import { InvoicingReportLoan } from '../models/base-invoicing-loan';
import { InvoicePeriodMetrics } from '../models/invoice-period-metrics';
import { InvoicingDetails } from '../models/invoicing-details';
@Injectable()
export class InvoicingService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  /**
   * Get Completed Loans for Invoice Period
   * @param invoicePeriodId
   * @returns CompletedLoan[]
   */
  getAllCompletedLoansByClient(invoicePeriodId: string): Observable<CompletedLoan[]> {
    this.actionUrl = "CompletedLoans/";
    this.Url = this.baseUrl.concat(this.actionUrl, invoicePeriodId);
    return this.http
      .get<CompletedLoan[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  /**
   * Get Completed Loans for Invoice Period List
   * @param invoicePeriodId
   * @returns CompletedLoan[]
   */
   getAllCompletedLoansByClientForList(invoicePeriodIdList: string[]): Observable<CompletedLoan[]> {
    this.actionUrl = "CompletedLoans/List";
    this.Url = this.baseUrl.concat(this.actionUrl);

    var params = new HttpParams({
      fromObject: {'invoicePeriodIdList': invoicePeriodIdList}
    });

    return this.http
      .get<CompletedLoan[]>(this.Url, { params })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  /**
   * Get Invoiced Loans for Invoice Period
   * @param invoicePeriodId
   * @returns InvoicedLoan[]
   */
   getAllInvoicedLoansByClientForList(invoicePeriodIdList: string[]): Observable<InvoicedLoan[]> {
    this.actionUrl = "InvoicedLoans/List";
    this.Url = this.baseUrl.concat(this.actionUrl);

    var params = new HttpParams({
      fromObject: {'invoicePeriodIdList': invoicePeriodIdList}
    });

    return this.http
      .get<InvoicedLoan[]>(this.Url, { params })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  /**
   * Get Invoiced Loans for Invoice Period
   * @param invoicePeriodId
   * @returns InvoicedLoan[]
   */
  getAllInvoicedLoansByClient(invoicePeriodId: string): Observable<InvoicedLoan[]> {
    this.actionUrl = "InvoicedLoans/";
    this.Url = this.baseUrl.concat(this.actionUrl, invoicePeriodId);
    return this.http
      .get<InvoicedLoan[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Invoice Periods

  getAllInvoicePeriodsByClient(clientContractId: string): Observable<InvoicePeriod[]> {
    this.actionUrl = "InvoicePeriods/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);
    return this.http
      .get<InvoicePeriod[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getInvoicePeriodByInvoicePeriodId(invoicePeriodId: string): Observable<InvoicePeriod> {
    this.actionUrl = "InvoicePeriod/";
    this.Url = this.baseUrl.concat(this.actionUrl, invoicePeriodId);
    return this.http
      .get<InvoicePeriod>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getCurrentInvoicePeriodForClient(clientContractId: string): Observable<InvoicePeriod> {
    this.actionUrl = "InvoicePeriod/Current/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);
    return this.http
      .get<InvoicePeriod>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getPreviousBilledInvoicePeriodForClient(clientContractId: string): Observable<InvoicePeriod> {
    this.actionUrl = "InvoicePeriod/PreviousBilled/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);
    return this.http
      .get<InvoicePeriod>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getLastBilledInvoicePeriodForClient(clientContractId: string): Observable<InvoicePeriod> {
    this.actionUrl = "InvoicePeriod/LastBilled/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);
    return this.http
      .get<InvoicePeriod>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }


  /**
   * Get Invoicing Report Loans for Invoice Period
   * @param invoicePeriodId
   * @returns InvoicingReportLoan[]
   */
   getAllInvoicingReportLoansByClient(invoicePeriodId: string): Observable<InvoicingReportLoan[]> {
    this.actionUrl = "InvoicingReportLoans/";
    this.Url = this.baseUrl.concat(this.actionUrl, invoicePeriodId);
    return this.http
      .get<InvoicingReportLoan[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  /**
   * Get Invoicing Report Loans for Invoice Period List
   * @param invoicePeriodId
   * @returns InvoicingReportLoan[]
   */
   getAllInvoicingReportLoansByClientForList(invoicePeriodIdList: string[]): Observable<InvoicingReportLoan[]> {
    this.actionUrl = "InvoicingReportLoans/List";
    this.Url = this.baseUrl.concat(this.actionUrl);

    var params = new HttpParams({
      fromObject: {'iPIdList': invoicePeriodIdList}
    });

    return this.http
      .get<InvoicingReportLoan[]>(this.Url, { params })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getInvoicePeriodMetricsForPeriod(invoicePeriodId: string): Observable<InvoicePeriodMetrics> {
    this.actionUrl = "InvoicePeriodMetric/";
    this.Url = this.baseUrl.concat(this.actionUrl, invoicePeriodId);
    return this.http
      .get<InvoicePeriodMetrics>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getInvoicePeriodMetricsForClient(clientContractId: string): Observable<InvoicePeriodMetrics[]> {
    this.actionUrl = "InvoicePeriodMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);
    return this.http
      .get<InvoicePeriodMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getInvoicePeriodMetricsForClientAndDates(clientContractId: string, start: Date, end: Date): Observable<InvoicePeriodMetrics[]> {
    this.actionUrl = "InvoicePeriodMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, "GetByDateRange/", clientContractId);

    let tmp: InvoicingDetails = new InvoicingDetails({
      dateStart: start,
      dateEnd: end
    });

    return this.http
     .post(this.Url, tmp, {headers: this.headers})
     .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getInvoicePeriodMetricsForDestinationAndDates(destinationEndpointId: number, start: Date, end: Date, includeDisabled: boolean = true): Observable<InvoicePeriodMetrics[]> {
    this.actionUrl = "InvoicePeriodMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, "AllContracts/GetByDateRange/", destinationEndpointId.toString());

    let tmp: InvoicingDetails = new InvoicingDetails({
      dateStart: start,
      dateEnd: end,
      includeDisabled: includeDisabled
    });

    return this.http
     .post(this.Url, tmp, {headers: this.headers})
     .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getInvoicePeriodMetricsForList(invoicePeriodIdList: string[]): Observable<InvoicePeriodMetrics[]> {
    this.actionUrl = "InvoicePeriodMetrics/List";
    this.Url = this.baseUrl.concat(this.actionUrl);

    var params = new HttpParams({
      fromObject: {'invoicePeriodIdList': invoicePeriodIdList}
    });

    return this.http
      .get<InvoicePeriodMetrics[]>(this.Url, { params })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
