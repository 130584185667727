import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../models/user';
import { MonacoPageComponent } from '../monaco-editor/monaco-editor.component';
import { CustomSQLQueryService } from '../../services/sql-queries.service';
import { ToastService } from '../../services/toast.service';
import { CustomSQLQuery } from 'src/app/models/sql-query';
import { MonacoDiffEditorComponent } from '@materia-ui/ngx-monaco-editor';
import { MonacoDiffPageComponent } from '../monaco-diff-editor/monaco-diff-editor.component';
import { LOSTalkerTitleService } from "../../services/lostalker-title-service.service";
import { TypeConstantService } from 'src/app/services/type-constant.service';


@Component({
  selector: 'app-edit-sql-query',
  templateUrl: './edit-sql-query.component.html',
  styleUrls: ['./edit-sql-query.component.scss'],
  providers: [CustomSQLQueryService, LOSTalkerTitleService]
})
export class EditSQLQueryComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private customQueryService: CustomSQLQueryService,
    private typeConstantService: TypeConstantService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private location: Location,
    private user: User,
    private titleService: LOSTalkerTitleService,
    private router: Router
  ) { }

  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild('monaco') public child: MonacoPageComponent;
  customSQLQuery: CustomSQLQuery;
  convertingToGeneric: boolean = false;
  sub: any;
  name: string;
  genericName: string;
  sql: string = "sql";
  monacoData: string = 'sql';
  monacoOriginalData: string = '';
  pageLoading: boolean = true;
  convertToGeneric: boolean = true;
  savingInformation: boolean = false;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(_ => this.initializeMonacoComponent());
  }

  initializeMonacoComponent(): void {
    this.child?.changeLanguage("sql");
    this.sub = this.route.params.subscribe(params => {
      this.customQueryService.getCustomQueryById(params['id']).subscribe(query => {
        this.customSQLQuery = query;
        this.name = this.customSQLQuery.queryName;
        
        if (this.customSQLQuery != null) {
          this.titleService.setUpTitle('Edit Custom Query - ' + this.customSQLQuery.queryName);
        }

        this.monacoData = query.sqlString;  
        this.monacoOriginalData = query.sqlString;     
        this.pageLoading = false;
      }, error => {
        this.pageLoading = false;
      });
    });
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  goBack(): void {
    this.location.back();
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  updateDataModel($event) {
    this.monacoData = $event;
  }

  updateCustomQuery(): void {
    this.savingInformation = true;
    this.customSQLQuery.sqlString = this.monacoData;

    this.customQueryService.updateCustomQuery(this.customSQLQuery)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          // on success
          this.toastService.toastCreate("Successfully updated " + this.customSQLQuery.queryName + " custom SQL Query.", "Success", {
            autoClose: false
          });
          this.monacoOriginalData = this.customSQLQuery.sqlString;
          this.savingInformation = false;
        }, error => {
          this.savingInformation = false;
        });
  }

  deleteCustomQuery(): void {
    this.savingInformation = true;

    var confirmMsg =
      "Are you sure you want to delete the selected Custom SQL Query?";
    if (window.confirm(confirmMsg)) {
    this.customQueryService.deleteCustomQuery(this.customSQLQuery)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          // on success
          this.toastService.toastCreate("Successfully deleted " + this.customSQLQuery.queryName + " custom SQL Query.", "Success", {
            autoClose: false,
            keepAfterRouteChange: true
          });
          this.savingInformation = false;
          this.router.navigate(['/sql-queries']);
        }, error => {
          this.savingInformation = false;
      });
    }
    this.savingInformation = false;
  }
}
