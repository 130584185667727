import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, Input, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AppComponent } from '../../app.component';
import { Client } from '../../models/client';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { FastFetchDetails } from '../../models/fast-fetch-details';
import { Integration } from '../../models/integration';
import { IntegrationIdentifier } from '../../models/integration-identifier';
import { IntegrationInformation } from '../../models/integration-information';
import { Toast, ToastType } from '../../models/toast';
import { User } from '../../models/user';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { FastFetchService } from '../../services/fastfetch.service';
import { ToastService } from '../../services/toast.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';

const cleanLoanData = (data: LoanNumberData[]) => data.map((item) => item.loan.replace('\r', ''));
export class LoanNumberData {
  loan: string;
}

@Component({
  selector: 'app-fastfetch',
  templateUrl: './fastfetch.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./fastfetch.component.scss'],
  providers: [ClientIntegrationService, FastFetchService, TypeConstantService]
})
export class FastfetchComponent implements OnInit, OnDestroy {

  @ViewChild('loanProgressDialog', { static: true }) loanProgressDialog: TemplateRef<any>;
  @ViewChild('fastFetchInfoDialog', { static: true }) fastFetchInfoDialog: TemplateRef<any>;

  @Input() integration: Integration;
  @Input() isInTab: boolean = false;
  @Input() integrationInfo: IntegrationInformation[];
  gridData: any[] = [];
  integrationGridData: any[] = [];
  dataSourceTypeList: string[];
  loanNumbers: LoanNumberData[] = [];
  activeUser: User;

  progressBarValue = 60;
  progressBarBufferValue = 99;
  progressBarTheme: "progress-bar-components-theme";
  loansBeingProcessed = true;
  loansProcessedResponse = "";

  componentDestroyed$: Subject<boolean> = new Subject();
  public userEmailOverride: string = "";
  overrideEmail: boolean = false;
  validClient: boolean = false;
  clientNameList: string[];

  clientName: string;
  clients: Client[];

  clientsFiltered: Observable<Client[]>;
  clientFormControl = new UntypedFormControl();

  integrations: Integration[];

  fullIntegrationList: IntegrationInformation[];
  selectedIntegration: IntegrationInformation;

  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  }
  public pageSizes: number[] = [5, 10, 25, 50, 100];
  approvedDomains: string[] = ["ncino.com", "lbaware.com", "simplenexus.com"];

  gridView: GridDataResult;
  allGroup: IntegrationInformation[];
  allOriginalGroup: IntegrationInformation[];
  filteredGroup: IntegrationInformation[];
  filter: CompositeFilterDescriptor;

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  public formGroup: UntypedFormGroup;
  public loanFormGroup: UntypedFormGroup;

  allDestinations: DestinationEndpoint[];
  filteredDestinations: DestinationEndpoint[];
  public selectedDestination: DestinationEndpoint;
  destinationsFiltered: Observable<DestinationEndpoint[]>;
  destinationFormControl = new UntypedFormControl();
  hasDestinations = false;
  pageLoading = true;

  csFastFetchRoles: string[] = [
    "LOSTalker.Integration.CompenSafe.FastFetch",
    "LOSTalker.Integration.CompenSafe"
  ];

  lgFastFetchRoles: string[] = [
    "LOSTalker.Integration.LimeGear.FastFetch",
    "LOSTalker.Integration.LimeGear"
  ];

  constructor(
    private fastFetchService: FastFetchService,
    private toastService: ToastService,
    protected typeService: TypeConstantService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    user: User
  ) {
    this.state.sort = [{ field: "integrationName", dir: 'asc' }];
    this.createLoanFormGroup = this.createLoanFormGroup.bind(this);
    this.activeUser = user;
    this.filteredDestinations = [];
    this.selectedDestination = null;
  }

  ngOnInit(): void {
    this.getDestinations();

    if (this.integrationInfo !== undefined) {
      this.fullIntegrationList = this.integrationInfo;
      this.allGroup = this.fullIntegrationList;
      this.allOriginalGroup = this.fullIntegrationList;
      this.loadDataIntoGridView();

      if (this.integrationInfo.length == 1) {
        this.selectedIntegration = this.integrationInfo[0];
      }
    }

  }

  getDestinations(): void {
    this.typeService.getAllDestinationEndpoints()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(r => {
      this.allDestinations = r;
      this.processFilteredDestinations();
      this.pageLoading = false;
    },
    (error) => {
      this.pageLoading = false;
    });
  }

  processFilteredDestinations(): void {
    if (this.integrationInfo !== undefined) {
      if (this.selectedIntegration) {
        this.selectedDestination = this.allDestinations.find(x => x.destinationEndpointId == this.selectedIntegration.destinationId)[0];
        this.filteredDestinations.push(this.selectedDestination);
      }
      else {
        let destArr = this.integrationInfo.map(i => i.destinationId);
        this.filteredDestinations = this.allDestinations.filter(i => destArr.indexOf(i.destinationEndpointId) != -1);
      }
    }
    else {
      this.filteredDestinations = this.allDestinations.filter(d => d.fastFetchEnabled == true);
    }

    if (!this.activeUser.checkAccess(this.csFastFetchRoles)) {
      this.filteredDestinations = this.filteredDestinations.filter(d => d.endpointName !== "CSDataSwap");
    }

    if (!this.activeUser.checkAccess(this.lgFastFetchRoles)) {
      this.filteredDestinations = this.filteredDestinations.filter(d => d.endpointName !== "LGDataSwap");
    }

    if (!this.activeUser.checkAccess(["LOSTalker.Integration"])) {
      this.filteredDestinations = this.filteredDestinations.filter(d => d.endpointName !== "Surefire3");
    }

    if (this.filteredDestinations.length == 1) {
      this.selectedDestination = this.filteredDestinations[0];
    }
    else if(this.filteredDestinations.length == 0) {
      this.toastService.toastCreate("You do not have credentials to FastFetch loans.", "Warning", { autoClose: true });
    }

    this.hasDestinations = true;

    if (this.integrationInfo == undefined) {
      this.getAllFFInfo();
    }
  }

  formatDestinationName(destName: string): string {
    let name = destName;
    if (destName == 'LGDataSwap'){
      name = 'NexusVision';
    }
    else if (destName == 'CSDataSwap') {
      name = 'CompenSafe'
    }
    return name;
  }

  getAllFFInfo(): void {
    var endpointName: string = undefined;
    if (this.selectedDestination != null){
      endpointName = this.selectedDestination.endpointName;
    }

    this.fastFetchService.getAllFastFetchIntegrationInformation(endpointName)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(intList => {
        intList = intList.filter(i => i.serverId != "0");
        this.fullIntegrationList = intList.filter(i => i.locked == false);
        this.allGroup = this.fullIntegrationList;
        this.allOriginalGroup = this.fullIntegrationList;
        this.loadDataIntoGridView();
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  selectionChangeHandler(event: any): void {
    this.selectedIntegration = event.selectedRows[0].dataItem;

    this.filteredGroup = this.fullIntegrationList.filter(i => i.integrationId == this.selectedIntegration.integrationId);
    this.updateGrid();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };

  }

  // Sort
  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  changeClient(): void {
    this.selectedIntegration = undefined;
    this.filterChange(this.filter);
  }

  // functions for getting loans
  public createLoanFormGroup(args: any): UntypedFormGroup {
    const item = args.isNew ? new LoanNumberData() : args.dataItem;

    this.loanFormGroup = this.formBuilder.group({
      loan: [item.loan, Validators.required],
    });

    return this.loanFormGroup;
  }

  // processes pasted data from excel into grid
  public onExcelPaste(data: any[]): void {
    // check if first row is loan, loans, loan: or loans:
    if ((data[0]["loan"].toString().toLowerCase() == "loan")
      || (data[0]["loan"].toString().toLowerCase() == "loans")
      || (data[0]["loan"].toString().toLowerCase() == "loan:")
      || (data[0]["loan"].toString().toLowerCase() == "loans:")) {
      data.shift();
    }

    // check if last row is blank
    while (data[data.length - 1].loan.trim() == "") {
      data.pop();
    }

    var originalData = [];
    data.forEach(function (d) {
      if (d.loan) {
        d.loan = d.loan.replace('\r', '');
        d.loan = d.loan.replaceAll('"', '');
        originalData.push(d);
      }
    });

    if(originalData.length > 300){
      this.toastService.toastCreate("FastFetch requests can only accept a maximum of 300 loans through the UI", "Warning", { autoClose: true, keepAfterRouteChange: false });
    }
    else {
      this.gridData = originalData;
    }

  }

  public submitFastFetchRequest(e: Event): void {
    if (!this.selectedIntegration) {
      var s = "Invalid Integration Identifier.";
      this.toastService.toastCreate(s, "Warning", { autoClose: true, keepAfterRouteChange: false });
    }
    else {
      var details = new FastFetchDetails();

      details.identifier = new IntegrationIdentifier();
      details.identifier.integrationId = this.selectedIntegration.integrationId;
      details.identifier.destinationId = this.selectedIntegration.destinationId;
      details.identifier.destinationName = this.selectedIntegration.destinationName;
      details.identifier.identifierIdValue = this.selectedIntegration.identifierIdValue;
      details.identifier.identifierNameValue = this.selectedIntegration.identifierNameValue;
      details.identifier.identifierKeyValue = this.selectedIntegration.identifierKeyValue;
      details.identifier.createdDate = this.selectedIntegration.identifierCreatedDate;
      details.identifier.updatedDate = this.selectedIntegration.identifierUpdatedDate;
      details.identifier.canFastFetch = this.selectedIntegration.canFastFetch;
      details.identifier.canDataSwap = this.selectedIntegration.canDataSwap;
      details.identifier.subscriptionName = this.selectedIntegration.subscriptionName;

      let loanStrings = this.gridData.map(l => l.loan.trim());
      details.loanNumbers = loanStrings;
      details.application = this.selectedIntegration.destinationName;

      details.integration = new Integration(this.selectedIntegration);

      let email = this.activeUser.userName;
      if (this.overrideEmail) {
        if (this.emailFormControl.valid) {
          email = this.emailFormControl.value;
        }
        else {
          var s = "Override email is not valid, sending update to original users email address.";
          this.toastService.toastCreate(s, "Warning", { autoClose: true });
        }
      }

      details.email = email;

      let emailParts = details.email.split("@");
      let domain = emailParts[emailParts.length - 1].toLowerCase();

      if(this.approvedDomains.includes(domain))
      {
        this.loansBeingProcessed = true;
        this.openLoansProgressDialog();
        this.fastFetchService.postFastFetchLoans(details)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (data) => {
              this.toastService.toastCreate("Loans sent to be processed", "Success", { autoClose: true });
              this.loansProcessedResponse = "Loans sent to be processed";
              this.loansBeingProcessed = false;

            },
            (error) => {
              this.loansProcessedResponse = "FastFetch POST Failed:" + error.message;
              this.loansBeingProcessed = false;
            }
          );
      }
      else
      {
        var s = "Email is not within NCino domain.";
        this.toastService.toastCreate(s, "Warning", { autoClose: true });
      }



    }

  }

  public clearFastFetchLoanGrid(e: Event): void {
    this.gridData = [];
    this.loanNumbers = [];
  }

  public clearFastFetchFormData(e: Event): void {
    this.gridData = [];
    this.allGroup = [];
    this.integrations = [];
    this.loanNumbers = [];
    this.selectedIntegration = null;
    this.userEmailOverride = "";
    this.selectedDestination = null;
    this.destinationFormControl.setValue('');
  }

  displayFnClient(x: Client): string {
    return x && x.name ? x.name : '';
  }

  private filterClients(name: string): Client[] {
    const filterValue = name.toLowerCase();
    return this.clients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  loadDataIntoGridView() {
    let orderedData = orderBy(this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  changeOverrideEmailCheck(x: boolean) {
    this.overrideEmail = !this.overrideEmail;
  }

  openLoanInfoDialog() {
    this.dialog.open(this.fastFetchInfoDialog);
  }

  openLoansProgressDialog() {
    this.dialog.open(this.loanProgressDialog);
  }

  getSNEmail(email: string): string {
    var rc = email;
    if(email.indexOf("@lbaware.com") > -1){
      var emailName = email.split("@")[0];
      var nameParts = emailName.split(".");
      rc = nameParts[0].substring(0,1) + nameParts[1] + "@simplenexus.com"
    }

    return rc;
  }

  // DestinationEndpoints
  selectDestination(c: DestinationEndpoint): void {
    this.selectedIntegration = null;
    this.selectedDestination = c;

    this.filteredGroup = this.allOriginalGroup.filter(x => x.destinationId == this.selectedDestination.destinationEndpointId);
    this.allGroup = this.allOriginalGroup.filter(x => x.destinationId == this.selectedDestination.destinationEndpointId);
    this.state.skip = 0;
    this.updateGrid();

  }
}
