import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { State, DataSourceRequestState, orderBy, CompositeFilterDescriptor, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, SelectionEvent, RowArgs, DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { Observable, Subscription, Subject } from 'rxjs';
import { takeUntil, take } from "rxjs/operators";
import { ToastService } from '../../services/toast.service';
import { LosTokenService } from 'src/app/services/los-token.service';
import { LOSToken } from 'src/app/models/los-token';
import { LOSTokenMapping } from 'src/app/models/los-token-mapping';
import { LOSTokenData } from 'src/app/models/los-token-data';


@Component({
  selector: 'app-los-token-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './los-token-view.component.html',
  styleUrls: ['./los-token-view.component.scss'],
  providers: [LosTokenService]
})
export class LosTokenViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild(GridComponent) grid: GridComponent;

  gettingData: boolean = false;
  hasData: boolean = false;

  allGroup: LOSToken[] = [];
  filteredGroup: LOSToken[] = [];

  gridView: GridDataResult;
  gridViewIsLoading: boolean = true;

  selectedItem: LOSToken = null;
  selectedRow: RowArgs;
  selectedRows: RowArgs[] = [];
  public expandedDetailKeys: any[] = [];
  public expandDetailsBy = (dataItem: any): any => {
    return dataItem.losTokenId;
  };

  type: 'numeric' | 'input' = 'input';
  public pageSizes: number[] = [10, 25, 50, 100];
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };


  constructor(
    private toastService: ToastService,
    private lTService: LosTokenService
  ) {
    this.state.sort = [{field: 'dataSourceTypeId', dir: 'desc'}];
    this.filteredGroup = [];
  }

  ngOnInit(): void {
    this.getAllData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getAllData(): void {
    this.gridViewIsLoading = true;
    this.allGroup = [];
    this.filteredGroup = [];

    this.lTService.getLOSTokens()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          // process formatting
          data.forEach(x => {
            this.allGroup.push(new LOSToken(x));
          });

          this.filteredGroup = this.allGroup;
          // sort and setup grid view
          let orderedData = orderBy(this.allGroup, this.state.sort);
          this.gridView = {
            data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
            total: orderedData.length
          };
          this.gridViewIsLoading = false;
        }
        else {
          this.gridView = { data: [], total: 0};
          this.gridViewIsLoading = false;
        }
      }
    );
  }

  reloadActiveToken(event: any) {
    this.toastService.toastCreate("Reloading LOS Tokens.", "Info");
    this.expandedDetailKeys = [];
    this.getAllData();
  }

  //--------------------------- Grid Functions ------------------------------------------//

  updateGrid() {
    this.gridViewIsLoading = true;
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  pageChange(state: DataStateChangeEvent): void {
    this.gridViewIsLoading = true;
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridViewIsLoading = true;
    this.state.sort = sort;
    this.updateGrid();
  }

  selectionChange(event: SelectionEvent) {
    if (event.selectedRows.length > 0) {
      this.expandedDetailKeys = [];
      this.expandedDetailKeys.push(event.selectedRows[0].dataItem?.losTokenId);
    }
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.gridViewIsLoading = true;
    this.state.skip = 0;
    this.state.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, filter);
    this.updateGrid();
  }

}
