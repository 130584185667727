import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import { ClientContract } from '../models/client-contract';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { IntegrationMapping } from '../models/integration-mapping';

@Injectable()
export class ClientContractService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  private contract: Observable<ClientContract>;

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Client Information ------------------------------------------//

  getAllClientContractsByClient(clientContractId: number): Observable<ClientContract[]> {
    this.actionUrl = "ClientContracts/ClientId/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId.toString());

    return this.http
      .get<ClientContract[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllClientContract(): Observable<ClientContract[]> {
    this.actionUrl = "ClientContracts";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<ClientContract[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllClientContractWithInfo(): Observable<ClientContract[]> {
    this.actionUrl = "ClientContracts/WithInfo";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<ClientContract[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllClientContractWithInfoByDest(destination: string): Observable<ClientContract[]> {
    this.actionUrl = "ClientContracts/WithInfo/";
    this.Url = this.baseUrl.concat(this.actionUrl, destination);

    return this.http
      .get<ClientContract[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllDeletedClientContract(): Observable<ClientContract[]> {
    this.actionUrl = "ClientContracts/Deleted";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<ClientContract[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientContractByContractId(clientContractId: string): Observable<ClientContract> {
    this.actionUrl = "ClientContract/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);

    return this.http
      .get<ClientContract>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientContractWithInfoByContractId(clientContractId: string): Observable<ClientContract> {
    this.actionUrl = "ClientContract/WithInfo/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);

    return this.http
      .get<ClientContract>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientContractByIdAndDestination(losTalkerClientId: number, destination: string): Observable<ClientContract> {
    this.actionUrl = "ClientContract/Client/";
    this.Url = this.baseUrl.concat(this.actionUrl, losTalkerClientId.toString(), "/", destination);

    return this.http
      .get<ClientContract>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateClientContract(clientContract: ClientContract): Observable<any> {
    this.actionUrl = "ClientContract/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put(this.Url, clientContract, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertClientContract(clientContract: ClientContract): Observable<any> {
    this.actionUrl = "ClientContract/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .post(this.Url, clientContract)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  undeleteClientContract(clientContract: ClientContract): Observable<ClientContract> {
    this.actionUrl = "ClientContract/Undelete/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContract.clientContractId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<ClientContract>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteClientContract(clientContract: ClientContract): Observable<ClientContract> {
    this.actionUrl = "ClientContract/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContract.clientContractId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<ClientContract>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  setRecreateInvoicesForAuditPeriod(clientContractId: string, recreateInvoicesForAuditPeriod: boolean): Observable<ClientContract> {
    this.actionUrl = "ClientContract/UpdateRecInvForAP/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId.toString(), "/", recreateInvoicesForAuditPeriod.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<ClientContract>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getIntegrationMappingsByClientContractId(clientContractId: string): Observable<IntegrationMapping[]> {
    this.actionUrl = "IntegrationMapping/Contract/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientContractId);

    return this.http
      .get<ClientContract>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getIntegrationMappingsByIntegrationId(integrationId: string): Observable<IntegrationMapping[]> {
    this.actionUrl = "IntegrationMappings/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

    return this.http
      .get<IntegrationMapping[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllIntegrationMappings(): Observable<IntegrationMapping[]> {
    this.actionUrl = "IntegrationMappings/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationMapping[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateIntegrationMapping(model: IntegrationMapping): Observable<any> {
    this.actionUrl = "IntegrationMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put(this.Url, model, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertIntegrationMapping(model: IntegrationMapping): Observable<any> {
    this.actionUrl = "IntegrationMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .post(this.Url, model)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteIntegrationMapping(model: IntegrationMapping): Observable<IntegrationMapping> {
    this.actionUrl = "IntegrationMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl, model.integrationMappingId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<ClientContract>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
