import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { EditGenericEndpointsComponent } from '../components/edit-generic-endpoints/edit-generic-endpoints.component';

@Injectable()
export class EditGenericEndpointsGuard  {

    canDeactivate(component: EditGenericEndpointsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
          return component.canDeactivate() ? true :
            window.confirm("You are about to leave the page without saving your changes. Are you sure you want to leave this page?");
    }

}
