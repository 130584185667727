import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import { Report } from '../models/report';
import { ReportType } from '../models/report-type';
import { ReportConstants } from '../models/report-constants';
import { ClientContract } from "src/app/models/client-contract";
import { Integration } from '../models/integration'
import { IntegrationErrorMessage } from '../models/integration-error-message';
import { IntegrationErrorMessageFilter } from '../models/integration-error-message-filter';
import { BaseMapping } from '../models/base-mapping';
import { GenericEndpoint } from '../models/generic-endpoint';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { BaseEndpointMapping } from '../models/base-endpoint-mapping';
import { ChangeLog } from '../models/changelog';
import { IntegrationInformation } from '../models/integration-information';
import { ReportErrorSearchFilter } from '../models/report-error-search-filter';
import { IntegrationError } from '../models/integration-error';
import { LTHttpUtility } from '../utilities/http-Utility';
import { ReportChangeLogFilter } from '../models/report-changelog-filter';

@Injectable()
export class ReportService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Report Data ------------------------------------------//

  // Get Integrations for Report by Report Id
  public getIntegrationTypeQueryData(reportId: string): Observable<Integration[]> {
    this.actionUrl = "ReportData/Integration/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Integrations for Report by Report Id - Include JS data
  public getIntegrationTypeQueryDataWithJS(reportId: string): Observable<Integration[]> {
    this.actionUrl = "ReportData/Integration/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId, "/", "JS");
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get IntegrationErrorMessage data for Report by Report Id
  public getIntegrationErrorMessageTypeQueryData(reportId: string, filter: IntegrationErrorMessageFilter): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "ReportData/IntegrationErrorMessage/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<IntegrationErrorMessage[]>(this.Url, filter, { headers: this.headers})
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get IntegrationErrorIds for Report by Report Id
  public getIntegrationErrorIdsForReport(filter: ReportErrorSearchFilter): Observable<Map<string, Date>> {
    this.actionUrl = "ReportData/IntegrationError";
    this.Url = this.baseUrl.concat(this.actionUrl);
    let params = LTHttpUtility.formatParams(filter);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Map<string, Date>>(this.Url, { params: params, headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get IntegrationErrorMessage data for Report by Report Id
  public getIntegrationErrorQueryData(reportId: string, filter: ReportErrorSearchFilter): Observable<IntegrationError[]> {
    this.actionUrl = "ReportData/IntegrationError/";
    let params = LTHttpUtility.formatParams(filter);
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationError[]>(this.Url, { params: params, headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Total Count of IntegrationErrorMessage data for Report by Report Id
  public getIntegrationErrorMessageTypeQueryDataCount(reportId: string, filter: IntegrationErrorMessageFilter): Observable<number> {
    this.actionUrl = "ReportData/IntegrationErrorMessage/Count/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<number>(this.Url, filter, { headers: this.headers})
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Base Endpoint Mappings for Report by Report Id
  public getBaseEndpointMappingTypeQueryData(reportId: string): Observable<BaseEndpointMapping[]> {
    this.actionUrl = "ReportData/BaseEndpointMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<BaseEndpointMapping[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Generic Endpoints for Report by Report Id
  public getGenericEndpointTypeQueryData(reportId: string): Observable<GenericEndpoint[]> {
    this.actionUrl = "ReportData/GenericEndpoint/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<GenericEndpoint[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Base Mapping for Report by Report Id
  public getBaseMappingTypeQueryData(reportId: string): Observable<BaseMapping[]> {
    this.actionUrl = "ReportData/BaseMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<BaseMapping[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Client Contract for Report by Report Id
  public getClientContractTypeQueryData(reportId: string): Observable<ClientContract[]> {
    this.actionUrl = "ReportData/Contract/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ClientContract[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Base Endpoint Mappings for Report by Report Id
  public getIntegrationInformationTypeQueryData(reportId: string): Observable<IntegrationInformation[]> {
    this.actionUrl = "ReportData/IntegrationInformation/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationInformation[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  public getChangeLogTypeQueryData(filter: ReportChangeLogFilter): Observable<ChangeLog[]> {
    this.actionUrl = "ReportData/ChangeLog";
    let params = LTHttpUtility.formatParams(filter);
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ChangeLog[]>(this.Url, { params: params, headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  public getReportConstants(reportId: number): Observable<ReportConstants> {
    this.actionUrl = "ReportData/constants/";
    this.Url = this.baseUrl.concat(this.actionUrl, reportId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ReportConstants[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Report Types ------------------------------------------//

  // Get Report Types
  public getReportTypes(): Observable<ReportType[]> {
    this.actionUrl = "ReportTypes/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<ReportType[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Report Types by Report Type Id
  public getReportTypeById(rId: number): Observable<ReportType> {
    this.actionUrl = "ReportType/";
    this.Url = this.baseUrl.concat(this.actionUrl + rId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ReportType>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Reports ------------------------------------------//

  // Get All Reports
  public getReports(): Observable<Report[]> {
    this.actionUrl = "Reports/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Report[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get All Active Reports
  public getActiveReports(): Observable<Report[]> {
    this.actionUrl = "Reports/Active/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Report[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get All Active Admin Reports
  public getAdminReports(): Observable<Report[]> {
    this.actionUrl = "Reports/Admin/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Report[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Report By Id
  public getReportById(rId: string): Observable<Report> {
    this.actionUrl = "Report/";
    this.Url = this.baseUrl.concat(this.actionUrl, rId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Report>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Report By RouterUrl Value
  public getReportByRouterUrl(rU: string): Observable<Report> {
    this.actionUrl = "Report/Custom/";
    this.Url = this.baseUrl.concat(this.actionUrl, rU);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Report>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
