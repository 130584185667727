import { Integration } from '../models/integration';
import { IntegrationIdentifier } from '../models/integration-identifier';

export class FastFetchDetails {
  application: string;
  email: string;
  integration: Integration;
  identifier: IntegrationIdentifier;
  loanNumbers: string[];
}

