import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { DataResponse } from '../models/data-response';
import { IntegrationPushBack } from '../models/integration-pushback';

@Injectable()
export class IntegrationPushBackService {
    private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
    private actionUrl: string = '';
    private Url: string = '';
    private headers = new HttpHeaders();

    private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
      console.log(error);
      ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
        keepAfterRouteChange: true
      });

      if (environment.enableDebuggingTools) {
        console.error("An HTTP transport error occured: " + error.message);
      }

      return throwError(error);
    }

    constructor(private http: HttpClient, private toastService: ToastService) { }

    // GET
    getIntegrationPushBack(integrationId: string): Observable<IntegrationPushBack> {
        this.actionUrl = "IntegrationPushBack/";
        this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
        return this.http.get<IntegrationPushBack>(this.Url)
        .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // GET
    getActiveIntegrationPushBackByRunTime(integrationRunTimeId: string): Observable<IntegrationPushBack> {
        this.actionUrl = "IntegrationPushBack/";
        this.Url = this.baseUrl.concat(this.actionUrl, integrationRunTimeId);
        return this.http.get<IntegrationPushBack[]>(this.Url)
        .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // POST
    addIntegrationPushBack(integrationPushBack: IntegrationPushBack): Observable<string> {
        this.actionUrl = "IntegrationPushBack/";
        this.Url = this.baseUrl.concat(this.actionUrl);
        this.headers.append('Content-Type', 'application/json');
        return this.http
            .post<IntegrationPushBack>(this.Url, integrationPushBack, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // PUT
    updateIntegrationPushBack(integrationPushBack: IntegrationPushBack): Observable<any> {
        this.actionUrl = "IntegrationPushBack/"
        this.Url = this.baseUrl.concat(this.actionUrl);
        this.headers.append('Content-Type', 'application/json');
        return this.http
            .put<IntegrationPushBack>(this.Url, integrationPushBack, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // DELETE
    deleteActiveIntegrationPushBack(integrationRunTimeId: string): Observable<any> {
        this.actionUrl = "IntegrationPushBack/active/"
        this.Url = this.baseUrl.concat(this.actionUrl, integrationRunTimeId);
        // console.log(this.Url);
        return this.http
            .delete<IntegrationPushBack>(this.Url)
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // GET
    getAllIntegrationPushBacksByRunTime(integrationRunTimeId: string): Observable<IntegrationPushBack[]> {
        this.actionUrl = "IntegrationPushBacks/";
        this.Url = this.baseUrl.concat(this.actionUrl, integrationRunTimeId);
        return this.http
            .get<IntegrationPushBack[]>(this.Url)
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // DELETE
    deleteIntegrationPushBacks(integrationRunTimeId: string): Observable<any> {
        this.actionUrl = "IntegrationPushBacks/"
        this.Url = this.baseUrl.concat(this.actionUrl, integrationRunTimeId);
        // console.log(this.Url);
        return this.http
            .delete<IntegrationPushBack>(this.Url)
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // DELETE
    deleteOldIntegrationPushBacks(integrationRunTimeId: string, deleteBeforeDate: Date): Observable<any> {
        this.actionUrl = "IntegrationPushBacks/"
        this.Url = this.baseUrl.concat(this.actionUrl, integrationRunTimeId);
        // console.log(this.Url);
        return this.http
            .delete<IntegrationPushBack>(this.Url)
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

}
