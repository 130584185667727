<div class="m-1">
  <app-view-generic-grid
    [data]="filteredGroup"
    [columns]="contractColumns"
    (itemSelected)="getItemFromGenericGrid($event)"
    [exportFileName]="'Contracts'"
    [customExport]="false"
    [isResizeable]="true"
    [showExportToolbar]="true"
    [isSelectable]="true"
    [showSelectionColumn]="false"
    [showChangeSelection]="false"
    [selectionfield]="'clientContractId'"
    [sortDescriptions]="contractSort"
    [overrideSelectedItem]="gridSelectedItem"
    [loading]="gettingContracts"
    >
  </app-view-generic-grid>
</div>
<div *ngIf="showProdClientSwitch">
  <kendo-switch
    [(ngModel)]="showProdContractsOnly"
    [onLabel]="'Show Only Production Contracts'"
    [offLabel]="'Show All Contracts'"
    (valueChange)="updateShowProdContracts($event)"
    style="width: 15%; max-width: 225px;">
  </kendo-switch>
</div>
