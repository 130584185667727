import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Report } from '../../models/report';
import { ReportType } from 'src/app/models/report-type';
import { ReportService } from '../../services/report.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { ToastService } from 'src/app/services/toast.service';
import { Toast, ToastType } from 'src/app/models/toast';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./reports.component.scss'],
  providers: [ReportService]
})
export class ReportsComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  reportList: Report[] = [];
  selectedReport: Report;

  gettingReports: boolean = false;
  haveReports: boolean = false;

  constructor(
    protected reportService: ReportService,
    protected toastService: ToastService,
    private router: Router
    ){  }

  ngOnInit(): void {
    this.gettingReports = true;
    this.haveReports = false;
    this.reportService.getActiveReports()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (data) => {
        if (data != null) {
          data.forEach(r => {
            this.reportList.push(new Report(r));
          });
          this.haveReports = true;
        }
        else {
          this.toastService.toastCreate('No Reports Returned.', "Warning");
        }
        this.gettingReports == false;
      },
      (error) => {
        this.toastService.toastCreate('Reports failed to return.', "Danger");
        this.gettingReports == false;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public selectReport(r: Report): void {
    this.selectedReport = r;
    if (this.selectedReport.isCustomReport) {
      if (this.selectedReport?.routerUrl == '' || this.selectedReport?.routerUrl == undefined) {
        this.toastService.toastCreate("Report is Custom Report but Router URL not setup.", "Warning");
      }
      else {
        this.router.navigate([this.selectedReport?.routerUrl]);
      }
    }
    else {
      this.router.navigate(['/report-view/', this.selectedReport.reportId]);
    }

  }

}
