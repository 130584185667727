<ng-container *ngIf="isInTab == false">
  <h1 style="text-align:center;">Revision History</h1>
</ng-container>
<ng-container *ngIf="isInTab == true">
  <h4 style="text-align:center;">Revision History</h4>
</ng-container>
<div class="inline" style="padding: 10px;">
  <kendo-switch [(ngModel)]="historyChecked" [onLabel]="'XML'" [offLabel]="'JS'" (valueChange)="clearHistory()"></kendo-switch>
</div>

<ng-container *ngIf="historyChecked == true">
  <ng-container *ngIf="integrationHistoryLoading == true">
    <div style="margin: auto; margin-top:0%; text-align: center;">
      <div class="k-i-loading"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="integrationHistoryLoading == false">
    <mat-form-field appearance="fill" class="dropdown-med-width">
      <mat-label>Integration History</mat-label>
      <mat-select name="i" (selectionChange)="selectIntegrationHistory($event.value)">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let i of integrationHistory" [value]="i">
          {{ i.updatedDate | date: 'MM/dd/yyyy h:mm a'}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-container>

<ng-container *ngIf="historyChecked == false">
  <ng-container *ngIf="javaScriptHistoryLoading == true">
    <div style="margin: auto; margin-top:0%; text-align: center;">
      <div class="k-i-loading"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="javaScriptHistoryLoading == false">
    <mat-form-field appearance="fill" class="dropdown-med-width">
      <mat-label>Destination Endpoint</mat-label>
      <mat-select name="d" [formControl]="endpointFormControl" (selectionChange)="selectEndpointType($event.value)">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let d of destinationEndpoints" [value]="d">
          {{ d.endpointName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    Compare Current to:

    <mat-form-field appearance="fill" class="dropdown-med-width" *ngIf="selectedEndpoint">
      <mat-label>Javascript History Compare</mat-label>
      <mat-select name="i" (selectionChange)="selectJsIntegrationCompare($event.value)">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let i of filterJavascriptHistoryList(selectedEndpoint)" [value]="i">
          {{ i.integrationTypeId }} | {{ i.createDate | date: 'MM/dd/yyyy h:mm a' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

</ng-container>

<ng-template *ngIf="historyChecked; then int1 else int2"></ng-template>

<ng-template #int1>
  <div *ngIf="selectedIntegrationHistory && !integrationHistoryLoading"  class="monaco-outer">
    <div>
      <app-monaco-diff-editor #monacoHistory
                  [currentData]="historyRawData"
                  [historyData]="historyData"
                  [lang]="monacoLangHtml"
                  [historyTitle]="historySideTitle"
                  [currentTitle]="currentSideTitle">
      </app-monaco-diff-editor>
    </div>
    <div class="center-button">
      <button mat-raised-button
        *ngIf="checkAccess(['LOSTalker.DevOps.Write'])"
        (click)="updateIntegrationFromHistory('xml')">
        Revert to Selected History
      </button>
    </div>
  </div>
</ng-template>

<ng-template #int2>
  <div *ngIf="selectedJavascript && selectedJavascriptCompare && !javaScriptHistoryLoading" class="monaco-outer">
    <div>
      <app-monaco-diff-editor #monacoJSHistory
                  [currentData]="historyRawData"
                  [historyData]="historyData"
                  [lang]="monacoLangJs"
                  [historyTitle]="historySideTitle"
                  [currentTitle]="currentSideTitle">
      </app-monaco-diff-editor>
    </div>
    <div class="center-button">
      <button mat-raised-button
        *ngIf="checkAccess(['LOSTalker.DevOps.Write'])"
        (click)="updateIntegrationFromHistory('js')">
        Revert to Selected History
      </button>
    </div>
  </div>
</ng-template>