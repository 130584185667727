<div class="container-fluid rows-cols-1">
  <div class="col">
    <h1 class="title-center my-2" *ngIf="!fromLoansReport">Invoicing Metrics Report</h1>
    <h3 class="title-center my-4" *ngIf="fromLoansReport">Invoicing Metrics Report</h3>
  </div>
  <div class="col" *ngIf="!fromLoansReport && pageReady">
    <div class="match-form-field title-center my-2">
      <mat-button-toggle-group
          #group="matButtonToggleGroup"
          [multiple]="false"
          [hideSingleSelectionIndicator]="true"
          (change)="onToggleShowContractSelectViewChange($event.value)">
          <mat-button-toggle
            [checked]="showAllContractsView == true"
            value="true"
            aria-label="All Contracts">
            All Contracts
          </mat-button-toggle>
          <mat-button-toggle
            [checked]="showAllContractsView == false"
            value="false"
            aria-label="Select Contracts">
            Select Contracts
          </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="col" *ngIf="!fromLoansReport && pageReady && showAllContractsView">
    <div class="row-cols-1 my-2">
      <div class="col-2">
        <app-destinations-dropdown-view #destinationView
            (itemSelected)="getDestinationFromView($event)"
            (loadingFailed)="setFailedLoading($event)"
            [labelName]="'Application'">
        </app-destinations-dropdown-view>
      </div>
      <div class="col">
        <mat-checkbox [checked]="includeDisabled" (change)="changeIncludeDisabledCheck($event.checked)">
          Include Disabled Contracts
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col" *ngIf="!fromLoansReport && pageReady && !showAllContractsView">
    <app-invoicing-select-view #selectView
      (returnFailedLoading)="setFailedLoading($event)"
      [initClientContract]="selectedContract"
      (returnedContract)="getContractFromSelect($event)"
      (returnDestination)="getDestinationFromSelect($event)"
      (itemSelectedFromOverride)="getContractFromOverrideView($event)"
      (initContractError)="processInitContractError($event)"
      (clearToStart)="clearToStart($event)">
    </app-invoicing-select-view>
  </div>

  <div class="col" *ngIf="pageReady && selectedDestination && showDateSelectView">
    <app-invoicing-select-date-view
          [showDatePeriodSwitch]="false"
          [dateStringPrefix]="selectedDestination.endpointName"
          [useMonthYearOnly]="true"
          [selectedContract]="selectedContract"
          (updatedRange)="submitDateRange($event)">
        </app-invoicing-select-date-view>
  </div>

  <div class="col" *ngIf="showMetricsContainer && selectedDestination && !showAllContractsView && selectedContract">
    <h5 class="title-center my-2">
      {{selectedContract.clientFullName}} | {{selectedContract.identifierIdValue}} | {{selectedDestination.endpointName}}
    </h5>
  </div>

  <div class="col" *ngIf="showMetricsContainer && hasMetrics">
    <app-invoicing-metrics-grid
        [data]="allMetrics"
        [showAllContractsView]="showAllContractsView"
        [currentStartDate]="currentStartDate"
        [currentEndDate]="currentEndDate"
        [selectedContract]="selectedContract">
      </app-invoicing-metrics-grid>
  </div>

  <div class="col mt-5">
    <app-page-loading-view [loading]="!pageReady" [loadingFailed]="loadingFailed"></app-page-loading-view>
  </div>

  <div class="col" *ngIf="showMetricsContainer && !hasMetrics">
    <app-page-loading-view
      [loading]="gettingMetrics"
      [loadingFailed]="!gettingMetrics"
      [loadingFailedMessage]="
        showAllContractsView == true ?
        'No Metrics for selected Date Range or Period Found.' :
        'No Metrics for selected contract found.'">
    </app-page-loading-view>
  </div>

  <div class="col" *ngIf="fromLoansReport">
    <ng-container *ngIf="gettingMetrics"><div class="loading-space"></div></ng-container>
    <div class="d-flex m-auto" [ngClass]="{'my-4': gettingMetrics}">
      <button mat-raised-button (click)="goBackToLoansReport()" class="title-center m-auto my-2">Go Back to Loans Report</button>
    </div>

  </div>

</div>
