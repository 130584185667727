import { Integration } from "./integration";
import { IntegrationIdentifier } from "./integration-identifier";

export class IntegrationInformation {

    constructor(i?: Integration, ii?: IntegrationIdentifier) {
        this.integrationId = i?.integrationId;
        this.destinationId = ii?.destinationId;
        this.destinationName = ii?.destinationName;
        this.identifierIdValue = ii?.identifierIdValue;
        this.identifierNameValue = ii?.identifierNameValue;
        this.identifierKeyValue = ii?.identifierKeyValue;
        this.identifierCreatedDate = ii?.createdDate;
        this.identifierUpdatedDate = ii?.updatedDate;
        this.canFastFetch = ii?.canFastFetch;
        this.canDataSwap = ii?.canDataSwap;
        this.subscriptionName = ii?.subscriptionName;
        this.clientId = i?.clientId;
        this.clientName = i?.clientName;
        this.dataSourceType = i?.dataSourceTypeId;
        this.integrationName = i?.name;
        this.locked = i?.locked;
        this.serverId = i?.serverId;
        this.errorRetentionDays = i?.errorRetentionDays;
        this.selfHosted = i?.selfHosted;
    }

    toIntegration = function (): Integration {
        return {
            clientId: this.clientId,
            clientName: this.clientName,
            dataSourceTypeId: this.dataSourceType,
            locked: this.locked,
            serverId: this.serverId,
            integrationId: this.integrationId,
            name: this.integrationName,
            ipRestricted: this.ipRestricted,
            errorRetentionDays: this.errorRetentionDays,
            selfHosted: this.selfHosted
        };
    }

    toIdentifier = function (): IntegrationIdentifier {

        return {
            integrationId: this.integrationId,
            destinationId: this.destinationId,
            destinationName: this.destinationName,
            identifierIdValue: this.identifierIdValue,
            identifierNameValue: this.identifierNameValue,
            identifierKeyValue: this.identifierKeyValue,
            createdDate: this.identifierCreatedDate,
            updatedDate: this.identifierUpdatedDate,
            canFastFetch: this.canFastFetch,
            canDataSwap: this.canDataSwap,
            subscriptionName: this.subscriptionName
        }
    }

    integrationId: string;
    destinationId: number;
    destinationName: string;
    identifierIdValue: string;
    identifierNameValue: string;
    identifierKeyValue: string;
    identifierCreatedDate: Date;
    identifierUpdatedDate: Date;
    canFastFetch: boolean;
    canDataSwap: boolean;
    subscriptionName: string;
    clientId: number;
    clientName: string;
    dataSourceType: string;
    integrationName: string;
    locked: boolean;
    serverId: string;
    errorRetentionDays: number;
    selfHosted: boolean;
}
