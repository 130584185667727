import {
    Component,
    Input,
    OnInit
  } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { stringify } from "querystring";
import { forkJoin, Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Client } from "src/app/models/client";
import { ClientInformation } from "src/app/models/client-information";
import { Integration } from "src/app/models/integration";
import { SearchTerm } from "src/app/models/search-term";
import { User } from "src/app/models/user";
import { ClientInformationService } from "src/app/services/client-information.service";
import { ClientIntegrationService } from "src/app/services/client-integration.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: 'app-edit-client',
  templateUrl: "./edit-client.component.html",
  styleUrls: ['./edit-client.component.scss'],
  providers: [ ClientIntegrationService ]
})

export class EditClientComponent implements OnInit {

  @Input() client: Client;
  @Input() integrations: Integration[];
  clientInformation: ClientInformation;

  clients: Client[];

  activeUser: User;
  mergeClient: Client;
  mergeClientSelected: boolean = false;
  mergeClientIntegrations: Integration[];

  clientName: string;
  clientIntegrationAbbrName: string;
  compensafeInternalName: string;
  aliases: string;

  myControl = new UntypedFormControl();
  filteredOptions: Observable<Client[]>;

  finishedLoading: boolean = false;
  mergingClient: boolean = false;
  editingClient: boolean = false;
  mergeClientConfirm: boolean = false;
  viewingClient: boolean = true;
  addingClientInfo: boolean = false;
  updateClientInfo: boolean = true;

  aliasSearchTerm: SearchTerm;

  constructor(
    private clientIntegrationService: ClientIntegrationService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private router: Router,
    private user: User,
    private clientInformationService: ClientInformationService
  ) {
    this.activeUser = user;
    this.aliasSearchTerm = new SearchTerm('', 'Search by Client Name or Alias');
  }

  ngOnInit(): void {

    this.clientName = this.client.name;
    this.clientIntegrationAbbrName = this.client.clientIntegrationAbbrName;
    this.compensafeInternalName = this.client.compensafeInternalName;
    this.aliases = this.client.aliases;

    forkJoin({
      clients: this.clientIntegrationService.getAllClients(),
      info: this.clientInformationService.getClientInformationByClientId(this.client.clientId)
    }).subscribe(({clients, info}) => {
      this.clients = clients;
      this.clientInformation = info;

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.clients.slice())
      );

      this.finishedLoading = true;
    });

  }
  //Utility Methods Start
  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }

  setEditing() {
    this.viewingClient = false;
    this.editingClient = true;
  }

  setMerging() {
    this.viewingClient = false;
    this.mergingClient = true;
  }

  cancel() {
    this.mergingClient = false;
    this.editingClient = false;
    this.clientName = this.client.name;
    this.clientIntegrationAbbrName = this.client.clientIntegrationAbbrName;
    this.compensafeInternalName = this.client.compensafeInternalName;
    this.aliases = this.client.aliases;
    this.mergeClientSelected = false;
    this.mergeClientConfirm = false;
    this.viewingClient = true;
    this.updateClientInfo = true;
  }

  updateClient() {
    if(!this.checkValidFields()) {
      this.toastService.toastCreate("Invalid Input: This client name is already in use.", "Warning", {
        autoClose: false
      });
    }

    if(this.updateClientInfo && (this.clientName != this.client.name || this.compensafeInternalName != this.client.compensafeInternalName))
    {
      this.clientInformation.clientFullName = this.client.name;
      this.clientInformation.clientShortName = this.client.compensafeInternalName ?? this.client.name;

      this.clientInformationService.updateClientInformation(this.clientInformation).subscribe();
    }

    this.client.name = this.clientName;
    this.client.clientIntegrationAbbrName = this.clientIntegrationAbbrName;
    this.client.compensafeInternalName = this.compensafeInternalName;
    this.client.aliases = this.aliases;

    this.clientIntegrationService.updateClient(this.client).subscribe(() => {
      this.router.navigate(['/editConfig', this.client.name])
    });
  }

  displayClientName(client : Client): string {
    return client && client.name ? client.name : '';
  }

  checkValidFields(): boolean {
    if(this.clientName == this.client.name){
      //client name is unchanged, so return true for existing valid name
      return true;
    }

    for (let c of this.clients) {
      if (c.name === this.clientName) {
        return false;
      }
    }

    return true;
  }

  private _filter(value: string): Client[] {
    const filterValue = value.toLowerCase();

    return this.clients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public ngOnDestroy(): void {}

  public selectMergeClient(client: Client) {

    this.mergeClient = client;

    this.clientIntegrationService.getIntegrationsByClient(client.name)
      .subscribe(
        integrations => {
          this.mergeClientIntegrations = integrations;
      })

    this.mergeClientSelected = true;

  }

  public submit() {

    if(this.client == this.mergeClient) {
      this.toastService.toastCreate("Invalid Input: The clients are the same.", "Warning", {
        autoClose: false
      });
      return;
    }

    for(let clientOneIntegration of this.integrations) {
      for(let clientTwoIntegration of this.mergeClientIntegrations) {
        if(clientOneIntegration.name == clientTwoIntegration.name) {
          this.toastService.toastCreate("Invalid Input: The clients have a matching integration name: " + clientOneIntegration.name + ".", "Warning", {
            autoClose: false
          });
          return;
        }
      }
    }

    this.mergingClient = false;
    this.mergeClientConfirm = true;
  }

  mergeClients() {
    this.clientIntegrationService.mergeClients(this.client, this.mergeClient)
      .subscribe(() => {
        this.toastService.toastCreate("Clients merged.", "Success", {
          autoClose: false,
          keepAfterRouteChange: true
        });

        this.router.navigate(["/editConfig", this.mergeClient.name])
      }
    );
  }

  addClientInformation() {
    this.addingClientInfo = true;
    let clientInformation: ClientInformation = {
      clientFullName: this.client.name,
      clientShortName: this.client.compensafeInternalName ?? "",
      losTalkerClientId: this.client.clientId
    }

    this.clientInformationService.insertClientInformation(clientInformation).subscribe(() => {
      this.clientInformation = clientInformation;
      this.addingClientInfo = false;
    })
  }
}
