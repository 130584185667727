<h1 style="text-align:center;">Generic Endpoints</h1>
<br />


  <kendo-grid [data]="gridView"
              [pageSize]="state.take"
              [skip]="state.skip"
              [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
              [sortable]="{mode: 'single',allowUnsort: false}"
              [sort]="state.sort"
              [scrollable]="'scrollable'" 
              [selectable]="true"
              [filter]="filter"
              [filterable]="true"
              (sortChange)="sortChange($event)"
              (selectionChange)="selectionChangeHandler($event)"
              (pageChange)="pageChange($event)"
              (filterChange)="filterChange($event)">

    <kendo-grid-column field="name" title="Endpoint Name" [width]="200">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true"
                                           [column]="column"
                                           [filter]="filter">
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="importType" title="Import Type" [width]="200">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true"
                                           [column]="column"
                                           [filter]="filter">
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="destination" title="Destination Endpoint" [width]="200">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true"
                                           [column]="column"
                                           [filter]="filter">
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200" [filterable]="false">
    </kendo-grid-column>
    <kendo-grid-column field="genericEndpointId" title="Go To" [width]="100" [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [routerLink]="['/editGenericEndpoint', dataItem.genericEndpointId]" target="_blank">
            <button mat-flat-button color="primary" (click)="stopPropagation($event)">Navigate</button>
          </a>
        </ng-template>
    </kendo-grid-column>


    <!-- <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage"> -->
    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="[5, 10, 25, 50, 100]"></kendo-pager-page-sizes>
    </ng-template>

    <ng-template kendoGridNoRecordsTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading && gridView">No records found.</div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridView">Loading failed.</div>
        </div>
    </ng-template>

  </kendo-grid>
