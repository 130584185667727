<div class="undelete-container">
  <div style="height: 5%;">
    <h2 class="title-center">Undelete Clients and Integrations</h2>
  </div>

  <!-- <div class="center-toggle-group" style="display: flex; justify-content: center; align-items: center;"> -->
  <div class="center-toggle-group undelete-toggle-group pb-2">
    <mat-button-toggle-group
      #group="matButtonToggleGroup"
      [hideSingleSelectionIndicator]="true"
      (change)="onToggleActiveClientChange($event.value)">
      <mat-button-toggle
        value="true"
        aria-label="Active Clients"
        [checked]="showActiveClients">
          Active Clients
      </mat-button-toggle>
      <mat-button-toggle
        value="false"
        aria-label="Deleted Clients"
        [checked]="!showActiveClients">
          Deleted Clients
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>


  <ng-container *ngIf="!clientSelected">

    <div *ngIf="showActiveClients == true">
      <mat-form-field appearance="fill" class="med-wide-text-box" >
        <mat-label>Active Client</mat-label>
        <input type="text"
          matInput
          [matAutocomplete]="auto"
          [formControl]="clientFormControl">
        <mat-autocomplete autoActiveFirstOption
            #auto="matAutocomplete"
            (optionSelected)="selectClient($event.option.value)"
            [displayWith]="displayFnClient">
          <mat-option *ngFor="let option of clientsFiltered | async" [value]="option" >
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div *ngIf="showActiveClients == false">
      <mat-form-field appearance="fill" class="med-wide-text-box" >
        <mat-label>Inactive Client</mat-label>
        <input type="text"
          matInput
          [matAutocomplete]="auto2"
          [formControl]="inactiveClientFormControl">
        <mat-autocomplete autoActiveFirstOption
            #auto2="matAutocomplete"
            (optionSelected)="selectClient($event.option.value)"
            [displayWith]="displayFnInactiveClient">
          <mat-option *ngFor="let option of inactiveClientsFiltered | async" [value]="option" >
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

  </ng-container>

  <ng-container *ngIf="clientSelected">
    <div class="client-title">
      <h4>Client: {{selectedClient.name}}</h4>
      <button class="btn client-button"
              (click)="editClient()">
              <i class="bi bi-pencil"></i>
      </button>
      <!-- <button class="btn"
              style="float: right; padding-left: 5%; padding-bottom: 5px; padding-top: 0px;"
              (click)="editClient()">
              <i class="bi bi-pencil"></i>
      </button> -->
    </div>

    <ng-container *ngIf="hasIntegrations">
      <kendo-grid [data]="gridView"
                  [selectable]="true"
                  (selectionChange)="selectionChangeHandler($event)"
                  [rowClass]="isDisabled"
                  [sort]="sortDesc"
                  [sortable]="{mode: 'single',allowUnsort: false}"
                  (sortChange)="sortChange($event)">
        <kendo-grid-column field="clientName" title="Selected" [width]="90"
          [style]="{'text-align': 'center'}" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="selectedIntegration">
              <span *ngIf="dataItem.integrationId == selectedIntegration.integrationId" style="text-align: center;">
                <i class="bi bi-star-fill"></i>
              </span>
            </span>
            <span *ngIf="dataItem.deleted == false" style="text-align: center;">
              ( Current )
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="integrationId" title="Integration Id"></kendo-grid-column>
        <kendo-grid-column field="name" title="Integration Name" [width]="170"></kendo-grid-column>
        <kendo-grid-column field="updatedDate" title="Updated Date" [width]="170">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedDate | date :'MM/dd/yyyy h:mm a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dataSourceTypeId" title="DataSource" [width]="150"></kendo-grid-column>
        <kendo-grid-column field="serverId" title="Server" [width]="80"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}">
        </kendo-grid-column>
        <kendo-grid-column field="locked" title="Locked?" [width]="90"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.locked == false">No</span>
            <span *ngIf="dataItem.locked == true">Yes</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="deleted" title="Deleted?" [width]="90"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.deleted == false">No</span>
            <span *ngIf="dataItem.deleted == true">Yes</span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>

      <div class="center-button-group" style="display: flex; justify-content: center; align-items: center; padding-top: 10px;">
        <button mat-raised-button [disabled]="!selectedIntegration" (click)="clickUndeleteButton($event)">Undelete Selected Integration</button>
      </div>
    </ng-container>

    <ng-container *ngIf="hasNoIntegrations && !showActiveClients">
      <div class="center-button-group" style="display: flex; justify-content: center; align-items: center; padding-top: 10px;">
        Client has no integrations.<br>
      </div>
      <div class="center-button-group" style="display: flex; justify-content: center; align-items: center; padding-top: 10px;">
        <button mat-raised-button (click)="clickUndeleteClientButton($event)">Undelete Client</button>
      </div>
    </ng-container>

    <ng-container *ngIf="hasNoIntegrations && showActiveClients && !showGoToRevisionHistory">
      <div class="center-button-group" style="display: flex; justify-content: center; align-items: center; padding-top: 10px;">
        Client has no integrations.<br>
      </div>
      <div class="center-button-group" style="display: flex; justify-content: center; align-items: center; padding-top: 10px;">
        <button mat-raised-button (click)="clickAddIntegration($event)">Add Integration</button>
      </div>
    </ng-container>

    <ng-container *ngIf="showGoToRevisionHistory && showActiveClients">
      <div class="center-button-group" style="display: flex; justify-content: center; align-items: center; padding-top: 10px;">
        All integrations have active versions. Use Revison History.<br>
      </div>
      <div class="center-button-group" style="display: flex; justify-content: center; align-items: center; padding-top: 10px;">
        <button mat-raised-button (click)="clickGoToClient($event)">Go To Client</button>
      </div>
    </ng-container>

  </ng-container>

</div>

<div style="margin: auto; margin-top:0%; text-align: center;" *ngIf="pageLoading">
  <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:0%; text-align: center;" *ngIf="!pageLoading && !activeClients">
  <div class="no-records-message">Loading failed.</div>
</div>
