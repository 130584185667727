import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientInformation } from 'src/app/models/client-information';
import { ClientInformationService } from 'src/app/services/client-information.service';

import { Client } from '../../models/client';

import { ClientIntegrationService } from '../../services/client-integration.service';

import { ToastService } from '../../services/toast.service';


@Component({
    selector: 'app-add-client',
    templateUrl: './add-client.component.html',
    styleUrls: ['./add-client.component.scss'],
    providers: [ ClientIntegrationService ]
})
export class AddClientComponent implements OnInit {

    client: Client = {
        clientId: 0,
        name: "",
        clientIntegrationAbbrName: "",
        compensafeInternalName: "",
        dataSourceTypeAgg: ""
    };

    clients: Client[];
    invalidInput: boolean = false;
    savingClient: boolean = false;
    clientsLoading: boolean = true;
    clientInfoAdd: boolean = true;

    constructor(
        private clientIntegrationService: ClientIntegrationService, 
        private clientInformationService: ClientInformationService,
        private toastService: ToastService, 
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getClients();
    }
 
    getClients(): void {
        this.clientIntegrationService.getAllClients().subscribe(
            clients =>  {
                this.clients = clients
                this.clientsLoading = false;
            },
            error => {
                this.clientsLoading = false;
            }
        );
    }

    checkValidFields(): boolean {
        for (let c of this.clients)
        {
            if (c.name === this.client.name)
            {
                return false;
            }
        }

        return true;
    }

    goToIntegration(): void {
        this.router.navigate(['/addIntegration', this.client.name]);
    }

    saveClient(): void {
        this.savingClient = true;
        if (this.checkValidFields()) {
            // send the new client
            this.clientIntegrationService.addClient(this.client, this.clientInfoAdd)
                .subscribe(() => {
                    this.goToIntegration();
                });

            this.toastService.toastCreate("Successfully added client.", "Success", {
                keepAfterRouteChange: true
            });
        }
        else
        {
            this.toastService.toastCreate("Invalid Input: This client name is already in use.", "Warning", {
                autoClose: false
            });
            this.savingClient = false;
        }
    }
}
