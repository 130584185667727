import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IntegrationMetrics } from '../models/integration-metrics';
import { LoanMetrics } from '../models/loan.metrics';

import { protectedResources } from '../auth-config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';

@Injectable()
export class MetricService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Integration Metrics ------------------------------------------//

  // GET Active Integration Metrics
  getActiveMetric( integrationId: string, integrationTypeId: string ): Observable<IntegrationMetrics> {
    this.actionUrl = "IntegrationMetrics/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      integrationId,
      "/",
      integrationTypeId
    );
    this.headers.append("Content-Type", "application/json");
    return this.http
      .get<IntegrationMetrics>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration Metrics Lifetime
  getLifetimeMetrics(integrationId: string): Observable<IntegrationMetrics[]> {
    this.actionUrl = "IntegrationMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");
    return this.http
      .get<IntegrationMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration Metrics Lifetime
  getAllLoanMetrics(integrationId: string): Observable<LoanMetrics[]> {
    this.actionUrl = "LoanMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");
    return this.http
      .get<LoanMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
