import { Component, Input } from "@angular/core";
import { BaseFilterCellComponent, FilterService } from "@progress/kendo-angular-grid";
import { FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";

const flatten = filter => {
  const filters = (filter || {}).filters;
  if (filters) {
      return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
    selector: "dropdown-filter-cell",
    styleUrls: ["./dropdown-cell-filter.component.scss"],
    templateUrl: "./dropdown-cell-filter.component.html",
})
export class DropDownFilterCellComponent extends BaseFilterCellComponent {
    public get selectedValue(): unknown {
      const filter = this.filterByField(this.valueField);
      return filter ? filter.value : null;
    }
    @Input() public filter: any;
    @Input() public data: unknown[];
    @Input() public textField: string;
    @Input() public valueField: string;

    private cellFilters: any[] = [];

    public get defaultItem(): { [Key: string]: unknown } {
      return {
        [this.textField]: "Select item...",
        [this.valueField]: null,
      };
    }

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(values: any[], filterService: FilterService): void {
      filterService.filter({
        filters: values.map(value => ({
            field: this.valueField,
            operator: 'eq',
            value
        })),
        logic: 'or'
      });
    }


    public dataFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
      this.cellFilters.splice(
          0, this.cellFilters.length,
          ...flatten(filter).map(({ value }) => value)
        );
      return this.cellFilters;
  }
}
