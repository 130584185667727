import { DataSourceType } from '../models/data-source-type';
import { ImportType } from '../models/import-type';
import { DestinationEndpoint } from '../models/destination-endpoint';

export class DefaultConfigurationPlaceholder {
  defaultConfigurationPlaceholderId: string;
  parentId?: string = null;
  destinationEndpointId: number;
  endpointName: string;
  dataSourceTypeId: string;
  importTypeId: number;
  typeDescription: string;
  xmlElementConfig: string;
  isGeneric?: boolean = null;
  updatedDate?: string;
  name: string;
  comments?: string = null;
  children?: DefaultConfigurationPlaceholder[];
}

export class DefaultConfigUtils {
  currentNode: DefaultConfigurationPlaceholder;
  children?: DefaultConfigurationPlaceholder[];
  flags?: any;

  base?: DefaultConfigurationPlaceholder;
  baseDoc?: any;
  domParser: DOMParser;
  xmlSer: XMLSerializer;

  constructor() {
    this.domParser = new DOMParser();
    this.xmlSer = new XMLSerializer();
  }

  // quick check to see who has kids
  hasKids(value: DefaultConfigurationPlaceholder, allConfigs: DefaultConfigurationPlaceholder[]): boolean {
    return allConfigs.some(x => x.parentId == value.parentId);
  }

  getStart(allConfigs: DefaultConfigurationPlaceholder[]): DefaultConfigurationPlaceholder {
    return allConfigs.find(x => x.parentId == '00000000-0000-0000-0000-000000000000');
  }


  filterGroup(data: DefaultConfigurationPlaceholder[], ds?: DataSourceType,
    imp?: ImportType, de?: DestinationEndpoint, isG?: boolean): DefaultConfigurationPlaceholder[] {
    let tmp = data;

    // filter on data source type
    if (ds != null) {
      tmp = tmp.filter(x =>
        x.dataSourceTypeId == ds.dataSourceTypeId
        || x.dataSourceTypeId == null
        || x.dataSourceTypeId == ''
      );
    }
    // filter on import type
    if (imp != null) {
      tmp = tmp.filter(x =>
        x.importTypeId == imp.importTypeId
        || x.importTypeId == null
        || x.importTypeId == undefined
        || x.importTypeId == 0
      );
    }
    // filter on destination
    if (de != null) {
      tmp = tmp.filter(x =>
        x.destinationEndpointId == de.destinationEndpointId
        || x.destinationEndpointId == null
        || x.destinationEndpointId == undefined
        || x.destinationEndpointId == 0
      );
    }
    // filter on is generic
    if (isG != null) {
      tmp = tmp.filter(x =>
        x.isGeneric == isG
        || x.isGeneric == null
      );
    }

    return tmp;

  }

  setUpBase(data: DefaultConfigurationPlaceholder[]) {
    let root;
    const idMapping = data.reduce((acc, el, i) => {
      acc[el.defaultConfigurationPlaceholderId] = i;
      return acc;
    }, {});

    data.forEach(el => {
      el.children = [];
    });

    data.forEach(el => {
      if (el.parentId == '00000000-0000-0000-0000-000000000000') {
        root = el;
        return;
      }
      const parentEl = data[idMapping[el.parentId]];
      if(parentEl == null)
      {
        return;
      }
      parentEl.children.push(el);
    });
    this.base = root;
    return root;
  }

  // recursivly runs to build document, appending children to parent and then
  buildStringFromBase() {
    this.baseDoc = this.domParser.parseFromString(this.base.xmlElementConfig, 'text/xml');
    this.base.children.forEach(c => {
      this.buildStringFromBaseChild(this.baseDoc, c);
    });
    return this.xmlSer.serializeToString(this.baseDoc);

  }

  buildStringFromBaseChild(p: Document, curr: DefaultConfigurationPlaceholder) {
    var cd = this.domParser.parseFromString(curr.xmlElementConfig, 'text/xml');
    curr.children.forEach(c => {
      this.buildStringFromBaseChild(cd, c);
    });
    p.documentElement.append(cd.documentElement);
  }

  formatXMLString(tmpStr: string): string {
    let reg = new RegExp(/(>)(<)(\/*)/g);
    let wsexp = new RegExp(/ *(.*) +\n/g);
    let contexp = new RegExp(/(<.+>)(.+\n)/g);
    tmpStr = tmpStr.replace(reg, '$1\r\n$2$3').replace(wsexp, '$1\n').replace(contexp, '$1\n$2');
    let pad = 0;
    var formatted = '';
    var lines = tmpStr.split('\n');
    var indent = 0;
    var lastType = 'other';
    var transitions = {
      'single->single': 0,
      'single->closing': -1,
      'single->opening': 0,
      'single->other': 0,
      'closing->single': 0,
      'closing->closing': -1,
      'closing->opening': 0,
      'closing->other': 0,
      'opening->single': 1,
      'opening->closing': 0,
      'opening->opening': 1,
      'opening->other': 1,
      'other->single': 0,
      'other->closing': -1,
      'other->opening': 0,
      'other->other': 0
    };

    for (var i = 0; i < lines.length; i++) {
      var ln = lines[i];
      var single = Boolean(ln.match(/<.+\/>/)); // is this line a single tag? ex. <br />
      var closing = Boolean(ln.match(/<\/.+>/)); // is this a closing tag? ex. </a>
      var opening = Boolean(ln.match(/<[^!].*>/)); // is this even a tag (that's not <!something>)
      var type = single ? 'single' : closing ? 'closing' : opening ? 'opening' : 'other';
      var fromTo = lastType + '->' + type;
      lastType = type;
      var padding = '';

      indent += transitions[fromTo];
      for (var j = 0; j < indent; j++) {
        // padding += '\t';
        padding += '  ';
      }
      if (fromTo == 'opening->closing')
        formatted = formatted.substr(0, formatted.length - 1) + ln + '\n'; // substr removes line break (\n) from prev loop
      else
        formatted += padding + ln + '\n';
    }

    return formatted;

  }

}
