import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { EditBaseMappingComponent } from '../components/edit-base-mapping/edit-base-mapping.component';

@Injectable()
export class EditBaseMappingGuard  {

    canDeactivate(component: EditBaseMappingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
          return component.canDeactivate() ? true :
            window.confirm("You are about to leave the page without saving your changes. Are you sure you want to leave this page?");
    }

}
