import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { ToastService } from '../../services/toast.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-destinations-dropdown-view',
  templateUrl: './destinations-dropdown-view.component.html',
  styleUrls: ['./destinations-dropdown-view.component.scss'],
  providers: [TypeConstantService]

})
export class DestinationsDropdownViewComponent implements OnInit, OnDestroy, OnChanges {
  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() destinations: DestinationEndpoint[];
  @Output() itemSelected = new EventEmitter<any>();
  @Output() loadingFailed = new EventEmitter<any>();
  @Input() labelName: string = "Destinations";

  @Input() overrideEndpointId: number = null;
  @Output() itemSelectedFromOverride = new EventEmitter<any>();

  allDestinations: DestinationEndpoint[];
  public selectedItem: DestinationEndpoint;
  destinationsFiltered: Observable<DestinationEndpoint[]>;
  destinationFormControl = new UntypedFormControl();
  hasDestinations = false;

  constructor(
    protected typeService: TypeConstantService,
    protected toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.getDestinations();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.overrideEndpointId != null && changes?.overrideEndpointId?.currentValue != null) {
      // console.log(changes);
      if (changes?.overrideEndpointId?.currentValue != null && changes?.overrideEndpointId.currentValue != undefined) {
        if (this.hasDestinations && this.allDestinations != null) {
          let d = this.allDestinations.find(x => x.destinationEndpointId == this.overrideEndpointId);
          if (d != null) {
            this.selectDestinationForOverride(d);
          }
        }
        else {
          this.getDestinations(this.overrideEndpointId);
        }
      }
    }
  }

  clearWithoutReturn(): void {
    this.selectedItem = null;
    this.destinationFormControl.setValue('');
  }

  getDestinations(x: number = null): void {
    this.typeService.getAllDestinationEndpoints()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(r => {
      this.loadingFailed.emit(false);
      this.allDestinations = r;
      this.hasDestinations = true;
      this.destinationsFiltered = this.destinationFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.fullName),
        map(name => name ? this.filterDestinations(name) : this.allDestinations.slice())
      );

      if (x != null && x != undefined) {
        let d = this.allDestinations.find(x => x.destinationEndpointId == this.overrideEndpointId);
        this.selectDestinationForOverride(d);
      }
    },
    (error) => {
      this.loadingFailed.emit(true);
    });
  }

  selectDestination(c: DestinationEndpoint): void {
    this.selectedItem = c;
    this.itemSelected.emit(this.selectedItem);
  }

  selectDestinationForOverride(c: DestinationEndpoint): void {
    this.selectedItem = c;
    this.itemSelectedFromOverride.emit(this.selectedItem);
  }

  displayFnDestination(x: DestinationEndpoint): string {
    return x && x.endpointName ? x.endpointName : '';
  }

  private filterDestinations(name: string): DestinationEndpoint[] {
    const filterValue = name.toLowerCase();
    return this.allDestinations.filter(option => option.endpointName.toLowerCase().includes(filterValue));
  }

  editDestination(): void {
    this.selectedItem = null;
    this.destinationFormControl.setValue('');
    this.itemSelected.emit('');
  }

}
