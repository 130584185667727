import { Client } from '../models/client';
import { Server } from '../models/server';
import { DestinationEndpoint } from '../models/destination-endpoint';
import { ImportType } from '../models/import-type';
import { DataSourceType } from '../models/data-source-type';
import { IntegrationType } from './integration-type';
import { Integration } from '../models/integration';
import { ReportType } from './report-type';

export class Report {
  public reportId: string;
  public reportTypeId: number;
  public isCustomReport: boolean;
  public isAdminReport: boolean;
  public title: string;
  public description: string;
  public routerUrl?: string;
  public createdDate: Date;
  public updatedDate: Date;
  public dataSproc?: string;
  public hasFilterCards: boolean;
  public hasGoToButton: boolean;
  public hasSearch: boolean;
  public tableVersion: number;
  public exportFileName: string;
  public disabled: boolean;
  public reportTypeName?: string;

  public reportType?: ReportType;


  constructor(init?: Partial<Report>, rT: ReportType = null) {
    this.reportId = init.reportId;
    this.reportTypeId = init.reportTypeId;
    this.isCustomReport = init.isCustomReport;
    this.isAdminReport = init.isAdminReport;
    this.title = init.title;
    this.description = init.description;
    this.routerUrl = init?.routerUrl ?? null;
    this.dataSproc = init?.dataSproc ?? null;
    this.hasFilterCards = init.hasFilterCards;
    this.hasGoToButton = init.hasGoToButton;
    this.hasSearch = init.hasSearch;
    this.tableVersion = init.tableVersion;
    this.exportFileName = init.exportFileName;
    this.disabled = init.disabled;
    this.reportTypeName = init?.reportTypeName ?? null;

    if (init?.createdDate != null) {
      this.createdDate = new Date(init.createdDate)
    }

    if (init?.updatedDate != null) {
      this.updatedDate = new Date(init.updatedDate)
    }

    if (rT != null) {
      this.reportType = rT;
    }

  }
}

export class ReportData {
  servers?: Server[] = [];
  dataSourceTypes?: DataSourceType[] = [];
  destinationEndpoints?: DestinationEndpoint[] = [];
  importTypes?: ImportType[] = [];
  integrationTypes?: IntegrationType[] = [];
  integrationData?: Integration[] = [];
  clientData?: Client[] = [];
}
