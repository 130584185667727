import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { State, DataSourceRequestState, SortDescriptor, CompositeFilterDescriptor, orderBy, filterBy } from '@progress/kendo-data-query';
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
  PageChangeEvent
} from '@progress/kendo-angular-grid';

import { Observable, Subscription } from 'rxjs';

import { GenericEndpoint } from '../../models/generic-endpoint';
import { BaseMappingService } from '../../services/base-mapping.service';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-generic-endpoints',
  templateUrl: './generic-endpoints.component.html',
  styleUrls: ['./generic-endpoints.component.scss'],
  providers: [BaseMappingService]
})
export class GenericEndpointsComponent implements OnInit, OnDestroy {

  // Grid data variables
  gridMappings: GridDataResult;
  gridView: GridDataResult;
  gridViewIsLoading = true;
  filteredGroup: GenericEndpoint[];
  filter: CompositeFilterDescriptor;
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  // Constants
  type: 'numeric' | 'input' = 'input';
  listSettings: any;
  allGroup: GenericEndpoint[];

  constructor(protected bmService: BaseMappingService, private user: User, private router: Router) {

    this.listSettings = {
      singleSelection: true,
      enableSearchFilter: true,
      showCheckbox: false,
      labelKey: 'clientId',
      primaryKey: 'identifier',
      position: top,
      escapeToClose: true
    };

    this.gridMappings = {
      data: [],
      total: 0
    }

    this.bmService.getGenericEndpoints().subscribe((r) => {
      this.allGroup = r;
      this.loadDataIntoGridView();
    }, error => {
      this.gridViewIsLoading = false;
    });
  }

  ngOnInit() {

  }

  public ngOnDestroy(): void {
  }

  selectionChangeHandler(event: any): void {
    let selectedGenericEndpoint = event.selectedRows[0].dataItem;
    if (this.checkAccess(["LOSTalker.BaseMapping"])) {
      this.router.navigate(['/editGenericEndpoint', selectedGenericEndpoint.genericEndpointId]);
    }
  }

  checkAccess(role: string[]): boolean {
    //User requires DevOps.Write or Admin

    return this.user.checkAccess(role);
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  loadDataIntoGridView() {
    this.state.sort = [{ field: "updatedDate", dir: 'desc' }];
    let orderedData = orderBy(this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };

  }

  // Sort

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
