<div class="row flex-grow-1">
    <div class="col-6">
        <div class="card search-card h-70">
            <div class="card-body">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">Log Message</span>
                    </div>
                    <input [disabled]="isSnapShot" type="text" class="form-control searchBox1"
                        placeholder="{{getLogPlaceholder()}}" [(ngModel)]="errorSearchFilter.logMessage">
                </div>
            </div>
        </div>
    </div>
    <div class="col-2" style="font-size: 18px;" *ngIf="categories != undefined">
        <mat-form-field appearance="fill" style="min-width: -webkit-fill-available; height: 100%;">
            <mat-label>Select a category</mat-label>
            <mat-select [(ngModel)]="errorSearchFilter.errorCategoryId"
                (selectionChange)="updateCategoryFilter($event.value)">
                <mat-option *ngFor="let category of categories" [value]="category.errorCategoryId">
                    {{category.categoryName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-2" style="font-size: 18px;" *ngIf="categories != undefined">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Number Of Days To Pull:</mat-label>
            <input matInput [(ngModel)]="errorSearchFilter.numberOfDays">
        </mat-form-field>
    </div>
    <div class="col-2 h-100" *ngIf="categories != undefined">
        <div class="row" style="margin-bottom: 10px;">
            <button kendoButton style="width: 95%; height: 100%; font-size: 15px;" (click)="updateFilters()">Filter From
                Database</button>
        </div>
        <div class="row" style="margin-bottom: 10px;">
            <button kendoButton style="width: 95%; height: 100%; font-size: 15px;" (click)="clearFilters()">Clear Grid
                Filters</button>
        </div>
    </div>
</div>
