<h1 mat-dialog-title *ngIf="viewingContract">Client Contracts</h1>

<h3 mat-dialog-title *ngIf="disablingContract">Disable Client Contract</h3>
<h3 mat-dialog-title *ngIf="enablingContract">Enable Client Contract</h3>
<h3 mat-dialog-title *ngIf="editingContract">Edit Client Contract:</h3>
<h3 mat-dialog-title *ngIf="addingContract">Add Client Contract:</h3>
<h3 mat-dialog-title *ngIf="reprocessingInvoicePeriods">Reprocess Invoices For Audit Period:</h3>
<div mat-dialog-content>

    <ng-container *ngIf="!viewingContract">
        <h3 *ngIf="!contractsLoading && integrationIdentifiers.length == 0">No identifiers exist. Add an identifier to
            the client's integration to add contracts.</h3>
        <h3 *ngIf="!contractsLoading && !clientInfo">No client information exist. Add client information through the
            edit client option.</h3>
    </ng-container>

    <ng-container *ngIf="viewingContract">
        <div class="row row-cols-1 row-cols-lg-2" *ngIf="!contractsLoading">
            <div *ngFor="let c of clientContracts" class="col mb-4" (click)="selectContract(c)">
                <div class="card">
                    <div class="card-header bg-secondary text-white"
                        style="display: flex; align-items: center; vertical-align: middle; justify-content: space-between;">
                        <p style="margin-bottom: 0px; float:left">Identifier Name: {{c.identifierNameValue}}</p>
                    <div>
                        <button *ngIf="c.recreateInvoicesForAuditPeriod" matTooltip="Currently Reprocessing Invoices For Audit Period"
                            class="btn" style="float: right; width:20px; color: white;">
                        <i style="color: yellow; " class="bi bi-exclamation-circle-fill"></i>
                        </button>
                        <button *ngIf="c.productionInvoiceReport" matTooltip="Is a Production Invoice Report" class="btn px-1" style="color: #00ff00;">
                          <i class="bi bi-lock-fill"></i>
                        </button>
                        <button *ngIf="!c.productionInvoiceReport" matTooltip="Is not a Production Invoice Report" class="btn px-1" style="color: #CCCFD2;" >
                          <i class="bi bi-unlock-fill"></i>
                        </button>
                    </div>
                    </div>
                    <div class="card-body w-85" style="width:100%!important">
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-6">Identifier ID</div>
                            <div class="col">{{c.identifierIdValue}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-6">Destination Endpoint</div>
                            <div class="col">{{getDestinationEndpointString(c.destinationEndpointId)}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-6">Mapped Integration</div>
                            <div class="col">{{getMappedIntegrationsDisplay(c.integrationMappings)}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-6">Disabled</div>
                            <div class="col">{{c.disabled}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-4">Start Date</div>
                            <div class="col text-center">{{c.contractStartDate | date :'MM/dd/yyyy'}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-4">Term Date</div>
                            <div class="col text-center">{{c.contractTermDate | date :'MM/dd/yyyy'}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                          <div class="col-4">Reinstated Date</div>
                          <div class="col text-center">{{c.contractReinstatedDate | date :'MM/dd/yyyy h:mm a'}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-4">Updated Date</div>
                            <div class="col text-center">{{c.updatedDate | date :'MM/dd/yyyy h:mm a'}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-6">Salesforce Id</div>
                            <div class="col">{{c.salesForceId}}</div>
                        </div>
                        <div class="row" style="margin-bottom: 5px">
                            <div class="col-6">SF Customer Number</div>
                            <div class="col">{{c.sfCustomerNumber}}</div>
                        </div>

                        <div class="edit-buttons">
                            <button mat-raised-button (click)="setEditing(c)"
                                [disabled]="!checkAccess(['LOSTalker.Admin'])">
                                Edit
                            </button>
                            <button mat-raised-button (click)="setDisabling(c)"
                                [disabled]="!checkAccess(['LOSTalker.Admin']) || c.disabled == true">
                                Disable
                            </button>
                            <button mat-raised-button (click)="setEnabling(c)"
                                [disabled]="!checkAccess(['LOSTalker.Admin']) || c.disabled == false">
                                Enable
                            </button>
                            <button mat-raised-button (click)="goToLoans(c)">
                                Go to Loans
                            </button>
                        </div>
                        <div class="edit-buttons">
                            <button mat-raised-button (click)="addMapping(c)"
                                [disabled]="isCurrentIntegrationMapped(c)">
                                Map to Selected Integration
                            </button>
                            <button mat-raised-button (click)="setReprocessInvoicePeriods(c)"
                                [disabled]="c.integrationMappings == null || c.integrationMappings.length == 0 || c.recreateInvoicesForAuditPeriod == true || c.productionInvoiceReport == true">
                                Reprocess Invoices For Audit Period
                            </button>
                            <button mat-raised-button (click)="goToMetrics(c)">
                                Go to Metrics
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="k-i-loading" *ngIf="contractsLoading"></div>
        <div class="no-records-message" *ngIf="!contractsLoading && clientContracts.length == 0">No records found.</div>
        <div class="no-records-message" *ngIf="!contractsLoading && !clientContracts">Loading failed.</div>
    </ng-container>

    <ng-container *ngIf="addingContract || editingContract || disablingContract || enablingContract || reprocessingInvoicePeriods">
        <div>
            <mat-grid-list style="height: 700px" cols="3" *ngIf="selectedContract.integrationMappings != null && (addingContract || editingContract)">
                <mat-grid-tile style="height: 100%">
                    <div style="padding-top: 50px; padding-bottom: 50px">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Invoice Day</mat-label>
                            <input matInput type="number" [(ngModel)]="selectedContract.invoiceDayOfMonth">
                        </mat-form-field>
                        <br>

                        Production Invoice
                        <br>
                        <kendo-switch [(ngModel)]="selectedContract.productionInvoiceReport"></kendo-switch>
                        <br>
                        <br>

                        Use First Business Day
                        <br>
                        <kendo-switch [(ngModel)]="selectedContract.useFirstBusinessDay"></kendo-switch>
                        <br>
                        <br>

                        Recreate Invoices For Audit Period
                        <br>
                        <kendo-switch
                            [(ngModel)]="selectedContract.recreateInvoicesForAuditPeriod"
                            [disabled]="selectedContract.integrationMappings != null && selectedContract.integrationMappings.length > 0"
                        ></kendo-switch>
                        <br>
                        <br>

                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Comments</mat-label>
                            <textarea matInput [(ngModel)]="selectedContract.comments"></textarea>
                        </mat-form-field>
                        <br>

                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Current Completed Field</mat-label>
                            <textarea matInput [(ngModel)]="selectedContract.currentCompletedField"></textarea>
                        </mat-form-field>
                        <br>
                        <ng-container *ngIf="selectContract">
                          <div class="my-2">
                            <p class="my-0" *ngIf="!editingContractTermDate">Contract Term Date</p>
                            <p class="my-0" *ngIf="selectedContract.contractTermDate">Original: {{selectedContract.contractTermDate | date :'MM/dd/yyyy h:mm a'}}</p>
                            <p class="my-0" *ngIf="!selectedContract.contractTermDate">Original: null</p>

                            <kendo-datetimepicker *ngIf="editingContractTermDate"
                              placeholder="Enter a date and time"
                              format="MM/dd/yyyy hh:mm:ss a"
                              [disabled]="!editingContractTermDate"
                              [fillMode]="'solid'"
                              [rounded]="'small'"
                              [(value)]="selectedContract.contractTermDate">
                            </kendo-datetimepicker>

                            <br *ngIf="editingContractTermDate" />
                            <mat-checkbox *ngIf="editingContractTermDate"
                              [(ngModel)]="deleteContractTermDate">
                              <span class="checkbox-text-display">Remove Contract Term Date</span>
                            </mat-checkbox>

                            <div class="submit" *ngIf="!editingContractTermDate">
                              <button *ngIf="!selectedContract.contractTermDate" id="btnAddContractTermDate" mat-raised-button
                                  (click)="editingContractTermDate=true"
                                  [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
                                  Add Contract Term Date
                              </button>
                              <button *ngIf="selectedContract.contractTermDate" id="btnAddContractTermDate" mat-raised-button
                                  (click)="editingContractTermDate=true"
                                  [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
                                  Edit Contract Term Date
                              </button>
                            </div>

                          </div>

                        </ng-container>

                    </div>
                </mat-grid-tile>
                <mat-grid-tile style="height: 100%">
                    <div style="padding-top: 50px; padding-bottom: 50px">
                      <ng-container *ngIf="selectedContract">
                        <div class="mb-2">
                          <p class="my-0" *ngIf="!editingContractReinstatedDate">Contract Reinstated Date</p>
                          <p class="my-0" *ngIf="selectedContract.contractReinstatedDate">Original: {{selectedContract.contractReinstatedDate | date :'MM/dd/yyyy h:mm a'}}</p>
                          <p class="my-0" *ngIf="!selectedContract.contractReinstatedDate">Original: null</p>

                          <kendo-datetimepicker *ngIf="editingContractReinstatedDate"
                            placeholder="Enter a date and time"
                            format="MM/dd/yyyy hh:mm:ss a"
                            [disabled]="!editingContractReinstatedDate"
                            [fillMode]="'solid'"
                            [rounded]="'small'"
                            [(value)]="selectedContract.contractReinstatedDate">
                          </kendo-datetimepicker>

                          <br *ngIf="editingContractReinstatedDate" />
                          <mat-checkbox *ngIf="editingContractReinstatedDate"
                            [(ngModel)]="deleteContractReinstatedDate">
                            <span class="checkbox-text-display">Remove Contract Reinstated Date</span>
                          </mat-checkbox>

                          <div class="submit" *ngIf="!editingContractReinstatedDate">
                            <button *ngIf="!selectedContract.contractReinstatedDate" id="btnAddContractReinstatedDate" mat-raised-button
                                (click)="editingContractReinstatedDate=true"
                                [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
                                Add Contract Reinstated Date
                            </button>
                            <button *ngIf="selectedContract.contractReinstatedDate" id="btnAddContractReinstatedDate" mat-raised-button
                                (click)="editingContractReinstatedDate=true"
                                [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
                                Edit Contract Reinstated Date
                            </button>
                          </div>
                        </div>

                        <div class="my-2">
                          <p class="my-0">Original: {{selectedContract.contractStartDate | date :'MM/dd/yyyy'}}</p>
                          <kendo-datepicker #contractStartDateDP
                            style="width: 210px"
                            placeholder="Contract Start Date"
                            format="MM/dd/yyyy"
                            [disabled]="!editingContractStartDate"
                            [fillMode]="'solid'"
                            [rounded]="'small'"
                            [size]="'large'"
                            [(value)]="selectedContract.contractStartDate">
                          </kendo-datepicker>
                        </div>

                        <div class="my-2">
                          <p class="my-0">Original: {{selectedContract.billingStartDate | date :'MM/dd/yyyy'}}</p>
                          <kendo-datepicker #billingStartDateDP
                            style="width: 210px"
                            placeholder="Billing Start Date"
                            format="MM/dd/yyyy"
                            [disabled]="!editingBillingStartDate"
                            [fillMode]="'solid'"
                            [rounded]="'small'"
                            [size]="'large'"
                            [(value)]="selectedContract.billingStartDate">
                          </kendo-datepicker>
                        </div>
                      </ng-container>

                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Identifer</mat-label>
                            <mat-select [(ngModel)]="contractIdentifier" (selectionChange)="selectNewIdentifier()"
                                [disabled]="availableIdentifiers.length == 0">
                                <mat-option *ngFor="let identifier of availableIdentifiers"
                                    [value]="identifier">{{identifier.integrationName}} -
                                    {{getDestinationEndpointString(identifier.destinationId)}} -
                                    {{identifier.identifierIdValue}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <br>

                        <mat-form-field class="example-full-width" appearance="fill" width="350">
                            <mat-label>Identifier Name</mat-label>
                            <input matInput [(ngModel)]="selectedContract.identifierNameValue">
                        </mat-form-field>
                        <br>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Identifier ID</mat-label>
                            <input matInput [(ngModel)]="selectedContract.identifierIdValue">
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Salesforce ID</mat-label>
                            <input matInput [(ngModel)]="selectedContract.salesForceId">
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>SF Customer Number</mat-label>
                            <input matInput [(ngModel)]="selectedContract.sfCustomerNumber">
                        </mat-form-field>

                    </div>
                </mat-grid-tile>
                <mat-grid-tile style="height: 100%">
                    <div class="integrationMappingList">
                        <mat-label><h4 mat-dialog-title>Mapped Integrations</h4></mat-label>
                        <div *ngFor="let intMapping of selectedContract.integrationMappings">
                            <mat-label style="padding-right: 10px;">{{getIntegrationName(intMapping.integrationId)}}</mat-label>
                            <button *ngIf="selectedContract" mat-raised-button
                                (click)="deleteIntegrationMapping(intMapping)"
                                [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
                                Delete Mapping
                            </button>
                            <hr/>
                        </div>
                        <br/>
                        <mat-label>
                            <h4 mat-dialog-title>Client Information</h4>
                        </mat-label>
                        <mat-label style="padding-right: 10px;"><b>Client Contract ID:</b></mat-label>
                        <span>{{selectedContract.clientContractId}}</span><hr />
                        <mat-label style="padding-right: 10px;"><b>Client Information ID:</b></mat-label>
                        <span>{{selectedContract.clientInformationId}}</span><hr />
                        <mat-label style="padding-right: 10px;"><b>Client Full Name:</b></mat-label>
                        <span>{{clientInfo.clientFullName}}</span><hr />
                        <mat-label style="padding-right: 10px;"><b>Client Short Name:</b></mat-label>
                        <span>{{clientInfo.clientShortName}}</span><hr />
                        <mat-label style="padding-right: 10px;"><b>Contract Destination:</b></mat-label>
                        <span>{{getDestinationEndpointString(selectedContract.destinationEndpointId)}}</span>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <ng-container *ngIf="disablingContract || enablingContract || reprocessingInvoicePeriods">
            <span>Identifier: {{selectedContract.identifierNameValue}} -
                {{getDestinationEndpointString(selectedContract.destinationEndpointId)}} -
                {{selectedContract.identifierIdValue}}</span>
            <br />
            <span>Contract Start Date: {{selectedContract.contractStartDate | date :'MM/dd/yyyy'}}</span>
            <br />
            <span>Billing Start Date: {{selectedContract.billingStartDate | date :'MM/dd/yyyy'}}</span>
            <br />
            <span>Comments: {{selectedContract.comments}}</span>
            <br />
        </ng-container>

    </ng-container>

</div>
<div mat-dialog-actions>
    <ng-container *ngIf="viewingContract">
        <button mat-raised-button (click)="setAdding()"
            [disabled]="availableIdentifiers.length == 0  || !checkAccess(['LOSTalker.Admin'])">
            <span matTooltip="All Identifiers IDs have an associated contract."
                [matTooltipDisabled]="!(availableIdentifiers.length == 0)">Add</span>
        </button>
    </ng-container>
    <ng-container *ngIf="addingContract">
        <button id="btnSaveIntegration" mat-raised-button (click)="addClientContract()"
            [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
            Save Client Contract
        </button>
    </ng-container>
    <ng-container *ngIf="editingContract">
        <button id="btnSaveIntegration" mat-raised-button (click)="updateClientContract()"
            [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
            Update Client Contract
        </button>
    </ng-container>

    <ng-container *ngIf="disablingContract">
        <button id="btnSaveIntegration" mat-raised-button (click)="disableContract()"
            [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
            Disable Client Contracteditident
        </button>
    </ng-container>

    <ng-container *ngIf="enablingContract">
        <button id="btnSaveIntegration" mat-raised-button (click)="enableContract()"
            [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
            Enable Client Contract
        </button>
    </ng-container>

    <ng-container *ngIf="reprocessingInvoicePeriods">
        <button id="btnSaveIntegration" mat-raised-button (click)="reprocessInvoicePeriods()"
            [disabled]="savingClientContract || !checkAccess(['LOSTalker.Admin'])">
            Reprocess Invoice Periods
        </button>
    </ng-container>

    <ng-container *ngIf="addingContract || editingContract || disablingContract || enablingContract || reprocessingInvoicePeriods">
        <button mat-raised-button (click)="cancel()">
            Cancel
        </button>
    </ng-container>

</div>
