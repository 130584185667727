import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { protectedResources } from '../auth-config';
import { ClientConfigurationSecret } from '../models/client-config-secret';
import { ConfigurationPath } from '../models/configuration-path';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ClientConfigurationSecretComparison } from '../models/client-config-secret-comparison';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { SecretType } from '../models/secret-type';
@Injectable()
export class ClientConfigurationSecretService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Configuration Path ------------------------------------------//

  getConfigurationPaths(): Observable<ConfigurationPath[]> {
    this.actionUrl = "ConfigurationPaths/"
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<ConfigurationPath[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Client Configuration Secrets ------------------------------------------//

  getClientConfigSecretByIntegration(integrationId: string): Observable<ClientConfigurationSecret[]> {
    this.actionUrl = "CCSList/"
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

    return this.http
      .get<ClientConfigurationSecret[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientConfigSecretByClient(clientId: number): Observable<ClientConfigurationSecret[]> {
    this.actionUrl = "CCSList/ByClient/"
    this.Url = this.baseUrl.concat(this.actionUrl, clientId.toString());

    return this.http
      .get<ClientConfigurationSecret[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientConfigSecret(clientConfigSecretId: string): Observable<ClientConfigurationSecret> {
    this.actionUrl = "CCS/"
    this.Url = this.baseUrl.concat(this.actionUrl, clientConfigSecretId);

    return this.http
      .get<ClientConfigurationSecret>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientConfigSecretComparison(compareSecret: ClientConfigurationSecret, secretMatches: ClientConfigurationSecret[]) : Observable<Map<string, boolean>> {
    let comparisonObj = new ClientConfigurationSecretComparison();
    comparisonObj.compareSecret = compareSecret;
    comparisonObj.secrets = secretMatches;

    this.actionUrl = "CCS/Compare/"
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put<Map<string, boolean>>(this.Url, comparisonObj)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  addClientConfigSecret(clientConfigurationSecret: ClientConfigurationSecret): Observable<any> {
    this.actionUrl = "CCS/"
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .post<ClientConfigurationSecret>(this.Url, clientConfigurationSecret)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateClientConfigSecret(clientConfigSecret: ClientConfigurationSecret[]) {
    this.actionUrl = "CCSList/"
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http.put<ClientConfigurationSecret[]>(this.Url, clientConfigSecret)
    .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  migrateClientConfigSecret(clientConfigSecret: ClientConfigurationSecret): Observable<ClientConfigurationSecret> {
    this.actionUrl = "CCS/Migrate/"
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http.put<ClientConfigurationSecret>(this.Url, clientConfigSecret)
    .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
