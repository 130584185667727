<div *ngIf="selectedConfig">
  <h2 class="title-center my-2">Add Default Config</h2>

  <div class="controls-container">

    <div class="top-controls-container">
      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="nameFormControl" placeholder="Ex. CSV-Generic-Loan" >
            <mat-error *ngIf="nameFormControl.hasError('checkNotInList')">
              Name must not already exist.
            </mat-error>
            <mat-error *ngIf="nameFormControl.hasError('required')">
              Name is required.
            </mat-error>
            <mat-error *ngIf="nameFormControl.hasError('maxLength')">
              Name must be less then 100 characters.
            </mat-error>
            <mat-error *ngIf="nameFormControl.hasError('pattern')">
              Name must only consist of alpha-numeric characters, underscores or dashes.
            </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Parent Element</mat-label>
          <mat-select
            [(value)]="selectedParentConfig"
            (selectionChange)="parentConfigChange($event)">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let option of allData" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

    </div>

    <div class="top-controls-container">

      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Data Source Type</mat-label>
          <mat-select
            [(value)]="selectedDataSourceType"
            (selectionChange)="dataSourceTypeChange($event)">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let option of dataSourceTypes" [value]="option">
              {{option.dataSourceTypeId}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Destination</mat-label>
          <mat-select
            [(value)]="selectedDestinationEndpoint"
            (selectionChange)="destinationEndpointChange($event)">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let option of destinationEndpoints" [value]="option">
              {{option.endpointName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Import Type</mat-label>
          <mat-select
            [(value)]="selectedImportType"
            (selectionChange)="importTypeChange($event)">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let option of importTypes" [value]="option">
              {{option.typeDescription}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

    </div>

    <div class="top-controls-container last-container">

      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput #commentTextArea
            type="text"
            maxlength="150"
            [(ngModel)]="selectedConfig.comments"></textarea>
            <!-- <mat-hint align="end">{{commentTextArea.value.length}} / 150</mat-hint> -->
        </mat-form-field>
      </ng-container>

      <ng-container class="match-form-field">
        <mat-button-toggle-group
            class="match-form-field"
            #group="matButtonToggleGroup"
            [multiple]="false"
            (change)="onToggleIsGenericChange($event.value)">
            <mat-button-toggle
              aria-label="Not Generic"
              value="false"
              [checked]="isGenericIntegration == false">
                Not Generic
            </mat-button-toggle>
            <mat-button-toggle
              aria-label="Generic"
              value="true"
              [checked]="isGenericIntegration == true">
                Generic
            </mat-button-toggle>
            <mat-button-toggle
              aria-label="Null Generic"
              value="null"
              [checked]="isGenericIntegration == null">
                Either / Neither
            </mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>

    </div>

  </div>


  <app-monaco-editor #monaco
    (textChange)="monacoData = $event"
    [editorData]="monacoData"
    [lang]="'xml'"
    [editAccess]="checkAccess(['LOSTalker.DevOps'])"
  ></app-monaco-editor>

  <br />

  <div class="bottom-buttons">
    <button mat-raised-button (click)="goBack()">Go Back</button>
    <button mat-raised-button *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="saveDefaultConfig()" [disabled]="savingDefaultConfig">Save Changes</button>
    <div class="k-i-loading" *ngIf="savingDefaultConfig"></div>
  </div>
</div>
