import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ClientInformation } from 'src/app/models/client-information';
import { ClientContract } from 'src/app/models/client-contract';
import { InvoicePeriod } from 'src/app/models/invoice-period';
import { ClientContractService } from 'src/app/services/client-contract.service';
import { InvoicingService } from 'src/app/services/invoicing.service';
import { ToastService } from '../../services/toast.service';
import { Observable, Subscription, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastType } from '../../models/toast';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-invoice-period-view',
  templateUrl: './invoice-period-view.component.html',
  styleUrls: ['./invoice-period-view.component.scss'],
  providers: [ClientContractService, InvoicingService]
})
export class InvoicePeriodViewComponent implements OnInit, OnDestroy, OnChanges {

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() selectedContract: ClientContract;
  @Input() invoicePeriods: InvoicePeriod[] = [];
  @Input() overrideInvoicePeriod: InvoicePeriod;

  @Output() itemSelected = new EventEmitter<InvoicePeriod | null>();
  @Output() completedLoansSelected = new EventEmitter<InvoicePeriod | null>();

  @Output() returnAllInvoicePeriods = new EventEmitter<InvoicePeriod[]>();
  @Output() itemSelectedForOverride = new EventEmitter<InvoicePeriod | null>();

  allInvoicePeriods: InvoicePeriod[] = [];
  selectedItem: InvoicePeriod;

  hasContract: boolean = false;
  hasPeriods: boolean = false;
  gettingPeriods: boolean = false;

  addingInvoicePeriod: boolean = false;
  minDate?: Date;
  maxDate?: Date;

  hasMultiPreviousPeriods: boolean = false;
  otherPreviousPeriods: InvoicePeriod[] = [];
  previousPeriodsFormControl = new UntypedFormControl('');

  previousBilledPeriod: InvoicePeriod = null;
  lastBilledPeriod: InvoicePeriod = null;
  completedPeriod: InvoicePeriod = null;

  constructor(
    protected invoicingService: InvoicingService,
    protected clientContractService: ClientContractService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.gettingPeriods = true;

    if (this.invoicePeriods != null && this.invoicePeriods.length > 0) {
      if (this.selectedContract != null) {
        this.hasContract = true;
      }

      if (this.overrideInvoicePeriod == null) {
        this.clearBasics();
      }

      this.processPeriods(this.invoicePeriods);
    }
    else {
      this.getPeriodsForContract();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.overrideInvoicePeriod != null && changes?.overrideInvoicePeriod?.currentValue != null
      && changes?.overrideInvoicePeriod?.currentValue != undefined) {
        if (this.hasContract && this.hasPeriods && this.allInvoicePeriods != null && this.allInvoicePeriods.length > 0) {
          if (this.allInvoicePeriods.some(x => x.invoicePeriodId == this.overrideInvoicePeriod.invoicePeriodId)) {
            this.setOverridePeriod();
          }
        }
    }
    else if (changes?.selectedContract?.currentValue != null) {
      this.getPeriodsForContract();
   }
  }

  clearBasics(): void {
    this.hasMultiPreviousPeriods = false;
    this.gettingPeriods = true;
    this.hasPeriods = false;
    this.allInvoicePeriods = [];
    this.completedPeriod = null;
    this.previousBilledPeriod = null;
    this.lastBilledPeriod = null;
    this.addingInvoicePeriod = false;
    this.otherPreviousPeriods = [];
    this.previousPeriodsFormControl = new UntypedFormControl('');
    this.itemSelected.emit(null);
  }

  getPeriodsForContract(): void {
    this.clearBasics();
    if (this.selectedContract != null) {
      this.hasContract = true;
      this.invoicingService.getAllInvoicePeriodsByClient(this.selectedContract.clientContractId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((r) => {
        if (r != null && r.length > 0) {
          this.processPeriods(r);
        }
        else {
          this.hasPeriods = false;
          this.gettingPeriods = false;
        }
      });
    }
    else {
      this.hasContract = false;
      this.hasPeriods = false;
      this.gettingPeriods = false;
    }
  }

  processPeriods(data: InvoicePeriod[]): void {
    data.forEach(x => {
      if (x.dateEnd != null) {
        x.dateEnd = new Date(x.dateEnd);
      }
      if (x.dateStart != null) {
        x.dateStart = new Date(x.dateStart);
      }
      if (x.invoiceDate != null) {
        x.invoiceDate = new Date(x.invoiceDate);
      }
    });
    data = data.sort((a,b) => a.dateEnd.getTime() - b.dateEnd.getTime());
    this.allInvoicePeriods = data;
    this.returnAllInvoicePeriods.emit(this.allInvoicePeriods);
    this.completedPeriod = this.allInvoicePeriods.find(x => x.currentInvoice == true);
    this.previousBilledPeriod = this.allInvoicePeriods.find(x => x.previousBilledInvoice == true);
    this.lastBilledPeriod = this.allInvoicePeriods.find(x => x.lastBilledInvoice == true);

    if (this.allInvoicePeriods.length > 0) {
      let tmp = this.allInvoicePeriods.filter(x => x != this.completedPeriod && x != this.previousBilledPeriod && x != this.lastBilledPeriod);
      if (tmp != null && tmp.length > 0) {
        this.otherPreviousPeriods = tmp;
        this.hasMultiPreviousPeriods = true;
      }
    }

    this.hasPeriods = true;
    this.gettingPeriods = false;

    if (this.overrideInvoicePeriod != null) {
      this.setOverridePeriod();
    }
  }

  setOverridePeriod() {
    if (this.overrideInvoicePeriod.currentInvoice == true) {
      this.selectedItem = this.completedPeriod;
      this.updateFormControl(null);
      this.itemSelectedForOverride.emit(this.overrideInvoicePeriod);
    }
    else if (this.overrideInvoicePeriod.previousBilledInvoice == true) {
      this.selectedItem = this.previousBilledPeriod;
      this.updateFormControl(null);
      this.itemSelectedForOverride.emit(this.overrideInvoicePeriod);
    }
    else if (this.overrideInvoicePeriod.lastBilledInvoice == true) {
      this.selectedItem = this.lastBilledPeriod;
      this.updateFormControl(null);
      this.itemSelectedForOverride.emit(this.overrideInvoicePeriod);
    }
    else {
      this.selectedItem = this.overrideInvoicePeriod;
      this.updateFormControl(this.selectedItem);
      this.itemSelectedForOverride.emit(this.overrideInvoicePeriod);
    }
  }

  selectPeriod(x?: InvoicePeriod) {
    if (x != null) {
      this.selectedItem = x;
      this.itemSelected.emit(this.selectedItem);
      this.updateFormControl(null);
    }
  }

  selectCompletedLoansPeriod(x?: InvoicePeriod) {
    if (this.completedPeriod) {
      this.selectedItem = this.completedPeriod;
      this.completedLoansSelected.emit(this.selectedItem);
      this.updateFormControl(null);
    }
    else {
      this.completedLoansSelected.emit();
    }
  }

  selectPreviousPeriod(event: any): void {
    this.selectedItem = event.value;
    this.itemSelected.emit(this.selectedItem);
  }

  updateFormControl(x?: InvoicePeriod) {
    this.previousPeriodsFormControl.setValue(x);
  }
}
