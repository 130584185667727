<div class="container-fluid">
  <ng-container *ngIf="currReport.hasFilterCards">
    <div class="report-filter-cards">
      <div class="row row-cols-1 row-cols-md-2 row-cols-sm-2 g-4 pb-1">

        <div class="col">
          <div class="card h-100">
            <div class="card-body">
              <div class="card-title">Destination Endpoints</div>
              <div class="example-wrapper">
                  <kendo-multiselect [checkboxes]="true"
                                  [data]="destinationEndpointItems"
                                  [textField]="'endpointName'"
                                  [valueField]="'destinationEndpointId'"
                                  [placeholder]="'Select Destination Endpoint...'"
                                  [clearButton]="true"
                                  [autoClose]="false"
                                  [filterable]="true"
                                  (valueChange)="externalFilterChange($event)"
                                  [(ngModel)]="destinationEndpointItemsSelected"></kendo-multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card h-100">
            <div class="card-body">
              <div class="card-title">Import Type:</div>
              <div class="example-wrapper">
                  <kendo-multiselect [checkboxes]="true"
                                  [data]="importTypeItems"
                                  [textField]="'typeDescription'"
                                  [valueField]="'importTypeId'"
                                  [placeholder]="'Select Import Type...'"
                                  [clearButton]="true"
                                  [autoClose]="false"
                                  [filterable]="true"
                                  (valueChange)="externalFilterChange($event)"
                                  [(ngModel)]="importTypeItemsSelected"></kendo-multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="currReport.hasSearch">
      <hr class="hr-solid">
      <div class="row row-cols-1 row-cols-md-2 row-cols-sm-2 pb-3 g-3">
          <div class="col">
              <div class="card search-card h-100">
                  <div class="card-body">
                      <div class="input-group">
                          <mat-form-field class="w-100">
                              <mat-label>{{xmlSearchTerm.defaultText}}</mat-label>
                              <input type="text" class="form-control" matInput [(ngModel)]="xmlSearchTerm.searchValue">
                              <button mat-icon-button matSuffix (click)="updateSearch(xmlSearchTerm)" class="px-1 search-icon-btn">
                                  <mat-icon class="material-icons-outlined search-icon">search</mat-icon>
                              </button>
                              <button mat-icon-button matSuffix (click)="clearSearch(xmlSearchTerm)" class="px-1 search-icon-btn">
                                  <mat-icon class="material-icons-outlined search-icon">close</mat-icon>
                              </button>
                          </mat-form-field>
                      </div>

                  </div>
                  <div *ngIf="xmlSearchTerm.hasSearchTerm" class="card-footer card-current-search">
                      Current Search: {{xmlSearchTerm.currentSearchValue | json}}
                  </div>
              </div>
          </div>

          <div class="col">
              <div class="card search-card h-100">
                  <div class="card-body">
                      <div class="input-group">
                          <mat-form-field class="w-100">
                              <mat-label>{{jsSearchTerm.defaultText}}</mat-label>
                              <input type="text" class="form-control integJSSearchBox" matInput [(ngModel)]="jsSearchTerm.searchValue">
                              <button mat-icon-button matSuffix (click)="updateSearch(jsSearchTerm)" class="px-1 search-icon-btn">
                                  <mat-icon class="material-icons-outlined search-icon">search</mat-icon>
                              </button>
                              <button mat-icon-button matSuffix (click)="clearSearch(jsSearchTerm)" class="px-1 search-icon-btn">
                                  <mat-icon class="material-icons-outlined search-icon">close</mat-icon>
                              </button>
                          </mat-form-field>
                      </div>
                  </div>
                  <div *ngIf="jsSearchTerm.hasSearchTerm" class="card-footer card-current-search">
                      Current Search: {{jsSearchTerm.currentSearchValue | json}}
                  </div>
              </div>
          </div>
      </div>
  </ng-container>

  <div class="report-command-button-group">
      <button type="button" class="btn btn-outline-secondary" (click)="resetFilters($event)">
          Reset Filters
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="applyFilters($event)" >
          Apply Filters
      </button>
  </div>

  <hr class="hr-solid">

  <ng-container *ngIf="showMappingContainer">
    <ng-container *ngIf="haveMappings">

      <ng-container *ngIf="currMappingType === mappingType.BaseMapping">
        <app-view-generic-grid #genericGrid
          [data]="filteredBaseMappingData"
          [columns]="reportColumns"
          [exportFileName]="currReport.exportFileName"
          [isResizeable]="true"
          [showExportToolbar]="true"
          [isSelectable]="false"
          [showSelectionColumn]="false"
          [showChangeSelection]="false"
          [selectionfield]="'baseMappingId'"
          [sortDescriptions]="reportSort"
          [hasActionGoToColumn]="true"
          [actionGoToUsePrefix]="true"
          [actionGoToField]="'genericEndpointId'"
          [actionGoToPrefix]="'/editBaseMapping'"
          [loading]="gettingMappings">
        </app-view-generic-grid>
      </ng-container>

      <ng-container *ngIf="currMappingType === mappingType.GenericEndpoint">
        <app-view-generic-grid #genericGrid
          [data]="filteredGenericEndpointData"
          [columns]="reportColumns"
          [exportFileName]="currReport.exportFileName"
          [isResizeable]="true"
          [showExportToolbar]="true"
          [isSelectable]="false"
          [showSelectionColumn]="false"
          [showChangeSelection]="false"
          [selectionfield]="'genericEndpointId'"
          [sortDescriptions]="reportSort"
          [hasActionGoToColumn]="true"
          [actionGoToUsePrefix]="true"
          [actionGoToField]="'genericEndpointId'"
          [actionGoToPrefix]="'/editGenericEndpoint'"
          [loading]="gettingMappings">
        </app-view-generic-grid>
      </ng-container>

      <ng-container *ngIf="currMappingType === mappingType.BaseEndpointMapping">
        <app-view-generic-grid #viewGenericGrid
          [data]="filteredBaseEndpointMappingData"
          [columns]="reportColumns"
          [exportFileName]="currReport.exportFileName"
          [isResizeable]="true"
          [showExportToolbar]="true"
          [isSelectable]="false"
          [showSelectionColumn]="false"
          [showChangeSelection]="false"
          [selectionfield]="'baseEndpointMappingId'"
          [sortDescriptions]="reportSort"
          [hasActionGoToColumn]="true"
          [actionGoToUsePrefix]="false"
          [actionGoToField]="'routerUrl'"
          [loading]="gettingMappings">
        </app-view-generic-grid>
      </ng-container>

    </ng-container>
    <ng-container *ngIf="!haveMappings">
      <!-- <div style="margin: auto; margin-top:20%; text-align: center;"> -->
      <div style="margin: auto; text-align: center;">
        <ng-container *ngIf="gettingMappings">
          <div class="k-i-loading"></div>
        </ng-container>
      </div>

      <ng-container *ngIf="!gettingMappings">
        <p>No Mappings Returned for Report Query.</p>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
