<ng-container *ngIf="currReport.hasFilterCards">
  <div class="report-filter-cards">
    <div class="row row-cols-1 row-cols-md-4 row-cols-sm-2 g-4 pb-1">

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">DataSource Type</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="dataSourceTypeItems"
                                [textField]="'description'"
                                [valueField]="'dataSourceTypeId'"
                                [placeholder]="'Select Data Source Type...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="dataSourceItemsSelected"></kendo-multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">Destination Endpoints</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="destinationEndpointItems"
                                [textField]="'endpointName'"
                                [valueField]="'destinationEndpointId'"
                                [placeholder]="'Select Destination Endpoint...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="destinationEndpointItemsSelected"></kendo-multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">Import Type</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="importTypeItems"
                                [textField]="'typeDescription'"
                                [valueField]="'importTypeId'"
                                [placeholder]="'Select Import Type...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="importTypeItemsSelected"></kendo-multiselect>
          </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">Server</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="serverItems"
                                [textField]="'description'"
                                [valueField]="'serverId'"
                                [placeholder]="'Select Server...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="serverItemsSelected"></kendo-multiselect>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="report-command-button-group">
    <button type="button" class="btn btn-outline-secondary" (click)="resetFilters($event)">
      Reset Filters
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="applyFilters($event)" >
      Apply Filters
    </button>
  </div>
</ng-container>

<ng-container *ngIf="currReport.hasSearch">
  <hr class="hr-solid">
  <div class="row row-cols-1 row-cols-md-2 row-cols-sm-2 pb-3 g-3">

    <div class="col">
      <div class="card search-card h-100">
        <div class="card-body">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="{{xmlSearchTerm.defaultText}}" [(ngModel)]="xmlSearchTerm.searchValue">
            <div class="input-group-append">
              <button mat-stroked-button (click)="updateSearch(xmlSearchTerm)" class="px-1 search-icon-btn">
                <mat-icon class="material-icons-outlined search-icon">search</mat-icon>
              </button>
              <button mat-stroked-button (click)="clearSearch(xmlSearchTerm)" class="px-1 search-icon-btn">
                <mat-icon class="material-icons-outlined search-icon">close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="xmlSearchTerm.hasSearchTerm" class="card-footer card-current-search">
          Current Search: {{xmlSearchTerm.currentSearchValue | json}}
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card search-card h-100">
        <div class="search-checkbox justify-content-end" *ngIf="!jsLoaded">
          <mat-checkbox
                [checked]="false"
                (change)="loadJS($event.checked)"
                [labelPosition]="'before'">
              Load JavaScripts
            </mat-checkbox>
        </div>
        <div class="card-body">
          <div class="input-group">
            <input type="text" class="form-control integJSSearchBox" placeholder="{{jsSearchTerm.defaultText}}" [(ngModel)]="jsSearchTerm.searchValue" [disabled]="!jsLoaded">
            <div class="input-group-append">
              <button mat-stroked-button (click)="updateSearch(jsSearchTerm)" class="px-1 search-icon-btn" [disabled]="!jsLoaded">
                <mat-icon class="material-icons-outlined search-icon">search</mat-icon>
              </button>
              <button mat-stroked-button (click)="clearSearch(jsSearchTerm)" class="px-1 search-icon-btn" [disabled]="!jsLoaded">
                <mat-icon class="material-icons-outlined search-icon">close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="jsSearchTerm.hasSearchTerm" class="card-footer card-current-search">
          Current Search: {{jsSearchTerm.currentSearchValue | json}}
        </div>
      </div>
    </div>

  </div>

</ng-container>

<ng-container *ngIf="currReport.tableVersion === 0">
  <!-- Grid without Checkbox column -->
  <div class="report-kendo-grid-wrapper">
    <kendo-grid 
                #genericGrid
                autosize="true"
                [columnMenu]="true"
                [reorderable]="true"
                [resizable]="true"  
                [data]="gridView"
                [pageSize]="state.take"
                [skip]="state.skip"
                [pageable]="{info: true, type: type, pageSizes: true, previousNext: true}"
                [sortable]="{mode: 'single', allowUnsort: false}"
                [sort]="state.sort"
                [scrollable]="'scrollable'"
                [selectable]="true"
                [filter]="currentFiltersFromGrid"
                [filterable]="true"
                (sortChange)="sortChange($event)"
                (selectionChange)="selectionGridChangeHandler($event)"
                (pageChange)="pageChange($event)"
                (filterChange)="filterChange($event)">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridExcelCommand icon="file-excel">
          Export to Excel
        </button>
      </ng-template>
      <ng-template kendoGridNoRecordsTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading && gridView">No records found.</div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridView">Loading failed.</div>
        </div>
      </ng-template>
      <kendo-grid-column field="integrationId" title="Open" [width]="40" [filterable]="false" [sortable]="false" *ngIf="currReport.hasGoToButton">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [routerLink]="['/editConfig', dataItem.clientName, dataItem.name]" target="_blank">
            <button mat-flat-button color="primary">Navigate</button>
          </a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="clientId" title="Client ID" [width]="35"></kendo-grid-column>
      <kendo-grid-column field="clientName" title="Client Name" [width]="100">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="true"
                                         [column]="column"
                                         [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name" title="Integration Name" [width]="100">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="true"
                                         [column]="column"
                                         [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dataSourceTypeId" title="DataSource" [width]="100">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="true"
                                         [column]="column"
                                         [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="serverId" title="Server" [width]="30">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="true"
                                         [column]="column"
                                         [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="updatedDate" title="UpdatedDate" [width]="70">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="true"
                                         [column]="column"
                                         [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="locked" title="Locked" [width]="40">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.locked ? "Yes" : "No"}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ipRestricted" title="IP Restricted" [width]="40">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.ipRestricted ? "Yes" : "No"}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="selfHosted" title="Self Hosted" [width]="40">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.selfHosted ? "Yes" : "No"}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="integrationId" title="Integration ID" [width]="100"></kendo-grid-column>

      <ng-template kendoPagerTemplate>
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="[5, 10, 25]"></kendo-pager-page-sizes>
      </ng-template>
      <kendo-grid-excel fileName="{{currReport.exportFileName}}" [fetchData]="selectedData"></kendo-grid-excel>
    </kendo-grid>
  </div>

</ng-container>
