<div *ngIf="currentReport">

  <h1 style="text-align:center;">
    Report <span *ngIf="currentReport">: {{currentReport.title}}</span>
  </h1>

  <hr class="hr-solid">

  <ng-container *ngIf="!customReport">
    <ng-container [ngSwitch]="currentReportType.reportTypeName">
        <ng-container *ngSwitchCase="'Integration'">
          <integration-report-view
              [reportId]="currentReport.reportId"
              [currentReportToShow]="currentReport"
              [serverItems]="serverItems"
              [dataSourceTypeItems]="dataSourceTypeItems"
              [destinationEndpointItems]="destinationEndpointItems"
              [importTypeItems]="importTypeItems"
              [integrationRunTimeItems]="integrationRunTimeItems"
              >
          </integration-report-view>
        </ng-container>

        <ng-container *ngSwitchCase="'IntegrationInformation'">
          <app-integrationinfo-report-view
              [reportId]="currentReport.reportId"
              [currentReportToShow]="currentReport"
              [serverItems]="serverItems"
              [dataSourceTypeItems]="dataSourceTypeItems"
              [destinationEndpointItems]="destinationEndpointItems"
              [importTypeItems]="importTypeItems"
              [integrationRunTimeItems]="integrationRunTimeItems"
              >
          </app-integrationinfo-report-view>
        </ng-container>

        <ng-container *ngSwitchCase="'IntegrationError'">
          <app-integration-error-report
            [currentReportToShow]="currentReport"
            [reportConstants]="reportConstants" >
          </app-integration-error-report>
        </ng-container>

        <ng-container *ngSwitchCase="'IntegrationErrorMessage'">
          <error-report
            [reportId]="currentReport.reportId"
            [currentReportToShow]="currentReport">
          </error-report>
        </ng-container>
        <ng-container *ngSwitchCase="'ClientContract'">
          <client-contract-report
            [reportId]="currentReport.reportId"
            [currentReportToShow]="currentReport">
          </client-contract-report>
        </ng-container>
        <ng-container *ngSwitchCase="
                     currentReportType.reportTypeName ==='BaseEndpointMapping'
                  || currentReportType.reportTypeName === 'BaseMapping'
                  || currentReportType.reportTypeName === 'GenericEndpoint'
                  ? currentReportType.reportTypeName : ''">
              <app-mappings-report-view
                  [reportId]="currentReport.reportId"
                  [currentReportToShow]="currentReport"
                  [importTypeItems]="importTypeItems"
                  [destinationEndpointItems]="destinationEndpointItems">
              </app-mappings-report-view>
        </ng-container>
    </ng-container>
  </ng-container>

  <!-- Report Back Button -->
  <ng-container>
    <div class="report-command-button-group">
      <button type="button" class="btn btn-outline-secondary" (click)="goBack($event)">
        Go Back
      </button>
    </div>
  </ng-container>
</div>
