import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Server } from '../../models/server';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { ClientIntegrationService } from "../../services/client-integration.service";
import { ServerService } from "../../services/server.service";
import { OperationCanceledException } from 'typescript';
import { ToastService } from 'src/app/services/toast.service';
import { mkdir } from 'fs';
import { keyframes } from '@angular/animations';

@Component({
  selector: 'app-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['./servers.component.scss'],
  providers: [ClientIntegrationService, ServerService],
  encapsulation: ViewEncapsulation.None
})
export class ServersComponent implements OnInit {

  serverList: Server[];
  serverSelected: Server;
  serverListLoading: boolean = true;

  opened: Boolean;

  serverStopped: Map<string, boolean> = new Map<string, boolean>();
  //lastDataSent: Map<string, Date> = new Map<string, Date>();

  constructor(private clientIntegrationService: ClientIntegrationService,
    private _serverService: ServerService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {
    this.refreshData();
    //this.getAllServersLastDataSent();
  }

  selectServer(name: string): void {
    this.serverSelected = this.serverList.find(s => s.serverId == name);
    this.router.navigate(['/serverintegrations', name]);
  }

  public close() {
    this.opened = false;
  }

  public saveServer() {
    this.close();

    // Validate Text
    let valid = true;

    // Only allow valid versions, such as
    // 0 || 21.10 || 40.5.3 || 35.2.5.32
    if (! /^(\d{1,2}[.]){0,3}\d{1,2}$/.test(this.serverSelected.encompassVersion)) {
      valid = false;
    }

    if (!valid) {
      // Validation failed
      this.toastService.toastCreate("Illegal version.", 'Danger', { autoClose: true, keepAfterRouteChange: false });
      return;
    }

    //this.clientIntegrationService.updateServer(this.serverSelected).then(
    this._serverService.updateServer(this.serverSelected).subscribe(
      (val) => {
        this.refreshData();
      },
      (response) => {
        // add a message later for error catch
        this.toastService.toastCreate(response, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      });
  }

  public refreshData(): void {
    this._serverService.getAllServersInformation().subscribe(s => {

      s = s.sort(function(a, b) {
        return a.serverId.localeCompare(b.serverId, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      this.serverList = s;
      this.serverList.forEach(s => {
        if(s.lastDataSentServer !== null && s.serverId !== '0'){
          s.lastDataSentServer = new Date(s.lastDataSentServer);
          if (s.lastDataSentServer.getTime() < Date.now() - (s.serverAlertTimespan * 60 * 1000)) {
            this.serverStopped.set(s.serverId, true);
          } else {
            this.serverStopped.set(s.serverId, false);
          }
        }
        else {
          this.serverStopped.set(s.serverId, false);
        }

      })
      this.serverListLoading = false;
    }, error => {
      this.serverListLoading = false;
    });
  }

  public servButton(e: Event, id: string): void {
    e.stopPropagation();
    this.serverSelected = this.serverList.find(s => s.serverId == id);
    this.opened = true;
  }

  // public getAllServersLastDataSent() {


  //   this.clientIntegrationService.getAllServersLastDataSent()
  //   .subscribe(map => {
  //     //Check through each id and whether the last data sent is outside of recent requirement
  //     for(let key in map) {
  //       this.lastDataSent = map;
  //       map[key] = new Date(map[key]);

  //       let serverAlertTimespan = this.serverList.find(s => s.serverId == key).serverAlertTimespan;

  //       // Check if current time is far enough ahead of lastdatasent
  //       if(map[key].getTime() < Date.now() - (serverAlertTimespan * 60 * 1000)) {
  //         this.serverStopped.set(key, true);
  //       } else {
  //         this.serverStopped.set(key, false);
  //       }
  //     }
  //   });
  // }

}
