import { ColumnSettings } from "../models/column-settings";
import { SortDescriptor } from "@progress/kendo-data-query";
import { BaseEndpointMappingType } from "../models/base-endpoint-mapping-type";

export class ReportingUtils {
    public defaultBaseMappingColumnSettings: ColumnSettings[] = [
        { field: 'baseMappingId', title: 'BaseMappingId', type: 'text', hidden: true},
        { field: 'name', title: 'Name', type: 'text', width: 100},
        { field: 'destinationEndpointId', title: 'DestinationEndpointId', type: 'text', hidden: true},
        { field: 'endpointName', title: 'EndpointName', type: 'text', width: 75},
        { field: 'importTypeId', title: 'ImportTypeId', type: 'text', hidden: true},
        { field: 'typeDescription', title: 'TypeDescription', type: 'text', width: 75},
        { field: 'updatedDate', title: 'UpdatedDate', type: 'date', format: '{0:MM/dd/yyyy h:mm a}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true},
        { field: 'xmlConfig', title: 'xmlConfig', type: 'text', hidden: true, width: 100, hideInExport: true},
        { field: 'javaScript', title: 'javaScript', type: 'text', hidden: true, width: 100, hideInExport: true},
    ];

    public defaultGenericEndpointColumnSettings: ColumnSettings[] = [
        { field: 'genericEndpointId', title: 'GenericEndpointId', type: 'text', hidden: true},
        { field: 'name', title: 'Name', type: 'text', width: 100},
        { field: 'destinationId', title: 'DestinationId', type: 'text', hidden: true},
        { field: 'destination', title: 'Destination', type: 'text', width: 75},
        { field: 'importTypeId', title: 'ImportTypeId', type: 'text', hidden: true},
        { field: 'importType', title: 'ImportType', type: 'text', width: 75},
        { field: 'updatedDate', title: 'UpdatedDate', type: 'date', format: '{0:MM/dd/yyyy h:mm a}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true},
        { field: 'deleted', title: 'Deleted', type: 'boolean', hidden: true},
        { field: 'xmlConfiguration', title: 'xmlConfiguration', type: 'text', hidden: true, hideInExport: true},
        { field: 'javaScript', title: 'javaScript', type: 'text', hidden: true, hideInExport: true},
        { field: 'javaScriptId', title: 'javaScriptId', type: 'text', hidden: true, hideInExport: true},
    ];

    public defaultBaseEndpointMappingColumnSettings: ColumnSettings[] = [
      { field: 'baseEndpointMappingId', title: 'Id', type: 'text', hidden: true},
      { field: 'mappingType', title: 'MappingType', type: 'text', width: 75},
      { field: 'typeDescription', title: 'ImportType', type: 'text', width: 75},
      { field: 'endpointName', title: 'Destination', type: 'text', width: 75},
      { field: 'baseMappingId', title: 'BaseMappingId', type: 'text', hidden: true},
      { field: 'genericEndpointId', title: 'GenericEndpointId', type: 'text', hidden: true},
      { field: 'name', title: 'Name', type: 'text', width: 100},
      { field: 'xmlConfig', title: 'XML', type: 'text', hidden: true, hideInExport: true},
      { field: 'updatedDate', title: 'UpdatedDate', type: 'date', format: '{0:MM/dd/yyyy h:mm a}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true},
      { field: 'importTypeId', title: 'ImportTypeId', type: 'text', hidden: true},
      { field: 'destinationEndpointId', title: 'DestinationEndpointId', type: 'text', hidden: true},
      { field: 'javaScript', title: 'JS', type: 'text', hidden: true, hideInExport: true},
  ];

  public defaultColumnSettingsIntegrationRunTimes: ColumnSettings[] = [
    new ColumnSettings({ field: 'integrationRunTimeId', title: 'IntegrationRunTimeId', type: 'text', hidden: true}),
    new ColumnSettings({ field: 'destinationEndpointName', title: 'DestinationEndpoint', type: 'text', width: 75}),
    new ColumnSettings({ field: 'importTypeDescription', title: 'ImportType', type: 'text', width: 75}),
    new ColumnSettings({ field: 'lastRunTime', title: 'LastRunTime', type: 'date', format: '{0:d}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true}),
    new ColumnSettings({ field: 'nextRunTime', title: 'NextRunTime', type: 'date', format: '{0:d}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true}),
    new ColumnSettings({ field: 'lastDataSent', title: 'LastDataSent', type: 'date', format: '{0:d}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true}),
    new ColumnSettings({ field: 'integrationId', title: 'IntegrationId', type: 'text', hidden: true}),
    new ColumnSettings({ field: 'importTypeId', title: 'ImportTypeId', type: 'text', hidden: true}),
    new ColumnSettings({ field: 'destinationEndpointId', title: 'DestinationEndpointId', type: 'text', hidden: true}),
    new ColumnSettings({ field: 'destinationEndpointDescription', title: 'DestinationEndpointDescription', type: 'text', hidden: true}),
    new ColumnSettings({ field: 'fastFetchEnabled', title: 'FastFetchEnabled', type: 'boolean', hidden: true}),
];

public defaultSortDescriptorsIntegrationRunTimes: SortDescriptor[] = [
  {field: "destinationEndpointName", dir: "asc"}
];

public defaultColumnSettingsIntegrationBulk: ColumnSettings[] = [
  new ColumnSettings({ field: 'integrationId', title: 'IntegrationId Id', type: 'text', hidden: true}),
  new ColumnSettings({ field: 'clientId', title: 'Client Id', type: 'numeric', hidden: true, width: 50}),
  new ColumnSettings({ field: 'clientName', title: 'Client Name', type: 'text', width: 100}),
  new ColumnSettings({ field: 'name', title: 'Name', type: 'text', width: 100}),
  new ColumnSettings({ field: 'dataSourceTypeId', title: 'DataSource', type: 'text', width: 75}),
  new ColumnSettings({ field: 'serverId', title: 'Server', type: 'text', width: 50}),
  new ColumnSettings({ field: 'locked', title: 'Locked', type: 'boolean', width: 50, filterable: false}),
  new ColumnSettings({ field: 'updatedDate', title: 'UpdatedDate', type: 'date', format: '{0:d}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true}),
  new ColumnSettings({ field: 'ipRestricted', title: 'IP Restricted', type: 'boolean', width: 50}),
  new ColumnSettings({ field: 'integrationTypesAgg', title: 'Integration Types', type: 'text', width: 100, hidden: true}),
  new ColumnSettings({ field: 'deleted', title: 'Deleted', type: 'boolean', width: 50, hidden: true}),
];

public defaultColumnSettingsServerInfo: ColumnSettings[] = [
  new ColumnSettings({ field: 'serverId', title: 'Server Id', type: 'text', filterable: false, width: 150}),
  new ColumnSettings({ field: 'description', title: 'Description', type: 'text', filterable: false}),
  new ColumnSettings({ field: 'active', title: 'Active', type: 'boolean', filterable: false, width: 100}),
  new ColumnSettings({ field: 'isWhitelisted', title: 'IsWhitelisted', type: 'boolean', filterable: false, width: 150}),
  new ColumnSettings({ field: 'testServer', title: 'TestServer', type: 'boolean', filterable: false, width: 150}),
  new ColumnSettings({ field: 'encompassVersion', title: 'Encompass Version', type: 'text', width: 100, filterable: false}),
  new ColumnSettings({ field: 'integrationCount', title: 'IntegrationCount', type: 'numeric', filterable: false, width: 100}),
];

      getDefaultColumns(type: BaseEndpointMappingType) : ColumnSettings[] {
      if (type == BaseEndpointMappingType.BaseMapping) {
          return this.defaultBaseMappingColumnSettings;
      }
      else if (type == BaseEndpointMappingType.GenericEndpoint){
          return this.defaultGenericEndpointColumnSettings;
      }
      else if (type == BaseEndpointMappingType.BaseEndpointMapping){
        return this.defaultBaseEndpointMappingColumnSettings;
      }
      else {
        return [];
      }
    }

    getDefaultBaseEndpointColumnsByString(type: string) : ColumnSettings[] {
      if (type == "BaseMapping") {
          return this.defaultBaseMappingColumnSettings;
      }
      else if (type == "GenericEndpoint"){
          return this.defaultGenericEndpointColumnSettings;
      }
      else if (type == "BaseEndpointMapping"){
        return this.defaultBaseEndpointMappingColumnSettings;
      }
      else {
        return [];
      }
    }

    getDefaultColumnsByString(type: string) : ColumnSettings[] {
      if (type == "BaseMapping") {
          return this.defaultBaseMappingColumnSettings;
      }
      else if (type == "GenericEndpoint"){
          return this.defaultGenericEndpointColumnSettings;
      }
      else if (type == "BaseEndpointMapping"){
        return this.defaultBaseEndpointMappingColumnSettings;
      }
      else if (type == "IntegrationRunTime"){
        return this.defaultColumnSettingsIntegrationRunTimes;
      }
      else if (type == "IntegrationBulk"){
        return this.defaultColumnSettingsIntegrationBulk;
      }
      else if (type == "ServerInfo"){
        return this.defaultColumnSettingsServerInfo;
      }
      else {
        return [];
      }
    }

    getBaseEndpointMappingTypeByString(type: string) : BaseEndpointMappingType {
      if (type == "BaseMapping") {
          return BaseEndpointMappingType.BaseMapping;
      }
      else if (type == "GenericEndpoint"){
          return BaseEndpointMappingType.GenericEndpoint;
      }
      else if (type == "BaseEndpointMapping"){
        return BaseEndpointMappingType.BaseEndpointMapping;
      }
      else {
        return null;
      }
    }


}
