import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

import { ClientIntegrationService } from '../../services/client-integration.service';
import { ToastService } from '../../services/toast.service';
import { Toast, ToastType } from '../../models/toast';
import { Client } from '../../models/client';
import { Integration } from '../../models/integration';
import { runInThisContext } from 'vm';
import { ThisReceiver } from '@angular/compiler';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { CustomSQLQueryService } from '../../services/sql-queries.service';
import { CustomSQLQuery } from '../../models/sql-query';
import { QueryType } from '../../models/query-type';

@Component({
  selector: 'app-add-sql-query',
  templateUrl: './add-sql-query.component.html',
  styleUrls: ['./add-sql-query.component.scss'],
  providers: [ClientIntegrationService, CustomSQLQueryService]
})
export class AddSQLQueryComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() isInTab = false;
  @Output() finished = new EventEmitter<string>();
  @Input() populatedAdd: boolean = false;

  hasClient = false;
  @Input() client: Client = null;
  clients: Client[];
  filteredClients: Observable<Client[]>;
  clientFormControl = new UntypedFormControl();

  hasIntegration = false;
  @Input() integration: Integration = null;
  integrations: Integration[];
  filteredIntegrations: Observable<Integration[]>;
  integrationFormControl = new UntypedFormControl();

  hasQueryType = false;
  queryType: QueryType = null;
  queryTypes: QueryType[] = [];
  filteredQueryTypes: Observable<QueryType[]>;
  queryTypeFormControl = new UntypedFormControl();

  newQuery: CustomSQLQuery = null;
  monacoData: string = '';
  queryName: string = '';

  private sub: any;
  dateNow: Date;
  checked: boolean = true;

  savingQuery: boolean = false;

  pageLoading: boolean = true;

  constructor(
    private clientService: ClientIntegrationService,
    private typeConstantService: TypeConstantService,
    private toastService: ToastService,
    private router: Router,
    private customQueryService: CustomSQLQueryService,
  ) {
  }

  ngOnInit(): void {

    if (this.client == null) {
      this.getAllQueryTypes();
      this.sub = this.clientService.getAllClients()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data) => {
          this.clients = data;
          this.filteredClients = this.clientFormControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterClients(name) : this.clients.slice())
          );
          this.pageLoading = false;
        }, error => {
          this.pageLoading = false;
        });
      this.hasIntegration = false;
    }
    else {
      this.hasClient = true;

      if (this.integration == null) {
        this.getIntegrations();
        this.hasIntegration = false;
      }
      else {
        this.hasIntegration = true;
      }
    }
    let date: Date = new Date();
    this.dateNow = date;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  selectClient(c: Client): void {
    this.pageLoading = true;
    this.client = c;
    this.hasClient = true;
    this.getIntegrations();
  }

  editClient() {
    this.client = null;
    this.integration = null;
    this.hasClient = false;
    this.hasIntegration = false;
    this.clientFormControl.setValue('');
  }

  getClients(): void {
    this.clientService.getAllClients().subscribe((data) => {
      this.clients = data;
      this.filteredClients = this.clientFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterClients(name) : this.clients.slice())
      );
    });
  }

  getAllQueryTypes(): void {
    this.customQueryService.getAllQueryTypes()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.queryTypes = data;

        this.filteredQueryTypes = this.queryTypeFormControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterQueryTypes(name) : this.queryTypes.slice())
        );
      }, error => {
        this.pageLoading = false;
      });
  }

  editQueryType() {
    this.queryType = null;
    this.hasQueryType = false;
    this.queryTypeFormControl.setValue('');
    this.newQuery = null;
  }

  selectQueryType(q: QueryType): void {
    this.queryType = q;
    this.hasQueryType = true;
    
    this.newQuery = new CustomSQLQuery();
    this.newQuery.customSQLQueryId = '00000000-0000-0000-0000-000000000000';
    this.newQuery.integrationId = this.integration.integrationId;
    this.newQuery.sqlString = '';
    this.newQuery.queryTypeId = this.queryType.queryTypeId;
  }

  selectIntegration(i: Integration): void {
    this.hasIntegration = true;
    this.integration = i;
  }

  editIntegration() {
    this.integration = null;
    this.hasIntegration = false;
    this.integrationFormControl.setValue('');
  }

  getIntegrations(): void {
    if (this.client) {
      this.clientService.getIntegrationsByClient(this.client.name)
        .subscribe((data) => {
          this.integrations = data;
          this.filteredIntegrations = this.integrationFormControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterIntegrations(name) : this.integrations.slice())
          );
          this.pageLoading = false;
        }, error => {
          this.pageLoading = false;
        });
    }
    else {
      this.toastService.toastCreate("Select Client.", "Warning");
    }
  }

  addCustomQuery(): void {
    this.savingQuery = true;
    this.newQuery.sqlString = this.monacoData;
    this.newQuery.queryName = this.queryName;

    this.customQueryService.addCustomQuery(this.newQuery).subscribe(
    (value) => {
      if (this.isInTab) {
        if (this.populatedAdd) {
          this.finished.emit('generated');
        }
        else {
          this.toastService.toastCreate("Custom Query " + this.newQuery.queryName + "Added.", "Success");
          this.finished.emit('added');
        }
      }
      else {
        this.toastService.toastCreate("Custom Query " + this.newQuery.queryName + "Added.", "Success", {
          keepAfterRouteChange: true
      });
        this.router.navigate(['/sql-queries']);
      }
      this.savingQuery = false;
    },
    error => {
      if (this.isInTab) {
        this.finished.emit('error');
      }
      this.savingQuery = false;
    });
  }

  /* Angular Material Functions */

  private _filterClients(value: string): Client[] {
    const filterValue = value.toLowerCase();
    return this.clients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterIntegrations(value: string): Integration[] {
    const filterValue = value.toLowerCase();
    return this.integrations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterQueryTypes(value: string): QueryType[] {
    const filterValue = value.toLowerCase();
    return this.queryTypes.filter(option => option.queryTypeName.toLowerCase().includes(filterValue));
  }

  displayFnClient(x: Client): string {
    return x && x.name ? x.name : '';
  }

  displayFnIntegration(x: Integration): string {
    return x && x.name ? x.name : '';
  }

  displayFnQueryType(x: QueryType): string {
    return x && x.queryTypeName ? x.queryTypeName : '';
  }
}
