 <kendo-multiselect
    [checkboxes]="true"
    [data]="data"
    [textField]="textField"
    [valueField]="valueField"
    [placeholder]="'Select...'"
    (valueChange)="onChange($event, filterService)"
    [value]="dataFilters(filter)"
    [clearButton]="true"
    [valuePrimitive]="true">
</kendo-multiselect>
