import { Component, OnInit, OnDestroy, ViewChild, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { take, takeUntil, map, catchError } from 'rxjs/operators';
import { forkJoin, Observable, Subject, of} from 'rxjs';
import { InvoicingService } from '../../services/invoicing.service';
import { InvoicePeriod } from '../../models/invoice-period';
import { InvoicePeriodMetrics } from '../../models/invoice-period-metrics';
import { ClientContractService } from 'src/app/services/client-contract.service';
import { ToastService } from '../../services/toast.service';
import { ToastType } from 'src/app/models/toast';
import { ClientContract } from '../../models/client-contract';
import { User } from '../../models/user';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { InvoicingUtils } from '../../utilities/invoicing-utilities';
import { DestinationsDropdownViewComponent } from '../destinations-dropdown-view/destinations-dropdown-view.component';
import { IntegrationMapping } from "src/app/models/integration-mapping";

@Component({
  selector: 'app-invoicing-metrics-report',
  templateUrl: './invoicing-metrics-report.component.html',
  styleUrls: ['./invoicing-metrics-report.component.scss'],
  providers: [InvoicingService, ClientContractService]
})
export class InvoicingMetricsReportComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  invoicingUtils: InvoicingUtils;

  @ViewChild('destinationView') public destinationViewC: DestinationsDropdownViewComponent;

  // vars for setup from Invoiced Loans Report
  fromLoansReport: boolean = false;
  @Output() closeView = new EventEmitter<any>();
  @Input() loanContract: ClientContract = null;
  @Input() loanDestination: DestinationEndpoint = null;
  @Input() loanPeriod: InvoicePeriod = null;
  @Input() loanStartDate: Date = null;
  @Input() loanEndDate: Date = null;

  @Input() showPeriodSelectView: boolean = false; // true - by period, false = by date


  pageLoading = true;
  loadingFailed = false;
  pageReady: boolean = true;

  // view booleans
  showAllContractsView: boolean = true;
  showDateSelectView: boolean = false;

  showMetricsContainer: boolean = false;

  // destinations
  selectedDestination: DestinationEndpoint = null;
  selectedContract: ClientContract = null;
  selectedMapping: IntegrationMapping = null;

  hasDateRangeSelected: boolean = false;
  currentStartDate: Date = null;
  currentEndDate: Date = null;

  includeDisabled: boolean = true;

  allContracts: ClientContract[] = [];
  filteredContracts: ClientContract[] = [];
  hasContracts: boolean = false;

  allMetrics: InvoicePeriodMetrics[] = [];
  hasMetrics: boolean = false;
  gettingMetrics: boolean = false;

  constructor(
    protected invoicingService: InvoicingService,
    protected contractService: ClientContractService,
    protected toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private user: User,
    private ngZone: NgZone
  ) {
    this.invoicingUtils = new InvoicingUtils();
  }

  ngOnInit(): void {
    // this.pageReady = false;

    // from invoiced loans report
    if (this.loanContract != null) {
      this.fromLoansReport = true;
      this.selectedContract = this.loanContract;
      this.selectedDestination = this.loanDestination;
      this.showAllContractsView = false;

      this.gettingMetrics = true;

      if (this.showPeriodSelectView && this.loanPeriod != null) {
        this.currentStartDate = this.loanPeriod.dateStart;
        this.currentEndDate = this.loanPeriod.dateEnd;
      }
      else if (this.loanStartDate != null && this.loanEndDate != null){
        this.currentStartDate = this.loanStartDate;
        this.currentEndDate = this.loanEndDate;
      }
      this.hasDateRangeSelected = true;
      this.getMetricsForDateRange();
    }
    else {
      // from router
      this.route.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params) => {
        if (params["contractid"] != undefined && params["contractid"] != null) {
          this.pageReady = false;

          this.contractService
            .getClientContractWithInfoByContractId(params["contractid"])
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((contract) => {

              if (contract == null || contract == undefined) {
                  this.clearToStart();
              }
              else {
                this.selectedContract = contract;
              }

              this.showAllContractsView = false;

              this.pageReady = true;
            }
            ,(error) => {
              // client contract returned null or bad
              this.toastService.toastCreate("Client Contract Id did not match a valid contract", ToastType.Danger);
              this.clearToStart();
            });
        }
        else {
          this.pageReady = true;
        }
      });

      //this.getDefaultColumns();
      this.getContracts();
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  // toggle between all contracts and single contract view
  onToggleShowContractSelectViewChange(value: any): void {
    if (value === 'true') {
      this.showAllContractsView = true;
    } else if (value === 'false') {
      this.showAllContractsView = false;
    }
    // this.clearDestination();
    this.clearBasics();
  }

  getMetricsForDateRange() {
    this.showMetricsContainer = true;
    this.gettingMetrics = true;
    if (this.showAllContractsView) {
      // for multi contracts
      this.invoicingService.getInvoicePeriodMetricsForDestinationAndDates(
        this.selectedDestination.destinationEndpointId, this.currentStartDate, this.currentEndDate, this.includeDisabled
      ).subscribe((data) => {
        if (data != null) {
          var tmpAllMet: InvoicePeriodMetrics[] = [];
          var contractsWithNoMetrics: ClientContract[] = [];
          this.filteredContracts.forEach(c => {
            var dataMetrics = data.filter(x => x.clientContractId == c.clientContractId);
            if (dataMetrics != null && dataMetrics.length > 0) {
              var tmpAllMetC: InvoicePeriodMetrics[] = [];
              if (dataMetrics.length > 1) {
                dataMetrics.forEach(y => {
                  let m: InvoicePeriodMetrics = new InvoicePeriodMetrics(y, c);
                  tmpAllMetC.push(m);
                });
                let combined = this.invoicingUtils.mergeMetricsForContract(tmpAllMetC);
                tmpAllMet.push(combined);
              }
              else {
                let m: InvoicePeriodMetrics = new InvoicePeriodMetrics(dataMetrics[0], c);
                tmpAllMet.push(m);
              }
            }
            else {
              // console.log("No metrics returned for Date Range for Contract: " + c.clientFullName);
              contractsWithNoMetrics.push(c);
            }
          });

          if (tmpAllMet.length > 0) {
            this.allMetrics = tmpAllMet;
            this.hasMetrics = true;
            this.showMetricsContainer = true;
          }

          if (contractsWithNoMetrics.length > 0) {
            this.toastService.toastCreate(contractsWithNoMetrics.length + " of " + this.filteredContracts.length
                + " Client Contracts have no Invoice Period Metrics for selected date range.", "Info");
          }
        }
        else {
          this.hasMetrics = false;
        }
        this.gettingMetrics = false;
      });
    }
    else {
      if (this.selectedContract.integrationMappings == null
        || this.selectedContract.integrationMappings.length == 0) {
        this.contractService.getIntegrationMappingsByClientContractId(this.selectedContract.clientContractId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((mappings) => {
            this.selectedContract.integrationMappings = mappings;
          })
      }

      this.invoicingService.getInvoicePeriodMetricsForClient(
        this.selectedContract.clientContractId,
      ).subscribe((data) => {
          var tmpAllMet: InvoicePeriodMetrics[] = [];
          if (data.length > 0) {
            data.forEach(x => {
              let d: InvoicePeriodMetrics = new InvoicePeriodMetrics(x, this.selectedContract);
              tmpAllMet.push(d);
            });
            if (tmpAllMet.length > 0) {
              this.allMetrics = tmpAllMet;
              this.hasMetrics = true;
              this.showMetricsContainer = true;
            }
            else {
              this.hasMetrics = false;
            }
          }
          else {
            this.hasMetrics = false;
          }
          this.gettingMetrics = false;
      });
    }
    this.showMetricsContainer = true;
  }

  // get date range from invoicing-select-date-view
  public submitDateRange(event: [Date,Date]) {
    this.hasMetrics = false;
    this.gettingMetrics = true;
    this.hasDateRangeSelected = false;
    this.currentStartDate = event[0];
    this.currentEndDate = event[1];
    this.hasDateRangeSelected = true;
    this.getMetricsForDateRange();
  }

  // get destination - for All Contracts View
  getDestinationFromView(event: any): void {
    if (event == null || event == '') {
      this.selectedDestination = null;
      this.clearBasics();
    }
    else {
      this.selectedDestination = event;

      if (this.allContracts != null) {
        if (this.showAllContractsView) {
          this.filteredContracts = this.allContracts.filter(x => x.destinationEndpointId == this.selectedDestination.destinationEndpointId);
          if (this.filteredContracts.length > 0) {
            this.showDateSelectView = true;
          }
        }
      }
    }
  }

  getDestinationFromSelect(event: DestinationEndpoint): void {
    this.selectedDestination = event;
  }

  // get contract from invoicing-select-view
  getContractFromSelect(event: ClientContract): void {
    this.selectedContract = null;
    this.clearContractBasics();
    if (event != null) {
      this.selectedContract = event;
      this.getMetricsForDateRange();
      let contractId = encodeURI(this.selectedContract.clientContractId).replace("(", "%28").replace(")", "%29");
      window.history.replaceState({}, '',`/invoiced-metrics-report/${contractId}`);
    }
    else {
      this.showMetricsContainer = false;
      window.history.replaceState({}, '',`/invoiced-metrics-report`);
    }
  }

  getContractFromOverrideView(event: ClientContract): void {
    this.selectedContract = event;
    this.showMetricsContainer = true;
    this.getMetricsForDateRange();
  }

  clearContractBasics(): void {
    this.allMetrics = [];
    this.showMetricsContainer = false;
    this.hasMetrics = false;
    this.gettingMetrics = false;

    // need ?
    this.filteredContracts = this.allContracts;
    this.currentStartDate = null;
    this.currentEndDate = null;
    this.hasDateRangeSelected = false;
  }

  clearBasics(): void {
    this.selectedDestination = null;
    this.selectedContract = null;

    this.filteredContracts = [];
    this.filteredContracts = this.allContracts;
    this.allMetrics = [];
    this.hasContracts = false;
    this.hasMetrics = false;
    this.gettingMetrics = false;
    this.showMetricsContainer = false;
    this.showDateSelectView = false;
    this.selectedMapping = null;

    this.currentStartDate = null;
    this.currentEndDate = null;
    this.hasDateRangeSelected = false;
    window.history.replaceState({}, '',`/invoiced-metrics-report`);
  }

  setFailedLoading(event: any): void {
    this.pageLoading = false;
    if (event == true) {
      this.loadingFailed = true;

    }
    else if(event == false) {
      this.loadingFailed = false;
    }
  }

  // for include disabled checkbox in all contracts view
  changeIncludeDisabledCheck(x: boolean) {
    this.includeDisabled = !this.includeDisabled;
  }

  goBackToLoansReport(): void {
    this.closeView.emit(true);
  }


  clearToStart(): void {
    window.history.replaceState({}, '', `/invoiced-metrics-report`);
    window.location.reload();
  }

  getContracts(): void {
    this.contractService.getAllClientContractWithInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((r) => {
        if (r != null && r.length > 0) {
          this.allContracts = r;

          if (this.selectedDestination != null) {
            this.filteredContracts = this.allContracts.filter(c => c.destinationEndpointId == this.selectedDestination.destinationEndpointId);
          }
          else {
            this.filteredContracts = this.allContracts;
          }

          this.hasContracts = true;
        }
        else {
          this.toastService.toastCreate("No contracts returned.", "Warning");
        }
      });
  }

}
