import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ViewEncapsulation, EventEmitter, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin, Subject } from 'rxjs';
import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State, DataResult, DataSourceRequestState, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ClientIntegrationService } from '../../../services/client-integration.service';
import { ToastService } from '../../../services/toast.service';
import { ReportService } from '../../../services/report.service';
import { Report } from '../../../models/report';
import { Server } from '../../../models/server';
import { Client } from '../../../models/client';
import { DestinationEndpoint } from '../../../models/destination-endpoint';
import { ImportType } from '../../../models/import-type';
import { DataSourceType } from '../../../models/data-source-type';
import { IntegrationType } from '../../../models/integration-type';
import { IntegrationRunTime } from '../../../models/integration-runtime';
import { SearchTerm } from '../../../models/search-term';
import { Integration } from '../../../models/integration';
import { Toast, ToastType } from '../../../models/toast';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent, RowArgs, ExcelModule } from '@progress/kendo-angular-grid';
import { takeUntil, throwIfEmpty, take } from 'rxjs/operators';
import { IntegrationJavaScript } from 'src/app/models/integration-javascript';
import { LOSTalkerTitleService } from '../../../services/lostalker-title-service.service';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'integration-report-view',
  templateUrl: './integration-report-view.component.html',
  styleUrls: ['./integration-report-view.component.scss'],
  providers: [ClientIntegrationService, ReportService, ToastService, LOSTalkerTitleService]
})
export class IntegrationReportViewComponent implements OnInit, OnDestroy {
  @ViewChild('genericGrid') public kendoGenericGrid: GridComponent;

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() reportId = "";
  @Input() currentReportToShow: Report;
  currReport: Report;

  public state: State = {
    skip: 0,
    take: 10,
    sort: []
  };

  type: 'numeric' | 'input' = 'input';
  gridView: GridDataResult;
  gridViewIsLoading = true;

  filtersChanged = false;
  xmlSearchTerm: SearchTerm;
  jsSearchTerm: SearchTerm;

  baseData: Integration[] = [];
  filteredData: Integration[];
  public currentFiltersFromGrid: CompositeFilterDescriptor = null; // holds current grid column filter

  // in report
  useJSSearch = false;
  jsLoaded = false;

  @Input() serverItems: Server[] = [];
  @Input() dataSourceTypeItems: DataSourceType[] = [];
  @Input() destinationEndpointItems: DestinationEndpoint[] = [];
  @Input() importTypeItems: ImportType[] = [];
  @Input() integrationRunTimeItems: IntegrationRunTime[] = [];
  javascripts: IntegrationJavaScript[] = [];

  serverItemsSelected: Server[] = [];
  dataSourceItemsSelected: DataSourceType[] = []; // Dynamic CSV ...
  destinationEndpointItemsSelected: DestinationEndpoint[] = []; // csd.. lg..
  importTypeItemsSelected: ImportType[] = [];

  constructor(
    protected clientService: ClientIntegrationService,
    protected reportService: ReportService,
    protected toastService: ToastService,
    private location: Location,
    private router: Router,
    private titleService: LOSTalkerTitleService,
    private ngZone: NgZone
  ) {
    this.xmlSearchTerm = new SearchTerm('xmlConfig', 'Search XML Config...');
    this.jsSearchTerm = new SearchTerm('', 'Search JS...');
    this.filteredData = [];
    this.selectedData = this.selectedData.bind(this);
  }

  ngOnInit(): void {
    this.state.sort = [{ field: "integrationId", dir: 'asc' }];
    if (this.currentReportToShow) {
      this.currReport = new Report(this.currentReportToShow);
      this.titleService.setUpTitle('Report - ' + this.currentReportToShow.title);

      this.getIntegrations();
    }
  }

  public ngAfterViewInit(): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.kendoGenericGrid.autoFitColumns();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getIntegrations(): void {
    this.gridView = { data: [], total: 0 }
    this.gridViewIsLoading = true;
    if (this.currReport.dataSproc !== "" && this.currReport.dataSproc !== undefined) {
      if (this.useJSSearch) {
        this.jsLoaded = true;
        this.reportService.getIntegrationTypeQueryDataWithJS(
          this.currReport.reportId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (data) => {
              this.baseData = data;

              if (this.integrationRunTimeItems.length > 0 && this.baseData.length > 0) {
                this.baseData.forEach(i => {
                  i.integrationRunTimes = this.integrationRunTimeItems.filter(x => x.integrationId == i.integrationId);
                });
              }

              this.updateFilteredData();
            },
            (error) => {
              this.gridViewIsLoading = false;
            });
      }
      else {
        this.reportService.getIntegrationTypeQueryData(
          this.currReport.reportId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (data) => {
              this.baseData = data;

              if (this.integrationRunTimeItems.length > 0 && this.baseData.length > 0) {
                this.baseData.forEach(i => {
                  i.integrationRunTimes = this.integrationRunTimeItems.filter(x => x.integrationId == i.integrationId);
                });
              }

              this.updateFilteredData();
            },
            (error) => {
              this.gridViewIsLoading = false;
            });
      }
    }
  }

  // selection in grid
  public selectionGridChangeHandler(event: any): void {

  }

  public sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateFilteredData();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.take = state.take;
    this.state.skip = state.skip;
    this.updateFilteredData();
  }

  // update filters from grid column filters
  public filterChange(filter: CompositeFilterDescriptor) {
    this.state.skip = 0;
    this.updateFilteredData(filter);
  }

  clearSearch(item: SearchTerm) {
    item.clear();
    this.updateFilteredData();
  }

  updateSearch(item: SearchTerm) {
    item.save();
    this.updateFilteredData();
  }

  loadJS(event: any) {
    this.jsLoaded = true;
    this.gridViewIsLoading = true;
    this.gridView = { data: [], total: 0 }
    this.clientService.getAllIntegrationJavaScripts().subscribe(data => {
      this.javascripts = data;
      this.baseData.forEach(i => {
        let js = this.javascripts.filter(j => j.integrationId == i.integrationId);
        if(js != null)
        {
          i.javaScript = js;
        }
      })
      this.updateFilteredData();
    });
  }

  // update filters without the column filters
  public updateFilteredData(filterFromGrid: CompositeFilterDescriptor = null) {
    this.filteredData = [];
    this.filteredData = this.baseData;

    if (filterFromGrid != null) {
      let lockFiltered:boolean = false;
      let usingLockedFiltered:boolean = false;
      let ipFiltered:boolean = false;
      let usingIPFiltered:boolean = false;
      let hostedFiltered:boolean = false;
      let usingHostedFiltered:boolean = false;
      filterFromGrid.filters.forEach(function (filter:FilterDescriptor) {
        if (filter.field != null && filter.field == 'locked') {
          usingLockedFiltered = true;

          if (typeof filter.value == 'boolean' && filter.operator == "equals") {
            lockFiltered = true;
          } else {
            filter.operator = "equals";
            filter.value = filter.value.toString().toLowerCase();
            if (filter.value =='y' || filter.value == 'ye' || filter.value == 'yes') {
              filter.value = true;
            } else if (filter.value == 'n' || filter.value == 'no'){
              filter.value = false;
            }
            lockFiltered = true;
          }
        }

        if (filter.field != null && filter.field == 'ipRestricted') {
          usingIPFiltered = true;

          if (typeof filter.value == 'boolean' && filter.operator == "equals") {
            ipFiltered = true;
          } else {
            filter.operator = "equals";
            filter.value = filter.value.toString().toLowerCase();
            if (filter.value =='y' || filter.value == 'ye' || filter.value == 'yes') {
              filter.value = true;
            } else if (filter.value == 'n' || filter.value == 'no'){
              filter.value = false;
            }
            ipFiltered = true;
          }
        }

        if (filter.field != null && filter.field == 'selfHosted') {
          usingHostedFiltered = true;

          if (typeof filter.value == 'boolean' && filter.operator == "equals") {
            hostedFiltered = true;
          } else {
            filter.operator = "equals";
            filter.value = filter.value.toString().toLowerCase();
            if (filter.value =='y' || filter.value == 'ye' || filter.value == 'yes') {
              filter.value = true;
            } else if (filter.value == 'n' || filter.value == 'no'){
              filter.value = false;
            }
            hostedFiltered = true;
          }
        }
      });

      if (usingLockedFiltered && !lockFiltered && filterFromGrid.filters.length > 0) {
        this.filteredData = [];
        return;
      }
      if (usingIPFiltered && !ipFiltered && filterFromGrid.filters.length > 0) {
        this.filteredData = [];
        return;
      }
      if (usingHostedFiltered && !hostedFiltered && filterFromGrid.filters.length > 0) {
        this.filteredData = [];
        return;
      }
    }

    if (filterFromGrid != null && this.currentFiltersFromGrid != null) {
      this.currentFiltersFromGrid = filterFromGrid;
      this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
    }
    else if (filterFromGrid != null && this.currentFiltersFromGrid == null) {
      this.currentFiltersFromGrid = filterFromGrid;
      this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
    }
    else if (filterFromGrid == null && this.currentFiltersFromGrid != null) {
      this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
    }

    // filter xml config by Search Term
    if (this.currReport.hasSearch && this.xmlSearchTerm.hasSearchTerm) {
      this.filteredData = filterBy(this.filteredData, {
        logic: 'and',
        filters: [
          { field: "xmlConfig", operator: "contains", value: this.xmlSearchTerm.currentSearchValue, ignoreCase: true }
        ]
      });
    }
    // filter js config by Search Term
    if (this.jsLoaded && this.currReport.hasSearch && this.jsSearchTerm.hasSearchTerm) {
      this.filteredData = this.filteredData.filter(x => x.javaScript.some(y => y.javaScript.toLowerCase().includes(this.jsSearchTerm.currentSearchValue.toLowerCase())));
    }

    // filter by Integration Filters
    if (this.currReport.hasFilterCards) {
      // data source
      if (this.dataSourceItemsSelected.length > 0) {
        let dataSourceIds = this.dataSourceItemsSelected.map(i => i.dataSourceTypeId);
        this.filteredData =
          this.filteredData.filter(i => dataSourceIds.indexOf(i.dataSourceTypeId) != -1);
      }

      // RunTime 
      if (this.integrationRunTimeItems.length > 0) {
        // Destination Endpoint
        if (this.destinationEndpointItemsSelected.length > 0
          && this.destinationEndpointItemsSelected.length != this.destinationEndpointItems.length) {
            var dests = this.destinationEndpointItemsSelected.map(i => i.destinationEndpointId);
            this.filteredData = this.filteredData.filter(i => i.integrationRunTimes != null);
            this.filteredData = this.filteredData.filter(x => x.integrationRunTimes?.filter(y => dests.some(z => y.destinationEndpointId == z)).length != 0);
        }
        // Import Type
        if (this.importTypeItemsSelected.length > 0
          && this.importTypeItemsSelected.length != this.importTypeItems.length) {
            var imTypes = this.importTypeItemsSelected.map(i => i.importTypeId);
            this.filteredData = this.filteredData.filter(i => i.integrationRunTimes != null);
            this.filteredData = this.filteredData.filter(x => x.integrationRunTimes?.filter(y => imTypes.some(z => y.importTypeId == z)).length != 0);
        }
      }
      // server
      if (this.serverItemsSelected.length > 0) {
        let serverIds = this.serverItemsSelected.map(i => i.serverId);
        this.filteredData = this.filteredData.filter(i => serverIds.indexOf(i.serverId) != -1);
      }
    }

    this.loadGridView();
  }

  public loadGridView(): void {
    if (this.filteredData != undefined) {
      let orderedData = orderBy(this.filteredData, this.state.sort);
      this.gridView = {
        data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
        total: orderedData.length
      };
      this.gridViewIsLoading = false;
    }
    this.fitColumns();
  }

  // reset filters
  public resetFilters(e: Event): void {
    this.state.skip = 0;
    this.dataSourceItemsSelected = [];
    this.destinationEndpointItemsSelected = [];
    this.serverItemsSelected = [];
    this.importTypeItemsSelected = [];
    this.currentFiltersFromGrid = null;
    this.updateFilteredData();
  }
  // apply filters
  public applyFilters(e: Event): void {
    this.state.skip = 0;
    this.updateFilteredData();
  }

  // gets data to export
  public selectedData(): ExcelExportData {
    var processedData = this.filteredData;
    processedData.forEach(i => i.xmlConfig = "");
    const result: ExcelExportData = {
      data: processedData
    };

    return result;
  }

  public externalFilterChange(value: any): void {
    //console.log("externalFilterChange", value);
  }

}
