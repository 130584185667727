<div class="container-fluid h-100 d-flex flex-column">
  <div class="row">
    <ng-container>
      <div class="errors-grid-container" kendoTooltip showOn="none" [tooltipTemplate]="ltErrorGridToolTip"
        filter=".k-grid td" (mouseover)="showTooltip($event)">

        <kendo-grid #errorGrid autosize="true" [data]="gridView" [kendoGridBinding]="data" [pageSize]="state.take"
          [skip]="state.skip" [sortable]="{mode: 'single',allowUnsort: false}" [sort]="state.sort" [resizable]="true"
          [reorderable]="true" [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
          [scrollable]="'scrollable'" [selectable]="{enabled: true, checkboxOnly: true, mode: 'multiple'}"
          kendoGridSelectBy="integrationErrorId" [(selectedKeys)]="selectedErrorIds" [rowSelected]="isRowSelected"
          [kendoGridExpandDetailsBy]="expandDetailsBy" [(expandedDetailKeys)]="expandedDetailKeys"
          [filter]="currentFiltersFromGrid" [filterable]="true" (sortChange)="sortChange($event)"
          (pageChange)="pageChange($event)" (cellClick)="onCellClick($event)"
          (filterChange)="updateFilteredData($event)">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton [primary]="true" type="button" (click)="openBulkUpdateDialog()"
              *ngIf="selectedErrorIds != undefined && selectedErrorIds.length > 0">
              Update {{selectedErrorIds.length}} Selected Errors
            </button>
          </ng-template>
          <kendo-grid-checkbox-column title="Select" [showSelectAll]="true" [width]="30"
            [columnMenu]="false"></kendo-grid-checkbox-column>
          <kendo-grid-column field="currentStatusId" title="Current Status" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="inline">
                <mat-button-toggle-group (change)="triggerUpdateStatusMessage(dataItem, $event.value)"
                  style="height: 40px;" [hideSingleSelectionIndicator]="true">
                  <mat-button-toggle value="Unresolved" matTooltip="Unresolved"
                    [checked]="'Unresolved' == dataItem.statusName">
                    <i class="bi bi-exclamation-circle-fill"></i>
                  </mat-button-toggle>
                  <mat-button-toggle value="InProgress" matTooltip="InProgress"
                    [checked]="'InProgress' == dataItem.statusName">
                    <i class="bi bi-cone-striped"></i>
                  </mat-button-toggle>
                  <mat-button-toggle value="NotImportant" matTooltip="NotImportant"
                    [checked]="'NotImportant' == dataItem.statusName">
                    <i class="bi bi-info-circle-fill"></i>
                  </mat-button-toggle>
                  <mat-button-toggle value="Resolved" matTooltip="Resolved"
                    [checked]="'Resolved' == dataItem.statusName">
                    <i class="bi bi-check-circle"></i>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </ng-template>

            <ng-template kendoGridFilterCellTemplate>
              <mat-button-toggle-group (change)="statusFilterChange($event.value)" style="height: 40px;"
                [(ngModel)]="statusSearchTerm" [hideSingleSelectionIndicator]="true">
                <mat-button-toggle value="Unresolved" matTooltip="Unresolved">
                  <i class="bi bi-exclamation-circle-fill"></i>
                </mat-button-toggle>
                <mat-button-toggle value="InProgress" matTooltip="InProgress">
                  <i class="bi bi-cone-striped"></i>
                </mat-button-toggle>
                <mat-button-toggle value="NotImportant" matTooltip="NotImportant">
                  <i class="bi bi-info-circle-fill"></i>
                </mat-button-toggle>
                <mat-button-toggle value="Resolved" matTooltip="Resolved">
                  <i class="bi bi-check-circle"></i>
                </mat-button-toggle>
              </mat-button-toggle-group>

              <button *ngIf="hasStatusFilter" class="k-button k-button-icon" title="Clear"
                (click)="clearStatusFilter()">
                <span class="k-icon k-i-filter-clear"></span>
              </button>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="receivedDate" title="Last Received" [width]="70" [filterable]="false">
            <ng-template #running kendoGridCellTemplate let-dataItem>
              <span [innerHTML]="formatDate(dataItem.receivedDate)"></span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="integrationName" title="Integration Name" [width]="100">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="currentFiltersFromGrid">
              </kendo-grid-string-filter-cell>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="logMessage" title="Log Message" [width]="400">
            <ng-template #running kendoGridCellTemplate let-dataItem>
              {{dataItem.logMessage?.substr(0, 200)}}
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="currentFiltersFromGrid">
              </kendo-grid-string-filter-cell>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="criticalError" title="Critical" [width]="70" filter="boolean"
            [class]="{ 'text-center': true }">
            <ng-template #running kendoGridCellTemplate let-dataItem>
              <ng-container
                *ngIf="(dataItem.criticalError == true || dataItem.actionNeeded == true) && dataItem.errorStatusId !== 'Resolved'">
                <i class="bi bi-x-circle-fill" style="color: red; font-size: 28px; left: 10px"></i>
              </ng-container>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="endpointName" title="Destination" [width]="100">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="currentFiltersFromGrid">
              </kendo-grid-string-filter-cell>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="errorCount" title="#" [width]="70">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="currentFiltersFromGrid">
              </kendo-grid-string-filter-cell>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="typeDescription" title="Import Type" [width]="60">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="currentFiltersFromGrid">
              </kendo-grid-string-filter-cell>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="categoryName" title="Category-Priority" [width]="100">
            <ng-template #running kendoGridCellTemplate let-dataItem>
              {{dataItem.categoryName}}-{{dataItem.categoryPriority}}
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [showOperators]="false" [column]="column"
                [filter]="currentFiltersFromGrid">
              </kendo-grid-string-filter-cell>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="serverId" [width]="50" [headerClass]="{ 'text-center': true }">
            <ng-template kendoGridHeaderTemplate>
              <i class="bi bi-server" matTooltip="Server"></i>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="currentFiltersFromGrid">
              </kendo-grid-string-filter-cell>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="initialErrorDate" title="First Received" [width]="70" [filterable]="false">
            <ng-template #running kendoGridCellTemplate let-dataItem>
              <span [innerHTML]="formatDate(dataItem.initialErrorDate)"></span>
            </ng-template>
          </kendo-grid-column>


          <ng-template kendoGridDetailTemplate let-dataItem>
            <section>
              <div class="container-fluid h-100 d-flex flex-column">
                <div class="row flex-grow-1">
                  <div class="col col-lg-10">
                    <p style="text-wrap: balance;"><strong>Full Log Message:</strong> {{dataItem.logMessage}}</p>
                  </div>
                </div>
                <div class="row flex-grow-1">
                  <div class="col-3">
                    <p><strong>Integration Name:</strong> {{dataItem.integrationName}}</p>
                    <p><strong>Integration ID:</strong> {{dataItem.integrationId}}</p>
                    <p><strong>Client Name:</strong> {{dataItem.clientName}}</p>
                    <p><strong>Client ID:</strong> {{dataItem.clientId}}</p>
                    <p><strong>Current Integration Server:</strong> {{dataItem.integrationServerId}}</p>
                  </div>
                  <div class="col-3">
                    <p><strong>Error Message ID:</strong> {{dataItem.integrationErrorId}}</p>
                    <p><strong>Server Error Occurred On:</strong> {{dataItem.serverId}}</p>
                    <p><strong>Auto Resolve On Successful Integration:</strong> {{dataItem.automatedResolution}}</p>
                    <p><strong>Data-Source Type:</strong> {{dataItem.dataSourceTypeId}}</p>
                    <p><strong>Function:</strong> {{dataItem.functionWithin}}</p>
                  </div>
                  <div class="col-3">
                    <p><strong>Action Needed:</strong> {{dataItem.actionNeeded}}</p>
                    <p><strong>Critical Error:</strong> {{dataItem.criticalError}}</p>
                    <p *ngIf="dataItem.statusName == 'Resolved'"><strong style="color: green">Resolved On:</strong>
                      {{formatDate(dataItem.resolutionDate)}}</p>
                    <p *ngIf="dataItem.statusName == 'Resolved'"><strong style="color: green">Resolution
                        Comment:</strong> {{dataItem.resolutionComment}}</p>
                  </div>
                </div>
                <div class="row flex-grow-1">
                  <div class="col col-lg-10">
                    <div style="display: flex;">
                      <button style="height: 75%; margin-left: 10px;"
                        *ngIf="dataItem.jiraStoryURL == undefined && !addJiraURL" kendoButton
                        (click)="addJiraURL = true">Add
                        Existing JIRA Ticket URL</button>
                      <button style="height: 75%; margin-left: 10px;"
                        *ngIf="dataItem.jiraStoryURL == undefined && !addJiraURL" kendoButton
                        (click)="generateJiraTicket(dataItem)">Generate JIRA Ticket</button>
                      <mat-form-field *ngIf="addJiraURL" style="width: 400px;height: 75%;" class="example-full-width"
                        appearance="fill">
                        <mat-label>JIRA Ticket URL</mat-label>
                        <textarea matInput [(ngModel)]="dataItem.jiraStoryURL"></textarea>
                      </mat-form-field>
                      <button *ngIf="addJiraURL" style="height: 75%; margin-left: 10px;" kendoButton
                        (click)="updateJiraURL(dataItem)">Update
                        Error</button>
                      <p *ngIf="dataItem.jiraStoryURL != undefined && !addJiraURL"><strong>JIRA Ticket:</strong>
                        {{dataItem.jiraStoryURL}}</p>
                      <button style="height: 75%; margin-left: 10px;"
                        *ngIf="dataItem.jiraStoryURL != undefined && !addJiraURL" kendoButton
                        (click)="addJiraURL = true">Update URL</button>
                      <button style="height: 75%; margin-left: 10px;" kendoButton
                        (click)="navigateToClient(dataItem)">Go To
                        Client</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ng-template>
          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
          </ng-template>

          <ng-template kendoGridNoRecordsTemplate>
            <div class="empty_spacing_for_norecords">
              <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
              <div class="no-records-message" *ngIf="!gridViewIsLoading && gridView">No records found.</div>
              <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridView">Loading failed.</div>
            </div>
          </ng-template>

        </kendo-grid>
      </div>
      <kendo-excelexport [data]="data" [collapsible]="true" fileName="Errors.xlsx" #excelexport>
        <kendo-excelexport-column field="dataSourceTypeId" title="Data Source Type"></kendo-excelexport-column>
        <kendo-excelexport-column field="serverId" title="Server Id"></kendo-excelexport-column>
        <kendo-excelexport-column field="integrationName" title="Integration"></kendo-excelexport-column>
        <kendo-excelexport-column field="integrationTypeId" title="Integration Type Id"></kendo-excelexport-column>
        <kendo-excelexport-column field="errorCount" title="Error Count"></kendo-excelexport-column>
        <kendo-excelexport-column field="receivedDate" title="Last Received"></kendo-excelexport-column>
        <kendo-excelexport-column field="initialErrorDate" title="First Received"></kendo-excelexport-column>
        <kendo-excelexport-column field="logMessage" title="Log Message"></kendo-excelexport-column>
        <kendo-excelexport-column field="categoryName" title="Category"></kendo-excelexport-column>
        <kendo-excelexport-column field="locked" title="Locked"></kendo-excelexport-column>
        <kendo-excelexport-column field="resolutionDate" title="Resolution Date"></kendo-excelexport-column>
        <kendo-excelexport-column field="resolutionComment" title="Resolution Comment"></kendo-excelexport-column>
      </kendo-excelexport>

    </ng-container>
  </div>
  <div class="row flex-grow-1" style="margin-top: 10px; margin-bottom: 10px;" *ngIf="data != undefined">
    <div class="col-2 h-100">
      <button kendoButton [primary]="true" style="width: 95%; height: 100%; font-size: 15px;"
        (click)="saveData(excelexport)">Export To Excel</button>
    </div>
  </div>
</div>

<ng-template #ltErrorGridToolTip let-anchor>
  <span>{{anchor.nativeElement.innerText}}</span>
</ng-template>

<ng-template #updateErrorStatusDialog>
  <h2 matDialogTitle>Enter Resolution Comment</h2>
  <mat-dialog-content>
    <p>Integration error: {{this.selectedError.logMessage}}</p>
    <p>New Status: {{this.selectedStatus.statusName}}</p>
    <div class="example-wrapper">
      <span>
        <h6>Resolution Comment:</h6>
      </span>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Max 200 characters</mat-label>
        <input matInput maxlength="200" [(ngModel)]="selectedError.resolutionComment">
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose (click)="updateError()">Update Status</button>
    <button mat-button matDialogClose>Close</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #bulkUpdateDialog>
  <h2 matDialogTitle>Bulk Error Update Scope</h2>
  <mat-dialog-content>
    <div class="container flexWrap" style="margin-bottom: 10px">
      <div class="row">
        <p>Updating {{bulkUpdateDetails.integrationErrorIdList.length}} Errors:</p>
        <p *ngIf="bulkUpdateSelectedStatus != undefined">New Status: {{this.bulkUpdateSelectedStatus.statusName}}</p>
      </div>
      <div class="row" style="margin-bottom: 20px;">
        <div class="col-sm">
          <h6>Critical Error</h6>
          <mat-button-toggle-group name="criticalError" [(ngModel)]="bulkUpdateDetails.criticalError"
            aria-label="Critical Error" value="">
            <mat-button-toggle value="true">Yes</mat-button-toggle>
            <mat-button-toggle value="false">No</mat-button-toggle>
            <mat-button-toggle>No Update</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="col-sm">
          <h6>Action Needed</h6>
          <mat-button-toggle-group name="actionNeeded" [(ngModel)]="bulkUpdateDetails.actionNeeded"
            aria-label="Action Needed" value="">
            <mat-button-toggle value="true">Yes</mat-button-toggle>
            <mat-button-toggle value="false">No</mat-button-toggle>
            <mat-button-toggle>No Update</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="col-sm">
          <h6>Automated Resolution</h6>
          <mat-button-toggle-group name="autoResolution" [(ngModel)]="bulkUpdateDetails.automatedResolution"
            aria-label="Auto Resolution" value="">
            <mat-button-toggle value="true">Yes</mat-button-toggle>
            <mat-button-toggle value="false">No</mat-button-toggle>
            <mat-button-toggle>No Update</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="row">
        <div class="col-4" style="margin-right: 10px;">
          <mat-form-field appearance="fill">
            <!-- style="min-width: -webkit-fill-available; height: 100%;" -->
            <mat-label>Update to Status</mat-label>
            <mat-select [(ngModel)]="bulkUpdateSelectedStatus" (selectionChange)="updateBulkStatus($event)">
              <mat-option *ngFor="let status of statuses" [value]="status">
                {{status.statusName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg">
          <mat-form-field class="example-full-width" appearance="fill" style="width: -webkit-fill-available">
            <mat-label>Jira Ticket URL</mat-label>
            <input matInput maxlength="200" [(ngModel)]="bulkUpdateDetails.jiraStoryURL">
          </mat-form-field>
        </div>
      </div>
      <div class="row"
        *ngIf="bulkUpdateSelectedStatus != undefined && bulkUpdateSelectedStatus.statusName == 'Resolved'">
        <span>
          <h6>Resolution Comment:</h6>
        </span>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Max 200 characters</mat-label>
          <input matInput maxlength="200" [(ngModel)]="bulkUpdateDetails.resolutionComment">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose
      [disabled]="bulkUpdateSelectedStatus != undefined && bulkUpdateSelectedStatus.statusName == 'Resolved' && bulkUpdateDetails.resolutionComment == undefined"
      (click)="bulkUpdateErrors()">
      Update Status
    </button>
    <button mat-button matDialogClose>Close</button>
  </mat-dialog-actions>
</ng-template>