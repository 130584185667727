export class Client {

  constructor(id: number, name?: string){
    this.clientId = id;
    this.name = name;
  }

  clientId: number;
  name: string;
  clientIntegrationAbbrName: string;
  compensafeInternalName: string;
  dataSourceTypeAgg: string;
  aliases?: string;
}
