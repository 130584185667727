import { Component, OnInit, OnChanges, OnDestroy, Input, Output, ViewChild, EventEmitter, SimpleChanges, AfterViewInit, NgZone } from '@angular/core';
import { Observable, Subscription, Subject, forkJoin } from 'rxjs';
import { takeUntil, take } from "rxjs/operators";
import { ToastService } from '../../services/toast.service';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { State, DataSourceRequestState, orderBy, CompositeFilterDescriptor, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, SelectionEvent, RowArgs, SelectableSettings, SelectableMode } from '@progress/kendo-angular-grid';
import { ColumnSettings } from '../../models/column-settings';
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

const flatten = (filter) => {
  const filters = filter.filters;
  if (filters) {
      return filters.reduce(
          (acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]),
          []
      );
  }
  return [];
};

@Component({
  selector: 'app-view-generic-grid',
  templateUrl: './view-generic-grid.component.html',
  styleUrls: ['./view-generic-grid.component.scss']
})
export class ViewGenericGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit  {
  @ViewChild('genericGrid') public kendoGenericGrid: GridComponent;
  @ViewChild('excelExportGrid') public excelExportGrid: ExcelExportComponent;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() data: any[];
  @Input() loading: boolean; // show loading icon from parent
  @Input() customExport: boolean = false;
  @Input() exportFileName: string = "Data";
  @Input() columns: ColumnSettings[] = [];
  @Input() isReorderable: boolean = true;
  @Input() isFilterable: boolean = true;
  @Input() isResizeable: boolean = false;
  @Input() isSelectable: boolean = true;
  @Input() showSelectionColumn: boolean = false;
  @Input() showColumnMenu: boolean = true;
  @Input() selectionfield: string = "";
  @Input() showExportToolbar: boolean = true;
  @Input() showChangeSelection: boolean = false;
  @Input() sortDescriptions: SortDescriptor[] = [];
  @Input() enableAutoFit: boolean = false;

  @Input() hasActionGoToColumn: boolean = false;
  @Input() actionGoToUsePrefix: boolean = false;
  @Input() actionGoToField: string = "";
  @Input() actionGoToPrefix: string = "";

  @Input() actionGoToFieldAddl: string[] = [];
  @Input() actionGoToUseAddlField: boolean = false;

  @Input() actionGoToUseSuffix: boolean = false;
  @Input() actionGoToSuffix: string = "";

  @Input() showSelectDeselectButtons: boolean = false;
  @Input() selectionColumnWidth: number = 100;
  @Input() selectionColumnHeader: string = "Select";

  @Output() getUpdated = new EventEmitter<string>(); // ask parent for updated data
  @Output() itemSelected = new EventEmitter<any>(); // ask parent for updated data

  @Input() overrideSelectedItem: any = null;

  public cols: ColumnSettings[] = [];

  hasData: boolean = false;
  selectedItem: any = null;

  public gridViewIsLoading: boolean = true;
  gridView: GridDataResult;

  public allGroup: any[];
  public filteredGroup: any[];

  type: 'numeric' | 'input' = 'input';
  public pageSizes: number[] = [5, 10, 25, 50, 100];
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor( private toastService: ToastService, private ngZone: NgZone ) { }

  ngOnInit(): void {
    if (this.columns != null && this.columns.length > 0) {
      this.cols = [];
      this.columns.forEach(c => this.cols.push(new ColumnSettings(c)));
    }
    else {
      this.buildColumnSettings();
    }

    if (this.sortDescriptions != null && this.sortDescriptions.length > 0) {
      this.state.sort = this.sortDescriptions;
    }

    if (this.data != null) {
      if (this.data.length > 0) {
        this.hasData = true;
        this.allGroup = this.data;
        this.loadDataIntoGridView()
      }
      else {
        this.hasData = false;
        this.gridViewIsLoading = false;
      }
    }

    this.allData = this.allData.bind(this);
  }

  public ngAfterViewInit(): void {
    if (this.columns.filter(x => x.hidden == false).length > 10) {
      this.fitColumns();
    }
    else if (this.enableAutoFit){
      this.fitColumns();
    }
  }

  public fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.kendoGenericGrid.autoFitColumns();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data?.currentValue != null && changes?.data?.firstChange != true) {
      if (this.data.length > 0) {
        this.gridViewIsLoading = true;
        if (this.showChangeSelection && this.selectionfield != null && this.selectionfield != "") {
          if (this.selectedItem != null ) {
            if (this.data.find(x => x[this.selectionfield] == this.selectedItem[this.selectionfield]) == null) {
              this.allGroup = this.data;
              this.selectedItem = null;
            }
          }
          else {
            this.allGroup = this.data;
          }
        }
        else {
          this.allGroup = this.data;
        }
        this.loadDataIntoGridView();
      }
      else if (this.data.length == 0) {
        this.gridViewIsLoading = true;
        this.allGroup = this.data;
        this.loadDataIntoGridView();
      }
    }
    if (changes?.columns?.currentValue != null && changes?.columns?.firstChange != true){
      if (this.columns.length > 0) {
        this.gridViewIsLoading = true;
        this.cols = [];
        this.columns.forEach(c => this.cols.push(new ColumnSettings(c)));
        this.loadDataIntoGridView();
      }
    }
    if (changes?.overrideSelectedItem?.currentValue != null && changes?.overrideSelectedItem?.firstChange != true) {
      if (this.data.find(x => x[this.selectionfield] == this.overrideSelectedItem[this.selectionfield]) == null) {
        this.selectedItem = null;
      }
      else {
        this.selectedItem = this.overrideSelectedItem;
      }
    }
  }

  selectionChange(event: any): void {
    this.selectedItem = event.selectedRows[0].dataItem;
    this.updateSelectedItem();
  }

  selectItem(dataItem: any) {
    this.selectedItem = dataItem;
    this.updateSelectedItem();
  }

  deselectItem() {
    this.selectedItem = undefined;
    this.updateSelectedItem();
  }

  updateSelectedItem() {
    this.itemSelected.emit(this.selectedItem);
    if (this.showChangeSelection) {
      this.allGroup = [...this.selectedItem];
    }
    //this.loadDataIntoGridView();
  }

  public changeSelectionView() {
    this.selectedItem = null;
    this.gridViewIsLoading = true;
    this.state.skip = 0
    this.state.filter = null;
    this.allGroup = this.data;
    this.gridViewIsLoading = false;
    this.selectedItem = null;
    this.itemSelected.emit(null);
    this.loadDataIntoGridView();
  }

  public saveData(component: any): void {
    component.save();
  }

  buildColumnSettings(): void {
    if (this.data != null && this.data.length > 0) {
      var fields: string[] = Object.getOwnPropertyNames(this.data[0]);
      if (fields != null && fields.length > 0) {
        this.cols = [];
        fields.forEach(c => this.cols.push(new ColumnSettings({ field: c, title: c})));
      }
    }
  }

  public getCurrentGridData(): any[] {
    let orderedData = orderBy(this.allGroup, this.kendoGenericGrid.sort);
    this.filteredGroup = filterBy(orderedData, this.kendoGenericGrid.filter);
    return this.filteredGroup;
  }

  public allData(): ExcelExportData {
    let orderedData = orderBy(this.allGroup, this.kendoGenericGrid.sort);
    this.filteredGroup = filterBy(orderedData, this.kendoGenericGrid.filter);
    const result: ExcelExportData = {
      data: this.filteredGroup
    };
    return result;
  }

  public resetSkipIndex(): void {
    this.state.skip = 0;
    this.loadDataIntoGridView();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  loadDataIntoGridView() {
    let orderedData = orderBy(this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.filter = filter;
    this.state.skip = 0;
    this.filteredGroup = filterBy(this.allGroup, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };

  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  getRouterLink(dataItem: any): string[] {
    var rc = [];
    if (this.actionGoToUsePrefix) {
      rc.push(this.actionGoToPrefix);
    }
    if (this.actionGoToField != null && this.actionGoToField != undefined && this.actionGoToField != "") {
      rc.push(dataItem[this.actionGoToField]);
    }
    if (this.actionGoToUseAddlField && this.actionGoToFieldAddl != null && this.actionGoToFieldAddl.length > 0) {
      this.actionGoToFieldAddl.forEach(x => {
        if (x != "") {
          rc.push(dataItem[x]);
        }
      });
    }
    if (this.actionGoToUseSuffix) {
      rc.push(this.actionGoToSuffix);
    }
    return rc;
  }
  
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.className.indexOf('k-column-title') > -1)
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

}
