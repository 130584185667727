import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentStatus } from 'src/app/models/currentStatus';
import { ErrorCategory } from 'src/app/models/error-category';
import { ErrorSearchFilter } from 'src/app/models/error-search-filter';
import { ImportType } from 'src/app/models/import-type';

@Component({
  selector: 'basic-error-search-filter',
  templateUrl: './basic-error-search-filter.component.html',
  styleUrls: ['./basic-error-search-filter.component.scss']
})
export class BasicErrorSearchFilterComponent implements OnInit {

  @Input() categories: ErrorCategory[];
  @Input() errorSearchFilter: ErrorSearchFilter;
  @Input() isSnapShot: boolean;

  @Output() updateFilter = new EventEmitter<ErrorSearchFilter>();
  @Output() clearGridFilters = new EventEmitter<boolean>(); 

  origErrorSearchFilter: ErrorSearchFilter;
  //Category Filter
  selectedCategory: ErrorCategory = {
    errorCategoryId: 0,
    categoryName: "Select a Category",
    categoryDescription: "Select a Category",
    categoryPriority: 0
  };
  
  constructor() { 
  }

  ngOnInit(): void {
    this.origErrorSearchFilter = Object.assign({}, this.errorSearchFilter);
  }


  updateFilters(): void {
    this.updateFilter.emit(this.errorSearchFilter)
  }

  clearFilters() {
    this.selectedCategory = this.categories.find(c => c.errorCategoryId == 0);
    this.errorSearchFilter = Object.assign({}, this.origErrorSearchFilter);
    this.clearGridFilters.emit(true);
    this.updateFilters();
  }

  getLogPlaceholder(): string {
    let rc = "Search...";
    if (this.isSnapShot) {
      rc = "Go To All Errors Tab For Log Message Database Search..."
    }
    return rc;
  }
}
