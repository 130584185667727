<mat-dialog-content>
  <div *ngIf="viewingIntegration && finishedLoading">
      <h1>Integration Information</h1>
      <br>

      <h5>Name: {{integration.name}}</h5>
      <br>

      <h5>DataSourceTypeId: {{integration.dataSourceTypeId}}</h5>
      <br>

      <h5>ErrorRetentionDays: {{integration.errorRetentionDays}}</h5>
      <br>

      <button style="float: left;"
          mat-raised-button (click)="setEditing()" [disabled]="!checkAccess(['LOSTalker.Admin'])">
          Edit
      </button>

      <button style="float: right;"
          mat-raised-button matDialogClose>
          Close
      </button>
  </div>

  <div *ngIf="editingIntegration">
      <h1>Edit Integration</h1>
      <br>

      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Integration Name</mat-label>
          <input matInput [(ngModel)]="integrationName">
      </mat-form-field>
      <br>

      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>DataSourceTypeId</mat-label>
          <mat-select
              [(value)]="selectedDataSourceType"
              (selectionChange)="dataSourceTypeChange($event)">
              <!-- <mat-option>All</mat-option> -->
              <mat-option *ngFor="let option of dataSourceTypes" [value]="option">
                  {{option.dataSourceTypeId}}
              </mat-option>
          </mat-select>
      </mat-form-field>
      <br>

      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>ErrorRetentionDays</mat-label>
          <input matInput [(ngModel)]="errorRetentionDays">
      </mat-form-field>
      <br>

      <button
          mat-raised-button matDialogClose (click)="updateIntegration()" [disabled]="!checkAccess(['LOSTalker.Admin'])">
          Save Changes
      </button>
      <button
          mat-raised-button (click)="cancel()" style="float: right;">
          Back
      </button>
  </div>
</mat-dialog-content>
