import { Component, OnInit, Input, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { ImportType } from 'src/app/models/import-type';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';
import { IntegrationRunTimeService } from 'src/app/services/integration-runtime.service';
import { IntegrationPushBackService } from 'src/app/services/integration-pushback.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';


import { Integration } from '../../models/integration';
import { IntegrationPushBack } from '../../models/integration-pushback';
import { User } from '../../models/user';
import { ToastService } from '../../services/toast.service';
import { IntegrationRunTime } from 'src/app/models/integration-runtime';
import { process, DataSourceRequestState } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-integration-pushback',
    templateUrl: './integration-pushback.component.html',
    styleUrls: ['./integration-pushback.component.scss']
})
export class IntegrationPushBackComponent implements OnInit {

    constructor(
        private clientService: ClientIntegrationService,
        private integrationPushBackService: IntegrationPushBackService,
        private integrationRunTimeService: IntegrationRunTimeService,
        private typeConstantService: TypeConstantService,
        private toastService: ToastService,
        private user: User,
        private dialog: MatDialog,
        private router: Router
    ) { }

    private sub: any;
    @Input() integration: Integration;
    integrationRunTimes: IntegrationRunTime[];
    integrationRunTimesDictionary: [];
    integrationPushBacks: IntegrationPushBack[];
    selectedPushBack: IntegrationPushBack;
    selectedRunTime: IntegrationRunTime;
    hasActivePushBack: boolean = false;
    addingIntegrationPushBack: boolean = false;
    pushBackLastRunTimeDateInput: Date;
    jiraStoryURLInput: string;
    commentInput: string;
    triggerDateInput: Date;
    isInTab: boolean;
    savingIntegrationPushBack: boolean = false;

    gridView: GridDataResult;
    public pageSizes: number[] = [5, 10, 25, 50, 100];
    public state: DataSourceRequestState = {
        skip: 0,
        take: 10
    };
    gridViewIsLoading: boolean = true;

    @ViewChild('deletePushBackDialog', { static: true }) deleteRunTimeDialog: TemplateRef<any>;

    ngOnInit(): void {
        if (this.integration !== null) {
            this.hasActivePushBack = false;
            this.integrationRunTimesDictionary = [];
            this.integrationRunTimes = null;
            this.integrationPushBacks = [];
            this.loadGrid();
            this.gridViewIsLoading = true;


            this.isInTab = true;

            this.integrationRunTimeService.getAllIntegrationRunTimesByIntegration(this.integration.integrationId).subscribe(value => {
                // on success
                this.integrationRunTimes = value.filter(r => !(r.destinationEndpointName == "NoDestination") && !r.importTypeDescription.includes("Cache"));

                if(this.integrationRunTimes.length > 0)
                {
                    this.selectedRunTime = this.integrationRunTimes.find(runTime => runTime.importTypeDescription.toLowerCase().includes("loan"));
                    if(this.selectedRunTime == null)
                    {
                        this.selectedRunTime = this.integrationRunTimes[0]
                    }

                    this.integrationRunTimesDictionary = [];
                    this.integrationPushBacks = [];
                    
                    for(var r of this.integrationRunTimes)
                    {
                        this.integrationRunTimesDictionary[r.integrationRunTimeId] = r;

                        this.integrationPushBackService.getAllIntegrationPushBacksByRunTime(r.integrationRunTimeId).subscribe(value => {
                            this.integrationPushBacks = this.integrationPushBacks.concat(value);
                            this.loadGrid();
                        });
                    }


                }
            });

        }
        else {
            this.isInTab = false;
        }
    }

    private loadGrid(dataOveride?: any): void {
        for(var p of this.integrationPushBacks)
        {
            if(p.active)
            {
                this.hasActivePushBack = true;
            }
        }

      let data = dataOveride ? dataOveride : this.integrationPushBacks;
      this.gridView = process(data, this.state);
      this.gridViewIsLoading = false;
    }

    public dataStateChange(state: DataStateChangeEvent): void {
      this.state = state;
      this.loadGrid();
    }

    selectIntegrationRunTime(event: any): void {
        let runtime = event.value
        this.selectedRunTime = runtime;
    }

    addIntegrationPushBack(): void {
        this.savingIntegrationPushBack = true;

        if (this.triggerDateInput == null) {
            this.toastService.toastCreate("No Trigger Date Selected", "Warning", {
                autoClose: false
            });

            this.savingIntegrationPushBack = false;
        }
        else if (this.pushBackLastRunTimeDateInput == null) {
            this.toastService.toastCreate("No PushBack Date Selected", "Warning", {
                autoClose: false
            });

            this.savingIntegrationPushBack = false;
        }
        else {

        
            var newPushBack = {
                integrationRunTimeId: this.selectedRunTime.integrationRunTimeId,
                active: true,
                pushBackLastRunTimeDate: this.pushBackLastRunTimeDateInput,
                triggerDate: this.triggerDateInput,
                jiraStoryURL: this.jiraStoryURLInput,
                comment: this.commentInput
            };

            this.integrationPushBackService.addIntegrationPushBack(newPushBack).subscribe(value => {
                this.integrationPushBacks.unshift(newPushBack);
                this.loadGrid();
                // on success
                this.toastService.toastCreate("Successfully added the pushback for " + this.integration.name + " runtime: " + this.selectedRunTime.importTypeDescription + " " + this.selectedRunTime.destinationEndpointName + ".", "Success", {
                    autoClose: false
                });
                this.addingIntegrationPushBack = false;

                //this.selectIntegrationPushBack(this.integrationPushBack);

                this.savingIntegrationPushBack = false;
            }, error => {

                this.savingIntegrationPushBack = false;
            });
        }

    }

    setAddingIntegrationPushBack(): void {
        this.commentInput = null;
        this.triggerDateInput = null;
        this.jiraStoryURLInput = null;
        this.pushBackLastRunTimeDateInput = null;
        this.addingIntegrationPushBack = true;
    }

    checkAccess(role: string[]): boolean {
        //User requires DevOps.Write or Admin

        return this.user.checkAccess(role);
    }

    setDeletingIntegrationRunTime(integrationPushBack: IntegrationPushBack): void {
        this.selectedPushBack = integrationPushBack;
        this.dialog.open(this.deleteRunTimeDialog);
    }

    deleteIntegrationPushBack(): void {
        this.savingIntegrationPushBack = true;
        this.integrationPushBackService.deleteActiveIntegrationPushBack(this.selectedPushBack.integrationRunTimeId).subscribe(() => {
            this.integrationPushBacks.splice(this.integrationPushBacks.indexOf(this.selectedPushBack), 1);
            this.loadGrid();
            this.savingIntegrationPushBack = false;
            this.toastService.toastCreate("Successfully Deleted Active PushBack for " + this.integration.name + " runtime: " + this.selectedRunTime.importTypeDescription + " " + this.selectedRunTime.destinationEndpointName + ".", "Success", {
                autoClose: false
            });
        }, error => {
            this.savingIntegrationPushBack = false;
        });
    }

    goBack(): void {
        this.savingIntegrationPushBack = false;
    }
}
