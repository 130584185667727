import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';
import { Client } from '../models/client';
import { CsvSummary } from '../models/csv-summary';
import { Integration, IntegrationResponseMessage, IntegrationFlags } from '../models/integration';
import { ErrorStatus } from '../models/error-status';
import { IntegrationType } from '../models/integration-type';
import { IntegrationJavaScript } from '../models/integration-javascript';
import { IntegrationLog } from '../models/integration-log';
import { IntegrationErrorMessage } from '../models/integration-error-message';
import { IntegrationMetrics } from '../models/integration-metrics';
import { LoanMetrics } from '../models/loan.metrics';
import { CommonErrorFilter } from '../models/common-error-filter';
import { Server } from '../models/server';
import { DataSourceType } from '../models/data-source-type';
import { DestinationEndpoint } from '../models/destination-endpoint';
import { IntegrationIdentifier } from '../models/integration-identifier';
import { protectedResources } from '../auth-config';
import { DataResponse } from '../models/data-response';
import { ImportType } from '../models/import-type';
import { IntegrationInformation } from '../models/integration-information';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';
import { FailedLoan } from '../models/failed-loan';

@Injectable()
export class ClientIntegrationService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Clients ------------------------------------------//

  // GET Clients - Observable
  getAllClients(): Observable<Client[]> {
    this.actionUrl = "Clients/GetAllClients";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Client[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Clients Base - Observable
  getAllClientsBase(): Observable<Client[]> {
    this.actionUrl = "Clients/all";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Client[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Deleted Clients - Observable
  getAllDeletedClients(): Observable<Client[]> {
    this.actionUrl = "Clients/GetAllDeletedClients";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Client[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Client By Client Id
  getClientById(clientId: number): Observable<Client> {
    this.actionUrl = "Client/Id/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientId.toString());

    return this.http
      .get<Client>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Client By Name
  getClientByName(clientName: string): Observable<Client> {
    this.actionUrl = "Client/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientName);

    return this.http
      .get<Client>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Update Client
  updateClient(client: Client): Observable<any> {
    this.actionUrl = "Client/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put(this.Url, client, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));

  }

  // POST Add Client
  addClient(client: Client, addClientInfo: boolean = false): Observable<any> {
    this.actionUrl = "Client/";
    this.Url = this.baseUrl.concat(this.actionUrl + addClientInfo);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, client, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Patch Undelete Client
  undeleteClient(c: Client): Observable<Client> {
    this.actionUrl = "Client/";
    this.Url = this.baseUrl.concat(this.actionUrl, c.clientId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<Client>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Delete Client
  deleteClient(c: Client): Observable<Client> {
    this.actionUrl = "Client/";
    this.Url = this.baseUrl.concat(this.actionUrl, c.name);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<Client>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Merge Clients
  mergeClients(mergeClient: Client, intoClient: Client): Observable<any> {
    this.actionUrl = "Client/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      mergeClient.clientId.toString(),
      "/",
      intoClient.clientId.toString()
    );

    return this.http
      .patch(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Integrations ------------------------------------------//

  // GET Integration by Integration Id
  getIntegrationById(integrationId: string): Observable<Integration> {
    this.actionUrl = "Integration/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

    return this.http
      .get<Integration>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getIntegrationByIdNoXML(integrationId: string): Observable<Integration> {
    this.actionUrl = "Integration/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId, "/true");

    return this.http
      .get<Integration>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integrations on Server
  getAllServerIntegrations(): Observable<Integration[]> {
    this.actionUrl = "Integrations/AllServerIntegrations";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllIntegrations(): Observable<Integration[]> {
     this.actionUrl = "Integrations/AllIntegrations";
     this.Url = this.baseUrl.concat(this.actionUrl);

     return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get All Integrations that are not soft-deleted
  getAllNotDeletedIntegrations(): Observable<Integration[]> {
    this.actionUrl = "Integrations/allnotdeleted/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get All Integrations that are not soft-deleted and are locked
  getAllNotDeletedLockedIntegrations(): Observable<Integration[]> {
    this.actionUrl = "Integrations/allnotdeletedlocked/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PATCH - Lock Integration (multi)
  lockIntegrations(integrationIds: string[], lock: boolean): Observable<any> {
    // true = lock
    // false = unlock
    const data = new DataResponse(integrationIds, lock);
    this.actionUrl = "Integrations/lock/integrationIds/";
    this.headers.append("Content-Type", "application/json");
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .patch<any>(this.Url, data, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PATCH - Move Server Integration (multi)
  moveServerIntegrations(integrationIds: string[], serverId: string): Observable<any> {
    const data = new DataResponse(integrationIds, false, serverId);
    this.actionUrl = "Integrations/move/integrationIds/";
    this.headers.append("Content-Type", "application/json");
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .patch<any>(this.Url, data, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integrations By Server
  getAllIntegrationsByServer(serverId: string): Observable<Integration[]> {
    this.actionUrl = "Integrations/server/full/";
    this.Url = this.baseUrl.concat(this.actionUrl, serverId);

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integrations By Server
  getIntegrationsByClient(clientName: string): Observable<Integration[]> {
    this.actionUrl = "Integrations/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientName);

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integrations By Server
  getUniqueIntegrationsByClient(clientName: string): Observable<Integration[]> {
    this.actionUrl = "Integrations/unique/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientName);

    return this.http
      .get(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // DELETE Integration
  deleteIntegration(integration: Integration): Observable<Integration> {
    this.actionUrl = "Integration/";
    this.Url = this.baseUrl.concat(this.actionUrl, integration.integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<Integration>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  undeleteIntegration(integration: Integration): Observable<Integration> {
    this.actionUrl = "Integration/undelete/";
    this.Url = this.baseUrl.concat(this.actionUrl, integration.integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<Integration>(this.Url, integration, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integrations By Server
  getIntegrationsByClientAndFlags(
    clientName: string,
    isDeleted: boolean = null,
    isLocked: boolean = null): Observable<Integration[]> {

    const i = new IntegrationFlags();
    i.deleted = isDeleted;
    i.locked = isLocked;

    this.actionUrl = "Integrations/flags/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientName);

    return this.http
      .patch<Integration[]>(this.Url, i, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration History By Server
  getIntegrationHistoryByClient(clientName: string, integrationName: string): Observable<Integration[]> {
    this.actionUrl = "IntegrationHistory/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      clientName,
      "/",
      integrationName
    );

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PUT Update Integration
  updateIntegration(integration: Integration): Observable<any> {
    this.actionUrl = "Integration/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put(this.Url, integration, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Add Integration
  addIntegration(integration: Integration): Observable<any> {
    this.actionUrl = "Integration/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, integration, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));

  }

  // PATCH - Lock Integration
  lockIntegration(integrationId: string, lock: boolean): Observable<Integration> {
    this.actionUrl = "Integration/lock/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      integrationId,
      "/",
      String(lock)
    );
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<Integration>(this.Url, null)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PATCH - Restrict IP
  restrictIPIntegration(integrationId: string, restrict: boolean): Observable<Integration> {
    this.actionUrl = "Integration/restrictip/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      integrationId,
      "/",
      String(restrict)
    );
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<Integration>(this.Url, null)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PATCH - Self Hosted
  selfHostIntegration(integrationId: string, selfHost: boolean): Observable<Integration> {
    this.actionUrl = "Integration/selfhosted/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      integrationId,
      "/",
      String(selfHost)
    );
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<Integration>(this.Url, null)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Add Generic Integration
  convertIntegrationToGeneric(integration: Integration, createNew: boolean): Observable<Integration> {
    this.actionUrl = "Integration/Convert/";
    this.Url = this.baseUrl.concat(this.actionUrl + createNew);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<Integration>(this.Url, integration, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  convertIntegrationToAPI(integration: Integration): Observable<Integration> {
    this.actionUrl = "Integration/APIConvert/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<Integration>(this.Url, integration, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integrations By Server
  getIntegrationsByClientId(clientId: number, lightMode: boolean = false): Observable<Integration[]> {
    this.actionUrl = "Integrations/ClientId/";
    this.Url = this.baseUrl.concat(this.actionUrl + clientId, '/', lightMode.toString());

    return this.http
      .get<Integration[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Integration JavaScript ------------------------------------------//

  // GET Integration JS History
  getIntegrationJavaScriptHistory(integrationId: string): Observable<IntegrationJavaScript[]> {
    this.actionUrl = "IntegrationJavaScriptHistory/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

    return this.http
      .get<IntegrationJavaScript[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET all JavaScripts
  getAllIntegrationJavaScripts(): Observable<IntegrationJavaScript[]> {
    this.actionUrl = "IntegrationJavaScriptModules/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationJavaScript[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Add JS
  addJavaScript(js: IntegrationJavaScript): Observable<string> {
    this.actionUrl = "IntegrationJavaScriptModule/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<string>(this.Url, js, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PUT Update JS
  updateJavaScript(js: IntegrationJavaScript): Observable<any> {
    this.actionUrl = "IntegrationJavaScriptModule/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, js, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // DELETE JS
  deleteJavaScript(integrationId: string, destEndpointId: number): Observable<IntegrationJavaScript> {
    this.actionUrl = "IntegrationJavaScriptModule/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId, "/", destEndpointId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<IntegrationJavaScript>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Failed Loans ------------------------------------------//

  // GET Clients - Observable
  getAllFailedLoansByIntegrationId(integrationId: string): Observable<FailedLoan[]> {
    this.actionUrl = "FailedLoans/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

    return this.http
      .get<FailedLoan[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
