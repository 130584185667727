import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { anyChanged } from '@progress/kendo-angular-common';
import { toDataSourceRequest } from '@progress/kendo-data-query';

@Directive({
  selector: '[excelPaste]'
})
export class ExcelPasteDirective {

  @Output()
  public excelPaste: EventEmitter<any[]> = new EventEmitter<any[]>();

  @HostListener("paste", ["$event"])
  public onPaste(e: any) {

    if (e.target.tagName && e.target.tagName.match(/(input|textarea)/i)) {
      // Do not handle past when an input element is currently focused
      return;
    }

    // Get clipboard data as text
    const data = e.clipboardData.getData("text");

    var rows = [];

    // Split data based on what type of deliminator it has
    if (data.includes(",") && data.includes(" ")) {
      var temp = data.replaceAll("\n",",");
      var tmp = temp.replaceAll(" ",",");
      rows = tmp.split(",");
    }
    else if (data.includes(",")) {
      var tmp = data.replaceAll("\n",",");
      rows = tmp.split(",");
    }
    else if (data.includes("|")) {
      var tmp = data.replaceAll("\n","|");
      rows = tmp.split("|");
    }
    else if (data.includes(" ")) {
      var tmp = data.replaceAll("\n"," ");
      rows = tmp.split(" ");
    }
    else {
      rows = data.split("\n");
    }

    const result = rows.map((r) => {

      if (r.includes("\t")) {
        const cells = r.split("\t");
        return {
          loan: cells[0].trim()
        };
      }
      else {
        return {
          loan: r.trim()
        };
      }

    });

    this.excelPaste.emit(result);
  }

}
