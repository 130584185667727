import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin, Subject } from 'rxjs';
import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State, DataResult, DataSourceRequestState, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ClientIntegrationService } from '../../../services/client-integration.service';
import { ToastService } from '../../../services/toast.service';
import { ReportService } from '../../../services/report.service';
import { Report } from '../../../models/report';
import { Server } from '../../../models/server';
import { Client } from '../../../models/client';
import { DestinationEndpoint } from '../../../models/destination-endpoint';
import { ImportType } from '../../../models/import-type';
import { DataSourceType } from '../../../models/data-source-type';
import { SearchTerm } from '../../../models/search-term';
import { Integration } from '../../../models/integration';
import { Toast, ToastType } from '../../../models/toast';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent, RowArgs, ExcelModule } from '@progress/kendo-angular-grid';
import { takeUntil, throwIfEmpty } from 'rxjs/operators';
import { IntegrationJavaScript } from 'src/app/models/integration-javascript';
import { LOSTalkerTitleService } from '../../../services/lostalker-title-service.service';
import { ColumnSettings } from 'src/app/models/column-settings';
import { ViewGenericGridComponent } from '../../view-generic-grid/view-generic-grid.component';
import { IntegrationInformation } from 'src/app/models/integration-information';
import { DataCollection } from '@progress/kendo-angular-grid/data/data.collection';
import { IntegrationRunTime } from 'src/app/models/integration-runtime';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-integrationinfo-report-view',
  templateUrl: './integrationinfo-report-view.component.html',
  styleUrls: ['./integrationinfo-report-view.component.scss']
})
export class IntegrationinfoReportViewComponent implements OnInit, OnDestroy {
  @ViewChild('viewGenericGrid') public viewGenericGrid: ViewGenericGridComponent;

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() reportId = "";
  @Input() currentReportToShow: Report;
  currReport: Report;

  @Input() serverItems: Server[] = [];
  @Input() dataSourceTypeItems: DataSourceType[] = [];
  @Input() destinationEndpointItems: DestinationEndpoint[] = [];

  serverItemsSelected: Server[] = [];
  dataSourceItemsSelected: DataSourceType[] = []; // Dynamic CSV ...
  destinationEndpointItemsSelected: DestinationEndpoint[] = []; // csd.. lg..

  allData: IntegrationInformation[] = [];
  filteredData: IntegrationInformation[] = [];

  haveData: boolean = false;
  gettingData: boolean = false;

  reportColumns: ColumnSettings[] = [
    { field: 'integrationId', title: 'integrationId', type: 'text', hidden: true},
    { field: 'clientName', title: 'ClientName', type: 'text', width: 100},
    { field: 'integrationName', title: 'IntegrationName', type: 'text', width: 100},
    { field: 'dataSourceType', title: 'DataSourceType', type: 'text', width: 75},
    { field: 'serverId', title: 'ServerId', type: 'text', width: 50},
    { field: 'locked', title: 'Locked', type: 'boolean', width: 50},

    { field: 'destinationName', title: 'EndpointName', type: 'text', width: 75},
    { field: 'identifierIdValue', title: 'IdentifierIdValue', type: 'text', width: 75},
    { field: 'identifierNameValue', title: 'IdentifierNameValue', type: 'text', width: 75},
    { field: 'canFastFetch', title: 'CanFastFetch', type: 'text', width: 50},
    { field: 'canDataSwap', title: 'CanDataSwap', type: 'text', width: 50},
  ];

  constructor(
    protected clientService: ClientIntegrationService,
    protected reportService: ReportService,
    protected toastService: ToastService,
    private location: Location,
    private router: Router,
    private titleService: LOSTalkerTitleService
  ) { }

  ngOnInit(): void {
    if (this.currentReportToShow) {
      this.currReport = new Report(this.currentReportToShow);
      this.titleService.setUpTitle('Report - ' + this.currentReportToShow.title);

      this.setUpReportData();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setUpReportData(): void {
    if (this.currReport != null && this.currReport != undefined){
      if (this.currReport.dataSproc !== "" && this.currReport.dataSproc !== undefined){
        this.gettingData = true;
        this.reportService
          .getIntegrationInformationTypeQueryData(this.currReport.reportId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (data) => {
              if (data != null && data.length > 0) {
                data.forEach(x => {

                  if (x.destinationName == "NoDestionation") {
                    x.canDataSwap = null;
                    x.canFastFetch = null;
                  }

                });

                this.allData = data;
                this.filteredData = data;
                this.haveData = true;
                this.gettingData = false;
              }
            },
            (error) => {
              // console.log(error);
              this.toastService.toastCreate("Error getting integration information.", "Warning");
              this.haveData = false;
              this.gettingData = false;
            }
          )


      }
      else {
        this.toastService.toastCreate("Error in Report Properties.", "Warning");
      }
    }
  }

  // reset filters
  public resetFilters(e: Event): void {
    this.dataSourceItemsSelected = [];
    this.destinationEndpointItemsSelected = [];
    this.serverItemsSelected = [];

    this.updateFilteredData();
  }

  // apply filters
  public applyFilters(e: Event): void {
    this.updateFilteredData();
  }

  public updateFilteredData() {
    this.filteredData = [];
    this.filteredData = this.allData;

    if (this.currReport.hasFilterCards){
      // data source
      if (this.dataSourceItemsSelected.length > 0) {
        let dataSourceIds = this.dataSourceItemsSelected.map(i => i.dataSourceTypeId);
        this.filteredData =
          this.filteredData.filter(i => dataSourceIds.indexOf(i.dataSourceType) != -1);
      }

      // destination endpoint
      if (this.destinationEndpointItemsSelected.length > 0
        && this.destinationEndpointItemsSelected.length != this.destinationEndpointItems.length) {
          let destNames = this.destinationEndpointItemsSelected.map(i => i.endpointName);
          this.filteredData =
          this.filteredData.filter(i => destNames.indexOf(i.destinationName) != -1);
      }

      // server
      if (this.serverItemsSelected.length > 0) {
        let serverIds = this.serverItemsSelected.map(i => i.serverId);
        this.filteredData = this.filteredData.filter(i => serverIds.indexOf(i.serverId) != -1);
      }

    }
  }
  // used for multi select
  public externalFilterChange(value: any): void {
    //console.log("externalFilterChange", value);
  }

}
