<div class="main-nav">
  <nav class="navbar navbar-dark bg-dark flex-column invisible-scroll px-1">
    <div class="container-fluid px-1">
      <div class='navbar-header'>
        <button type='button' class='navbar-toggle' data-bs-toggle='collapse' data-bs-target='.navbar-collapse'>
            <span class="navbar-toggler-icon"></span>
            <span class='visually-hidden'>Toggle navigation</span>
        </button>
        <a class='navbar-brand' [routerLink]="['home']">
          <img class="logo lt-nav-logo" src="../../../assets/images/ncino_logo_mono_cloud_RGB.png" [routerLink]="['home']" alt="Los Talker Logo" />
          LOS Talker Configuration
        </a>
      </div>
      <div class='navbar-content navbar-nav-scroll invisible-scroll' style="scrollbar-width: none;">
        <div class='clearfix'></div>
        <nav class='collapse navbar-collapse'>
          <ul class='nav navbar-nav'>
              <li *ngIf="appComponent.userName !== undefined" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['userData']" class="nav-link">
                      <i class="bi bi-person-fill"></i> {{appComponent.userFullName}}
                  </a>
              </li>
              <li *ngIf="checkAccess(['LOSTalker.Client'])" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['clients']" class="nav-link">
                      <i class="bi bi-list-ul"></i> Clients
                  </a>
              </li>
              <li *ngIf="checkAccess(['LOSTalker.DevOps'])" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['servers']" class="nav-link">
                      <i class="bi bi-list-ul"></i> Servers
                  </a>
              </li>
              <li *ngIf="checkAccess(['LOSTalker.BaseMapping'])" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['baseMapping']" class="nav-link">
                      <i class="bi bi-box"></i> Base Mappings
                  </a>
              </li>
              <li *ngIf="checkAccess(['LOSTalker.BaseMapping'])" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['generic-endpoints']" class="nav-link">
                      <i class="bi bi-box"></i> Generic Endpoints
                  </a>
              </li>

              <li *ngIf="checkAccess(['LOSTalker.Admin'])" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['admin-dashboard']" class="nav-link">
                      <i class="bi bi-list-ul"></i> Admin Menu
                  </a>
              </li>

              <li *ngIf="checkAccess(['LOSTalker.Integration.CompenSafe.FastFetch', 'LOSTalker.Integration.LimeGear.FastFetch'])" [routerLinkActive]="['link-active']" class="nav-item">
                <a [routerLink]="['fastfetch']" class="nav-link">
                    <i class="bi bi-arrow-up-circle"></i> FastFetch
                </a>
              </li>
              <li *ngIf="checkAccess(['LOSTalker.Integration.CompenSafe.DataSwap', 'LOSTalker.Integration.LimeGear.DataSwap'])" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['dataswap']" class="nav-link">
                      <i class="bi bi-arrow-repeat"></i> DataSwap
                  </a>
              </li>

            <li *ngIf="checkAccess(['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports'])" class="nav-item dropdown" >

                <a class="nav-link dropdown-toggle" id="navbarDropdown" style="overflow:visible" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-pencil-square"></i> <span class="pe-3">Invoicing ...</span>
                </a>

                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                    <li *ngIf="checkAccess(['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports'])" [routerLinkActive]="['link-active']" class="nav-item">
                        <a [routerLink]="['invoiced-loans-report']" class="nav-link">
                            <i class="bi bi-list-ul"></i> Loans Report
                        </a>
                    </li>
                    <li *ngIf="checkAccess(['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports'])" [routerLinkActive]="['link-active']" class="nav-item">
                        <a [routerLink]="['invoiced-metrics-report']" class="nav-link">
                            <i class="bi bi-list-ul"></i> Metrics Report
                        </a>
                    </li>
                </ul>
            </li>
              <li *ngIf="checkAccess(['LOSTalker.DevOps'])" [routerLinkActive]="['link-active']" class="nav-item">
                <a [routerLink]="['swagger-interface']" class="nav-link">
                    <i class="bi bi-pencil-square"></i> Swagger Interface
                </a>
            </li>
              <li *ngIf="checkAccess(['LOSTalker.DevOps', 'LOSTalker.Integration'])" [routerLinkActive]="['link-active']" class="nav-item">
                <a [routerLink]="['errorMessagesv2']" class="nav-link">
                    <i class="bi bi-exclamation-lg"></i> Current Errors
                </a>
              </li>
              <li *ngIf="checkAccess(['LOSTalker.DevOps'])" [routerLinkActive]="['link-active']" class="nav-item">
                  <a [routerLink]="['reports']" class="nav-link">
                      <i class="bi bi-list-ul"></i> Reports
                  </a>
              </li>
              <li *ngIf="checkAccess(['LOSTalker.Client', 'LOSTalker.Client.LOSData'])" [routerLinkActive]="['link-active']" class="nav-item">
                <a [routerLink]="['field-paths']" class="nav-link">
                    <i class="bi bi-list-ul"></i> Field Paths
                </a>
              </li>
              <!-- [ngClass]="{'link-active': LTSChildNav0.isActive || LTSChildNav1.isActive}" -->
              <li *ngIf="checkAccess(['LOSTalker.DevOps'])" class="nav-item dropdown" >

                <a class="nav-link dropdown-toggle" id="navbarDropdown" style="overflow:visible" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-pencil-square"></i> <span class="pe-3">Add ...</span>
                </a>

                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">

                  <li *ngIf="checkAccess(['LOSTalker.Admin'])" [routerLinkActive]="['link-active']" class="dropdown-item" >
                    <a [routerLink]="['addClient']" class="nav-link">
                        <i class="bi bi-pencil-square"></i> Client
                    </a>
                  </li>
                  <li *ngIf="checkAccess(['LOSTalker.Admin'])" [routerLinkActive]="['link-active']" class="dropdown-item" >
                      <a [routerLink]="['addIntegration']" class="nav-link">
                          <i class="bi bi-pencil-square"></i> Integration
                      </a>
                  </li>
                  <li *ngIf="checkAccess(['LOSTalker.DevOps'])" [routerLinkActive]="['link-active']" class="dropdown-item" >
                      <a [routerLink]="['addIntegrationIdentifier']" class="nav-link">
                          <i class="bi bi-pencil-square"></i> Identifier
                      </a>
                  </li>
                  <li *ngIf="checkAccess(['LOSTalker.DevOps'])" [routerLinkActive]="['link-active']" class="dropdown-item" >
                    <a [routerLink]="['addBaseMapping']" class="nav-link">
                        <i class="bi bi-pencil-square"></i> Base Mapping
                    </a>
                  </li>
                </ul>
              </li>


          </ul>
        </nav>
      </div>
    </div>
    </nav>
</div>
