import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from 'src/app/models/column-settings';
import { Integration } from 'src/app/models/integration';
import { FailedLoan } from 'src/app/models/failed-loan';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-failed-loans-view',
  templateUrl: './failed-loans-view.component.html',
  styleUrls: ['./failed-loans-view.component.scss'],
  providers: [ClientIntegrationService]
})
export class FailedLoansViewComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();

  @Input() integration: Integration;

  baseData: FailedLoan[] = [];
  allData: FailedLoan[] = [];

  exportFileName: string = "FailedLoans.xlsx";

  haveData: boolean = false;
  gettingData: boolean = false;

  sortDescs: SortDescriptor[] = [
    {field: 'loanSendSuccessful', dir: 'asc'},
    {field: 'lastAttemptDate', dir: 'desc'}
  ];

  allColumns: ColumnSettings[] = [
    { field: 'failedLoanId', title: 'FailedLoanId', type: 'text', hidden: true},
    { field: 'integrationId', title: 'IntegrationId', type: 'text', hidden: true},
    { field: 'destinationEndpointId', title: 'DestinationEndpointId', type: 'text', hidden: true},
    { field: 'endpointName', title: 'EndpointName', type: 'text', width: 75},
    { field: 'loanNumber', title: 'LoanNumber', type: 'text', width: 75},
    { field: 'numberOfFailures', title: 'NumberOfFailures', type: 'numeric', width: 75},
    { field: 'loanSendSuccessful', title: 'LoanSendSuccessful', type: 'boolean', width: 50},
    { field: 'lastAttemptDate', title: 'LastAttemptDate', type: 'date', format: '{0:MM/dd/yyyy h:mm a}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true},
    { field: 'firstAttemptDate', title: 'FirstAttemptDate', type: 'date', format: '{0:MM/dd/yyyy h:mm a}', width: 100, pipeFormat: 'MM/dd/yyyy h:mm a', usePipeFormat: true},
    { field: 'currentError', title: 'CurrentError', type: 'text', width: 100},
  ];

  constructor(
    private clientService: ClientIntegrationService,
    private toastService: ToastService
  ) {
    this.haveData = false;
    this.gettingData = false;
  }

  ngOnInit(): void {
    this.baseData = [];
    this.allData = [];
    this.getFailedLoans();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  getFailedLoans(): void {
    this.gettingData = true;
    if (this.integration != null) {
      this.haveData = false;

      this.clientService.getAllFailedLoansByIntegrationId(this.integration.integrationId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.gettingData = false;
        if (data != null && data.length > 0) {
          this.baseData = data;
          data.forEach(x => {
            this.allData.push(new FailedLoan(x));
          });
        }
        this.haveData = true;
      }, (error) => {
        this.gettingData = false;
        this.toastService.toastCreate("Error getting Failed Loans for Integration.", "Warning");
      })
    }
  }

}
