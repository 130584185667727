import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Client } from '../../models/client';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { Router } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Observable, Subscription } from 'rxjs';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
  PageChangeEvent
} from '@progress/kendo-angular-grid';
import { User } from '../../models/user';
import { SearchTerm } from 'src/app/models/search-term';


const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./clients.component.scss'],
  providers: [ClientIntegrationService]
})
export class ClientsComponent implements OnInit {

  // Constants
  type: 'numeric' | 'input' = 'input';
  allGroup: Client[];

  // Dynamic
  gridView: GridDataResult;
  filteredGroup: Client[];
  filter: CompositeFilterDescriptor;
  gridViewIsLoading = true;
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  aliasSearchTerm: SearchTerm;

  constructor(protected clientsUpdated: ClientIntegrationService, private router: Router, private user: User) {
    this.state.sort = [{ field: "name", dir: 'asc' }];
    this.clientsUpdated.getAllClients()
      .subscribe(r => {
        this.allGroup = r;
        this.loadDataIntoGridView();
      },
      error => {
        this.gridViewIsLoading = false;
      });
      this.aliasSearchTerm = new SearchTerm('', 'Search by Client Name or Alias');

  }

  ngOnInit(): void {
    //this.rebind();
  }

  selectionChangeHandler(event: any): void {
    let selectedClient = event.selectedRows[0].dataItem;
    if (this.checkAccess(["LOSTalker.Integration"])) {
      this.router.navigate(['/editConfig', selectedClient.clientId]);
    }
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  loadDataIntoGridView() {
    let orderedData = orderBy(this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };

  }

  clearSearch(item: SearchTerm) {
    item.clear();
    this.updateFilteredData();
  }

  updateSearch(item: SearchTerm) {
    item.save();
    this.updateFilteredData();
  }

  // update filters without the column filters
  public updateFilteredData(filterFromGrid: CompositeFilterDescriptor = null) {
    this.state.skip = 0;
    // filter clients by client name or alias
    this.filteredGroup = filterBy(this.allGroup, {
      logic: 'or',
      filters: [
        {field: "name", operator:"contains", value: this.aliasSearchTerm.currentSearchValue, ignoreCase: true},
        {field: "aliases", operator:"contains", value: this.aliasSearchTerm.currentSearchValue, ignoreCase: true}
      ]
    });

    this.updateGrid();
  }

  // Sort

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
