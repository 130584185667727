import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InvoicingService } from '../../services/invoicing.service';
import { InvoicedLoan } from '../../models/invoiced-loan';
import { InvoicePeriod } from 'src/app/models/invoice-period';
import { CompletedLoan } from '../../models/completed-loan';
import { ClientContractService } from 'src/app/services/client-contract.service';
import { ToastService } from '../../services/toast.service';
import { ToastType } from 'src/app/models/toast';
import { ClientContract } from '../../models/client-contract';
import { User } from '../../models/user';
import { ColumnSettings } from '../../models/column-settings';
import { InvoicingReportLoan } from 'src/app/models/base-invoicing-loan';
import { InvoicingLoanType } from 'src/app/models/invoicing-loan-type';
import { InvoicingUtils } from '../../utilities/invoicing-utilities';

@Component({
  selector: 'app-invoiced-loans-report',
  templateUrl: './invoiced-loans-report.component.html',
  styleUrls: ['./invoiced-loans-report.component.scss'],
  providers: [InvoicingService, ClientContractService]
})
export class InvoicedLoansReportComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  invoicingUtils: InvoicingUtils;
  public containerType: InvoicingLoanType = InvoicingLoanType.CompletedLoan;
  public invoicingLoanType = InvoicingLoanType;

  loadingFailed = false;
  pageReady: boolean = true;

  currentStartDate: Date = null;
  currentEndDate: Date = null;

  selectedContract: ClientContract = null;

  allInvoicePeriods: InvoicePeriod[] = [];
  allInvoicePeriodsMap: Map<string, InvoicePeriod> = new Map<string, InvoicePeriod>();
  gettingInvoicePeriods: boolean = true;
  haveInvoicePeriods: boolean = false;
  selectedInvoicePeriod: InvoicePeriod = null;

  allInvoicedLoans: InvoicedLoan[] = [];
  allCompletedLoans: CompletedLoan[] = [];
  allReportLoans: InvoicingReportLoan[] = [];

  haveLoans: boolean = false;
  gettingLoans: boolean = false;
  showLoansContainer: boolean = false;

  showMetricsContainer: boolean = false;

  public reportLoanColumns: ColumnSettings[] = [];
  public columnFieldDefs: Map<string, string> = new Map<string, string>();

  excelFileName: string = "LoansReport"; // for invoicing loans grid

  constructor(
    protected invoicingService: InvoicingService,
    protected contractService: ClientContractService,
    protected toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private user: User
  ) {
    this.invoicingUtils = new InvoicingUtils();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params) => {
        this.pageReady = false;

        if (params["contractid"] != null && params["contractid"] != undefined) {
          this.contractService
          .getClientContractWithInfoByContractId(params["contractid"])
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((contract) => {

            if (contract == null || contract == undefined) {
              this.router.navigate(["/invoiced-loans-report"]);
            }
            else {
              this.selectedContract = contract;
            }

            if (params["invoiceperiodid"] != null && params["invoiceperiodid"] != undefined) {
              this.invoicingService
                .getInvoicePeriodByInvoicePeriodId(params["invoiceperiodid"])
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe((ip) => {
                  if (ip == null || ip == undefined) {
                    this.router.navigate(["/invoiced-loans-report/", contract.clientContractId]);
                  }
                  else {
                    this.selectedInvoicePeriod = new InvoicePeriod(ip);
                  }
                },
                (error) => {
                  // invoice period returned null or bad
                  this.toastService.toastCreate("Invoice Period Id did not match a valid Invoice Period", ToastType.Danger);
                  this.router.navigate(["/invoiced-loans-report/", contract.clientContractId]);
                });

              this.pageReady = true;
            }
            else {
              this.pageReady = true;
            }
          }
          ,(error) => {
            // client contract returned null or bad
            this.toastService.toastCreate("Client Contract Id did not match a valid contract", ToastType.Danger);
            this.router.navigate(["/invoiced-loans-report"]);
          });
        }
        else {
          this.pageReady = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }


  clearToStart(): void {
    window.history.replaceState({}, '', `/invoiced-loans-report`);
    window.location.reload();
  }

  clearContractBasics(fromOverride: boolean = false): void {
    this.haveInvoicePeriods = false;
    this.allInvoicePeriodsMap.clear();
    this.allInvoicePeriods = [];
    this.reportLoanColumns = [];
    this.haveLoans = false;
    this.gettingLoans = false;
    this.allInvoicedLoans = [];
    this.allCompletedLoans = [];
    this.allReportLoans = [];
    this.currentStartDate = null;
    this.currentEndDate = null;
    this.showMetricsContainer = false;

    if (fromOverride == false) {
      this.selectedInvoicePeriod = null;
    }
  }

  // get Invoiced Loans by Invoice Period
  getInvoicedLoansForClient() {
    this.containerType = InvoicingLoanType.InvoicedLoan;
    this.showLoansContainer = true;
    this.haveLoans = false;
    this.gettingLoans = true;
    this.setExportFileName();
    if (this.selectedInvoicePeriod != null) {
      this.invoicingService
        .getAllInvoicedLoansByClient(this.selectedInvoicePeriod.invoicePeriodId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((r) => {
          if (r != null && r.length > 0) {
            this.loadLoansData(r, InvoicingLoanType.InvoicedLoan);
            this.gettingLoans = false;
          }
          else {
            this.haveLoans = false;
            this.gettingLoans = false;
          }
        });
    }
    else {
      this.toastService.toastCreate("Selected Invoice Period is empty", ToastType.Warning);
      this.gettingLoans = false;
    }
  }

  // get Completed Loans by Invoice Period
  getCompletedLoansForClient() {
    this.containerType = InvoicingLoanType.CompletedLoan;
    this.showLoansContainer = true;
    this.haveLoans = false;
    this.gettingLoans = true;
    this.setExportFileName();
    if (this.selectedContract != null && this.selectedInvoicePeriod != null) {
      this.invoicingService
        .getAllCompletedLoansByClient(this.selectedInvoicePeriod.invoicePeriodId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((r) => {
          if (r != null && r.length > 0) {
            this.loadLoansData(r, InvoicingLoanType.CompletedLoan);
            this.gettingLoans = false;
          }
          else {
            this.haveLoans = false;
            this.gettingLoans = false;
          }
        });
    }
    else {
      this.toastService.toastCreate("Selected Invoice Period is empty", ToastType.Warning);
      this.gettingLoans = false;
    }
  }

  // get grouped Completed and Invoiced Loans
  getLoansForClientForDateRange() {
    this.containerType = InvoicingLoanType.ReportLoan;
    this.showLoansContainer = true;
    this.haveLoans = false;
    this.gettingLoans = true;
    this.setExportFileName();
    var tmpList = new Map([...this.allInvoicePeriodsMap]
      .filter(([k, v]) =>
        (this.currentStartDate > v.dateStart && this.currentStartDate < v.dateEnd) ||
        (this.currentEndDate > v.dateStart && this.currentEndDate < v.dateEnd) ||
        (this.currentStartDate <= v.dateStart && this.currentEndDate >= v.dateEnd)
      ));

    if (tmpList != null && tmpList?.size > 0) {
      var invoicePeriodIds = Array.from(tmpList.keys());
      this.invoicingService.getAllInvoicingReportLoansByClientForList(invoicePeriodIds)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((r) => {
          if (r != null && r.length > 0) {
            this.loadLoansData(r, InvoicingLoanType.ReportLoan);
            this.gettingLoans = false;
          }
          else {
            this.haveLoans = false;
            this.gettingLoans = false;
          }
        });
    }
    else {
      this.gettingLoans = false;
      this.toastService.toastCreate("Time period is outside of available time periods for current contract", "Warning");
    }

  }

  // process loans from service into grid
  loadLoansData(data: InvoicedLoan[] | CompletedLoan[] | InvoicingReportLoan[], type: InvoicingLoanType) {
    this.allInvoicedLoans = [];
    this.allCompletedLoans = [];
    this.allReportLoans = [];
    this.reportLoanColumns = [];
    this.reportLoanColumns = Array.from(this.invoicingUtils.getDefaultColumns(type));

    var keys: string[] = [];
    data.forEach(x => {

      if (type == InvoicingLoanType.CompletedLoan) {
        let i: CompletedLoan = new CompletedLoan(x, this.selectedContract, this.allInvoicePeriodsMap.get(x.invoicePeriodId));
        this.allCompletedLoans.push(i);
      }
      else if (type == InvoicingLoanType.InvoicedLoan) {
        let i: InvoicedLoan = new InvoicedLoan(x, this.selectedContract, this.allInvoicePeriodsMap.get(x.invoicePeriodId));
        this.allInvoicedLoans.push(i);
      }
      else if (type == InvoicingLoanType.ReportLoan) {
        let i: InvoicingReportLoan = new InvoicingReportLoan(x, this.selectedContract, this.allInvoicePeriodsMap.get(x.invoicePeriodId));
        this.allReportLoans.push(i);
      }

      if (x.jsonFieldCollection != null) {
        let newKeys = Object.keys(x.jsonFieldCollection).filter(y => !keys.includes(y));
        if (newKeys != null && newKeys.length > 0) {
          keys = [...new Set([...keys, ...newKeys])];
        }
      }
    });

    if (type == InvoicingLoanType.CompletedLoan && this.allCompletedLoans.length > 0 && this.columnFieldDefs.size < 1) {
      this.columnFieldDefs.clear();
      var periods = this.allCompletedLoans.map(i => i.invoicePeriod);
      var uniquePeriods = periods.filter((v, i, a) => a.indexOf(v) === i);
      this.processPeriodsForFieldDefs(uniquePeriods);
    }
    else if (type == InvoicingLoanType.InvoicedLoan && this.allInvoicedLoans.length > 0 && this.columnFieldDefs.size < 1) {
      this.columnFieldDefs.clear();
      var periods = this.allInvoicedLoans.map(i => i.invoicePeriod);
      var uniquePeriods = periods.filter((v, i, a) => a.indexOf(v) === i);
      this.processPeriodsForFieldDefs(uniquePeriods);
    }
    else if (type == InvoicingLoanType.ReportLoan && this.allReportLoans.length > 0) {
      this.columnFieldDefs.clear();
      var periods = this.allReportLoans.map(i => i.invoicePeriod);
      var uniquePeriods = periods.filter((v, i, a) => a.indexOf(v) === i);
      this.processPeriodsForFieldDefs(uniquePeriods);
    }

    if (!this.reportLoanColumns.some(c => c.field == "clientContract.clientFullName" && c.title == "Client Full Name")) {
      this.reportLoanColumns.push(new ColumnSettings({ field: "clientContract.clientFullName", title: "Client Full Name" }));
    }

    if (!this.reportLoanColumns.some(c => c.field == "clientContract.currentCompletedField" && c.title == "Current Completed Field")) {
      this.reportLoanColumns.push(new ColumnSettings({ field: "clientContract.currentCompletedField", title: "Current Completed Field" }));
    }

    if (keys.length > 0) {
      keys.forEach(k => {
        if (!this.reportLoanColumns.some(c => c.field == "jsonFieldCollection." + k && c.title == k)) {
          this.reportLoanColumns.push(new ColumnSettings({ field: "jsonFieldCollection." + k, title: k, prettyField: k }));
        }
      });
    }
    this.haveLoans = true;
  }

  processPeriodsForFieldDefs(periods: InvoicePeriod[]) {
    this.columnFieldDefs.clear();
    periods = periods.filter(i => i.jsonFieldInfoCollection != null && Object.keys(i.jsonFieldInfoCollection).length > 0);
    if (periods.length == 1) {
      var tmpM = new Map(Object.entries(periods[0].jsonFieldInfoCollection));
      this.columnFieldDefs = tmpM;
    }
    else if (periods.length > 1) {
      periods.forEach(u => {
        if (u.jsonFieldInfoCollection != null && u.jsonFieldInfoCollection != undefined) {
          var tmpM = new Map(Object.entries(u.jsonFieldInfoCollection));
          tmpM.forEach((value: string, key: string) => {
            if (!this.columnFieldDefs.has(key)) {
              this.columnFieldDefs.set(key, value);
            }
          });
        }
      });
    }
  }

  getInvoicePeriodFromOverrideView(event: InvoicePeriod) {
    this.selectedInvoicePeriod = event;
    this.columnFieldDefs.clear();

    if (this.selectedInvoicePeriod != null && this.selectedInvoicePeriod.jsonFieldInfoCollection != null && this.selectedInvoicePeriod.jsonFieldInfoCollection.size > 0) {
      this.columnFieldDefs = this.selectedInvoicePeriod.jsonFieldInfoCollection;
    }

    if (this.selectedInvoicePeriod.currentInvoice == true) {
      this.getCompletedLoansForClient();
    }
    else {
      this.getInvoicedLoansForClient();
    }
  }

  // get non Completed Loans Invoice Period
  getInvoicePeriodFromView(event: InvoicePeriod): void {
    this.selectedInvoicePeriod = null;
    this.columnFieldDefs.clear();
    if (event != null && event != undefined) {
      this.selectedInvoicePeriod = event;
      if (this.selectedInvoicePeriod != null && this.selectedInvoicePeriod.jsonFieldInfoCollection != null && this.selectedInvoicePeriod.jsonFieldInfoCollection.size > 0) {
        this.columnFieldDefs = this.selectedInvoicePeriod.jsonFieldInfoCollection;
      }
      this.getInvoicedLoansForClient();

      let contractId = encodeURI(this.selectedContract.clientContractId).replace("(", "%28").replace(")", "%29");
      let invoiceId = encodeURI(this.selectedInvoicePeriod.invoicePeriodId).replace("(", "%28").replace(")", "%29");
      window.history.replaceState({}, '', `/invoiced-loans-report/${contractId}/${invoiceId}`);
    }
    else {
      let contractId = encodeURI(this.selectedContract.clientContractId).replace("(", "%28").replace(")", "%29");
      window.history.replaceState({}, '', `/invoiced-loans-report/${contractId}`);
    }
  }

  // get Completed Loans Invoice Period
  getCompletedLoansInvoicePeriodFromView(event: any): void {
    this.selectedInvoicePeriod = null;
    this.columnFieldDefs.clear();
    if (event != null && event != undefined) {
      this.selectedInvoicePeriod = event;
      if (this.selectedInvoicePeriod != null && this.selectedInvoicePeriod.jsonFieldInfoCollection != null && this.selectedInvoicePeriod.jsonFieldInfoCollection.size > 0) {
        this.columnFieldDefs = this.selectedInvoicePeriod.jsonFieldInfoCollection;
      }
      this.getCompletedLoansForClient();
      if (this.selectedInvoicePeriod != null) {
        let contractId = encodeURI(this.selectedContract.clientContractId).replace("(", "%28").replace(")", "%29");
        let invoiceId = encodeURI(this.selectedInvoicePeriod.invoicePeriodId).replace("(", "%28").replace(")", "%29");
        window.history.replaceState({}, '', `/invoiced-loans-report/${contractId}/${invoiceId}`);
      }
    }
    else {
      let contractId = encodeURI(this.selectedContract.clientContractId).replace("(", "%28").replace(")", "%29");
      window.history.replaceState({}, '', `/invoiced-loans-report/${contractId}`);
    }
  }

  // get Date Range from Date Period Selector
  submitDateRange(event: [Date,Date]) {
    this.gettingLoans = true;
    this.currentStartDate = event[0];
    this.currentEndDate = event[1];
    this.getLoansForClientForDateRange();
  }

  returnInvoicePeriodsList(periods: InvoicePeriod[]): void {
    this.gettingInvoicePeriods = true;
    this.allInvoicePeriods = [];
    if (periods != null && periods.length > 0) {
      this.allInvoicePeriods = periods;
      if (this.allInvoicePeriodsMap.size > 0) {
        this.allInvoicePeriodsMap.clear();
      }
      this.allInvoicePeriodsMap = new Map(periods.map(k => [k.invoicePeriodId, k] as [string, InvoicePeriod]));
      this.haveInvoicePeriods = true;
    }
    else {
      if (this.selectedContract != null) {
        this.getInvoicePeriods();
      }
    }
    this.gettingInvoicePeriods = false;
  }

  // get and populate Invoice Periods and contract mapping - backup if hits here before period list is returned
  getInvoicePeriods(): void {
    this.gettingInvoicePeriods = true;
    if (this.selectedContract != null) {

      this.invoicingService.getAllInvoicePeriodsByClient(this.selectedContract.clientContractId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((r) => {
          if (r != null && r.length > 0) {
            r.forEach(x => {
              if (x.dateEnd != null) {
                x.dateEnd = new Date(x.dateEnd);
              }
              if (x.dateStart != null) {
                x.dateStart = new Date(x.dateStart);
              }
            });
            r = r.sort((a, b) => a.dateEnd.getTime() - b.dateEnd.getTime());
            this.allInvoicePeriods = r;
            if (this.allInvoicePeriodsMap.size > 0) {
              this.allInvoicePeriodsMap.clear();
            }
            this.allInvoicePeriodsMap = new Map(r.map(k => [k.invoicePeriodId, k] as [string, InvoicePeriod]));
            this.haveInvoicePeriods = true;
          }
          else {
            this.toastService.toastCreate("No Invoice Periods exist for Client Contract.", ToastType.Warning);
          }
        });

    }
    else {
      this.haveInvoicePeriods = false;
    }
    this.gettingInvoicePeriods = false;
  }

  setExportFileName(): void {
    this.excelFileName = "LoansReport";

    if (this.containerType == InvoicingLoanType.CompletedLoan) {
      this.excelFileName = "CompletedLoans";
    }
    else if (this.containerType == InvoicingLoanType.InvoicedLoan) {
      this.excelFileName = "InvoicedLoans";
    }

    if (this.selectedContract != null) {
      this.excelFileName = this.excelFileName + "_" + this.selectedContract.clientFullName;
    }
  }

  getContractFromSelect(event: ClientContract): void {
    this.selectedContract = null;

    if (event != null && event != undefined) {
      this.selectedContract = event;
    }

    if (this.selectedContract != null) {
      let contractId = encodeURI(this.selectedContract.clientContractId).replace("(", "%28").replace(")", "%29");
      window.history.replaceState({}, '', `/invoiced-loans-report/${contractId}`);
    }
    else {
      window.history.replaceState({}, '', `/invoiced-loans-report`);
    }
    this.clearContractBasics();
  }

  getContractFromOverrideView(event: ClientContract): void {
    this.selectedContract = event;
    this.showLoansContainer = false;
    this.clearContractBasics(true);
  }

  processInitContractError(event: any): void {
    this.toastService.toastCreate("Client Contract Id did not match a valid contract", ToastType.Danger);
    this.router.navigate(["/invoiced-loans-report"]);
  }

  setGoToMetricsContainer(event: boolean): void {
    if (event == true) {
      this.showMetricsContainer = true;
    }
    else {
      this.showMetricsContainer = false;
    }
  }

  closeMetricsContainer(event: any): void {
    this.showMetricsContainer = false;
  }

  setFailedLoading(event: any): void {
    if (event == true) {
      this.loadingFailed = true;
    }
    else if (event == false) {
      this.loadingFailed = false;
    }
  }
}
