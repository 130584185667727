import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { User } from '../models/user';
import { Role } from '../models/roles';
import { protectedResources } from '../auth-config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';

@Injectable()
export class UserService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = '';
  private Url: string = '';
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, @Inject('ORIGIN_URL') private originUrl: string, private toastService: ToastService) { }

  // GET
  getAllUsers(): Observable<User[]> {
    var actionUrl = 'Users/';
    this.Url = this.baseUrl + '/' + this.baseUrl.concat(actionUrl);

    return this.http
      .get<User[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllRoles(): Observable<Role[]> {
    var actionUrl = 'Roles/';
    this.Url = this.originUrl + '/' + this.baseUrl.concat(actionUrl);

    return this.http
      .get<Role[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getUserRoles(userName): Observable<Role[]> {
    //todo: user id or username?
    var actionUrl = 'UserRole/';
    this.Url = this.baseUrl.concat(actionUrl, userName);
    
    return this.http
      .get<Role[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET
  getClaimsFromController(): Observable<Map<string, string>> {
    this.Url = this.originUrl + '/' + 'Home/GetClaims';

    return this.http
      .get<Map<string, string>>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertUser(user: User): Observable<User> {
    var actionUrl = 'User/';
    this.Url = this.originUrl + '/' + this.baseUrl.concat(actionUrl);
    this.headers.append('Content-Type', 'application/json');

    return this.http
      .post<User>(this.Url, JSON.stringify(user), { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateUser(user: User): Observable<User> {
    var actionUrl = 'User/';
    this.Url = this.originUrl + '/' + this.baseUrl.concat(actionUrl);
    this.headers.append('Content-Type', 'application/json');

    /*

    return this.http
        .put(this.Url, JSON.stringify(user), { headers: this.headers })
        .toPromise()
        .then(() => user)
        .catch(this.handleError);

    */

    this.http.put(this.Url, user, { headers: this.headers }).subscribe(
      (val) => {
        console.log("updateUser successful");
      },
      (response) => {
        console.log("updateUser PUT call in error", response);
      },
      () => {
        console.log("The updateUser PUT observable is now completed.");
      }
    );

    return;

  }

  deleteUser(user: User): Observable<User> {
    var actionUrl = 'User/';
    this.Url = this.originUrl + '/' + this.baseUrl.concat(actionUrl) + '/' + encodeURIComponent(user.userName);
    this.headers.append('Content-Type', 'application/json');

    return this.http
      .delete<User>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteUserRole(userId: string, roleId: string): Observable<Role[]> {
    var actionUrl = 'UserRole/';
    this.Url = this.originUrl + '/' + this.baseUrl.concat(actionUrl, encodeURIComponent(userId), "/", encodeURIComponent(roleId));

    return this.http
      .delete<Role[]>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertUserRole(userName: string, roleName: string): Observable<Role[]> {
    var actionUrl = 'UserRole/';
    var roleNames = new Array(roleName);
    this.Url = this.originUrl + '/' + this.baseUrl.concat(actionUrl, encodeURIComponent(userName));
    this.headers.append('Content-Type', 'application/json');

    return this.http
      .post<Role[]>(this.Url, roleNames, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
