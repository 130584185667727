<div class="container-fluid rows-cols-1">

  <div class="col">
    <h1 class="title-center my-2">Invoiced Loans Report</h1>
  </div>

  <div class="col" *ngIf="pageReady">
    <app-invoicing-select-view
            (returnFailedLoading)="setFailedLoading($event)"
            [initClientContract]="selectedContract"
            [showGoToMetrics]="true"
            [currentInvoicePeriod]="selectedInvoicePeriod"
            (setGoToMetrics)="setGoToMetricsContainer($event)"
            (returnedContract)="getContractFromSelect($event)"
            (itemSelectedFromOverride)="getContractFromOverrideView($event)"
            (initContractError)="processInitContractError($event)"
            (clearToStart)="clearToStart($event)">
      </app-invoicing-select-view>
  </div>

  <div class="col" *ngIf="selectedContract && !showMetricsContainer">
    <app-invoicing-select-date-view
        [selectedContract]="selectedContract"
        [overrideInvoicePeriod]="selectedInvoicePeriod"
        (updatedRange)="submitDateRange($event)"
        (completedLoansPeriodSelected)="getCompletedLoansInvoicePeriodFromView($event)"
        (updatedInvoicePeriod)="getInvoicePeriodFromView($event)"
        (updatedInvoicePeriodFromOverride)="getInvoicePeriodFromOverrideView($event)"
        (returnAllInvoicePeriods)="returnInvoicePeriodsList($event)">
      </app-invoicing-select-date-view>
  </div>

  <div class="col" *ngIf="selectedContract && haveInvoicePeriods && showLoansContainer && !showMetricsContainer">

    <app-page-loading-view
      [loading]="gettingLoans"
      [loadingFailed]="!haveLoans"
      [loadingFailedMessage]="
        containerType == invoicingLoanType.ReportLoan ?
        'No Loans for selected Date Range Found.' :
        'No Loans for selected Invoice Period Found.'">
    </app-page-loading-view>

    <ng-container *ngIf="haveLoans">

      <ng-container *ngIf="containerType === invoicingLoanType.CompletedLoan">
        <app-invoicing-loans-grid
          [data]="allCompletedLoans"
          [columns]="reportLoanColumns"
          [exportFileName]="excelFileName"
          [columnFieldInfoMap]="columnFieldDefs">
        </app-invoicing-loans-grid>
      </ng-container>

      <ng-container *ngIf="containerType === invoicingLoanType.InvoicedLoan">
        <app-invoicing-loans-grid
          [data]="allInvoicedLoans"
          [columns]="reportLoanColumns"
          [exportFileName]="excelFileName"
          [columnFieldInfoMap]="columnFieldDefs">
        </app-invoicing-loans-grid>
      </ng-container>

      <ng-container *ngIf="containerType === invoicingLoanType.ReportLoan">
        <app-invoicing-loans-grid
          [data]="allReportLoans"
          [columns]="reportLoanColumns"
          [exportFileName]="excelFileName"
          [columnFieldInfoMap]="columnFieldDefs">
        </app-invoicing-loans-grid>
      </ng-container>
    </ng-container>

  </div>

  <div class="col" *ngIf="selectedContract && !haveInvoicePeriods && !gettingInvoicePeriods">
    <p>No Invoice Periods available for Client Contract.</p>
  </div>

  <div class="col" *ngIf="selectedContract && showMetricsContainer">
    <app-invoicing-metrics-report #metricsLoanReport
      (closeView)="closeMetricsContainer($event)"
      [loanContract]="selectedContract"
      [loanDestination]="selectedDestination"
      [showPeriodSelectView]="true"
      [loanPeriod]="selectedInvoicePeriod"
      [loanStartDate]="currentStartDate"
      [loanEndDate]="currentEndDate">
    </app-invoicing-metrics-report>
  </div>

  <div class="col mt-5">
    <app-page-loading-view [loading]="!pageReady" [loadingFailed]="loadingFailed"></app-page-loading-view>
  </div>
</div>
