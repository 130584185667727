import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent, EditEvent, RemoveEvent, AddEvent } from '@progress/kendo-angular-grid';
import { User } from '../../models/user';
import { EncompassAPIFieldPath } from '../../models/los-field-path';
import { FieldPathService } from '../../services/field-path.service';
import { ToastService } from 'src/app/services/toast.service';
import { FormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { checkNotInList } from 'src/app/directives/check-not-in-list.directive';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-field-paths-view',
  templateUrl: './field-paths-view.component.html',
  styleUrls: ['./field-paths-view.component.scss'],
  providers: [FieldPathService]
})
export class FieldPathsViewComponent implements OnInit, OnDestroy {

  @ViewChild('addUpdateFieldForm', { static: true }) addUpdateFieldForm: TemplateRef<any>;
  componentDestroyed$: Subject<boolean> = new Subject();

  allGroup: EncompassAPIFieldPath[];
  filteredGroup: EncompassAPIFieldPath[];
  selectedItem: EncompassAPIFieldPath;
  gridView: GridDataResult;

  public gridViewIsLoading: boolean = true;
  private savingFieldPath: boolean = false;

  public isNew: boolean = false;

  type: 'numeric' | 'input' = 'input';
  filter: CompositeFilterDescriptor;
  public pageSizes: number[] = [5, 10, 25, 50, 100];
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  fieldIdFormControl = new UntypedFormControl('', {validators: [Validators.required], updateOn: 'blur'});
  apiFieldPathFormControl = new UntypedFormControl('', {validators: [Validators.required], updateOn: 'blur'});

  fieldIds: string[];
  constructor(
    protected fieldPathService: FieldPathService,
    protected toastService: ToastService,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private user: User
  ) {
    this.state.sort = [{ field: "fieldId", dir: 'asc' }];
    this.fieldIds = [];
  }

  ngOnInit(): void {
    this.reloadData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private reloadData(): void {
    this.gridViewIsLoading = true;
    this.fieldPathService.getAllFieldPaths()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(r => {
        this.allGroup = r;
        this.fieldIds = this.allGroup.map(i => i.fieldId.toLowerCase());
        this.updateGrid();
      },
      error => {
        // console.log(error);
        this.gridViewIsLoading = false;
      });
  }

  updateGrid() {
    this.gridViewIsLoading = true;
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.gridViewIsLoading = true;
    this.state.skip = 0;
    this.state.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, this.state.filter);
    this.updateGrid();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  public saveData(component: any): void {
    component.save();
  }

  public addHandler(e?: AddEvent): void {
    this.selectedItem = new EncompassAPIFieldPath();
    this.fieldIds = this.allGroup.map(i => i.fieldId.toLowerCase());
    this.fieldIdFormControl.setValidators([
      checkNotInList(this.fieldIds),
      Validators.required,
      Validators.maxLength(100)
    ]);
    this.fieldIdFormControl.enable();
    this.apiFieldPathFormControl.setValidators([
      Validators.required,
      Validators.maxLength(500)
    ]);
    this.fieldIdFormControl.setValue('');
    this.apiFieldPathFormControl.markAsUntouched();
    this.apiFieldPathFormControl.setValue('');
    this.apiFieldPathFormControl.markAsUntouched();
    this.isNew = true;
    this.dialog.open(this.addUpdateFieldForm);
  }

  public editHandler(e: EditEvent): void {
    this.selectedItem = e.dataItem;
    this.apiFieldPathFormControl.setValidators([
      Validators.required,
      Validators.maxLength(500)
    ]);
    this.fieldIdFormControl.setValue(this.selectedItem.fieldId);
    this.fieldIdFormControl.disable();
    this.apiFieldPathFormControl.setValue(this.selectedItem.apiFieldPath);
    this.isNew = false;
    this.dialog.open(this.addUpdateFieldForm);
  }

  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }

  public cancelHandler(): void {
    this.dialog.closeAll();
    this.selectedItem = null;
  }

  public onSave(): void {
    this.savingFieldPath = true;
    var validFields = true;

    if (this.isNew && this.fieldIdFormControl.status !== 'VALID') {
      this.toastService.toastCreate("Field Id is invalid. Try again.", "Danger");
      validFields = false;
    }
    else if (this.apiFieldPathFormControl.status !== 'VALID') {
      this.toastService.toastCreate("Field Id and/or Field Path is invalid. Try again.", "Danger");
      validFields = false;
    }
    else if (!this.isNew && (this.apiFieldPathFormControl.value === this.selectedItem.apiFieldPath)) {
      this.toastService.toastCreate("No change in api field path to save. Skipping save.", "Warning.");
      validFields = false;
    }

    if (validFields) {
      this.selectedItem.apiFieldPath = this.apiFieldPathFormControl.value;

      if (this.isNew == true) {
        this.selectedItem.fieldId = this.fieldIdFormControl.value;

        this.fieldPathService.insertEncompassAPIFieldPath(this.selectedItem)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (value) => {
            this.toastService.toastCreate(
              "Successfully added field: " + this.selectedItem.fieldId,
              "Success",{ keepAfterRouteChange: true }
            );
            this.dialog.closeAll();
            this.reloadData();
          }, error => {
            // console.log(error);
          }
        )
      }
      else {
        this.fieldPathService.updateEncompassAPIFieldPath(this.selectedItem)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (value) => {
            this.toastService.toastCreate("Successfully updated field: " + this.selectedItem.fieldId,
              "Success",{ keepAfterRouteChange: true }
            );
            this.dialog.closeAll();
            this.reloadData();
          }, error => {
            // console.log(error);
          }
        )
      }
    }
    this.savingFieldPath = false;
  }

  public onCancel(): void {
    this.dialog.closeAll();
    this.selectedItem = null;
  }
}
