<div class="row flex-grow-1">
    <kendo-tabstrip height="100%" #tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab [title]="'Received Date'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row flex-grow-1">
                    <div class="col-6">
                        <div class="card search-card h-70">
                            <div class="card-body">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon3">Start</span>
                                    </div>
                                    <input type="date" class="form-control" [ngModel]="errorSearchFilter.startReceivedDate | date:'yyyy-MM-dd'" 
                                        (ngModelChange)="errorSearchFilter.startReceivedDate = formatDateSearchField($event)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card search-card h-70">
                            <div class="card-body">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon3">End</span>
                                    </div>
                                    <input type="date" class="form-control" [ngModel]="errorSearchFilter.endReceivedDate | date:'yyyy-MM-dd'"
                                            (ngModelChange)="errorSearchFilter.endReceivedDate = formatDateSearchField($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Initial Error Date'">
            <ng-template kendoTabContent>
                <span>Initial Error Date Filters Current Error List, use Received Date to request more errors from
                    Database</span>
                <div class="row flex-grow-1">
                    <div class="col-6">
                        <div class="card search-card h-70">
                            <div class="card-body">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon3">Start</span>
                                    </div>
                                    <input type="date" class="form-control" [ngModel]="errorSearchFilter.startInitialErrorDate | date:'yyyy-MM-dd'"
                                            (ngModelChange)="errorSearchFilter.startInitialErrorDate = formatDateSearchField($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card search-card h-70">
                            <div class="card-body">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon3">End</span>
                                    </div>
                                    <input type="date" class="form-control" [ngModel]="errorSearchFilter.endInitialErrorDate | date:'yyyy-MM-dd'"
                                            (ngModelChange)="errorSearchFilter.endInitialErrorDate = formatDateSearchField($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <ng-container>
            <kendo-tabstrip-tab [title]="'Resolution Date'">
                <ng-template kendoTabContent>
                    <span>Resolution Date Filters Current Error List, use Received Date to request more errors from
                        Database</span>
                    <div class="row flex-grow-1">
                        <div class="col-6">
                            <div class="card search-card h-70">
                                <div class="card-body">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon3">Start</span>
                                        </div>
                                        <input type="date" class="form-control" [ngModel]="errorSearchFilter.startResolutionDate | date:'yyyy-MM-dd'"
                                                (ngModelChange)="errorSearchFilter.startResolutionDate = formatDateSearchField($event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card search-card h-70">
                                <div class="card-body">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon3">End</span>
                                        </div>
                                        <input type="date" class="form-control" [ngModel]="errorSearchFilter.endResolutionDate | date:'yyyy-MM-dd'"
                                                (ngModelChange)="errorSearchFilter.endResolutionDate = formatDateSearchField($event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </kendo-tabstrip-tab>
        </ng-container>
    </kendo-tabstrip>
</div>
<div class="row flex-grow-1" style="margin-top: 10px;" *ngIf="reportConstants != undefined">
    <div class="col-4">
        <div class="card search-card h-70">
            <div class="card-body">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">Log Message</span>
                    </div>
                    <input type="text" class="form-control searchBox1" placeholder="Search..."
                        [(ngModel)]="errorSearchFilter.logMessage">
                </div>
            </div>
        </div>
    </div>
    <div class="col-2" style="font-size: 18px;">
        <mat-form-field appearance="fill" style="min-width: -webkit-fill-available; height: 100%;">
            <mat-label>Select a category</mat-label>
            <mat-select [(ngModel)]="errorSearchFilter.errorCategoryId">
                <mat-option *ngFor="let category of reportConstants.errorCategories" [value]="category.errorCategoryId">
                    {{category.categoryName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-2" style="font-size: 18px;">
        <mat-form-field appearance="fill" style="min-width: -webkit-fill-available; height: 100%;">
            <mat-label>Select a Status</mat-label>
            <mat-select [(ngModel)]="errorSearchFilter.currentStatusId">
                <mat-option *ngFor="let status of reportConstants.currentStatuses" [value]="status.currentStatusId">
                    {{status.statusName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-2" style="font-size: 18px;">
        <mat-form-field appearance="fill" style="min-width: -webkit-fill-available; height: 100%;">
            <mat-label>Select a ImportType</mat-label>
            <mat-select [(ngModel)]="errorSearchFilter.importTypeId">
                <mat-option *ngFor="let importtype of reportConstants.importTypes" [value]="importtype.importTypeId">
                    {{importtype.typeDescription}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-2" style="font-size: 18px;">
        <mat-form-field appearance="fill" style="min-width: -webkit-fill-available; height: 100%;">
            <mat-label>Select a Destination</mat-label>
            <mat-select [(ngModel)]="errorSearchFilter.destinationEndpointId">
                <mat-option *ngFor="let dest of reportConstants.destinationEndpoints"
                    [value]="dest.destinationEndpointId">
                    {{dest.endpointName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row flex-grow-1" style="margin-top: 10px; margin-bottom: 10px;" *ngIf="reportConstants != undefined">
    <div class="col-2 h-100">
        <button kendoButton style="width: 95%; height: 100%; font-size: 15px;" (click)="updateFilters()">Filter
            From Database</button>
    </div>
    <div class="col-2 h-100">
        <button kendoButton style="width: 95%; height: 100%; font-size: 15px;" (click)="clearFilters()">Clear
            Grid Filters</button>
    </div>
</div>
<div class="row flex-grow-1" style="margin-top: 10px;" *ngIf="allErrorsReturned && !allErrorsDisplayed">
    <div class="col-2" style="margin-bottom: 10px;">
        <button kendoButton [primary]="true" style="width: 95%; height: 100%; font-size: 15px;"
            (click)="reloadErrorData()">Refresh</button>
    </div>
    <div class="col-4" style="margin-bottom: 10px; font-size: 18px;">
        <span>Remaining Errors loaded, refresh to view full list</span>
    </div>
</div>