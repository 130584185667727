export class DataResponse {
  integrationIds: string[];
  locked: boolean;
  serverId: string;

  lastRunTime?: Date;
  nextRunTime?: Date;

  destinationEndpointIds: number[] = [];
  importTypeIds: number[] = [];

  constructor(ids: string[], lock: boolean, serverId: string = "", lastRT: Date = null, nextRT: Date = null, dest: number[] = [], imp: number[] = []) {
    this.integrationIds = ids;
    this.locked = lock;
    this.serverId = serverId;

    this.lastRunTime = lastRT;
    this.nextRunTime = nextRT;
    this.destinationEndpointIds = dest;
    this.importTypeIds = imp;
  }
}
