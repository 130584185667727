import { Component, OnInit, Input, ViewChild, ViewEncapsulation, OnDestroy, Inject, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { process, State, DataSourceRequestState } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { IdentifierService } from '../../services/identifier.service';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { IntegrationIdentifier } from '../../models/integration-identifier';
import { Client } from '../../models/client';
import { Integration } from '../../models/integration';
import { TypeConstantService } from '../../services/type-constant.service';
import { ToastService } from '../../services/toast.service';
import { ThisReceiver } from '@angular/compiler';
import { environment } from '../../../environments/environment';
import { User } from 'src/app/models/user';
@Component({
  selector: 'app-identifier-view',
  templateUrl: './identifier-view.component.html',
  styleUrls: ['./identifier-view.component.scss'],
  providers: [ClientIntegrationService, IdentifierService, TypeConstantService]
})
export class IdentifierViewComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() client: Client;
  @Input() integration: Integration;
  @Input() baseData: IntegrationIdentifier[];
  @Output() identifier = new EventEmitter<IntegrationIdentifier>();

  inAddMode = false;
  showAddUpdateForm = false;
  generatedNewIdentifier = false;
  runningGeneratingIdentifiers: boolean = false;
  gridView: GridDataResult;
  allDestinations: DestinationEndpoint[];
  selectedIdentifier: IntegrationIdentifier;
  isInTab: boolean;
  public pageSizes: number[] = [5, 10, 25, 50, 100];
  public state: DataSourceRequestState = {
      skip: 0,
      take: 10
  };
  gridViewIsLoading: boolean = true;

  constructor(
    private identifierService: IdentifierService,
    private typeService: TypeConstantService,
    protected toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private user: User
  ) {
    this.baseData = [];
    this.allDestinations = [];
  }

  ngOnInit(): void {
    this.gridViewIsLoading = true;
    if (this.integration != null){
      this.isInTab = true;
      if (this.baseData === undefined) {
        this.identifierService.getIntegrationIdentifiers(this.integration.integrationId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.baseData = data;
          this.loadGrid();
        });
      }
      else {
        this.loadGrid();
      }
    }
    else {
      this.isInTab = false;
    }
    this.getDestinations();
  }

  public changeIntegration(integration: Integration) {
    this.gridViewIsLoading = true;
    this.gridView = null;
    this.integration = integration;
    this.identifierService.getIntegrationIdentifiers(this.integration.integrationId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.baseData = data;
      this.loadGrid();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadGrid();
  }

  private loadGrid(dataOveride?: any): void {
    let data = dataOveride ? dataOveride : this.baseData;
    this.gridView = process(data, this.state);
    this.gridViewIsLoading = false;
  }

  private reloadData(flag: boolean = false): void {
    this.gridViewIsLoading = true;
    this.identifierService.getIntegrationIdentifiers(this.integration.integrationId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.baseData = data;
        this.loadGrid();

        if (this.runningGeneratingIdentifiers && flag) {
          this.generateIdentifier();
        }
      });
  }

  private outputIdentifier(event: any): void {
    this.identifier.emit(event);
  }

  selectionChange(event: any): void {
    this.selectedIdentifier = event.selectedRows[0].dataItem;
  }

  public addHandler(event: any) {
    this.selectedIdentifier = null;
    this.inAddMode = true;
    this.showAddUpdateForm = true;
  }

  public editHandler(event: any) {
    this.selectedIdentifier = event.dataItem;
    this.inAddMode = false;
    this.showAddUpdateForm = true;
  }

  public addUpdateFormFinished(event: any) {
    this.showAddUpdateForm = false;
    
    if (this.runningGeneratingIdentifiers && event == 'generated') {
      this.reloadData(true);
    }
    else {
      this.reloadData();
    }
  }


  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }

  
  idDestinationsChecked: number = 0;
  generateIdentifier(): void {
    if (this.runningGeneratingIdentifiers) {
      this.toastService.clear();
    }
    else {
      this.runningGeneratingIdentifiers = true;
    }
    
    this.generatedNewIdentifier = true;
    let date: Date = new Date();
    let continueTesting = true;
    this.idDestinationsChecked = 0;

    var domParser = new DOMParser();
    var base = domParser.parseFromString(this.integration.xmlConfig, 'text/xml');

    let tmpIdentifier: IntegrationIdentifier = new IntegrationIdentifier();
    tmpIdentifier.integrationId = this.integration.integrationId;
    tmpIdentifier.createdDate = date
    tmpIdentifier.updatedDate = date;
    tmpIdentifier.canDataSwap = false;
    tmpIdentifier.canFastFetch = false;

    if (this.baseData.filter(i => i.destinationName == "LGDataSwap").length > 0) {
      if (environment.enableDebuggingTools) {
        console.log("LGDataSwap identifier already exists. Use edit to update.");
      }
    }
    else if (base.getElementsByTagName("LimeGear").length > 1) {
      this.toastService.toastCreate("Too many LimeGear elements exist to generate identifier. Update and try again.", "Warning");
    }
    else if (base.getElementsByTagName("LimeGear").length == 1) {
      continueTesting = false;
      
      tmpIdentifier.identifierIdValue = base.getElementsByTagName("LimeGear")[0].getAttribute("CompanyId");
      tmpIdentifier.identifierNameValue = base.getElementsByTagName("LimeGear")[0].getAttribute("LimeGearCompany");
      tmpIdentifier.identifierKeyValue = base.getElementsByTagName("LimeGear")[0].getAttribute("LimeGearKey");
      
      if (this.allDestinations != null && this.allDestinations.length > 0) {
        let dest: DestinationEndpoint = this.allDestinations.filter(d => d.endpointName == "LGDataSwap")[0];
        tmpIdentifier.destinationId = dest.destinationEndpointId;
        tmpIdentifier.destinationName = dest.endpointName;
      }
    }

    if (continueTesting) {
      if (this.baseData.filter(i => i.destinationName == "CSDataSwap").length > 0) {
        if (environment.enableDebuggingTools) {
          console.log("CSDataSwap identifier already exists. Use edit to update.");
        }
      }
      else if (base.getElementsByTagName("CSDataSwap").length > 1) {
        this.toastService.toastCreate("Too many CSDataSwap elements exist to generate identifier. Update and try again.", "Warning");
      }
      else if (base.getElementsByTagName("CSDataSwap").length == 1) {
        continueTesting = false;
  
        tmpIdentifier.identifierIdValue = base.getElementsByTagName("CSDataSwap")[0].getAttribute("CompanyId");
        tmpIdentifier.identifierNameValue = base.getElementsByTagName("CSDataSwap")[0].getAttribute("CSDataSwapCompany");
        tmpIdentifier.identifierKeyValue = base.getElementsByTagName("CSDataSwap")[0].getAttribute("CSDataSwapKey");
  
        if (this.allDestinations != null && this.allDestinations.length > 0) {
          let dest: DestinationEndpoint = this.allDestinations.filter(d => d.endpointName == "CSDataSwap")[0];
          tmpIdentifier.destinationId = dest.destinationEndpointId;
          tmpIdentifier.destinationName = dest.endpointName;
        }
      }
    }
    
    if (continueTesting) {
      if (this.baseData.filter(i => i.destinationName == "Surefire3").length > 0) {
        if (environment.enableDebuggingTools) {
          console.log("Surefire3 identifier already exists. Use edit to update.");
        }
      }
      else if (base.getElementsByTagName("Surefire3").length > 1) {
        this.toastService.toastCreate("Too many Surefire3 elements exist to generate identifier. Update and try again.", "Warning");
      }
      else if (base.getElementsByTagName("Surefire3").length == 1) {
        continueTesting = false;
        tmpIdentifier.canDataSwap = false;
  
        tmpIdentifier.identifierIdValue = "";
        tmpIdentifier.identifierNameValue = base.getElementsByTagName("Surefire3")[0].getAttribute("MappingTag");
        tmpIdentifier.identifierKeyValue = base.getElementsByTagName("Surefire3")[0].getAttribute("ApiKey");
  
        if (this.allDestinations != null && this.allDestinations.length > 0) {
          let dest: DestinationEndpoint = this.allDestinations.filter(d => d.endpointName == "Surefire3")[0];
          tmpIdentifier.destinationId = dest.destinationEndpointId;
          tmpIdentifier.destinationName = dest.endpointName;
        }
      }
    }

    if (!continueTesting) {
      this.toastService.toastCreate("Creating '" + tmpIdentifier.destinationName + "' identifier. Check form values are valid and click Create.", "Info");
      this.selectedIdentifier = tmpIdentifier;
      this.inAddMode = true;
      this.showAddUpdateForm = true;
    }

    if (continueTesting) {
      this.toastService.toastCreate("Checked CSDataSwap, LGDataSwap and Surefire3 - identifiers for valid elements already created.", "Info");
      this.generatedNewIdentifier = false;
      this.runningGeneratingIdentifiers = false;
    }
  }

  getDestinations(): void {
    this.typeService.getAllDestinationEndpoints()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(r => {
      this.allDestinations = r;
    },
    (error) => {
      this.toastService.toastCreate("Error getting destinations: " + error.substr(0, 112), "Warning");
    });
  }

}
