<div class="outer-editor-container">
  <div id="monaco-diff-title-group" class="monaco-diff-title-group">
    <div id="original-current-side" class="original-current-side">
      <h5>Original / Current</h5>
      <h6>{{currentTitle}}</h6>
    </div>
    <div id="modified-history-side" class="modified-history-side">
      <h5>Modified / History</h5>
      <h6>{{historyTitle}}</h6>
    </div>
  </div>
  <ngx-monaco-diff-editor [options]="mergeOptions({renderSideBySide: true})"
                          style="height: 100%"
                          [original]="current"
                          [modified]="history"
                          (init)="editorInit($event)">
  </ngx-monaco-diff-editor>
</div>
