import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
  PageChangeEvent
} from '@progress/kendo-angular-grid';

import { DefaultConfigurationPlaceholder, DefaultConfigUtils } from '../../models/default-config-placeholder';
import { User } from '../../models/user';

import { DefaultConfigPlaceholderService } from '../../services/default-config-placeholder.service';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MonacoPageComponent } from '../../components/monaco-editor/monaco-editor.component';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-default-configs',
  templateUrl: './default-configs.component.html',
  styleUrls: ['./default-configs.component.scss'],
  providers: [DefaultConfigPlaceholderService]
})
export class DefaultConfigsComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();

  allGroup: DefaultConfigurationPlaceholder[];
  filteredGroup: DefaultConfigurationPlaceholder[];
  selectedConfig: DefaultConfigurationPlaceholder;

  showFullMapping = false;
  monacoData = "";

  gridView: GridDataResult;
  type: 'numeric' | 'input' = 'input';
  searchTerm: string;
  filter: CompositeFilterDescriptor;
  gridViewIsLoading = true;

  public state: DataSourceRequestState = {
    skip: 0,
    take: 10,
    sort: []
  };


  constructor(
    protected configService: DefaultConfigPlaceholderService,
    private router: Router,
    private user: User
    ) {
      this.state.sort = [{ field: "name", dir: 'asc' }];
    }

  ngOnInit(): void {
    this.configService.getAllDefaultConfigPlaceholders()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(r => {
        this.allGroup = r;
        this.loadDataIntoGridView();
      },
      error => {
        this.gridViewIsLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  loadDataIntoGridView() {
    let orderedData = orderBy(this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  updateGrid() {
    this.gridViewIsLoading = true;
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.gridViewIsLoading = true;
    this.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  selectionChangeHandler(event: any): void {
    this.selectedConfig = event.selectedRows[0].dataItem;
    if (this.checkAccess(["LOSTalker.Integration"])) {
      this.router.navigate(['/editDefaultConfigs', this.selectedConfig.name]);
    }
  }

  goToAddPage(): void {
    if (this.checkAccess(["LOSTalker.Integration"])) {
      this.router.navigate(['/addDefaultConfigs']);
    }
  }

  initShowFullMapping() {
    let baseUtils = new DefaultConfigUtils();
    let filteredConfigs = baseUtils.filterGroup(this.allGroup, null, null, null, null);
    baseUtils.setUpBase(filteredConfigs);
    let tmpStr = baseUtils.buildStringFromBase();
    let formattedStr = baseUtils.formatXMLString(tmpStr);
    this.monacoData = formattedStr;
    this.showFullMapping = true;
  }

  initHideFullMapping() {
    this.showFullMapping = false;
  }

}
