<div class="toast-block-div">
  <div>
    <div *ngFor="let t of toasts" 
         class="{{setStyle(t)}} toast-div" 
         role="alert">

      <i class="icon-div bi {{setIconClass(t)}}"></i>
      
      <div class="message-div">
        <span [innerHTML]="t.message"></span>
      </div>

      <div class="close-div"><a class="close" (click)="closeToast(t)">&times;</a></div>

    </div>
  </div>

  <button mat-stroked-button 
          *ngIf="toasts.length > 1" 
          color="primary" 
          class="button-class"
          (click)="clearAllMessages()">
    Close All
  </button>
  
</div>
