<h1 class="text-center mb-3">Client Configuration Secrets</h1>

<div *ngIf="!addingSecret && !editingSecret">
    <app-view-generic-grid #genericGrid *ngIf="!selectedClient"
      [data]="allClients"
      [loading]="gettingClients"
      [columns]="clientColumns"
      [isResizeable]="true"
      [showExportToolbar]="false"
      [isReorderable]="false"
      [isSelectable]="true"
      [showSelectionColumn]="false"
      [showChangeSelection]="false"
      [sortDescriptions]="clientSortDescriptions"
      (itemSelected)="getClientFromGrid($event)">
    </app-view-generic-grid>

    <div *ngIf="selectedClient">

        <h4 class="float-start">Selected Client: {{selectedClient.name}}</h4>

        <button mat-icon-button (click)="editClient()" class="ms-2">
          <i class="bi bi-pencil"></i>
        </button>

        <div class="my-2">
          <kendo-grid [data]="allSecrets">
            <kendo-grid-column field="integrationName" title="Integration Name" [width]="250"></kendo-grid-column>
            <kendo-grid-column field="elementPath" title="Element Path" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.attributeName}} - {{dataItem.elementPath}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="displayName" title="Display Name" [width]="250"></kendo-grid-column>
            <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="clientConfigurationSecretId" title="" [width]="85">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button mat-flat-button color="primary"
                            [disabled]="updatingSecretLoading"
                            (click)="editSecret(dataItem)">
                        Edit
                    </button>
                </ng-template>
            </kendo-grid-column>

            <ng-template kendoGridNoRecordsTemplate>
                <div class="empty_spacing_for_norecords">
                    <div class="k-i-loading" *ngIf="secretGridViewIsLoading"></div>
                    <div class="no-records-message" *ngIf="!secretGridViewIsLoading && allSecrets">No records found.</div>
                    <div class="no-records-message" *ngIf="!secretGridViewIsLoading && !allSecrets">Loading failed.</div>
                </div>
            </ng-template>

          </kendo-grid>
        </div>

        <div class="text-center m-2">
            <button mat-flat-button color="primary" (click)="addSecret()">Add a secret?</button>
        </div>
    </div>
</div>

<div *ngIf="(addingSecret || editingSecret) && allGroupConfigPath">

    <div class="container">
        <div class="row">
            <div *ngIf="editingSecret" class="col-5">
                <h4>IntegrationId: {{this.selectedIntegration.integrationId}}</h4>
                <h4>IntegrationName: {{this.selectedIntegration.name}}</h4>
                <h4>ServerId: {{this.selectedIntegration.serverId}}</h4>
                <h4>ClientId: {{this.selectedIntegration.clientId}}</h4>
                <br>
            </div>

            <div *ngIf="matchingSecrets && matchingSecrets.length > 0" class="col-7">

                <h4>Matching Secrets</h4>

                <app-view-generic-grid #matchingSecretsGrid
                  [data]="matchingSecrets"
                  [columns]="matchingSecretsColumns"
                  [showExportToolbar]="false"
                  [isReorderable]="false"
                  [isSelectable]="false"
                  [loading]="updatingSecretLoading">
                </app-view-generic-grid>

                <button mat-flat-button color="primary" (click)="confirmMultipleSecretsUpdate()">
                    Submit changes for all
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-7">
                <div *ngIf="addingSecret">
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            Select Integration
                        </mat-panel-title>
                        <mat-panel-description *ngIf="selectedIntegration">
                            <h6 class="p-0 m-0">Selected: {{selectedIntegration.name}} - {{selectedIntegration.dataSourceTypeId}}</h6>
                        </mat-panel-description>
                        </mat-expansion-panel-header>

                        <app-view-generic-grid #integrationsGrid
                          [data]="integrations"
                          [columns]="integrationColumns"
                          [isReorderable]="false"
                          [isSelectable]="true"
                          [showSelectionColumn]="true"
                          [showSelectDeselectButtons]="true"
                          [selectionfield]="'integrationId'"
                          [showColumnMenu]="false"
                          [selectionColumnWidth]="60"
                          [selectionColumnHeader]="''"
                          [isFilterable]="false"
                          (itemSelected)="getIntegrationFromGrid($event)"
                          [loading]="secretGridViewIsLoading">
                        </app-view-generic-grid>
                    </mat-expansion-panel>

                    <br>
                    <br>
                </div>

                <div *ngIf="editingSecret">
                    <h6>Original Configuration Path:</h6>
                    <h6>DisplayName: {{clientConfigurationSecret.displayName}}</h6>
                    <h6>Element Path: {{clientConfigurationSecret.elementPath}}</h6>
                    <h6>Attribute Name: {{clientConfigurationSecret.attributeName}}</h6>
                </div>

                <div *ngIf="!editingPath">
                    <button mat-flat-button color="primary" (click)="editPath()">
                        Edit Path
                    </button>
                    <br>
                </div>

                <mat-expansion-panel hideToggle *ngIf="editingPath">
                    <mat-expansion-panel-header>
                    <mat-panel-title>
                        Select Configuration Path
                    </mat-panel-title>
                    <mat-panel-description *ngIf="configurationPath">
                        <h6 class="p-0 m-0">Selected: {{configurationPath.displayName}} - {{configurationPath.elementPath}} - {{configurationPath.attributeName}}</h6>
                    </mat-panel-description>
                    </mat-expansion-panel-header>

                    <app-view-generic-grid #configPathGrid
                          [data]="allGroupConfigPath"
                          [columns]="configPathColumns"
                          [isReorderable]="false"
                          [isSelectable]="true"
                          [showSelectionColumn]="true"
                          [showSelectDeselectButtons]="true"
                          [selectionfield]="'configurationPathId'"
                          [showColumnMenu]="false"
                          [selectionColumnWidth]="60"
                          [selectionColumnHeader]="''"
                          (itemSelected)="getConfigPathFromGrid($event)"
                          [loading]="gettingConfigPaths">
                    </app-view-generic-grid>

                </mat-expansion-panel>

                <br>

                <div *ngIf="editingSecret">
                    <h6 *ngIf="!showMigration">Original Secret Value: {{clientConfigurationSecret.secretValue}}</h6>
                    <h6 *ngIf="showMigration">Original Secret Value: Configured in Disabled Secret Provider</h6>
                </div>

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>secretValue</mat-label>
                    <input matInput [(ngModel)]="secretValue" [disabled]="showMigration">
                </mat-form-field>


                <div class="d-flex flex-column mb-2">
                  <div class="mb-2" *ngIf="showMigration"><button mat-flat-button color="primary" (click)="openMigrateDialog()">Migrate Secret</button></div>
                  <div class="mb-2" *ngIf="addingSecret"><button mat-flat-button color="primary" (click)="confirmAdd()" [disabled]="showMigration">Submit</button></div>
                  <div class="mb-2" *ngIf="editingSecret" ><button mat-flat-button color="primary" (click)="confirmUpdate()" [disabled]="showMigration">Submit</button></div>
                  <div><button mat-flat-button color="primary" (click)="cancelAddingEditingSecret()">Cancel</button></div>
                </div>

            </div>
        </div>
    </div>

</div>

<ng-template #confirmDialog>
  <mat-dialog-content>
    <h3 *ngIf="editingSecret">The following secrets will be updated with the following information.</h3>
    <h3 *ngIf="addingSecret">The following secret will be added with the following information.</h3>

    <kendo-grid [data]="secretsToUpdate">
        <kendo-grid-column field="integrationName" title="Integration Name" [width]="165"></kendo-grid-column>
        <!-- Data source type of integration -->
        <kendo-grid-column field="elementPath" title="Element Path" [width]="210">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{configurationPath ? configurationPath.attributeName + " - " + configurationPath.elementPath : dataItem.attributeName + " - " + dataItem.elementPath}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="displayName" title="Display Name" [width]="175">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{configurationPath ? configurationPath.displayName : dataItem.displayName}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="secretValue" title="Secret Value" [width]="155">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{secretValue ? secretValue : dataItem.secretValue}}
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoGridNoRecordsTemplate>
            <div class="empty_spacing_for_norecords">
                <div class="k-i-loading" *ngIf="secretGridViewIsLoading"></div>
                <div class="no-records-message" *ngIf="!gridViewIsLoading && secretsToUpdate">No records found.</div>
                <div class="no-records-message" *ngIf="!gridViewIsLoading && !secretsToUpdate">Loading failed.</div>
            </div>
        </ng-template>

    </kendo-grid>

    <button mat-flat-button color="primary" class="m-1" [disabled]="editAddSecretLoading" (click)="submitEditSecret()" *ngIf="editingSecret && !matchingSecrets">Update</button>
    <button mat-flat-button color="primary" class="m-1" [disabled]="editAddSecretLoading" (click)="submitEditMultipleSecrets()" *ngIf="editingSecret && matchingSecrets">Update</button>
    <button mat-flat-button color="primary" class="m-1" [disabled]="editAddSecretLoading" (click)="submitAddSecret()" *ngIf="addingSecret">Add</button>
    <button mat-flat-button color="primary" class="m-1" [disabled]="editAddSecretLoading" (click)="closeDialog()">Cancel</button>
  </mat-dialog-content>
</ng-template>

<ng-template #migrateDialog>
  <h2 mat-dialog-title>Migrate Secret to Other Secret Client?</h2>
  <mat-dialog-content>
    <div>
      Secret does not exist in current Secret Client, migrate secret from other Secret Client?
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button matDiaogClose (click)="migrateSecret()">Migrate Secret</button>
    <button mat-flat-button matDiaogClose (click)="cancelMigrateSecret()">Close and Don't Migrate Secret</button>
  </mat-dialog-actions>
</ng-template>
