import { Component, OnInit, OnDestroy, ViewChild, NgZone, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, Subject, of} from 'rxjs';
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { InvoicingService } from '../../services/invoicing.service';
import { InvoicePeriod } from '../../models/invoice-period';
import { InvoicePeriodMetrics } from '../../models/invoice-period-metrics';
import { ToastService } from '../../services/toast.service';
import { ToastType } from 'src/app/models/toast';
import { InvoicingUtils } from '../../utilities/invoicing-utilities';
import { AggregateDescriptor, State, aggregateBy, AggregateResult, orderBy, filterBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { GridComponent, ExcelExportEvent, GridDataResult } from "@progress/kendo-angular-grid";
import { ColumnSettings } from 'src/app/models/column-settings';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { ClientContract } from 'src/app/models/client-contract';
import { IntegrationMapping } from 'src/app/models/integration-mapping';

@Component({
  selector: 'app-invoicing-metrics-grid',
  templateUrl: './invoicing-metrics-grid.component.html',
  styleUrls: ['./invoicing-metrics-grid.component.scss']
})
export class InvoicingMetricsGridComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild("metricGrid") public metricsGrid: GridComponent;

  @Input() data: InvoicePeriodMetrics[] = [];
  @Input() loading: boolean;
  @Input() exportFileName: string = "InvoicedMetricsReport.xlsx";
  @Input() selectedContract: ClientContract = null;
  @Input() showAllContractsView: boolean = true;

  @Input() currentStartDate: Date = null;
  @Input() currentEndDate: Date = null;

  invoicingUtils: InvoicingUtils;
  pageLoading = true;
  loadingFailed = false;
  pageReady: boolean = true;

  sortDescAll: SortDescriptor[] = [{ field: "contract.clientFullName", dir: "asc", },];
  sortDescSingle: SortDescriptor[] = [{ field: "dateStart", dir: "asc", },];

  allGroup: InvoicePeriodMetrics[] = [];
  hasMetrics: boolean = false;

  hasData: boolean = false;
  public gridViewIsLoading: boolean = true;

  gettingMetrics: boolean = false;

  public columns: ColumnSettings[] = [];
  generalColumns: ColumnSettings[] = [];
  generalGroupedColumns: ColumnSettings[] = [];
  allColumns: ColumnSettings[] = [];
  allColumnsBackup: ColumnSettings[] = [];
  hasExtraColumns: boolean = false;

  public aggregates: AggregateDescriptor[] = [];

  public defaultAggregates: AggregateDescriptor[] = [
    { field: "totalLoanCount", aggregate: "sum" },
    { field: "loanVolume", aggregate: "sum" },
    { field: "clientFullName", aggregate: "count" }
  ];
  total: AggregateResult = null;

  public state: State = {
    sort: [],
    skip: 0,
    take: 20
  };

  type: 'numeric' | 'input' = 'input';
  public pageSizes = [10, 20, 50, 75, 100];

  // destinations
  selectedDestination: DestinationEndpoint = null;
  selectedMapping: IntegrationMapping = null;

  showMetricsContainer: boolean = false;


  constructor(
    protected toastService: ToastService
  ) {
    this.invoicingUtils = new InvoicingUtils();
    this.state.sort = this.sortDescAll;
  }

  ngOnInit(): void {
    this.getDefaultColumns();

    if (this.data != null && this.data.length > 0) {
      this.hasData = true;
      this.allGroup = this.data;
      this.loadMetrics();
    }
    else {
      this.hasData = false;
      this.gridViewIsLoading = false;
    }

    this.allData = this.allData.bind(this);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.allGroup.map(m => ({ ...m, }))
    };
    return result;
  }

  loadMetrics() {
    this.allColumns = [];
    if (this.allGroup.length > 0) {
      this.columns = this.invoicingUtils.getMetricsGroupedColumns(this.allGroup);
    }
    else {
      this.columns = [];
    }

    this.allColumns = this.generalColumns.concat(this.columns);

    if (this.generalGroupedColumns.length > 0) {
      this.allColumns = this.generalGroupedColumns.concat(this.generalColumns, this.columns);
    }

    if (this.columns.length > 0) {
      this.hasExtraColumns = true;
    }

    this.total = aggregateBy(this.allGroup, this.aggregates);
    this.gettingMetrics = false;

    if (this.allGroup.length > 0) {
      this.hasMetrics = true;
    }
    else {
      this.hasMetrics = false;
    };
    this.showMetricsContainer = true;
  }

  public onExcelExport(e: any): void {

    if (this.showAllContractsView) {
      this.exportFileName = "InvoicedMetricsReport";

      if (this.selectedDestination != null && this.selectedDestination.endpointName != null && this.selectedDestination.endpointName != "") {
        this.exportFileName = this.exportFileName + "_" + this.selectedDestination.endpointName;
      }
    }
    else if (this.selectedContract != undefined && this.selectedContract != null && this.selectedContract?.clientFullName != null){
      this.exportFileName = "InvoicedMetricsReport_" + this.selectedContract.clientFullName;
    }
    else {
      this.exportFileName = "InvoicedMetricsReport";
    }
    if (this.currentStartDate != null && this.currentEndDate != null) {
      let startDateString = (this.currentStartDate.toISOString().split('T')[0]).replace("-","").replace("-","");
      let endDateString = this.currentEndDate.toISOString().split('T')[0].replace("-","").replace("-","");
      this.exportFileName = this.exportFileName + "_" + startDateString + "_" + endDateString + ".xlsx";
    }
    else {
      this.exportFileName = this.exportFileName + ".xlsx";
    }

    let colIndexVolume;
    let gridCols = this.metricsGrid.columnsContainer.leafColumnsToRender;
    gridCols.forEach((col, index) => {
      if (col.title == "Total Volume") {
        colIndexVolume = col.leafIndex;
        return;
      }
    });

    const rows = e.workbook.sheets[0].rows;
    rows.forEach((row) => {
      // Use the column index to format the price cell value
      if (row.type === "data") {
        row.cells.forEach((cell, index) => {
          if (index === colIndexVolume) {
            cell.format = "$0.00";
          }
          else if (cell.value == "" || cell.value == null) {
            cell.value = 0;
          }
        });
      }
    });
  }

  getDefaultColumns(): void {
    this.aggregates = this.defaultAggregates;

    if (this.showAllContractsView) {
      let cols = this.invoicingUtils.defaultInvoicePeriodMetricsColumnSettings;
      this.generalColumns = cols.map(x => new ColumnSettings(x));
    }
    else {
      let cols = this.invoicingUtils.defaultClientInvoicePeriodMetricsColumnSettings;
      this.generalColumns = cols.map(x => new ColumnSettings(x));
      this.aggregates.push({field: "dateEnd", aggregate: "count"});
      let groupedCols = this.invoicingUtils.defaultClientInvoicePeriodMetricsGroupedColumnSettings;
      this.generalGroupedColumns = groupedCols.map(x => new ColumnSettings(x));
    }
  }

  // get the value of the sub key of the agg key
  public getAggregateSubKeyValue(dataItem: InvoicePeriodMetrics, columnInfo: any, subColumnInfo: any): number {
    var rc = 0;
    if (dataItem.jsonFieldCollection != null) {
      if (dataItem.jsonFieldCollection.hasOwnProperty(columnInfo.field)) {
        if (dataItem.jsonFieldCollection[columnInfo.field].hasOwnProperty(subColumnInfo.field)) {
          rc = dataItem.jsonFieldCollection[columnInfo.field][subColumnInfo.field];
        }
      }
    }
    return rc;
  }

  public getAggregateFieldValue(columnInfo: any, subColumnInfo: any): string {
    var rc = "jsonFieldCollection." + columnInfo.prettyField + "." + subColumnInfo.prettyField;
    return rc;
  }

  public getAggregateTotalSubKeyValue(dataItem: InvoicePeriodMetrics, columnInfo: any, subColumnInfo: any): number {
    var rc = 0;
    let initialValue = 0;
    let total = this.allGroup
                  .filter(x => x.jsonFieldCollection != null)
                  .filter(x => x.jsonFieldCollection.hasOwnProperty(columnInfo.field)
                              && x.jsonFieldCollection[columnInfo.field].hasOwnProperty(subColumnInfo.field))
                  .map(y => y.jsonFieldCollection[columnInfo.field][subColumnInfo.field])
                  .reduce((p,c) => p + c, initialValue);

    if (total != null && this.invoicingUtils.isNumeric(total)) {
      rc = total;
    }
    return rc;
  }

  public getAggregateTotalAggKeyValue(columnInfo: any): number {
    var rc = 0;
    let total = 0;

    let aggSubKeyMap = this.allGroup
                  .filter(x => x.jsonFieldCollection != null)
                  .filter(x => x.jsonFieldCollection.hasOwnProperty(columnInfo.field))
                  .map(y => y.jsonFieldCollection[columnInfo.field]);

    aggSubKeyMap.forEach(val => {
      total += val;
    });

    if (total != null && this.invoicingUtils.isNumeric(total)) {
       rc = total;
    }
    return rc;
  }

  // Custom Column Chooser Logic
  // unhide all columns - from button in custom column menu
  public unHideAllColumns() {
    this.allColumns.forEach(x => {
      this.invoicingUtils.setHiddenForColumnAndSubColumns(x, false);
    });
  }

  // Custom Column Chooser Logic
  public hideCol(columnInfo: ColumnSettings) {
    this.invoicingUtils.setHiddenForColumnAndSubColumns(columnInfo, !columnInfo.hidden, this.allColumns);
  }

  public getHeaderTooltipText(t: string, field: string = null) {
    var rc = `${t}`
    if (field != null && field != '') {
      rc = `${t} | Field: ${field}`;
    }
    return rc;
  }

}
