import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { Router } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { User } from '../../models/user';
import { Integration } from 'src/app/models/integration';
import { ClientConfigurationSecretService } from 'src/app/services/client-configuration-secret.service';
import { ClientConfigurationSecret } from 'src/app/models/client-config-secret';
import { ConfigurationPath } from 'src/app/models/configuration-path';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { Client } from 'src/app/models/client';
import { IntegrationIdentifier } from 'src/app/models/integration-identifier';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ClientConfigurationSecretComparison } from 'src/app/models/client-config-secret-comparison';
import { ToastService } from 'src/app/services/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { IdentifierService } from 'src/app/services/identifier.service';
import { SecretType } from 'src/app/models/secret-type';
import { ColumnSettings } from 'src/app/models/column-settings';

@Component({
  selector: 'app-client-configuration-secrets',
  templateUrl: './client-configuration-secrets.component.html',
  styleUrls: ['./client-configuration-secrets.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ClientIntegrationService]
})
export class ClientConfigurationSecretsComponent implements OnInit {
  //Class variables
  @ViewChild('confirmDialog', { static: true }) confirmDialog: TemplateRef<any>;
  @ViewChild('migrateDialog', { static: true }) migrateDialog: TemplateRef<any>;
  componentDestroyed$: Subject<boolean> = new Subject();

  //Selected values from generic grids
  selectedClient: Client = null;
  selectedIntegration: Integration = null;
  configurationPath: ConfigurationPath = null;
  identifiers: IntegrationIdentifier[] = [];

  // all groups for generic grids
  allClients: Client[] = [];
  integrations: Integration[] = [];
  allGroupConfigPath: ConfigurationPath[] = [];
  matchingSecrets: ClientConfigurationSecret[] = [];
  gettingClients: boolean = false;

  //Secret grid variables
  allSecrets: ClientConfigurationSecret[] = [];
  // for initial select secret
  secretGridViewIsLoading: boolean;

  //Status variables
  editingSecret: boolean = false;
  editingPath: boolean = false;
  addingSecret: boolean = false;

  // Loading variables
  gettingConfigPaths: boolean = false;
  editAddSecretLoading: boolean = false;
  updatingSecretLoading: boolean = false;

  //Object variables
  clientConfigurationSecret: ClientConfigurationSecret;
  secretsToUpdate: ClientConfigurationSecret[];
  secretValue: string;

  showMigration: boolean = false;
  migratingSecret: boolean = false;

  // column settings for generic grids
  clientColumns: ColumnSettings[] = [
    { field: 'name', title: 'Client Name', type: 'text', width: 100},
	  { field: 'clientId', title: 'Client ID', type: 'text', width: 50},
    { field: 'dataSourceTypeAgg', title: 'Data Source Type Id', type: 'text', width: 100},
  ];
  matchingSecretsColumns: ColumnSettings[] = [
    { field: 'integrationName', title: 'Integration Name', type: 'text', width: 165, filterable: false},
	  { field: 'attributeName', title: 'AttributeName', type: 'text', width: 100, filterable: false},
    { field: 'elementPath', title: 'ElementPath', type: 'text', width: 110, filterable: false},
	  { field: 'displayName', title: 'DisplayName', type: 'text', width: 175, filterable: false},
    { field: 'updatedDate', title: 'UpdatedDate', type: 'text', width: 155, filterable: false},
  ];
  integrationColumns: ColumnSettings[] = [
    { field: 'integrationId', title: 'ID', type: 'text', width: 200, filterable: false},
	  { field: 'name', title: 'Name', type: 'text', width: 80, filterable: false},
    { field: 'dataSourceTypeId', title: 'Data Source Type Id', type: 'text', width: 100, filterable: false},
  ];
  configPathColumns: ColumnSettings[] = [
    { field: 'displayName', title: 'DisplayName', type: 'text', width: 100},
	  { field: 'elementPath', title: 'ElementPath', type: 'text', width: 125},
	  { field: 'attributeName', title: 'AttributeName', type: 'text', width: 75},
  ];
  // sort for client grid
  clientSortDescriptions: SortDescriptor[] = [{ field: "name", dir: 'asc' }]


  constructor(
    private clientConfigurationSecretService: ClientConfigurationSecretService,
    private clientIntegrationService: ClientIntegrationService,
    private identifierService: IdentifierService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private user: User
  ) {
    this.gettingClients = true;
    this.clientIntegrationService.getAllClients()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.allClients = r;
        this.gettingClients = false;
      },
      error => {
        this.toastService.toastCreate("Failed to get Clients.", "Warning")
        this.gettingClients = false;
      });
  }

  ngOnInit(): void {
    this.gettingConfigPaths = true;
    this.clientConfigurationSecretService.getConfigurationPaths()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(paths => {
      this.allGroupConfigPath = paths;
      this.gettingConfigPaths = false;
    },
    error => {
      this.toastService.toastCreate("Failed to get Configuration Paths.", "Warning")
      this.gettingConfigPaths = false;
    });
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  addSecret(): void {
    this.addingSecret = true;
    this.editingPath = true;
  }

  cancelAddingEditingSecret(): void {
    this.addingSecret = false;
    this.editingSecret = false;
    this.selectedIntegration = null;
    this.configurationPath = null;
    this.editingPath = false;
    this.secretValue = undefined;
    this.showMigration = false;
    // this.clientConfigurationSecret = null;
    // this.secretsToUpdate = [];
  }


  confirmAdd() {

    if(!this.configurationPath && !this.selectedIntegration && !this.secretValue) {
      this.toastService.toastCreate("All fields must be populated to add secret.", "Danger");
      return;
    }

    this.clientConfigurationSecret = null;
    let secret: ClientConfigurationSecret = {
      clientConfigurationSecretId : undefined,
      displayName : this.configurationPath.displayName,
      azureSecretId : null,
      azureSecretVersion : null,
      azureName : null,
      deleted : false,
      updatedDate : new Date(),
      integrationId : this.selectedIntegration.integrationId,
      integrationName : this.selectedIntegration.name,
      serverId : this.selectedIntegration.serverId,
      clientId : this.selectedIntegration.clientId,
      secretValue : this.secretValue,
      configurationPathId : this.configurationPath.configurationPathId,
      elementPath : this.configurationPath.elementPath,
      attributeName : this.configurationPath.attributeName,
      destinationEndpointId : this.configurationPath.destinationEndpointId,
      dataSourceTypeId : this.configurationPath.dataSourceTypeId,
      aWSDisplayName: null,
      aWSResourceName: null,
      aWSVersionId: null
    };

    this.clientConfigurationSecret = secret;

    this.secretsToUpdate = [this.clientConfigurationSecret];

    this.dialog.open(this.confirmDialog);
  }

  submitAddSecret(): void {
    this.editAddSecretLoading = true;
    this.clientConfigurationSecretService.addClientConfigSecret(this.clientConfigurationSecret)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(() => {

      this.clientConfigurationSecret = null;
      this.secretsToUpdate = [];

      this.toastService.toastCreate("Secret added.", "Success");

      this.refreshClientSecrets();

      this.cancelAddingEditingSecret();

      this.dialog.closeAll();
      this.editAddSecretLoading = false;

    }, error => {
      this.dialog.closeAll();
      this.editAddSecretLoading = false;
    });

  }

  editClient() {
    this.selectedClient = null;
    this.integrations = [];
    this.identifiers = [];
    this.allSecrets = [];
  }

  editSecret(clientConfigurationSecret: ClientConfigurationSecret) {
    this.updatingSecretLoading = true;
    this.matchingSecrets = [];

    this.clientConfigurationSecretService
      .getClientConfigSecret(clientConfigurationSecret.clientConfigurationSecretId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( (response) => {
        this.clientConfigurationSecret = response;

        if (this.clientConfigurationSecret?.migrationNeeded == true) {
          this.showMigration = true;
        }

        //Find duplicate secrets
        let identifier = this.identifiers.find(i => i.integrationId == this.clientConfigurationSecret.integrationId);
        let identifierMatches: IntegrationIdentifier[] = [];
        for (let i of this.identifiers) {
          if (identifier == i) {
            continue;
          }

          if (identifier.identifierNameValue == i.identifierNameValue
              && identifier.identifierIdValue == i.identifierIdValue
              && identifier.identifierKeyValue == i.identifierKeyValue){
            identifierMatches.push(i);
          }
        }

        let secretMatches: ClientConfigurationSecret[] = [];

        for(let i of identifierMatches) {
          let m = this.allSecrets.filter(x =>
            x.integrationId == i.integrationId
            && x.elementPath == this.clientConfigurationSecret.elementPath
            && x.attributeName == this.clientConfigurationSecret.attributeName
          );
          if (m != null && m.length > 0) {
            secretMatches = m;
          }
        }

        this.secretValue = undefined;
        this.configurationPath = null;
        this.selectedIntegration = this.integrations.find(x => x.integrationId == this.clientConfigurationSecret.integrationId);

        if (secretMatches.length > 0) {
          let sameSecrets = [];
          if (this.showMigration || secretMatches.some(x => x.migrationNeeded == true)) {
            this.toastService.toastCreate("There may be " + secretMatches.length + " additional secrets to migrate", "Info");
            this.editingSecret = true;
            this.updatingSecretLoading = false;
          }
          else {
            this.clientConfigurationSecretService
            .getClientConfigSecretComparison(this.clientConfigurationSecret, secretMatches)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(value => {
                for (let key in value) {
                  if (value[key]) {
                    sameSecrets.push(secretMatches.find(s => s.clientConfigurationSecretId == key))
                  }
                }

                if (sameSecrets.length > 0) {
                  this.matchingSecrets = sameSecrets;
                }

                this.editingSecret = true;
                this.updatingSecretLoading = false;
            },
            error => {
              this.toastService.toastCreate("Error getting comparison secret data. " + error, "Warning");
            });
          }
        }
        else {
          this.editingSecret = true;
          this.updatingSecretLoading = false;
        }


    },
    error => {
      this.updatingSecretLoading = false;
    });
  }

  submitEditSecret() {
    this.submitEditingSecrets([this.clientConfigurationSecret]);
  }

  submitEditMultipleSecrets() {
    this.submitEditingSecrets(this.secretsToUpdate);
  }

  submitEditingSecrets(secretsToUpdate: ClientConfigurationSecret[]) {
    this.editAddSecretLoading = true;

    if (this.secretValue?.trim() != '' && this.secretValue != null) {
      secretsToUpdate.forEach(secret => secret.secretValue = this.secretValue);
    }

    if (this.configurationPath != null) {
      secretsToUpdate.forEach( secret => {
        secret.configurationPathId = this.configurationPath.configurationPathId;
        secret.elementPath = this.configurationPath.elementPath;
        secret.attributeName = this.configurationPath.attributeName;
        secret.destinationEndpointId = this.configurationPath.destinationEndpointId;
        secret.dataSourceTypeId = this.configurationPath.dataSourceTypeId;
        secret.displayName = this.configurationPath.displayName;
      });
    }

    this.clientConfigurationSecretService.updateClientConfigSecret(secretsToUpdate)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(value => {
      this.cancelAddingEditingSecret();
      this.toastService.toastCreate("Secrets updated.", "Success");

      this.refreshClientSecrets();

      this.dialog.closeAll();
      this.editAddSecretLoading = false;

    }, error => {
      this.dialog.closeAll();
      this.editAddSecretLoading = false;
    });
  }

  editPath() {
    this.editingPath = true;
  }

  confirmUpdatingSecrets(secretsToUpdate: ClientConfigurationSecret[]) {
    if(!this.configurationPath && !this.secretValue) {
      this.toastService.toastCreate("No field is populated to update secret.", "Danger");
      return;
    }

    if (secretsToUpdate.length > 0) {
      this.secretsToUpdate = secretsToUpdate;
    }

    this.dialog.open(this.confirmDialog);

  }

  confirmUpdate() {
    this.confirmUpdatingSecrets([this.clientConfigurationSecret]);
  }

  confirmMultipleSecretsUpdate() {
    this.confirmUpdatingSecrets([this.clientConfigurationSecret, ...this.matchingSecrets]);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  // get client from Grid
  getClientFromGrid(event: any): void {
    this.secretGridViewIsLoading = true;
    this.selectedClient = event;
    forkJoin({
      integrations: this.clientIntegrationService.getIntegrationsByClient(this.selectedClient.name),
      identifiers: this.identifierService.getIntegrationIdentifiersByClient(this.selectedClient.clientId),
      secrets: this.clientConfigurationSecretService.getClientConfigSecretByClient(this.selectedClient.clientId)
    })
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      ({ integrations, identifiers, secrets}) => {
        this.integrations = integrations;
        this.identifiers = identifiers;
        if (secrets != null) {
          this.allSecrets = secrets;
        }
        this.secretGridViewIsLoading = false;
      } ,
      error => {
        this.secretGridViewIsLoading = false;
      }
    );
  }

  // refresh secrets for selected client - call after adding, editing secret
  refreshClientSecrets(): void {
    this.secretGridViewIsLoading = true;
    this.allSecrets = [];

    this.clientConfigurationSecretService.getClientConfigSecretByClient(this.selectedClient.clientId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      if (data != null) {
        this.allSecrets = data;
      }
      this.secretGridViewIsLoading = false;
    },
    error => {
      this.secretGridViewIsLoading = false;
    });
  }

  // get config path from grid
  getConfigPathFromGrid(event: any): void {
    this.configurationPath = event;
  }

  // get integration from grid
  getIntegrationFromGrid(event: any): void {
    this.selectedIntegration = event;
  }

  openMigrateDialog(): void {
    this.dialog.open(this.migrateDialog);
  }

  migrateSecret(): void {
    this.migratingSecret = true;
    this.dialog.closeAll();
    this.clientConfigurationSecretService.migrateClientConfigSecret(this.clientConfigurationSecret)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(value => {
      this.cancelAddingEditingSecret();
      this.toastService.toastCreate("Secret migrated: " + this.clientConfigurationSecret.displayName ?? "", "Success");

      this.refreshClientSecrets();
      this.editSecret(this.clientConfigurationSecret);

      this.dialog.closeAll();
      this.migratingSecret = false;

    }, error => {
      this.dialog.closeAll();
      this.migratingSecret = false;
    });
  }

  cancelMigrateSecret(): void {
    this.dialog.closeAll();
    this.toastService.toastCreate("Can not get or update secret with current Secret Client.", "Warning");
  }


}
