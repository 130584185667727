<div *ngIf="currentRoles !== undefined && roleList !== undefined">
    <h1>{{fullName}}</h1>
    <h3>E-mail: {{email}}</h3> 
    <h3>Role(s):</h3>
    <li *ngFor="let r of currentRoles">
        {{r}}
    </li>
    <div *ngIf="enableImpersonation && currentRoles.includes('LOSTalker.Admin')">
        <h1>Role Test Mode</h1>
        <p>Select roles: </p>
        <div *ngFor="let rl of roleList">
            <input type="checkbox" class="k-checkbox" (change)="updateRole(rl)" id="rl" [checked]="selectedRoles.includes(rl)">
            <label for="rl">  {{rl}}</label>
        </div>
        <br />
        <button (click)="impersonate()">Start Impersonation</button>
    </div>
</div>