import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { UserService } from './services/user.service';
import { User } from './models/user';
import { Subject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import { protectedResources } from './auth-config';

import { ToastService } from './services/toast.service';
import { Toast, ToastType } from './models/toast';
import { Router, UrlTree, NavigationBehaviorOptions, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LOSTalkerTitleService } from './services/lostalker-title-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'LOS Talker';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  //loginResult: UserAuthResult;
  loggedIn: boolean = false;
  userFullName: string = "";
  userName: string = "";
  impersonationActive: boolean = false;
  environmentName: string = environment.environmentName;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private user: User,
    private router: Router,
    private titleService: LOSTalkerTitleService
  ) { }

  ngOnInit(): void {

    if (!this.loggedIn) {
      this.checkAndSetActiveAccount();
    }

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.impersonationActive = this.user.enableImpersonation;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {

          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setUpTitle(title);
        }
      })

  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.userName = accounts[0].username;
      this.userFullName = accounts[0].name;
      this.setUser(accounts[0]);
    }
    else if (activeAccount) {
      this.userName = activeAccount.username;
      this.userFullName = activeAccount.name;
      this.setUser(activeAccount);
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.setUser(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.setUser(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setUser(loggedInUser: AccountInfo) {
    this.loggedIn = true;
    this.user.userId = loggedInUser.localAccountId;
    this.user.userName = loggedInUser.username;
    this.user.fullName = loggedInUser.name;
    this.user.email = loggedInUser.username;
    this.user.claims = loggedInUser.idTokenClaims;
    this.user.disabled = false;

    if (loggedInUser.idTokenClaims["roles"] != undefined) {
      this.user.setRoles(loggedInUser.idTokenClaims["roles"]);
    }
  }

  endImpersonation() {
    this.user.endImpersonation();
    this.impersonationActive = false;
    this.router.navigate(['/userData']);
  }

  goToSwagger() {
    this.router.navigate(['/swagger']);
  }

}


