import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ViewEncapsulation, EventEmitter, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, formatDate } from '@angular/common';
import { forkJoin, Subject, Observable } from 'rxjs';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { ToastService } from '../../services/toast.service';
import { ReportService } from '../../services/report.service';
import { Report } from '../../models/report';
import { Server } from '../../models/server';
import { Client } from '../../models/client';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { DataSourceType } from '../../models/data-source-type';
import { Integration } from '../../models/integration';
import { ReportChangeLogFilter } from '../../models/report-changelog-filter';
import { takeUntil, take, map, startWith } from 'rxjs/operators';
import { LOSTalkerTitleService } from '../../services/lostalker-title-service.service';
import { ColumnSettings } from 'src/app/models/column-settings';
import { ViewGenericGridComponent } from '../view-generic-grid/view-generic-grid.component';
import { ChangeLog } from 'src/app/models/changelog';
import { ReportConstants } from 'src/app/models/report-constants';
import { UntypedFormControl, FormControl } from '@angular/forms';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-changelogs',
  templateUrl: './changelogs.component.html',
  styleUrls: ['./changelogs.component.scss']
})
export class ChangeLogsComponent implements OnInit, OnDestroy {
  @ViewChild('genericGrid') public genericGrid: ViewGenericGridComponent;

  componentDestroyed$: Subject<boolean> = new Subject();
  reportConstants: ReportConstants;

  @Input() serverItems: Server[] = [];
  @Input() dataSourceTypeItems: DataSourceType[] = [];
  @Input() destinationEndpointItems: DestinationEndpoint[] = [];

  serverItemsSelected: Server[] = [];
  dataSourceItemsSelected: DataSourceType[] = []; // Dynamic CSV ...
  destinationEndpointItemsSelected: DestinationEndpoint[] = []; // csd.. lg..
  
  defaultMinDate: Date;
  maxDate: Date;
  minDateFormControl = new FormControl();
  maxDateFormControl = new FormControl();
  
  byIntegration: boolean = false;

  filteredClients: Observable<Client[]>;
  clients: Client[] = [];
  client: Client = null;
  clientFormControl = new UntypedFormControl();

  filteredIntegrations: Observable<Integration[]>;
  integrations: Integration[] = [];
  integration: Integration = null;
  integrationFormControl = new UntypedFormControl();

  allData: ChangeLog[] = [];
  filteredData: ChangeLog[] = [];

  haveData: boolean = true;
  gettingData: boolean = false;
  gettingClients: boolean = false;
  gettingIntegrations: boolean = false;

  changeLogFilter: ReportChangeLogFilter = new ReportChangeLogFilter();
  reportTypeId: number = 10;

  reportColumns: ColumnSettings[] = [
    { field: 'changeLogId', title: 'ChangeLogId', type: 'text', hidden: true},
    { field: 'requestPath', title: 'RequestPath', type: 'text', width: 200},
    { field: 'userEmail', title: 'UserEmail', type: 'text', width: 100},
    { field: 'userRole', title: 'UserRole', type: 'text', width: 75},
    { field: 'httpMethod', title: 'HTTPMethod', type: 'text', width: 50},
    { field: 'changeScope', title: 'ChangeScope', type: 'text', width: 50},
    { field: 'changeDate', title: 'ChangeDate', type: 'text', width: 75},
    { field: 'oldValue', title: 'OldValue', type: 'text', width: 75},
    { field: 'newValue', title: 'NewValue', type: 'text', width: 75},
    { field: 'primaryKeyValue', title: 'PrimaryKeyValue', type: 'text', width: 50},
    { field: 'primaryKeyTypeId', title: 'PrimaryKeyTypeId', type: 'text', width: 50, hidden: true},
    { field: 'primaryKeyDataType', title: 'PrimaryKeyDataType', type: 'text', width: 50},
  ];

  constructor(
    protected clientService: ClientIntegrationService,
    protected reportService: ReportService,
    protected toastService: ToastService,
    private location: Location,
    private router: Router,
    private titleService: LOSTalkerTitleService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.defaultMinDate = new Date();
    this.defaultMinDate.setMonth(this.defaultMinDate.getMonth() - 1);
    this.defaultMinDate.setHours(0, 0, 0, 0);

    this.maxDate = new Date();
    this.maxDate.setHours(0, 0, 0, 0);

    this.setUpDefaultFilter();

    this.getTypeConstants();
    this.getClients();

    this.setUpReportData();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.genericGrid.fitColumns();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  switchChangeLogReportType(type: string): void {
    if (type == "date") {
      this.byIntegration = false;
      this.setUpDefaultFilter();
      this.setUpReportData();
    }
    else if (type == "integration") {
      this.byIntegration = true;
      this.minDateFormControl.setValue(null);
      this.maxDateFormControl.setValue(null);
      this.editClient();
    }
  }

  getTypeConstants(): void {
    this.reportService.getReportConstants(this.reportTypeId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.reportConstants = r;
      },
      (error) => {
        this.toastService.toastCreate("Error getting report constants.", "Warning");
      });
  }

  setUpDefaultFilter(): void {
    this.changeLogFilter.integrationId = "00000000-0000-0000-0000-000000000000";

    this.minDateFormControl.setValue(formatDate(this.defaultMinDate.toISOString(), 'yyyy-MM-dd', 'en'));
    this.changeLogFilter.minChangeDate = this.defaultMinDate;
    
    this.maxDateFormControl.setValue(formatDate(this.maxDate.toISOString(), 'yyyy-MM-dd', 'en'));
    this.changeLogFilter.maxChangeDate = this.formatMaxDate(this.maxDateFormControl.value);
  }

  //to include current date
  formatMaxDate(maxDate: string): Date {
    let formatDate = new Date(maxDate);
    formatDate.setDate(Number(maxDate.split("-")[2]) + 1);
    return formatDate;
  }

  getClients(): void {
    this.gettingClients = true;
    this.clientService.getAllClients().subscribe(
      clients => {
        this.clients = clients;
        this.filteredClients = this.clientFormControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterClients(name) : this.clients.slice())
        );
        this.gettingClients = false;
      },
      error => {
        this.gettingClients = false;
      }
    );
  }

  selectClient(c: Client): void {
    this.client = c;
    this.changeLogFilter.clientId = this.client.clientId;
    this.getIntegrations();
  }

  editClient() {
    this.client = null;
    this.changeLogFilter.clientId = null;
    this.integration = null;
    this.changeLogFilter.integrationId = null;
    this.allData = [];
    this.filteredData = [];
    this.clientFormControl.setValue('');
  }

  getIntegrations(): void {
    this.gettingIntegrations = true;
    this.clientService.getIntegrationsByClient(this.client.name)
      .subscribe((data) => {
        this.integrations = data;
        this.filteredIntegrations = this.integrationFormControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterIntegrations(name) : this.integrations.slice())
        );
        this.gettingIntegrations = false;
      }, error => {
        this.gettingIntegrations = false;
      });
  }
  
  selectIntegration(i: Integration): void {
    this.integration = i;

    this.changeLogFilter.integrationId = this.integration.integrationId;

    this.setUpReportData();
  }

  editIntegration() {
    this.integration = null;
    this.changeLogFilter.integrationId = null;
    this.allData = [];
    this.filteredData = [];
    this.integrationFormControl.setValue('');
  }

  setUpReportData(): void {
    this.gettingData = true;
    this.allData = [];
    this.filteredData = [];
    this.reportService
      .getChangeLogTypeQueryData(this.changeLogFilter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.gettingData = false;
          this.haveData = true;
          if (data != null && data.length > 0) {
            this.allData = data;
            for (let log of this.allData){
              log.primaryKeyDataType = this.reportConstants.primaryKeyTypes.find(p => p.primaryKeyTypeId == log.primaryKeyTypeId).primaryKeyDataType;
            }
            this.updateFilteredData();
          }
        },
        (error) => {
          // console.log(error);
          this.toastService.toastCreate("Error getting change logs.", "Warning");
          this.haveData = false;
          this.gettingData = false;
        }
      )
  }

  public clearMinDateSearch() {
    if (this.byIntegration) {
      this.minDateFormControl.setValue(null);
    }   
    else {
      this.minDateFormControl.setValue(formatDate(this.defaultMinDate.toISOString(), 'yyyy-MM-dd', 'en'));
      this.changeLogFilter.minChangeDate = this.defaultMinDate;
    }
    this.updateDate();
  }

  public clearMaxDateSearch() {
    if (this.byIntegration) {
      this.maxDateFormControl.setValue(null);
    }
    else {
      this.maxDateFormControl.setValue(formatDate(this.maxDate.toISOString(), 'yyyy-MM-dd', 'en'));
      this.changeLogFilter.maxChangeDate = this.formatMaxDate(this.maxDateFormControl.value);
    }
    this.updateDate();
  }

  displayFnClient(x: Client): string {
    return x && x.name ? x.name : '';
  }

  displayFnIntegration(x: Integration): string {
    if (this.integration == null) {
      return '';
    }
    return x && x.name ? x.name : '';
  }

  private _filterClients(value: string): Client[] {
    const filterValue = value.toLowerCase();
    return this.clients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterIntegrations(value: string): Integration[] {
    const filterValue = value.toLowerCase();
    return this.integrations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public updateDate() {
    if (!this.byIntegration) {
      if (this.minDateFormControl.value != null) {
        this.changeLogFilter.minChangeDate = this.minDateFormControl.value;
      }
  
      if (this.maxDateFormControl.value != null) {
        this.changeLogFilter.maxChangeDate = this.formatMaxDate(this.maxDateFormControl.value);
      }
      this.setUpReportData();
    }
    else {
      this.updateFilteredData();
    }
  }

  public updateFilteredData() {
    this.filteredData = this.allData;

    if (this.byIntegration) {
      if (this.minDateFormControl.value != null) {
        this.filteredData = this.filteredData.filter(i => i.changeDate > this.minDateFormControl.value);
      }
  
      if (this.maxDateFormControl.value != null) {
        this.filteredData = this.filteredData.filter(i => i.changeDate < this.maxDateFormControl.value);
      }
    }

    this.fitColumns();
  }
}
