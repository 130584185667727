

<h1 style="text-align:center;">Integration Errors</h1>
<br />



<br>
<br>

<div class="row row-cols-1 row-cols-md-2 row-cols-sm-2 pb-3 g-3">

  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Min Date</span>
          </div>
          <input type="date" class="form-control" [(ngModel)]="integrationErrorMessageFilter.minDate">
          <div class="input-group-append">
            <button mat-stroked-button (click)="clearMinDateSearch()" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">clear</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Max Date</span>
          </div>
          <input type="date" class="form-control" [(ngModel)]="integrationErrorMessageFilter.maxDate">
          <div class="input-group-append">
            <button mat-stroked-button (click)="clearMaxDateSearch()" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">clear</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row row-cols-1 row-cols-md-2 row-cols-sm-2 pb-3 g-3">

  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Log Message</span>
          </div>
          <input type="text" class="form-control" placeholder="Search Log Message" [(ngModel)]="integrationErrorMessageFilter.logMessageContains">
          <div class="input-group-append">
            <button mat-stroked-button (click)="clearLogMessageSearch()" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">clear</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Stack Trace</span>
          </div>
          <input type="text" class="form-control" placeholder="Search Stack Trace" [(ngModel)]="integrationErrorMessageFilter.stackTraceContains">
          <div class="input-group-append">
            <button mat-stroked-button (click)="clearStackTraceSearch()" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">clear</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="report-command-button-group">
  <button type="button" class="btn btn-outline-secondary" (click)="resetFilters()">
    Reset Filters
  </button>
  <button type="button" class="btn btn-outline-secondary" (click)="applyFilters()" >
    Apply Filters
  </button>
</div>

<div class="report-command-button-group" *ngIf="!gridViewIsLoading">
  <button type="button" class="btn btn-outline-secondary" (click)="pageBack()" [disabled]="integrationErrorMessageFilter.skip == 0">
    Previous Error Group
  </button>
  <div style="text-align: center;">
    <span>Current Error Group: </span>
    <br>
    <span>{{integrationErrorMessageFilter.skip}} - {{integrationErrorMessageFilter.skip + gridView.total}} out of {{totalIntegrationErrorMessages}}</span>
  </div>
  <button type="button" class="btn btn-outline-secondary" (click)="pageForward()" [disabled]="gridView.total != errorGroupSize">
    Next Error Group
  </button>
</div>

<button type="button" class="btn btn-outline-secondary" style="float: left" (click)="firstPage()" [disabled]="integrationErrorMessageFilter.skip == 0">
  First Error Group
</button>

<button type="button" class="btn btn-outline-secondary" style="float: right" (click)="lastPage()" [disabled]="gridView.total != errorGroupSize">
  Last Error Group
</button>

<br>
<br>

<kendo-grid #grid [data]="gridView" [pageSize]="state.take" [skip]="state.skip"
  [sortable]="{mode: 'single',allowUnsort: false}" [pageable]="true" [scrollable]="'scrollable'" [sort]="state.sort"
  [selectable]="true" [filter]="filter" [filterable]="true" (sortChange)="sortChange($event)"
  (pageChange)="pageChange($event)" (selectionChange)="expand($event)"
  (filterChange)="filterChange($event)">

  <kendo-grid-column field="integrationTypeId" title="Integration Type" [width]="60">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="integrationName" title="Integration Name" [width]="80">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="serverId" [width]="50" [headerClass]="{ 'text-center': true }">
    <ng-template kendoGridHeaderTemplate>
      <i class="bi bi-server" matTooltip="Server"></i>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="integrationStopped" title="Running" [width]="60" filter="boolean"
    [class]="{ 'text-center': true }">
    <ng-template #running kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="dataItem.integrationStopped == true && dataItem.errorStatusId !== 'Resolved'">
        <i class="bi bi-x-circle-fill" style="color: red; font-size: 28px; left: 10px"></i>
      </ng-container>
      <ng-container *ngIf="dataItem.integrationStopped == false&& dataItem.errorStatusId !== 'Resolved'">
        <i class="bi bi-check-circle-fill" style="color: green; font-size: 28px; left: 10px"></i>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="errorCount" title="Count" [width]="50">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="logMessage" title="Log Message" [width]="100" [filterable]="false">
    <ng-template #running kendoGridCellTemplate let-dataItem>
      {{dataItem.logMessage.substr(0, 100)}}
    </ng-template>
    <!-- <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template> -->
  </kendo-grid-column>

  <kendo-grid-column field="receivedDate" title="Date Received" [width]="95" [filterable]="false">
    <!-- filter="date" -->
    <ng-template #running kendoGridCellTemplate let-dataItem>
      <span [innerHTML]="formatDate(dataItem)"></span>
    </ng-template>

    <!-- <ng-template kendoGridFilterCellTemplate let-filter>
      <date-range-filter-cell
        class="date-range-filter"
        [filter]="filter"
        field="receivedDate"
      >
      </date-range-filter-cell>
    </ng-template> -->

  </kendo-grid-column>

  <kendo-grid-column field="errorStatusId" title="Current Status" [width]="115">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="inline">
        <mat-button-toggle-group (change)="updateError(dataItem, $event.value)" style="height: 40px;" [hideSingleSelectionIndicator]="true">
          <mat-button-toggle value="Unresolved" matTooltip="Unresolved"
            [checked]="'Unresolved' == dataItem.errorStatusId">
            <i class="bi bi-exclamation-circle-fill"></i>
          </mat-button-toggle>
          <mat-button-toggle value="InProgress" matTooltip="InProgress"
            [checked]="'InProgress' == dataItem.errorStatusId">
            <i class="bi bi-cone-striped"></i>
          </mat-button-toggle>
          <mat-button-toggle value="NotImportant" matTooltip="NotImportant"
            [checked]="'NotImportant' == dataItem.errorStatusId">
            <i class="bi bi-info-circle-fill"></i>
          </mat-button-toggle>
          <mat-button-toggle value="Resolved" matTooltip="Resolved"
            [checked]="'Resolved' == dataItem.errorStatusId">
            <i class="bi bi-check-circle"></i>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </ng-template>

    <ng-template kendoGridFilterCellTemplate>
      <mat-button-toggle-group (change)="statusFilterChange($event.value)" style="height: 40px;"
        [(ngModel)]="statusSelectedFilter" [hideSingleSelectionIndicator]="true">
        <mat-button-toggle value="Unresolved" matTooltip="Unresolved">
          <i class="bi bi-exclamation-circle-fill"></i>
        </mat-button-toggle>
        <mat-button-toggle value="InProgress" matTooltip="InProgress">
          <i class="bi bi-cone-striped"></i>
        </mat-button-toggle>
        <mat-button-toggle value="NotImportant" matTooltip="NotImportant">
          <i class="bi bi-info-circle-fill"></i>
        </mat-button-toggle>
        <mat-button-toggle value="Resolved" matTooltip="Resolved">
          <i class="bi bi-check-circle"></i>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button *ngIf="hasStatusFilter" class="k-button k-button-icon" title="Clear"
        (click)="clearStatusFilter()">
        <span class="k-icon k-i-filter-clear"></span>
      </button>

    </ng-template>

  </kendo-grid-column>

  <ng-template kendoGridDetailTemplate let-dataItem>
    <section>
      <p><strong>Error Message ID:</strong> {{dataItem.integrationErrorMessageId}}</p>
      <p><strong>Integration Name:</strong> {{dataItem.integrationName}}</p>
      <p><strong>Current Integration Server:</strong> {{dataItem.integrationServerId}}</p>
      <button kendoButton (click)="helpDesk(dataItem)">Send HelpDesk Ticket</button>
      <br />
      <p><strong>Server:</strong> {{dataItem.serverId}}</p>
      <p><strong>Data-Source Type:</strong> {{dataItem.dataSourceTypeId}}</p>
      <p><strong>Function:</strong> {{dataItem.functionWithin}}</p>
      <p><strong>Full Log Message:</strong> {{dataItem.logMessage}}</p>
      <p><strong>Error Stack Trace:</strong> {{dataItem.stackTrace}}</p>
      <button kendoButton (click)="navigateToClient(dataItem)">Go To Client</button>
    </section>
  </ng-template>

  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
  </ng-template>

  <ng-template kendoGridNoRecordsTemplate>
    <div class="empty_spacing_for_norecords">
      <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && gridView">No records found.</div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridView">Loading failed.</div>
    </div>
  </ng-template>

</kendo-grid>

<button type="button" class="k-button" (click)="saveData(excelexport)">
  Export To Excel
</button>
<kendo-excelexport [data]="errorsList" [collapsible]="true" fileName="Errors.xlsx" #excelexport>
  <kendo-excelexport-column field="dataSourceTypeId" title="Data Source Type"></kendo-excelexport-column>
  <kendo-excelexport-column field="serverId" title="Server Id"></kendo-excelexport-column>
  <kendo-excelexport-column field="integrationTypeId" title="Integration Type Id"></kendo-excelexport-column>
  <kendo-excelexport-column field="errorCount" title="Error Count"></kendo-excelexport-column>
  <kendo-excelexport-column field="receivedDate" title="Error Last Received"></kendo-excelexport-column>
  <kendo-excelexport-column field="logMessage" title="Log Message"></kendo-excelexport-column>
  <kendo-excelexport-column field="locked" title="Locked"></kendo-excelexport-column>
</kendo-excelexport>
