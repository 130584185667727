import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { ToastService } from '../../services/toast.service';
import { IdentifierService } from '../../services/identifier.service';
import { Toast, ToastType } from '../../models/toast';
import { Client } from '../../models/client';
import { Integration } from '../../models/integration';
import { IntegrationIdentifier } from '../../models/integration-identifier';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { runInThisContext } from 'vm';
import { ThisReceiver } from '@angular/compiler';
import { TypeConstantService } from 'src/app/services/type-constant.service';

@Component({
  selector: 'app-add-integration-identifier',
  templateUrl: './add-integration-identifier.component.html',
  styleUrls: ['./add-integration-identifier.component.scss'],
  providers: [ClientIntegrationService, IdentifierService]
})
export class AddIntegrationIdentifierComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() isInTab = false;
  @Input() inAddMode = false;
  @Output() finished = new EventEmitter<string>();
  @Output() outputIdentifier = new EventEmitter<IntegrationIdentifier>();
  @Input() populatedAdd: boolean = false;

  hasClient = false;
  @Input() client: Client = null;
  clients: Client[];
  filteredClients: Observable<Client[]>;
  clientFormControl = new UntypedFormControl();

  hasIntegration = false;
  @Input() integration: Integration = null;
  integrations: Integration[];
  filteredIntegrations: Observable<Integration[]>;
  integrationFormControl = new UntypedFormControl();

  hasDestination = false;
  destination: DestinationEndpoint;
  destinationList: DestinationEndpoint[] = [];
  filteredDestinations: Observable<DestinationEndpoint[]>;
  destinationFormControl = new UntypedFormControl();

  identifiersExist = false; // is selected and edited ids setup
  hasIdentifier = false; // has actual identifier
  @Input() selectedIdentifier: IntegrationIdentifier = null;
  identifier: IntegrationIdentifier;
  allIdentifiers: IntegrationIdentifier[];
  editingIdentifier: IntegrationIdentifier;

  private sub: any;
  dateNow: Date;
  checked: boolean = true;
  editing: boolean = false;
  canFastFetch: boolean = true;
  canDataSwap: boolean = true;

  savingIdentifier: boolean = false;

  pageLoading: boolean = true;

  constructor(
    private clientService: ClientIntegrationService,
    private identifierService: IdentifierService,
    private typeConstantService: TypeConstantService,
    private toastService: ToastService,
    private route: ActivatedRoute,
  ) {
    this.destinationList = [];
  }

  ngOnInit(): void {

    if (this.client == null) {
      this.getAllDestinations(false, false);
      this.sub = this.clientService.getAllClients()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data) => {
          this.clients = data;
          this.filteredClients = this.clientFormControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterClients(name) : this.clients.slice())
          );
          this.pageLoading = false;
        }, error => {
          this.pageLoading = false;
        });
      this.hasIntegration = false;
    }
    else {
      this.hasClient = true;

      if (this.integration == null) {
        this.getIntegrations();
        this.hasIntegration = false;
      }
      else {
        this.hasIntegration = true;

        if (this.selectedIdentifier == null) {
          this.hasIdentifier = false;
          this.identifiersExist = false;
          this.integrations = [];
          this.integrations.push(this.integration);
          //this.editing = true;
          
          if (this.inAddMode) {
            this.editing = false;
            this.getAllDestinations(false, true);
          }
          else {
            this.editing = true;
            this.getAllDestinations(false, false);
          }
        }
        else {
          //this.editingIdentifier = this.selectedIdentifier;
          this.copySelectedIdentifierToEditingIdentifier();
          this.hasIdentifier = true;
          this.identifiersExist = true;
          if (this.inAddMode) {
            this.editing = false;
            this.getAllDestinations(this.populatedAdd, !this.populatedAdd);
          }
          else {
            this.editing = true;
            this.getAllDestinations(true, false);
          }

        }

      }
    }
    let date: Date = new Date();
    this.dateNow = date;
  }

  copySelectedIdentifierToEditingIdentifier() {
    this.editingIdentifier = null;
    this.editingIdentifier = new IntegrationIdentifier();
    this.editingIdentifier.integrationId = this.selectedIdentifier.integrationId;
    this.editingIdentifier.destinationId = this.selectedIdentifier.destinationId;
    this.editingIdentifier.destinationName = this.selectedIdentifier.destinationName;
    this.editingIdentifier.identifierIdValue = this.selectedIdentifier.identifierIdValue;
    this.editingIdentifier.identifierNameValue = this.selectedIdentifier.identifierNameValue;
    this.editingIdentifier.identifierKeyValue = this.selectedIdentifier.identifierKeyValue;
    this.editingIdentifier.createdDate = this.selectedIdentifier.createdDate;
    this.editingIdentifier.updatedDate = this.selectedIdentifier.updatedDate;
    this.editingIdentifier.canFastFetch = this.selectedIdentifier.canFastFetch;
    this.editingIdentifier.canDataSwap = this.selectedIdentifier.canDataSwap;
    this.editingIdentifier.comments = this.selectedIdentifier.comments ?? '';
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }



  selectClient(c: Client): void {
    this.pageLoading = true;
    this.client = c;
    this.hasClient = true;
    this.getIntegrations();
  }

  editClient() {
    this.client = null;
    this.integration = null;
    this.hasClient = false;
    this.hasIntegration = false;
    this.hasIdentifier = false;
    this.selectedIdentifier = null;
    this.editingIdentifier = null;
    this.hasDestination = false;
    this.destination = null;
    this.clientFormControl.setValue('');
  }

  getClients(): void {
    this.clientService.getAllClients().subscribe((data) => {
      this.clients = data;
      this.filteredClients = this.clientFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterClients(name) : this.clients.slice())
      );
    });
  }



  filterDestinationsForAdd() {
    this.identifierService.getIntegrationIdentifiers(this.integration.integrationId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        if (data != null) {
          if (data.length > 0) {
            this.allIdentifiers = data;
            var destIds = this.allIdentifiers.map(d => d.destinationId);
            if (destIds.length > 0) {
              this.destinationList = this.destinationList.filter(d => destIds.includes(d.destinationEndpointId) == false);
              this.filteredDestinations = this.destinationFormControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filterDestinations(name) : this.destinationList.slice())
              );
            }
          }
          else {
            this.filteredDestinations = this.destinationFormControl.valueChanges.pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.name),
              map(name => name ? this._filterDestinations(name) : this.destinationList.slice())
            );
          }
        }
      });
  }

  getAllDestinations(hasDestflag: boolean, filterForAddFlag: boolean): void {
    this.typeConstantService.getAllDestinationEndpoints()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.destinationList = data;

        if (hasDestflag) {
          this.destination = this.destinationList.filter(d => d.destinationEndpointId == this.selectedIdentifier.destinationId)[0];
          this.hasDestination = true;
        }
        if (filterForAddFlag) {
          this.filterDestinationsForAdd();
        }
        else {
          this.filteredDestinations = this.destinationFormControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterDestinations(name) : this.destinationList.slice())
          );
        }

        this.pageLoading = false;

      }, error => {
        this.pageLoading = false;
      });
  }

  selectIntegration(i: Integration): void {
    this.pageLoading = true;
    this.hasIntegration = true;
    this.integration = i;
    this.getAllDestinations(false, false);
  }

  editIntegration() {
    this.integration = null;
    this.hasIntegration = false;
    this.hasIdentifier = false;
    this.selectedIdentifier = null;
    this.editingIdentifier = null;
    this.hasDestination = false;
    this.destination = null;
    this.integrationFormControl.setValue('');
  }

  getIntegrations(): void {
    if (this.client) {
      this.clientService.getIntegrationsByClient(this.client.name)
        .subscribe((data) => {
          this.integrations = data;
          this.filteredIntegrations = this.integrationFormControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterIntegrations(name) : this.integrations.slice())
          );
          this.pageLoading = false;
        }, error => {
          this.pageLoading = false;
        });
    }
    else {
      this.toastService.toastCreate("Select Client.", "Warning");
    }
  }

  editDestination() {
    this.destination = null;
    this.hasDestination = false;
    this.destinationFormControl.setValue('');
  }


  selectDestination(d: DestinationEndpoint): void {
    this.destination = d;
    this.hasDestination = true;
    this.identifierService
      .getIntegrationIdentifierByIntegrationAndDestination(this.integration.integrationId, d.endpointName)
      .subscribe(
        (i) => {
          if (i != null) {
            //this.editingIdentifier = i;
            this.editingIdentifier = new IntegrationIdentifier();
            this.editingIdentifier.integrationId = i.integrationId;
            this.editingIdentifier.destinationId = i.destinationId;
            this.editingIdentifier.destinationName = i.destinationName;
            this.editingIdentifier.identifierIdValue = i.identifierIdValue;
            this.editingIdentifier.identifierNameValue = i.identifierNameValue;
            this.editingIdentifier.identifierKeyValue = i.identifierKeyValue;
            this.editingIdentifier.createdDate = i.createdDate;
            this.editingIdentifier.updatedDate = i.updatedDate;
            this.editingIdentifier.canFastFetch = i.canFastFetch;
            this.editingIdentifier.canDataSwap = i.canDataSwap;
            this.editingIdentifier.comments = i.comments ?? '';
            // this.selectedIdentifier = i;
            this.selectedIdentifier = new IntegrationIdentifier();
            this.selectedIdentifier.integrationId = i.integrationId;
            this.selectedIdentifier.destinationId = i.destinationId;
            this.selectedIdentifier.destinationName = i.destinationName;
            this.selectedIdentifier.identifierIdValue = i.identifierIdValue;
            this.selectedIdentifier.identifierNameValue = i.identifierNameValue;
            this.selectedIdentifier.identifierKeyValue = i.identifierKeyValue;
            this.selectedIdentifier.createdDate = i.createdDate;
            this.selectedIdentifier.updatedDate = i.updatedDate;
            this.selectedIdentifier.canFastFetch = i.canFastFetch;
            this.selectedIdentifier.canDataSwap = i.canDataSwap;
            this.selectedIdentifier.comments = i.comments ?? '';
            this.editing = false;
            this.inAddMode = false;
            this.identifiersExist = true;
            this.hasIdentifier = true;
          }
          else {
            this.selectedIdentifier = new IntegrationIdentifier();
            this.selectedIdentifier.destinationId = this.destination.destinationEndpointId;
            this.selectedIdentifier.destinationName = this.destination.endpointName;
            this.selectedIdentifier.integrationId = this.integration.integrationId;
            this.selectedIdentifier.createdDate = this.dateNow;
            this.selectedIdentifier.updatedDate = this.dateNow;
            this.editingIdentifier = new IntegrationIdentifier();
            this.editingIdentifier.destinationId = this.destination.destinationEndpointId;
            this.editingIdentifier.destinationName = this.destination.endpointName;
            this.editingIdentifier.integrationId = this.integration.integrationId;
            this.editingIdentifier.createdDate = this.dateNow;
            this.editingIdentifier.updatedDate = this.dateNow;
            this.inAddMode = true;
            this.editing = false;
            this.hasIdentifier = false;
            this.identifiersExist = true;
          }

        },
        error => {
          this.hasIdentifier = false;
          this.identifiersExist = false;
        });
  }

  getAllIdentifiers() {
    this.identifierService.getIntegrationIdentifiers(this.integration.integrationId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.allIdentifiers = data;
      });
  }

  insertIntegrationIdentifier(): void {
    this.savingIdentifier = true;
    this.identifierService.addIntegrationIdentifier(this.editingIdentifier).subscribe(
    (value) => {
      if (this.isInTab) {
        if (this.populatedAdd) {
          this.finished.emit('generated');
        }
        else {
          this.toastService.toastCreate("Identifier " + this.editingIdentifier.destinationName + "Added.", "Success");
          this.finished.emit('added');
        }
        this.outputIdentifier.emit(this.editingIdentifier);
      }
      else {
        this.toastService.toastCreate("Identifier " + this.editingIdentifier.destinationName + "Added.", "Success");
        this.inAddMode = false;
        this.editing = false;
        this.selectDestination(this.destination);
      }
      this.savingIdentifier = false;
    },
    error => {
      if (this.isInTab) {
        this.finished.emit('error');
      }
      this.savingIdentifier = false;
    });
  }

  updateIntegrationIdentifier(): void {
    this.identifierService.updateIntegrationIdentifier(this.editingIdentifier).subscribe(
    (value) => {
      this.toastService.toastCreate("Identifier " + this.editingIdentifier.destinationName + "Updated.", "Success");
      if (this.isInTab) {
        this.finished.emit('updated');
        this.outputIdentifier.emit(this.editingIdentifier);
      }
      else {
        this.inAddMode = false;
        this.editing = false;
        this.selectDestination(this.destination);
      }
    },
    error => {
      if (this.isInTab) {
        this.finished.emit('error');
      }
    });
  }

  /* Checkbox Function */
  changeCanFastFetch(x: boolean) {
    this.canFastFetch = !this.canFastFetch;
    this.editingIdentifier.canFastFetch = !this.editingIdentifier.canFastFetch;
  }
  changeCanDataSwap(x: boolean) {
    this.canDataSwap = !this.canDataSwap;
    this.editingIdentifier.canDataSwap = !this.editingIdentifier.canDataSwap;
  }


  /* Angular Material Functions */

  private _filterClients(value: string): Client[] {
    const filterValue = value.toLowerCase();
    return this.clients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterIntegrations(value: string): Integration[] {
    const filterValue = value.toLowerCase();
    return this.integrations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterDestinations(value: string): DestinationEndpoint[] {
    const filterValue = value.toLowerCase();
    return this.destinationList.filter(option => option.endpointName.toLowerCase().includes(filterValue));
  }

  displayFnClient(x: Client): string {
    return x && x.name ? x.name : '';
  }

  displayFnIntegration(x: Integration): string {
    return x && x.name ? x.name : '';
  }

  displayFnDestination(x: DestinationEndpoint): string {
    return x && x.endpointName ? x.endpointName : '';
  }

  toggleEditIdentifier(): void {
    this.editing = true;
  }

  toggleCancelEditIdentifier(): void {
    if (this.isInTab) {
      this.finished.emit('cancelled');
    }
    this.editing = false;
    this.copySelectedIdentifierToEditingIdentifier();
  }

}
