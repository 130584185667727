import { HttpParams } from "@angular/common/http";

export abstract class LTHttpUtility{

    public static formatParams(paramObject: any): HttpParams{
        let rc = new HttpParams();
        if(paramObject != undefined){
            var props = Object.getOwnPropertyNames(paramObject);
            props.forEach(p => {
                if(paramObject[p] != undefined){
                    if (paramObject[p] instanceof Date) {
                        let d = new Date(paramObject[p]);
                        rc = rc.append(p, d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate());
                    }
                    else {
                        rc = rc.append(p, paramObject[p]);
                    }
                }
            });
        }

        return rc;
    }
}