<ng-container *ngIf="currReport.hasFilterCards">
  <div class="report-filter-cards">
    <div class="row row-cols-1 row-cols-md-3 row-cols-sm-2 g-3 pb-1">

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">DataSource Type:</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="dataSourceTypeItems"
                                [textField]="'description'"
                                [valueField]="'dataSourceTypeId'"
                                [placeholder]="'Select Data Source Type...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="dataSourceItemsSelected"></kendo-multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">Destination Endpoints</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="destinationEndpointItems"
                                [textField]="'endpointName'"
                                [valueField]="'destinationEndpointId'"
                                [placeholder]="'Select Destination Endpoint...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="destinationEndpointItemsSelected"></kendo-multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">Server:</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="serverItems"
                                [textField]="'description'"
                                [valueField]="'serverId'"
                                [placeholder]="'Select Server...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="serverItemsSelected"></kendo-multiselect>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="report-command-button-group">
    <button type="button" class="btn btn-outline-secondary" (click)="resetFilters($event)">
      Reset Filters
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="applyFilters($event)" >
      Apply Filters
    </button>
  </div>
</ng-container>

<ng-container *ngIf="currReport.tableVersion === 0">
  <ng-container *ngIf="haveData">
    <app-view-generic-grid #genericGrid
    [data]="filteredData"
    [columns]="reportColumns"
    [exportFileName]="currReport.exportFileName"
    [isResizeable]="true"
    [showExportToolbar]="true"
    [hasActionGoToColumn]="true"
    [actionGoToUsePrefix]="true"
    [actionGoToField]="'clientName'"
    [actionGoToPrefix]="'/editConfig'"
    [actionGoToUseAddlField]="true"
    [actionGoToFieldAddl]="['integrationName']"
    [actionGoToUseSuffix]="true"
    [actionGoToSuffix]="'0'"
    [loading]="gettingData"
    >
  </app-view-generic-grid>

  </ng-container>
  <ng-container *ngIf="!haveData && gettingData">
    <div class="empty_spacing_for_norecords">
      <div class="k-i-loading"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="!haveData && !gettingData">
    <div class="empty_spacing_for_norecords">
      <div class="no-records-message">Loading failed.</div>
    </div>
  </ng-container>
</ng-container>

