import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';
import { filter, take, takeUntil } from "rxjs/operators";
import { Client } from "../../models/client";
import { Integration } from "../../models/integration";
import { IntegrationJavaScript } from "../../models/integration-javascript";
import { IntegrationMetrics } from "../../models/integration-metrics";
import { MonacoPageComponent } from "../monaco-editor/monaco-editor.component";
import { ClientIntegrationService } from "../../services/client-integration.service";
import { ToastService } from '../../services/toast.service';
import { Toast, ToastType } from '../../models/toast';
import { Observable, Subject } from "rxjs";
import { MonacoDiffPageComponent } from "../monaco-diff-editor/monaco-diff-editor.component";
import { User } from "../../models/user";
import { DestinationEndpoint } from "src/app/models/destination-endpoint";

@Component({
  selector: 'app-integration-revision',
  templateUrl: './integration-revision.component.html',
  styleUrls: ['./integration-revision.component.scss'],
  providers: [ClientIntegrationService]
})
export class IntegrationRevisionComponent implements OnInit, OnDestroy {

  @ViewChild("monaco") public monaco: MonacoPageComponent;
  @ViewChild("monacoHistory") public monacoHistory: MonacoDiffPageComponent;
  @ViewChild("monacoJSHistory") public monacoJSHistory: MonacoDiffPageComponent;

  @Input() integration: Integration;
  @Input() client: Client;
  @Input() destinationEndpoints: DestinationEndpoint[];
  @Output() dataChanged = new EventEmitter<boolean>();

  selectedIntegrationHistory: Integration;
  integrationHistory: Integration[];
  integrationFormControl = new UntypedFormControl('');
  integrationHistoryLoading: boolean = true;
  javaScriptHistoryLoading: boolean = true;

  selectedEndpoint: DestinationEndpoint;
  selectedJavascript: IntegrationJavaScript;
  selectedJavascriptCompare: IntegrationJavaScript;
  javascriptHistoryList: IntegrationJavaScript[];
  endpointFormControl = new UntypedFormControl('');

  historyData = '';
  historyRawData = '';
  rawData = '';

  tmpHistoryData = '';
  tmpHistoryRawData = '';
  tmpRawData = '';

  monacoLangHtml = 'html';
  monacoLangJs = 'javascript';
  historyChecked = true;

  historyChanged = false;
  jsHistoryChanged = false;
  checked = true;
  
  isInTab: boolean;

  currentSideTitle = '';
  historySideTitle = '';

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private clientService: ClientIntegrationService,
    private toastService: ToastService,
    private user: User
  ) { }

  ngAfterViewInit(): void {
    // setTimeout(_ => this.initializeMonacoComponent());
  }

  ngOnInit(): void {
    this.integrationHistoryLoading = true;
    this.javaScriptHistoryLoading = true;
    this.clearHistory();
    this.isInTab = true;
    this.clientService
    .getIntegrationHistoryByClient(
      this.client.name,
      this.integration.name
    )
    .subscribe((i) => {
      this.integrationHistory = i;
      this.integrationHistory.sort((a,b) => {
        return (new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime());
      });

      this.integrationHistoryLoading = false;      
    });

    this.clientService
        .getIntegrationJavaScriptHistory(
          this.integration.integrationId
        )
        .subscribe((i) => {
          this.javascriptHistoryList = i;
          this.javascriptHistoryList.sort((a,b) => {
            return (new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
          });
          this.javaScriptHistoryLoading = false;
        });
    
    var newEndpoints = [];
    for (var js of this.integration.javaScript) {
      for (var d of this.destinationEndpoints) {
        if (d.destinationEndpointId == js.destinationEndpointId) {
          newEndpoints.push(d);
        }
      }
    }
    this.destinationEndpoints = newEndpoints;
    this.setDefaultEndpoint();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  selectIntegrationHistory(i: Integration) {
    this.selectedIntegrationHistory = i;
    this.historyData = this.selectedIntegrationHistory.xmlConfig;
    this.historyRawData = this.integration.xmlConfig;
    this.currentSideTitle = this.integration.updatedDate.toString();
    this.historySideTitle = this.selectedIntegrationHistory.updatedDate.toString();
  }

  selectEndpointType(d: DestinationEndpoint) {
    this.selectedEndpoint = d;

    for (var js of this.integration.javaScript) {
      if (this.selectedEndpoint.destinationEndpointId == js.destinationEndpointId) {
        this.selectedJavascript = js;
        this.historyRawData = this.selectedJavascript.javaScript;
        this.currentSideTitle = this.selectedJavascript.integrationTypeId
        + ' | ' + this.selectedJavascript.createDate;
      }
    }
  }

  selectJsIntegrationCompare(i: IntegrationJavaScript) {
    this.selectedJavascriptCompare = i;
    this.historyData = this.selectedJavascriptCompare.javaScript;
    this.historySideTitle = this.selectedJavascriptCompare.integrationTypeId
        + ' | ' + this.selectedJavascriptCompare.createDate;
  }

  filterJavascriptHistoryList(d: DestinationEndpoint): IntegrationJavaScript[] {
    var filteredList = [];
    for (var js of this.javascriptHistoryList) {
      if (js.destinationEndpointId == d.destinationEndpointId) {
        filteredList.push(js);
      }
    }

    return filteredList;
  }

  //If there is only 1 JS endpoint, select it
  setDefaultEndpoint() {
    if (this.destinationEndpoints.length == 1) {
      this.selectedEndpoint = this.destinationEndpoints[0];
      this.endpointFormControl.setValue(this.selectedEndpoint);
      this.selectEndpointType(this.selectedEndpoint);
    }
  }

  public clearHistory(): void {
    this.historyData = '';
    this.historyRawData = '';
    this.selectedIntegrationHistory = null;
    this.selectedJavascript = null;
    this.selectedJavascriptCompare = null;
    this.selectedEndpoint = null;
    this.setDefaultEndpoint();
  }

  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }
  
  updateIntegrationFromHistory(field: string): void {
    var updateMsg = '';
    if (field == 'xml') {
      this.integration.xmlConfig = this.selectedIntegrationHistory.xmlConfig;
      updateMsg = this.selectedIntegrationHistory.updatedDate.toString();

      this.clientService.updateIntegration(this.integration)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
          const msg = "Successfully reverted " + this.integration.name + " integration XML to " + updateMsg;

          this.toastService.toastCreate(msg, "Success", {keepAfterRouteChange: true});
          this.dataChanged.emit(true);
        });
    }
    else if (field == 'js') {
      for (var js of this.integration.javaScript) {
        if (this.selectedJavascriptCompare.integrationTypeId == js.integrationTypeId) {
          js.javaScript = this.selectedJavascriptCompare.javaScript;
          updateMsg = this.selectedJavascriptCompare.integrationTypeId
            + ' ' + this.selectedJavascriptCompare.createDate.toString();

          this.clientService.updateJavaScript(js)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((value) => {
              const msg = "Successfully reverted " + this.integration.name + " integration JavaScript to " + updateMsg;
    
              this.toastService.toastCreate(msg, "Success", {keepAfterRouteChange: true});
              this.dataChanged.emit(true);
            });
        }
      }
    }
  }
}
