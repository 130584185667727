import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user';

@Component({
  selector: 'userData',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  enableImpersonation: boolean = environment.enableImpersonation;

  constructor(private user: User, private router: Router, private appComponent: AppComponent) { }

  fullName: string = this.user.fullName;
  email: string = this.user.email;
  currentRoles: string[] = this.user.getRoles();
  selectedRoles: string[] = [];
  roleList: string[] = ['LOSTalker.BaseMapping', 'LOSTalker.Client.InvoicingReports', 'LOSTalker.Client.LOSData', 'LOSTalker.Client', 'LOSTalker.Integration.CompenSafe.FastFetch', 
    'LOSTalker.Integration.LimeGear.FastFetch', 'LOSTalker.Integration.CompenSafe.DataSwap', 'LOSTalker.Integration.LimeGear.DataSwap', 'LOSTalker.Integration.LimeGear',
    'LOSTalker.Integration.CompenSafe', 'LOSTalker.Integration', 'LOSTalker.DevOps', 'LOSTalker.Admin']

  checkAccess(aL: string[]): boolean {
    return this.user.checkAccess(aL);
  }

  updateRole(r: string) {
    if (this.selectedRoles.includes(r)) {
      this.selectedRoles = this.selectedRoles.filter(role => role !== r);
    }
    else {
      this.selectedRoles.push(r);
    }
  }

  impersonate() {
    this.user.setImpersonation(this.selectedRoles);
    this.selectedRoles = [];
    this.appComponent.impersonationActive = true;
    this.router.navigate(['/home']);
  }
}
