import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { IntegrationRunTime } from '../models/integration-runtime';
import { DataResponse } from '../models/data-response';

@Injectable()
export class IntegrationRunTimeService {
    private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
    private actionUrl: string = '';
    private Url: string = '';
    private headers = new HttpHeaders();

    private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
      console.log(error);
      ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
        keepAfterRouteChange: true
      });

      if (environment.enableDebuggingTools) {
        console.error("An HTTP transport error occured: " + error.message);
      }

      return throwError(error);
    }

    constructor(private http: HttpClient, private toastService: ToastService) { }

    // GET
    getAllIntegrationRunTimes(): Observable<IntegrationRunTime[]> {
      this.actionUrl = "IntegrationRunTimes/";
      this.Url = this.baseUrl.concat(this.actionUrl);
      return this.http
        .get<IntegrationRunTime[]>(this.Url)
        .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // GET
    // getRunTime(integrationId: string, typeId: string): Observable<RunTime> {
    //     this.actionUrl = "RunTime/GetRunTime/";
    //     this.Url = this.baseUrl.concat(this.actionUrl);
    //     return this.http.get<RunTime>(this.Url)
    //     .pipe(catchError(this.handleError));
    // }

    // GET
    getAllIntegrationRunTimesByIntegration(integrationId: string): Observable<IntegrationRunTime[]> {
        this.actionUrl = "IntegrationRunTimes/";
        this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
        return this.http.get<IntegrationRunTime[]>(this.Url)
        .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // POST
    addIntegrationRunTime(integrationRunTime: IntegrationRunTime): Observable<IntegrationRunTime> {
        this.actionUrl = "IntegrationRunTime/";
        this.Url = this.baseUrl.concat(this.actionUrl);
        this.headers.append('Content-Type', 'application/json');
        return this.http
            .post<IntegrationRunTime>(this.Url, integrationRunTime, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // PUT
    updateIntegrationRunTime(integrationRunTime: IntegrationRunTime): Observable<IntegrationRunTime> {
        this.actionUrl = "IntegrationRunTime/"
        this.Url = this.baseUrl.concat(this.actionUrl);
        this.headers.append('Content-Type', 'application/json');
        return this.http
            .put<IntegrationRunTime>(this.Url, integrationRunTime, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // DELETE
    deleteIntegrationRunTime(integrationRunTimeId: string): Observable<IntegrationRunTime> {
        this.actionUrl = "IntegrationRunTime/"
        this.Url = this.baseUrl.concat(this.actionUrl, integrationRunTimeId);
        // console.log(this.Url);
        return this.http
            .delete<IntegrationRunTime>(this.Url)
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    // PUT
    updateBulkIntegrationRunTimes(integrationIds: string[], i: number[], d: number[], l?: Date, n?: Date): Observable<any> {
      const data = new DataResponse(integrationIds, false, "", l, n, d, i);
      this.actionUrl = "IntegrationRunTimes/update"
      this.Url = this.baseUrl.concat(this.actionUrl);
      this.headers.append('Content-Type', 'application/json');

      return this.http
          .put<any>(this.Url, data, { headers: this.headers })
          .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
