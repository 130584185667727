import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import { environment } from 'src/environments/environment';
import { LOSToken } from '../models/los-token';
import { LOSTokenMapping } from '../models/los-token-mapping';
import { LOSTokenData } from '../models/los-token-data';
import { ToastService } from './toast.service';

@Injectable()
export class LosTokenService {

  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- LOS TOKEN ------------------------------------------//

  getLOSTokens(): Observable<LOSToken[]> {
    this.actionUrl = "LOSTokens/"
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<LOSToken[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getLOSTokenMappingDataForTokenIdWithoutTokenValue(losTokenId: string): Observable<LOSTokenData[]> {
    this.actionUrl = "LOSTokenData/"
    this.Url = this.baseUrl.concat(this.actionUrl, losTokenId);

    return this.http
      .get<LOSTokenData[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteLOSTokenMapping(losTokenMappingId: number): Observable<any> {
    this.actionUrl = "LOSTokenMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl, losTokenMappingId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete(this.Url, { headers: this.headers } )
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteLOSToken(item: LOSToken): Observable<any> {
    this.actionUrl = "LOSToken/";
    this.Url = this.baseUrl.concat(this.actionUrl, item.losTokenId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete(this.Url, { headers: this.headers } )
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertLOSTokenMapping(item: LOSToken): Observable<any> {
    this.actionUrl = "LOSTokenMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .post<LOSToken>(this.Url, item)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
