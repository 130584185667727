import { Component, OnInit, OnDestroy, ViewChild, NgZone, AfterViewInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State, SortDescriptor, orderBy, filterBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, RowArgs, CellClickEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Report } from '../../../models/report';
import { ReportingUtils } from 'src/app/utilities/reporting-utilities';
import { Server } from '../../../models/server';
import { DestinationEndpoint } from '../../../models/destination-endpoint';
import { ImportType } from '../../../models/import-type';
import { DataSourceType } from '../../../models/data-source-type';
import { IntegrationRunTime } from '../../../models/integration-runtime';
import { Integration } from '../../../models/integration';
import { ClientIntegrationService } from '../../../services/client-integration.service';
import { ToastService } from '../../../services/toast.service';
import { ReportService } from '../../../services/report.service';
import { ServerService } from 'src/app/services/server.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { LOSTalkerTitleService } from '../../../services/lostalker-title-service.service';
import { ColumnSettings } from 'src/app/models/column-settings';
import { BulkIntegrationActionType } from 'src/app/models/bulk-integration-action-type';
import { IntegrationRunTimeService } from 'src/app/services/integration-runtime.service';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-integration-bulk-report',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './integration-bulk-report.component.html',
  styleUrls: ['./integration-bulk-report.component.scss'],

})
export class IntegrationBulkReportComponent implements OnInit, OnDestroy, AfterViewInit {
  pageLoading: boolean = true;
  public currReport: Report;
  @ViewChild('reportGrid') public reportGrid: GridComponent;
  @ViewChild('serverInfoDialog', { static: true }) serverInfoDialog: TemplateRef<any>;

  componentDestroyed$: Subject<boolean> = new Subject();
  reportingUtils: ReportingUtils;

  public bulkActionType: BulkIntegrationActionType = BulkIntegrationActionType.None;
  public bulkIntegActionType = BulkIntegrationActionType;
  bulkActionSelected: boolean = false;

  serverItems: Server[] = [];
  dataSourceTypeItems: DataSourceType[] = [];
  destinationEndpointItems: DestinationEndpoint[] = [];
  importTypeItems: ImportType[] = [];
  integrationRunTimeItems: IntegrationRunTime[] = [];

  serverItemsSelected: Server[] = [];
  dataSourceItemsSelected: DataSourceType[] = [];
  destinationEndpointItemsSelected: DestinationEndpoint[] = [];
  importTypeItemsSelected: ImportType[] = [];

  // Lock Unlock Report Specific
  public switchLocked: boolean = true;

  // Move Servers Report Specific
  selectedServer: Server = null;
  serverInfoReady: boolean = false;

  baseData: Integration[] = [];
  filteredData: Integration[] = [];
  gridData: Integration[] = [];
  public selectedIds: string[] = []; // selected integrations in grid

  exportFileName: string = "IntegractionsReport.xlsx";

  // grid variables
  public gridViewIsLoading: boolean = true;
  public gettingServerItems: boolean = true;

  public currentFiltersFromGrid: CompositeFilterDescriptor = null; // holds current grid column filter
  public tmpFilter: CompositeFilterDescriptor;

  type: 'numeric' | 'input' = 'input';
  public pageSizes: number[] = [5, 10, 25, 50, 100];
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  cols: ColumnSettings[] = [];
  serverInfoCols: ColumnSettings[] = [];
  integrationRunTimeCols: ColumnSettings[] = [];

  // Grid Row Selected Boolean Check
  public isRowSelected = (e: RowArgs) =>
    this.selectedIds.indexOf(e.dataItem.integrationId) >= 0;

  // RUNTIME REPORT VARS
  enableLastRunTime: boolean = true;
  enableNextRunTime: boolean = true;

  public lastDateFormControl = new FormControl<Date|null>(null, {nonNullable: false, validators: [Validators.required]});
  public nextDateFormControl = new FormControl<Date|null>(null, {nonNullable: false, validators: [Validators.required]});
  public importTypesControl = new FormControl<ImportType[]>([], {nonNullable: true, validators: [Validators.required]});
  public destinationsControl = new FormControl<DestinationEndpoint[]>([], {nonNullable: true, validators: [Validators.required]});

  public runTimeUpdateFormGroup = new FormGroup({
    lastDate: this.lastDateFormControl,
    nextDate: this.nextDateFormControl,
    destinationEndpoints: this.destinationsControl,
    importTypes: this.importTypesControl,
  });

  constructor(
    protected clientService: ClientIntegrationService,
    protected serverService: ServerService,
    protected reportService: ReportService,
    protected iRunTimeService: IntegrationRunTimeService,
    private titleService: LOSTalkerTitleService,
    protected toastService: ToastService,
    protected typeConstantService: TypeConstantService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private dialog: MatDialog
  ) {
    this.pageLoading = true;
    this.reportingUtils = new ReportingUtils();
    this.selectedData = this.selectedData.bind(this);
  }

  ngOnInit(): void {
    this.integrationRunTimeCols = this.reportingUtils.getDefaultColumnsByString("IntegrationRunTime");
    this.serverInfoCols = this.reportingUtils.getDefaultColumnsByString("ServerInfo");
    this.cols = this.reportingUtils.getDefaultColumnsByString("IntegrationBulk");

    this.state.sort = [{ field: "integrationId", dir: 'asc' }];
    this.route.params
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((params) => {

      if (params["reportId"] == null) {
        // get report by url
        this.reportService.getReports()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data) => {
          if (data != null) {
            var bulkReport = data.filter(x => x.routerUrl == '/' + this.route.snapshot.url[0].path + '/');
            if (bulkReport != null) {
              this.currReport = new Report(bulkReport[0]);
              this.exportFileName = this.currReport.exportFileName;
              this.titleService.setUpTitle('Report - ' + this.currReport.title);
            }
            else {
              this.toastService.toastCreate("Report failed to return", "Danger");
              this.pageLoading = false;
            }
          }
          else {
            this.toastService.toastCreate("Reports failed to return", "Danger");
            this.pageLoading = false;
          }
        })
      }
      else {
        // get report by id
        this.reportService.getReportById(params["reportId"])
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data) => {
          if (data != null) {
            this.currReport = new Report(data);
            this.exportFileName = this.currReport.exportFileName;
            this.titleService.setUpTitle('Report - ' + data.title);
          }
          else {
            this.toastService.toastCreate("Report failed to return", "Danger");
            this.pageLoading = false;
          }
        },
        (error) => {
          this.toastService.toastCreate("Report failed to return", "Danger");
          this.pageLoading = false;
        });
      }
    });
    this.getFullServerInformation();
    this.getReportConstants();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public ngAfterViewInit(): void {
    if (this.cols.filter(x => x.hidden == false).length > 10) {
      //this.fitColumns();
    }
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      get data
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  // refresh grid for non Integration RunTime Bulk Action Types
  refreshSelection(): void {
    this.baseData = [];
    this.filteredData = [];
    this.gridData = [];
    this.selectedIds = [];
    this.gridViewIsLoading = true;
    this.getIntegrations();
  }

  // refresh grid for Integration RunTime Bulk Action Type
  refreshSelectionForRunTimes(): void {
    this.iRunTimeService.getAllIntegrationRunTimes()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (data) => {
        this.integrationRunTimeItems = [];
        this.integrationRunTimeItems = data;

        if (this.baseData.length > 0) {
          if (this.integrationRunTimeItems.length > 0) {
            this.baseData.forEach(i => {
              i.integrationRunTimes = [];
              i.integrationRunTimes = this.integrationRunTimeItems.filter(x => x.integrationId == i.integrationId);
            });
          }
        }
      }
    )
  }

  // get integrations
  getIntegrations(): void {
    this.gridViewIsLoading = true;

    if (this.currReport.dataSproc !== "" && this.currReport.dataSproc !== undefined) {
      this.reportService.getIntegrationTypeQueryData(
        this.currReport.reportId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (data) => {
            this.baseData = data;

            if (this.integrationRunTimeItems.length > 0 && this.baseData.length > 0) {
              this.baseData.forEach(i => {
                i.integrationRunTimes = this.integrationRunTimeItems.filter(x => x.integrationId == i.integrationId);
              });
            }

            this.updateFilteredData();
          },
          (error) => {
            this.gridViewIsLoading = false;
          });
    }
  }

  getReportConstants(): void {
    this.typeConstantService.getAllReportViewConstants()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          if (data != null) {
            this.dataSourceTypeItems = data.dataSourceTypes;
            this.destinationEndpointItems = data.destinationEndpoints;
            this.importTypeItems = data.importTypes;
            this.integrationRunTimeItems = data.integrationRunTimes;

            if (this.baseData.length > 0) {
              if (this.integrationRunTimeItems.length > 0) {
                this.baseData.forEach(i => {
                  i.integrationRunTimes = this.integrationRunTimeItems.filter(x => x.integrationId == i.integrationId);
                });
              }
              this.updateFilteredData();
            }
          }
        },
        (error) => {
          // console.log(error);
          this.toastService.toastCreate("Report Constants failed to return", "Danger");
        }
      );
  }

  updateFilteredData(filterFromGrid: CompositeFilterDescriptor = null): void {
    this.filteredData = [];

    if (this.bulkActionSelected) {
      // bulk action selected so good to process
      this.filteredData = this.baseData;

      if (this.bulkActionType == BulkIntegrationActionType.LockUnlock) {
        this.filteredData = this.filteredData.filter(i => i.locked == !this.switchLocked);
      }

      if (filterFromGrid != null && this.currentFiltersFromGrid != null) {
        this.currentFiltersFromGrid = filterFromGrid;
        this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
      }
      else if (filterFromGrid != null && this.currentFiltersFromGrid == null) {
        this.currentFiltersFromGrid = filterFromGrid;
        this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
      }
      else if (filterFromGrid == null && this.currentFiltersFromGrid != null) {
        this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
      }

      // filter from cards
      if (this.currReport.hasFilterCards) {

        // RunTime V2
        if (this.integrationRunTimeItems.length > 0) {
          // Destination Endpoint
          if (this.destinationEndpointItemsSelected.length > 0
            && this.destinationEndpointItemsSelected.length != this.destinationEndpointItems.length) {
              var dests = this.destinationEndpointItemsSelected.map(i => i.destinationEndpointId);
              this.filteredData = this.filteredData.filter(i => i.integrationRunTimes != null);
              this.filteredData = this.filteredData.filter(x => x.integrationRunTimes?.filter(y => dests.some(z => y.destinationEndpointId == z)).length != 0);
          }

          // Import Type
          if (this.importTypeItemsSelected.length > 0
            && this.importTypeItemsSelected.length != this.importTypeItems.length) {
              var imTypes = this.importTypeItemsSelected.map(i => i.importTypeId);
              this.filteredData = this.filteredData.filter(i => i.integrationRunTimes != null);
              this.filteredData = this.filteredData.filter(x => x.integrationRunTimes?.filter(y => imTypes.some(z => y.importTypeId == z)).length != 0);
          }
        }

        // Server
        if (this.serverItemsSelected.length > 0) {
          let serverIds = this.serverItemsSelected.map(i => i.serverId);
          this.filteredData = this.filteredData.filter(i => serverIds.indexOf(i.serverId) != -1);
        }
      }

      this.loadDataIntoGridView();
    }
    else {
      // console.log('bulk action not set so grid not ready to be viewed')
    }
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      BULK ACTION FUNCTIONS
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  onToggleActionChange(value: any): void {
    this.bulkActionSelected = true;
    if (value == 'LockUnlock') {
      this.bulkActionType = BulkIntegrationActionType.LockUnlock;
    }
    else if (value == 'MoveServerIntegrations') {
      this.bulkActionType = BulkIntegrationActionType.MoveServerIntegrations;
    }
    else if (value == 'UpdateRunTimes') {
      this.bulkActionType = BulkIntegrationActionType.UpdateRunTimes;

      this.enableLastRunTime = true;
      this.enableNextRunTime = true;
      this.lastDateFormControl.reset();
      this.nextDateFormControl.reset();
      this.importTypesControl.reset();
      this.destinationsControl.reset();
    }
    else {
      this.bulkActionSelected = false;
      // none match so default = None
      this.bulkActionType = BulkIntegrationActionType.None;
    }

    this.refreshSelection();
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      MOVE SERVER REPORT FUNCTIONS
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  // MOVE SERVER - change server input dropdown
  changeServer(event: any): void {
    this.selectedServer = event.value;
  }

  moveIntegrations(e: Event): void {
    if (this.selectedIds.length > 0 && this.selectedServer != null && this.selectedServer != undefined) {

      var s = this.selectedIds.length.toString() + " Selected Integrations to " + this.selectedServer.serverId + ". Refreshing integrations.";

      this.clientService.moveServerIntegrations(this.selectedIds, this.selectedServer.serverId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          this.toastService.toastCreate("Moved " + s, "Success", {
            autoClose: false,
            keepAfterRouteChange: true
          });
          this.refreshSelection();
        },
        (error) => {
          // console.log(error);
          this.toastService.toastCreate("Error in Moving " + s, "Danger", {
            autoClose: false,
            keepAfterRouteChange: true
          });
          this.refreshSelection();
        }
      );
    }
    else if (this.selectedServer == null || this.selectedServer == undefined) {
      this.toastService.toastCreate("Selected Server is null or empty. Select Server to move integrations to.", "Info");
    }
    else {
      this.toastService.toastCreate("No integrations selected.", "Info");
    }
  }

  getFullServerInformation(): void {
    this.gettingServerItems = true;
    this.serverService.getAllServersInformation()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((s) => {
      this.gettingServerItems = false;
      this.serverItems = s;
      this.serverInfoReady = true;
    });
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      LOCK UNLOCK REPORT FUNCTIONS
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  public onLockSwitchChange(e: Event): void {
    this.switchLocked = !this.switchLocked;
    this.updateFilteredData();
  }

  // LOCK UNLOCK REPORT - Action Button
  lockIntegrations(e: Event): void {
    if (this.selectedIds.length > 0) {
      var s = "" + (this.switchLocked ? " Locked All Selected Integrations: " : " Unlocked All Selected Integrations: ");
      s += this.selectedIds.length.toString() + " Total Integrations. Refreshing integrations.";

      this.clientService.lockIntegrations(this.selectedIds, this.switchLocked)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          this.toastService.toastCreate(s, "Success", {
            autoClose: false,
            keepAfterRouteChange: true
          });
          this.refreshSelection();
        },
        (error) => {
          this.toastService.toastCreate("Error in " + s, "Danger", {
            autoClose: false,
            keepAfterRouteChange: true
          });
          this.refreshSelection();
        }
      );
    }
    else {
      this.toastService.toastCreate("No integrations selected.", "Info");
    }
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      BUTTON FUNCTIONS
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  // select all button
  public selectAll(e: Event): void {
    let ids = this.baseData.map(i => i.integrationId);
    if (this.filteredData != null && this.filteredData.length > 0) {
      ids = this.filteredData.map(i => i.integrationId);
    }
    if (this.selectedIds.length > 0) {
      this.selectedIds = [];
    }
    this.selectedIds = ids;
  }

  // unselect all button
  public unSelectAll(e: Event): void {
    if (this.selectedIds.length > 0) {
       this.selectedIds = [];
    }
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Filter Card Functions
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  // reset filter cards
  public resetFilters(e: Event): void {
    this.dataSourceItemsSelected = [];
    this.destinationEndpointItemsSelected = [];
    this.serverItemsSelected = [];
    this.importTypeItemsSelected = [];
    this.currentFiltersFromGrid = null;
    this.updateFilteredData();
  }

  // apply filters changes
  public applyFilters(e: Event): void {
    this.updateFilteredData();
  }

  public externalFilterChange(value: any): void {
    //console.log("externalFilterChange", value);
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      grid functions
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  public originalOrder = (): number => {
    return 0;
  };

  public saveData(component: any): void {
    component.save();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateFilteredData();
  }

  loadDataIntoGridView() {
    this.gridData = orderBy(this.filteredData, this.state.sort);
    this.gridViewIsLoading = false;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.updateFilteredData(filter);
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateFilteredData();
  }

  // gets data to export
  public selectedData(): ExcelExportData {
    var processedData = this.filteredData;
    const result: ExcelExportData = {
      data: processedData
    };

    return result;
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      dialog functions
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  openServerInfoDialog() {
    this.dialog.open(this.serverInfoDialog);
  }

  public goBack(e: Event): void {
    this.location.back();
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Integration RunTime Form Field Functions
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  changeEnableRunTimeChecks(name: string, x: boolean) {
    if (name == "LAST") {
      this.enableLastRunTime = !this.enableLastRunTime;
      if (this.enableLastRunTime) {
        this.lastDateFormControl.enable();
      }
      else {
        this.lastDateFormControl.disable();
      }
    }
    else if (name == "NEXT") {
      this.enableNextRunTime = !this.enableNextRunTime;
      if (this.enableNextRunTime) {
        this.nextDateFormControl.enable();
      }
      else {
        this.nextDateFormControl.disable();
      }
    }
  }

  public onRTSelectionRemoved(name: string, x: any) {
    if (name == "DESTINATION") {
      const destEndpoints = this.destinationsControl.value as DestinationEndpoint[];
      this.removeFirst(destEndpoints, x);
      this.destinationsControl.setValue(destEndpoints);
    }
    else if (name == "IMPORTTYPE") {
      const impTypes = this.importTypesControl.value as ImportType[];
      this.removeFirst(impTypes, x);
      this.importTypesControl.setValue(impTypes);
    }
  }

  private removeFirst(array: any[], toRemove: any): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  // UPDATE RUN TIMES - Action Button
  updateIntegrationRunTimes(e: Event): void {
    if (this.selectedIds.length > 0
        && this.destinationsControl.valid
        && this.importTypesControl.valid
        && (this.enableLastRunTime == true || this.enableNextRunTime == true)
        && (
          (this.enableLastRunTime == true && this.lastDateFormControl.valid
            && this.enableNextRunTime == true && this.nextDateFormControl.valid
            && this.lastDateFormControl.value <= this.nextDateFormControl.value)
          ||
          (
            (this.enableLastRunTime == true && this.lastDateFormControl.valid && this.enableNextRunTime == false)
            || (this.enableNextRunTime == true && this.nextDateFormControl.valid && this.enableLastRunTime == false)
          )
        )
        ) {

          let iIds = this.importTypesControl.value.map(x => x.importTypeId);
          let dIds = this.destinationsControl.value.map(x => x.destinationEndpointId);
          let lDate: Date = null;
          let nDate: Date = null;
          if (this.enableLastRunTime) {
            lDate = this.lastDateFormControl.value;
          }
          if (this.enableNextRunTime) {
            nDate = this.nextDateFormControl.value;
          }

          var msg = this.selectedIds.length.toString() + " Selected Integrations."
          this.iRunTimeService.updateBulkIntegrationRunTimes
            (this.selectedIds, iIds, dIds, lDate, nDate)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
              () => {
                this.toastService.toastCreate("Updated Integration Runtimes for " + msg, "Success", {
                  autoClose: false,
                  keepAfterRouteChange: true
                });
                // this.refreshSelection();
                this.refreshSelectionForRunTimes();
              },
              (error) => {
                if (error instanceof HttpErrorResponse) {
                  if (error.error != null && error?.error?.toString().length > 0) {
                    msg = msg + " " + error.error;
                  }
                }

                this.toastService.toastCreate("Error in Updating Integration Runtimes for " + msg, "Danger", {
                  autoClose: false,
                  keepAfterRouteChange: true
                });
                // this.refreshSelection();
                this.refreshSelectionForRunTimes();
              }
            );
    }
    else if (this.enableLastRunTime == true && !this.lastDateFormControl.valid) {
      this.toastService.toastCreate("Last Run Time is enabled but not valid.", "Warning");
    }
    else if (this.enableNextRunTime == true && !this.nextDateFormControl.valid) {
      this.toastService.toastCreate("Next Run Time is enabled but not valid.", "Warning");
    }
    else if (this.enableLastRunTime == false && this.enableNextRunTime == false) {
      this.toastService.toastCreate("Last Run Time and Next Run Time are disabled. At least one is required.", "Warning");
    }
    else if (!this.destinationsControl.valid) {
      this.toastService.toastCreate("Destination Endpoints to use to select Integration RunTimes by is not valid. At least one is required.", "Warning");
    }
    else if (!this.importTypesControl.valid) {
      this.toastService.toastCreate("Import Types to use to select Integration RunTimes by is not valid. At least one is required.", "Warning");
    }
    else if (this.enableLastRunTime == true && this.enableNextRunTime == true &&
      !(this.lastDateFormControl.value <= this.nextDateFormControl.value)) {
      this.toastService.toastCreate("Last Run Time must be before Next Run Time if both are enabled.", "Warning");
    }
    else {
      this.toastService.toastCreate("No integrations selected.", "Warning");
    }
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Expand / Collapse Master Detail Grids
     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  public expandedDetailKeys: string[] = [];

  public expandDetailsBy = (dataItem: Integration): string => {
    return dataItem.integrationId;
  };

  public onCellClick(event: CellClickEvent) : void {
    let findKeyState = this.checkIfIdExist(event.dataItem.integrationId);
    if (findKeyState === -1) {
      this.expandedDetailKeys.push(event.dataItem.integrationId);
    }
    else {
      this.expandedDetailKeys.splice(findKeyState, 1);
    }
    this.expandedDetailKeys = [...this.expandedDetailKeys];
  }

  public checkIfIdExist(id: string): number {
    return this.expandedDetailKeys.findIndex((i) => i === id);
  }
}
