import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { protectedResources } from '../auth-config';
import { IntegrationResponseMessage } from '../models/integration';
import { IntegrationIdentifier } from '../models/integration-identifier';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { IntegrationInformation } from '../models/integration-information';

@Injectable()
export class IdentifierService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Integration Identifiers ------------------------------------------//

  // Get Integration Identifiers by Integration Id and CanFastFetch = true
  // getFastFetchIntegrationIdentifiers(integrationId: string): Observable<IntegrationIdentifier[]> {
  //   this.actionUrl = "IntegrationIdentifiers/FastFetch/";
  //   this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

  //   return this.http
  //     .get<IntegrationIdentifier[]>(this.Url)
  //     .pipe(catchError(this.errorHandler));
  // }

  // Get Integration Identifiers by Integration Id and CanFastFetch = true
  // getDataSwapIntegrationIdentifiers(integrationId: string): Observable<IntegrationIdentifier[]> {
  //   this.actionUrl = "IntegrationIdentifiers/DataSwap/";
  //   this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

  //   return this.http
  //     .get<IntegrationIdentifier[]>(this.Url)
  //     .pipe(catchError(this.errorHandler));
  // }


  // Get All Integration Identifiers
  getAllIntegrationIdentifiers(): Observable<IntegrationIdentifier[]> {
    this.actionUrl = "IntegrationIdentifiers/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationIdentifier[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get All Active Integration Identifiers
  getAllActiveIntegrationIdentifiers(): Observable<IntegrationIdentifier[]> {
    this.actionUrl = "IntegrationIdentifiers/Active";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationIdentifier[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllActiveIntegrationIdentifiersForFastFetch(): Observable<IntegrationIdentifier[]> {
    this.actionUrl = "IntegrationIdentifiers/Active/fastfetch";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationIdentifier[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllActiveIntegrationIdentifiersForDataSwap(): Observable<IntegrationIdentifier[]> {
    this.actionUrl = "IntegrationIdentifiers/Active/dataswap";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationIdentifier[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllActiveIntegrationIdentifiersForFastFetchWithInfo(): Observable<IntegrationInformation[]> {
    this.actionUrl = "IntegrationIdentifiers/ActiveWithInfo/fastfetch";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationInformation[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getIntegrationInformationForDataSwap(): Observable<IntegrationInformation[]> {
    this.actionUrl = "IntegrationIdentifiers/ActiveWithInfo/dataswap";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationInformation[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration Identifiers By Id and Destination
  getIntegrationIdentifierByIntegrationAndDestination(integrationId: string, destinationName: string): Observable<IntegrationIdentifier> {
    this.actionUrl = "IntegrationIdentifier/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId, "/", destinationName);

    return this.http
      .get<IntegrationIdentifier>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Integration Identifiers by Integration Id
  getIntegrationIdentifiers(integrationId: string): Observable<IntegrationIdentifier[]> {
    this.actionUrl = "IntegrationIdentifiers/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);

    return this.http
      .get<IntegrationIdentifier[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Integration Identifiers by Client Id
  getIntegrationIdentifiersByClient(clientId: number): Observable<IntegrationIdentifier[]> {
    this.actionUrl = "IntegrationIdentifiers/ByClient/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientId.toString());

    return this.http
      .get<IntegrationIdentifier[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // DELETE Integration Identifier
  deleteIntegrationIdentifier(identifier: IntegrationIdentifier): Observable<IntegrationIdentifier> {
    this.actionUrl = "IntegrationIdentifier/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      identifier.integrationId,
      "/",
      identifier.destinationId.toString()
    );

    return this.http
      .delete<IntegrationIdentifier>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PUT Update Integration Identifier
  updateIntegrationIdentifier(integrationIdentifier: IntegrationIdentifier): Observable<IntegrationIdentifier> {
    this.actionUrl = "IntegrationIdentifier/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put<IntegrationIdentifier>(this.Url, integrationIdentifier, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Insert Integration Identifier
  addIntegrationIdentifier(integrationIdentifier: IntegrationIdentifier): Observable<IntegrationResponseMessage> {
    this.actionUrl = "IntegrationIdentifier/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<IntegrationResponseMessage>(this.Url, integrationIdentifier, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
