<div class="container">

  <div class="my-2 row align-items-center d-flex justify-content-center gap-1">
    <div class="col-5 date-range-selector ms-1 d-flex justify-content-center gap-2 align-items-end">

      <ng-container *ngIf="monthYearOnly">
        <form [formGroup]="metricRange" class="date-form">
          <kendo-daterange class="d-flex gap-2">
              <kendo-floatinglabel text="Start" class="date-form-field">
                <kendo-formfield><kendo-dateinput #kRangeStart
                  kendoDateRangeStartInput
                  formControlName="start"
                  format="MMM yyyy"
                  [spinners]="true"></kendo-dateinput></kendo-formfield>
              </kendo-floatinglabel>
              <kendo-floatinglabel text="End">
                <kendo-formfield><kendo-dateinput #kRangeEnd
                  kendoDateRangeEndInput
                  formControlName="end"
                  format="MMM yyyy"
                  [spinners]="true"></kendo-dateinput></kendo-formfield>
              </kendo-floatinglabel>
            <kendo-daterange-popup>
                <ng-template kendoDateRangePopupTemplate>
                    <kendo-multiviewcalendar
                      kendoDateRangeSelection
                      bottomView="year"
                      topView="decade">
                    </kendo-multiviewcalendar>
                </ng-template>
            </kendo-daterange-popup>
          </kendo-daterange>
        </form>
        <div>
          <button mat-raised-button (click)="submitDateRange($event)" >Apply</button>
        </div>
      </ng-container>

      <ng-container *ngIf="!monthYearOnly">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="fill" class="example-form-field">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class="col my-auto">
            <button mat-raised-button (click)="submitDateRange($event)">Apply</button>
          </div>
        </div>
      </ng-container>

    </div>

    <div class="col-5 date-range-buttons d-flex justify-content-center gap-2">
      <button mat-raised-button (click)="submitDefaultRange('yearToDate')">Year to Date</button>
      <button mat-raised-button (click)="submitDefaultRange('thisYear')">This Year</button>
      <button mat-raised-button (click)="submitDefaultRange('past3Months')">Last 3 Months</button>
      <button mat-raised-button (click)="submitDefaultRange('lastMonth')" *ngIf="showThisMonthButton">Last Month</button>
    </div>

  </div>
  <div class="my-2 row align-items-center" *ngIf="hasDateRangeSelected">
    <h5 class="title-center my-2">
      <span *ngIf="dateStringPrefix">{{dateStringPrefix}} | </span>Selected Range: {{currentStartDate | date:'MM/dd/y'}} - {{currentEndDate | date:'MM/dd/y'}}
    </h5>
  </div>

</div>
