import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, AfterViewInit, Input, Output, forwardRef, EventEmitter, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
// import { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Integration } from '../../models/integration';
import * as _ from 'lodash';
import { IntegrationJavaScript } from '../../models/integration-javascript';
import { filter, take } from "rxjs/operators";
import {
  MonacoEditorComponent,
  MonacoEditorConstructionOptions,
  MonacoDiffEditorConstructionOptions,
  MonacoEditorLoaderService,
  MonacoStandaloneCodeEditor,
  MonacoStandaloneDiffEditor,
  MonacoDiffEditorComponent
} from "@materia-ui/ngx-monaco-editor";

declare const monaco: any;
declare const require: any;

@Component({
  selector: 'app-monaco-diff-editor',
  templateUrl: './monaco-diff-editor.component.html',
  styleUrls: ['./monaco-diff-editor.component.scss']
})
export class MonacoDiffPageComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MonacoDiffEditorComponent, { static: false })
  monacoComponent: MonacoDiffEditorComponent;

  diffEditor: MonacoStandaloneDiffEditor;
  editor: MonacoStandaloneDiffEditor;

  // currently live for integration
  @Input() currentData: string = '<head></head>';
  @Input() currentTitle = '';
  current: string = '<head></head>';

  // historical choice to compare from
  @Input() historyData: string = '<head></head>';
  @Input() historyTitle = '';
  history: string = '<head></head>';

  @Input() lang: string = 'html';
  @Input() readOnly: boolean = true;

  editorOptions: MonacoEditorConstructionOptions = {
    theme: 'myCustomTheme',
    language: this.lang,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: this.readOnly
  };

  constructor(private monacoLoaderService: MonacoEditorLoaderService) {
    this.monacoLoaderService.isMonacoLoaded$
    .pipe(
      filter(isLoaded => isLoaded),
      take(1)
    )
    .subscribe(() => {
      monaco.editor.defineTheme("myCustomTheme", {
        base: "vs-dark", // can also be vs or hc-black
        inherit: true, // can also be false to completely replace the builtin rules
        rules: [
          { token: "comment", foreground: "ffa500", fontStyle: "italic underline" },
          { token: "comment.html", foreground: "ffa500", fontStyle: "italic underline" },
          { token: "comment.js", foreground: "008800", fontStyle: "bold" },
          { token: "comment.css", foreground: "0000ff" } // will inherit fontStyle from `comment` above
        ],
        colors: {}
      });
      monaco.editor.setTheme("myCustomTheme");
    });
  }

  ngOnInit(): void {  }

  ngOnDestroy() {  }

  ngAfterViewInit(): void {
    this.editorOptions = this.editorOptions;
  }

  // to change value in diff editor while still keeping theme and language correct
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.currentData != null) {
      if (changes?.currentData.firstChange == false) {
        this.diffEditor.getOriginalEditor().getModel().setValue(changes?.currentData.currentValue);
      }
      else {
        this.current = changes?.currentData.currentValue;
      }
    }

    if (changes?.historyData != null) {
      if (changes?.historyData.firstChange == false) {
        this.diffEditor.getModifiedEditor().getModel().setValue(changes?.historyData.currentValue);
      }
      else {
        this.history = changes?.historyData.currentValue;
      }
    }
  }

  initMonaco() {  }

  editorInit(editor: MonacoStandaloneDiffEditor) {
    this.diffEditor = editor;
    let orig: monaco.editor.ITextModel = monaco.editor.createModel(this.current, this.lang); // current
    let mod: monaco.editor.ITextModel = monaco.editor.createModel(this.history, this.lang); // history
    this.diffEditor.setModel({original: orig, modified: mod});
  }

  mergeOptions(moreOptions?) {
    return { ...this.editorOptions, ...moreOptions, language: this.lang };
  }

  public changeLanguage(langu: string): void {
    this.lang = langu;
    let orig: monaco.editor.ITextModel = monaco.editor.createModel(this.current, langu); // current
    let mod: monaco.editor.ITextModel = monaco.editor.createModel(this.history, langu); // history
    this.diffEditor.setModel({original: orig, modified: mod});
  }

}
