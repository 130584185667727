import { ClientContract } from "./client-contract";
import { InvoicePeriod } from "./invoice-period";

export class CompletedLoan {
  completedLoanId: string;
  loanNumber: string;
  loanGuid: string;
  channel: string;
  completedDate: Date;
  losUpdatedDate: Date;
  postedAsOf?: Date;
  jsonFieldCollection: Map<string,string>;
  lastLTSModifiedDate: Date;
  loanAmount: number;

  actionTaken: string;
  actionTakenDate?: Date;
  actualClosingDate?: Date;
  applicationDate?: Date;
  approvedDate?: Date;
  companyNMLSNumber: string;
  createdDate?: Date;
  disbursementDate?: Date;
  estClosingDate?: Date;
  lienPosition: string;
  loanOfficerNMLS: string;
  loanOfficerEmail: string;
  loanPurpose: string;
  loanSource: string;
  startedDate?: Date;
  signedDate?: Date;
  includeInInvoice: boolean;

  invoicePeriodId: string;
  invoicePeriod?: InvoicePeriod = null;

  clientContractId?: string;

  clientContract?: ClientContract = null;

  constructor(init?: Partial<CompletedLoan>, c?: ClientContract, p?: InvoicePeriod){
    this.completedLoanId = init.completedLoanId;
    this.loanNumber = init.loanNumber;
    this.loanGuid = init.loanGuid;
    this.channel = init.channel;
    this.jsonFieldCollection = init.jsonFieldCollection;
    this.loanAmount = init.loanAmount;

    this.actionTaken = init.actionTaken;
    this.companyNMLSNumber = init.companyNMLSNumber;
    this.lienPosition = init.lienPosition;
    this.loanOfficerNMLS = init.loanOfficerNMLS;
    this.loanOfficerEmail = init.loanOfficerEmail;
    this.loanPurpose = init.loanPurpose;
    this.loanSource = init.loanSource;
    this.includeInInvoice = init.includeInInvoice;

    if (init.completedDate != null) {
      this.completedDate = new Date(init.completedDate);
    }

    if (init.lastLTSModifiedDate != null) {
      this.lastLTSModifiedDate = new Date(init.lastLTSModifiedDate)
    }

    if (init.losUpdatedDate != null) {
      this.losUpdatedDate = new Date(init.losUpdatedDate)
    }

    if (init.postedAsOf != null) {
      this.postedAsOf = new Date(init.postedAsOf)
    }
    else {
      this.postedAsOf = null;
    }

    this.invoicePeriodId = init.invoicePeriodId;
    this.clientContractId = init.clientContractId;

    if (init?.clientContract != null) {
      this.clientContract = init.clientContract;
    }
    else if (c != null) {
      this.clientContract = c;
    }

    if (init?.invoicePeriod != null) {
      this.invoicePeriod = init.invoicePeriod;
    }
    else if (p != null) {
      this.invoicePeriod = p;
    }

    if (init.actionTakenDate != null) {
      this.actionTakenDate = new Date(init.actionTakenDate)
    }
    else {
      this.actionTakenDate = null;
    }

    if (init.actualClosingDate != null) {
      this.actualClosingDate = new Date(init.actualClosingDate)
    }
    else {
      this.actualClosingDate = null;
    }

    if (init.applicationDate != null) {
      this.applicationDate = new Date(init.applicationDate)
    }
    else {
      this.applicationDate = null;
    }

    if (init.approvedDate != null) {
      this.approvedDate = new Date(init.approvedDate)
    }
    else {
      this.approvedDate = null;
    }

    if (init.createdDate != null) {
      this.createdDate = new Date(init.createdDate)
    }
    else {
      this.createdDate = null;
    }

    if (init.disbursementDate != null) {
      this.disbursementDate = new Date(init.disbursementDate)
    }
    else {
      this.disbursementDate = null;
    }

    if (init.estClosingDate != null) {
      this.estClosingDate = new Date(init.estClosingDate)
    }
    else {
      this.estClosingDate = null;
    }

    if (init.startedDate != null) {
      this.startedDate = new Date(init.startedDate)
    }
    else {
      this.startedDate = null;
    }

    if (init.signedDate != null) {
      this.signedDate = new Date(init.signedDate)
    }
    else {
      this.signedDate = null;
    }

  }
}
