import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, AfterViewInit, Input, Output, forwardRef, EventEmitter, OnDestroy, OnChanges, SimpleChanges, SimpleChange }
  from '@angular/core';
// import { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

import { filter, take } from "rxjs/operators";
import {
  MonacoEditorComponent,
  MonacoEditorConstructionOptions,
  MonacoEditorLoaderService,
  MonacoStandaloneCodeEditor
} from "@materia-ui/ngx-monaco-editor";

declare const monaco: any;
declare const require: any;

@Component({
  selector: 'app-monaco-editor',
  templateUrl: './monaco-editor.component.html',
  styleUrls: ['./monaco-editor.component.scss'],
  /*
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => MonacoEditorComponent),
          multi: true
      }
  ],
*/

})
export class MonacoPageComponent implements OnInit, AfterViewInit {
  @Input() lang: string = 'xml';

  @ViewChild(MonacoEditorComponent, { static: false })
  monacoComponent: MonacoEditorComponent;
  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.lang,
    roundedSelection: true,
    autoIndent: "full"
  };
  @Input() editorData: string = '<head></head>';
  @Output() textChange = new EventEmitter<string>();
  @Input() editAccess: boolean;

  originalData: any;
  readOnly: boolean;
  editor: any;

  // availableLanguages: string[] = [ "javascript", "js", "html", "css", "json", "xml" ];

  constructor(private monacoLoaderService: MonacoEditorLoaderService) {
    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => isLoaded),
        take(1)
      )
      .subscribe(() => {
        monaco.editor.defineTheme("myCustomTheme", {
          base: "vs-dark", // can also be vs or hc-black
          inherit: true, // can also be false to completely replace the builtin rules
          rules: [
            {
              token: "comment",
              foreground: "ffa500",
              fontStyle: "italic underline"
            },
            { token: "comment.js", foreground: "008800", fontStyle: "bold" },
            { token: "comment.css", foreground: "0000ff" }, // will inherit fontStyle from `comment` above
            {
              token: "comment.xml",
              foreground: "ffa500",
              fontStyle: "italic underline"
            },
          ],
          colors: {}
        });
      });
  }

  ngAfterViewInit(): void {
    this.initMonaco();
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    editor.setSelection({
      startLineNumber: 1,
      startColumn: 1,
      endColumn: 50,
      endLineNumber: 3
    });
  }


  modelChange($event) {
    this.editorData = $event;
    this.textChange.emit(this.editorData);
  }

  changeLanguage(event) {
    this.editorOptions = { ...this.editorOptions, language: event }
    this.lang = event;
  }

  ngOnInit() {
    if (this.lang == null) {
      this.lang = this.editorOptions.language;
    }
    if (this.editorData != null) {
      this.originalData = this.editorData;
    }
  }

  ngOnDestroy() {  }

  hasBeenEdited(): boolean {
    if (this.editorData != null && this.originalData != null) {
      if (this.editorData != this.originalData) {
        return true;
      }
      else {
        return false;
      }
    }
    else if ((this.editorData == null && this.originalData != null)
          || (this.editorData != null && this.originalData == null)) {
        return true;
    }
    else {
      return false
    }
  }

  getValue(): string {
    return this.editorData; // this.editor.getValue();
  }

  initMonaco() {
    // if (this.editorData != null) {
    //   this.originalData = this.editorData;
    // }

    if (this.editAccess == true) {
      this.readOnly = false;
    } else {
      this.readOnly = true;
    }
    this.editorOptions = { ...this.editorOptions, readOnly: this.readOnly, language: this.lang }
  }

  updateOriginal(value: string) {
    this.originalData = value;
  }

}
