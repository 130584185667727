import { IntegrationError } from "./integration-error";

export class JiraAPIErrorReport {
  integrationError: IntegrationError;
  labels?: string[];
  titleMessage?: string;
  descriptionMessage?: string;

  constructor(init?: Partial<JiraAPIErrorReport>) {
    this.labels = init?.labels;
    this.integrationError = init.integrationError;
    this.titleMessage = init.titleMessage ?? "";
    this.descriptionMessage = init.descriptionMessage ?? "";
}
}
