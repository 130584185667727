import { Component, OnInit, Input, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { ImportType } from 'src/app/models/import-type';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';
import { IntegrationRunTimeService } from 'src/app/services/integration-runtime.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';


import { Integration } from '../../models/integration';
import { IntegrationRunTime } from '../../models/integration-runtime';
import { User } from '../../models/user';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-integration-runtime',
    templateUrl: './integration-runtime.component.html',
    styleUrls: ['./integration-runtime.component.scss']
})
export class IntegrationRunTimeComponent implements OnInit {

    constructor(
        private clientService: ClientIntegrationService,
        private integrationRunTimeService: IntegrationRunTimeService,
        private typeConstantService: TypeConstantService,
        private toastService: ToastService,
        private user: User,
        private dialog: MatDialog,
        private router: Router
    ) { }

    private sub: any;
    @Input() integration: Integration;
    @Output() runTimesPopulated = new EventEmitter<boolean>();
    integrationRunTimes: IntegrationRunTime[];
    integrationRunTime: IntegrationRunTime;
    destinationEndpoints: DestinationEndpoint[];
    importTypes: ImportType[];
    integrationRunTimeSelected: boolean = false;
    destinationsAndImportTypes: string[] = [];
    addingIntegrationRunTime: boolean = false;
    lastRunTimeDateInput: Date;
    nextRunTimeDateInput: Date;
    isInTab: boolean;
    savingIntegrationRuntime: boolean = false;
    @ViewChild('deleteRunTimeDialog', { static: true }) deleteRunTimeDialog: TemplateRef<any>;

    ngOnInit(): void {
        this.integrationRunTimeSelected = false;

        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        this.lastRunTimeDateInput = new Date(today);

        today.setSeconds(1);

        this.nextRunTimeDateInput = new Date(today);

        if (this.integration !== null) {
            this.isInTab = true;
            this.integrationRunTimeService.getAllIntegrationRunTimesByIntegration(this.integration.integrationId).subscribe(value => {
                // on success
                this.integrationRunTimes = value;

                if(this.integrationRunTimes.length > 0)
                {
                    this.selectIntegrationRunTime(this.integrationRunTimes[0]);
                    this.integrationRunTimeSelected = true;
                    this.runTimesPopulated.emit(true);
                }
            });
        }
        else {
            this.isInTab = false;
        }

        this.typeConstantService.getAllDestinationEndpoints().subscribe(value => { this.destinationEndpoints = value });
        this.typeConstantService.getAllImportTypes().subscribe(value => { this.importTypes = value });
    }

    updateIntegrationRunTime(num?: number, timeType?: string): void {

        this.savingIntegrationRuntime = true;

        if ((num != null) && (timeType != null)) {
            if (timeType == "hours") {
                var currentDate = new Date(this.integrationRunTime.lastRunTime);
                this.lastRunTimeDateInput = new Date(
                    (currentDate.getTime() - (num * 60 * 60 * 1000))
                );

                this.nextRunTimeDateInput = new Date(this.integrationRunTime.nextRunTime);
            }

        }
        //Else use lastruntime and nextruntime, already set

        if (this.lastRunTimeDateInput.getTime() >= this.nextRunTimeDateInput.getTime()) {
            this.toastService.toastCreate("Invalid date/time: lastRunTime is greater than the nextRunTime.", "Warning", {
                autoClose: false
            });

            this.savingIntegrationRuntime = false;
        } else {
            var lrtUTC = this.lastRunTimeDateInput.toUTCString();
            var nrtUTC = this.nextRunTimeDateInput.toUTCString()
            this.integrationRunTime.lastRunTime = new Date(lrtUTC);
            this.integrationRunTime.nextRunTime = new Date(nrtUTC);
            this.integrationRunTimeService.updateIntegrationRunTime(this.integrationRunTime).subscribe(value => {
                // on success
                this.toastService.toastCreate("Successfully updated the runtime for " + this.integration.name + ": "
                    + this.integrationRunTime.importTypeDescription + " " + this.integrationRunTime.destinationEndpointName + ".", "Success", {
                        autoClose: false
                });

                this.savingIntegrationRuntime = false;
            }, error => {
                this.savingIntegrationRuntime = false;
            });
        }
    }

    rollbackIntegrationRuntime(rollback: string): void {

        let today;

        if (rollback == "StartOfMonth") {
            today = new Date();
            today = new Date(today.getFullYear(), today.getMonth());
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
        } else if (rollback == "StartOfDay") {
            today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
        }

        this.lastRunTimeDateInput = today;

        this.updateIntegrationRunTime();
    }

    addIntegrationRunTime(): void {

        this.savingIntegrationRuntime = true;

        //Last runtime and next runtime are already set

        if (this.lastRunTimeDateInput.getTime() >= this.nextRunTimeDateInput.getTime()) {
            this.toastService.toastCreate("Invalid date/time: lastRunTime is greater than the nextRunTime.", "Warning", {
                autoClose: false
            });

            this.savingIntegrationRuntime = false;
        }
        else if (this.integrationRunTime.destinationEndpointId == -1) {
            this.toastService.toastCreate("No DestinationEndpoint Selected", "Warning", {
                autoClose: false
            });

            this.savingIntegrationRuntime = false;
        }
        else if (this.integrationRunTime.importTypeId == -1) {
            this.toastService.toastCreate("No ImportType Selected", "Warning", {
                autoClose: false
            });

            this.savingIntegrationRuntime = false;
        }
        else if (this.destinationsAndImportTypes.indexOf(this.integrationRunTime.destinationEndpointId + " " + this.integrationRunTime.importTypeId) > -1) {
            // console.log(this.destinationsAndImportTypes);
            this.toastService.toastCreate(this.integrationRunTime.destinationEndpointName + " " + this.integrationRunTime.importTypeDescription + " RunTime already exists.", "Warning", {
                autoClose: false
            });

            this.savingIntegrationRuntime = false;
        }
        //dE.destinationEndpointId + " " + iT.importTypeId
        else {
            this.integrationRunTime.lastRunTime = this.lastRunTimeDateInput;
            this.integrationRunTime.nextRunTime = this.nextRunTimeDateInput;

            this.integrationRunTimeService.addIntegrationRunTime(this.integrationRunTime).subscribe(value => {
                this.integrationRunTimes.push(this.integrationRunTime);
                // on success
                this.toastService.toastCreate("Successfully added the runtime for " + this.integration.name + ": " + this.integrationRunTime.importTypeDescription + " " + this.integrationRunTime.destinationEndpointName + ".", "Success", {
                    autoClose: false
                });
                this.addingIntegrationRunTime = false;

                this.selectIntegrationRunTime(this.integrationRunTime);

                if (this.integrationRunTime != undefined) {
                    this.integrationRunTimeSelected = true;
                }

                this.savingIntegrationRuntime = false;
            }, error => {

                this.savingIntegrationRuntime = false;
            });
        }

    }

    selectIntegrationRunTime(r: IntegrationRunTime): void {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        this.lastRunTimeDateInput = new Date(today);

        today.setSeconds(1);

        this.nextRunTimeDateInput = new Date(today);

        this.integrationRunTime = r;

        this.integrationRunTime.lastRunTime = new Date(this.integrationRunTime.lastRunTime);
        this.integrationRunTime.nextRunTime = new Date(this.integrationRunTime.nextRunTime);
        if (this.integrationRunTime.lastDataSent != undefined) {
            this.integrationRunTime.lastDataSent = new Date(this.integrationRunTime.lastDataSent);
        }

        this.integrationRunTimeSelected = true;
        this.addingIntegrationRunTime = false;
    }

    checkAccess(role: string[]): boolean {
        //User requires DevOps.Write or Admin

        return this.user.checkAccess(role);
    }

    addIntegrationRunTimeSwitch(): void {
        this.integrationRunTimeSelected = false;
        this.addingIntegrationRunTime = true;
        this.integrationRunTime = {
            integrationId: this.integration.integrationId,
            lastRunTime: new Date(),
            nextRunTime: new Date(),
            importTypeId: -1,
            destinationEndpointId: -1
        };

        this.destinationsAndImportTypes = [];

        for (let integrationRunTime of this.integrationRunTimes) {
            this.destinationsAndImportTypes.push(integrationRunTime.destinationEndpointId + " " + integrationRunTime.importTypeId);
        }
    }

    selectDestinationEndpoint(event: any): void {
        let destinationEndpoint = event.value;
        this.integrationRunTime.destinationEndpointId = destinationEndpoint.destinationEndpointId;
        this.integrationRunTime.destinationEndpointName = destinationEndpoint.endpointName;
    }

    selectImportType(event: any): void {
        let importType = event.value;
        this.integrationRunTime.importTypeId = importType.importTypeId;
        this.integrationRunTime.importTypeDescription = importType.typeDescription;
        // console.log(importType);
    }

    setDeletingIntegrationRunTime(): void {
        this.dialog.open(this.deleteRunTimeDialog);
    }

    deleteIntegrationRunTime(): void {
        this.savingIntegrationRuntime = true;
        // console.log(this.integrationRunTime);
        this.integrationRunTimeService.deleteIntegrationRunTime(this.integrationRunTime.integrationRunTimeId).subscribe(() => {
            this.integrationRunTimes.splice(this.integrationRunTimes.indexOf(this.integrationRunTime), 1);
            // console.log(this.integrationRunTimes);
            if(this.integrationRunTimes.length > 0)
            {
                this.selectIntegrationRunTime(this.integrationRunTimes[0]);
            }
            else
            {
                this.integrationRunTimeSelected = false;
            }
            this.destinationsAndImportTypes.splice(this.destinationsAndImportTypes.indexOf(this.integrationRunTime.destinationEndpointId + " " + this.integrationRunTime.importTypeId), 1);
            this.savingIntegrationRuntime = false;
        }, error => {
            this.savingIntegrationRuntime = false;
        });
    }
}
