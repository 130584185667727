import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import { DefaultConfigurationPlaceholder, DefaultConfigUtils } from '../models/default-config-placeholder';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultConfigPlaceholderService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Default Config Placeholders ------------------------------------------//

  // GET All Default Config Placeholders
  getAllDefaultConfigPlaceholders(): Observable<DefaultConfigurationPlaceholder[]> {
    this.actionUrl = "DefaultConfigurationPlaceholders/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    
    return this.http
      .get<DefaultConfigurationPlaceholder[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET All Filtered Default Config Placeholders
  getDefaultConfigPlaceholdersFiltered(model: DefaultConfigurationPlaceholder): Observable<DefaultConfigurationPlaceholder[]> {
    this.actionUrl = "DefaultConfigurationPlaceholders/filtered";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch(this.Url, model, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET All Default Config Placeholders
  getDefaultConfigPlaceholderById(id: string): Observable<DefaultConfigurationPlaceholder> {
    this.actionUrl = "DefaultConfigurationPlaceholder/";
    this.Url = this.baseUrl.concat(this.actionUrl, id);

    return this.http
      .get<DefaultConfigurationPlaceholder>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteDefaultConfigPlaceholder(id: string): Observable<any> {
    this.actionUrl = "DefaultConfigurationPlaceholder/";
    this.Url = this.baseUrl.concat(this.actionUrl, id);

    return this.http
      .delete(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateDefaultConfigPlaceholder(model: DefaultConfigurationPlaceholder): Observable<any> {
    this.actionUrl = "DefaultConfigurationPlaceholder/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, model, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertDefaultConfigPlaceholder(model: DefaultConfigurationPlaceholder): Observable<any> {
    this.actionUrl = "DefaultConfigurationPlaceholder/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, model, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  isGuidNull(value: string) : boolean {
    if (value == '00000000-0000-0000-0000-000000000000')
      return true;
    else
      return false;
  }

}
