export class FailedLoan {
  failedLoanId: string;
  integrationId: string;
  destinationEndpointId: number;
  endpointName?: string;
  loanNumber: string;
  numberOfFailures: number;
  loanSendSuccessful: boolean;
  lastAttemptDate?: Date;
  firstAttemptDate?: Date;
  currentError: string;

  constructor(init?: Partial<FailedLoan>) {
    this.failedLoanId = init.failedLoanId;
    this.integrationId = init.integrationId;
    this.destinationEndpointId = init.destinationEndpointId;
    this.endpointName = init.endpointName;
    this.loanNumber = init.loanNumber;
    this.numberOfFailures = init.numberOfFailures;
    this.loanSendSuccessful = init.loanSendSuccessful;
    this.currentError = init.currentError;

    if (init?.lastAttemptDate != null) {
      this.lastAttemptDate = new Date(init.lastAttemptDate)
    }

    if (init?.firstAttemptDate != null) {
      this.firstAttemptDate = new Date(init.firstAttemptDate)
    }

  }
}
