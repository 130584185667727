import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function checkNotInList(checkList: string[]) : ValidatorFn {

  return (control: AbstractControl) : ValidationErrors | null  => {
    return checkList.includes(control.value.toLowerCase()) ?
      {checkNotInList: {value: control.value}} : null;
  };

}

@Directive({
   selector: '[appCheckNotInList]',
   providers: [{provide: NG_VALIDATORS, useExisting: CheckNotInListDirective, multi: true}]
})
export class CheckNotInListDirective implements Validator {

  @Input('appCheckNotInList') checkList : string[] = [];

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == null) {
      return null;
    }
    else {
      return (control: AbstractControl) : ValidationErrors | null  => {
        return this.checkList.includes(control.value.toLowerCase()) ?
          {checkNotInList: {value: control.value}} : null;
      }
    }
  }

}
