<header class="lts-info-nav-header navbar h-auto flex-md-nowrap p-0 shadow test-header" *ngIf="environmentName === 'Test'">
  <div class="mx-auto text-center fw-bold align-middle">
    Test
  </div>
</header>


<header class="lts-info-nav-header navbar h-auto flex-md-nowrap p-0 shadow staging-header" *ngIf="environmentName === 'Staging'">
  <div class="mx-auto text-center fw-bold align-middle">
    Staging
  </div>
</header>

<div class="container-fluid" >
  <div class="row justify-content-between vh-100">
      <div class="col-sm-2">
        <app-nav-menu></app-nav-menu>
      </div>
      <div class='col-sm-10 body-content d-flex flex-column'>
        <div class="inline user-controls">
          <div *ngIf="impersonationActive">
            <button kendoButton [primary]="false" (click)="endImpersonation()">End Impersonation</button>
          </div>
          <div *ngIf="loggedIn">
            <button kendoButton [primary]="true" (click)="logout('true')">Sign Out</button>
          </div>
          <div *ngIf="!loggedIn">
            <button kendoButton [primary]="true" (click)="login()">Sign In</button>
          </div>
        </div>
          <div class="main-router-content">
            <toast></toast>
            <router-outlet id="main"></router-outlet>
          </div>
          <div id="footer-wrapper" class="mt-auto">
            <div id="footer-inside">
              <app-footer></app-footer>
            </div>
          </div>
      </div>
  </div>
</div>
