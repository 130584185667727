import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { IntegrationError } from '../models/integration-error';
import { IntegrationErrorResponse } from '../models/integration-error-response';
import { ErrorStatus } from '../models/error-status';
import { protectedResources } from '../auth-config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IntegrationMetrics } from '../models/integration-metrics';
import { IntegrationLog } from '../models/integration-log';
import { CommonErrorFilter } from '../models/common-error-filter';
import { CsvSummary } from '../models/csv-summary';
import { LoanMetrics } from '../models/loan.metrics';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { ErrorCategory } from '../models/error-category';
import { ServiceError } from '../models/service-error';
import { CurrentStatus } from '../models/currentStatus';
import { ErrorSearchFilter } from '../models/error-search-filter';
import { URLSearchParams } from 'url';
import { LTHttpUtility } from '../utilities/http-Utility';
import { JiraAPIErrorReport } from '../models/jira-api-error-report';
import { BulkErrorUpdateScope } from '../models/bulk-error-update-scope';

@Injectable()
export class IntegrationErrorsService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Integration Error Functions ------------------------------------------//

  //Get all errors by Id
  getAllErrorsById(integrationId: string): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/GetAllErrorsFor/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    return this.http
      .get<IntegrationError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }



  //Get all errors
  getAllErrors(filters: ErrorSearchFilter): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/GetAllErrors";
    let params = LTHttpUtility.formatParams(filters);
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<IntegrationError[]>(this.Url, { params: params })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getErrorsByErrorIdList(errorList: string[]): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/getbyerrorIds";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");
    return this.http
      .post(this.Url, errorList, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
  //Get 5 day critical errors
  getSnapshot(filters: ErrorSearchFilter): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/snapshot";
    let params = LTHttpUtility.formatParams(filters);
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<IntegrationError[]>(this.Url, { params: params })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // searchErrors(filters: ErrorSearchFilter): Observable<IntegrationError[]> {
  //   this.actionUrl = "IntegrationErrors/SearchErrors";
  //   let params = LTHttpUtility.formatParams(filters);

  //   this.Url = this.baseUrl.concat(this.actionUrl);
  //   return this.http
  //     .get<IntegrationError[]>(this.Url, { params: params})
  //     .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  // }

  // get all errors by status
  getAllStatusErrors(statusName: string): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/Status/";
    this.Url = this.baseUrl.concat(this.actionUrl, statusName);
    return this.http
      .get<IntegrationError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by status and by integration
  getAllStatusErrorsById(statusName: string, integrationId: string): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/Status/";
    this.Url = this.baseUrl.concat(this.actionUrl, statusName + "/", integrationId);
    return this.http
      .get<IntegrationError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by category
  getAllCategoryErrors(categoryName: string): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/Category/";
    this.Url = this.baseUrl.concat(this.actionUrl, categoryName);
    return this.http
      .get<IntegrationError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by category and by integration
  getAllCategoryErrorsById(categoryName: string, integrationId: string): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/Category/";
    this.Url = this.baseUrl.concat(this.actionUrl, categoryName + "/", integrationId);
    return this.http
      .get<IntegrationError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by categoryId
  getAllCategoryIdErrors(categoryId: number): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/CategoryId/";
    this.Url = this.baseUrl.concat(this.actionUrl, categoryId.toString());
    return this.http
      .get<IntegrationError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by categoryId and by integration
  getAllCategoryIdErrorsById(categoryId: number, integrationId: string): Observable<IntegrationError[]> {
    this.actionUrl = "IntegrationErrors/CategoryId/";
    this.Url = this.baseUrl.concat(this.actionUrl, categoryId.toString() + "/", integrationId);
    return this.http
      .get<IntegrationError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  resolve(error: IntegrationError): Observable<any> {
    //TODO - Implement new statuses
    this.actionUrl = "IntegrationErrors/resolve/";
    this.Url = this.baseUrl.concat(this.actionUrl, error.integrationId + "/", error.destinationEndpointId.toString() + "/", error.importTypeId.toString() + "/", error.statusName);
    return this.http
      .patch(this.Url, null)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }


  updateIntegrationError(error: IntegrationError): Observable<any> {
    //TODO - Implement new statuses
    this.actionUrl = "IntegrationError/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, error, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateJiraURLForError(error: IntegrationError): Observable<any> {
    //TODO - Implement new statuses
    this.actionUrl = "IntegrationError/jiraurl";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, error, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  generateJiraTicketForError(error: IntegrationError, shortMsg: string): Observable<any> {
    this.actionUrl = "IntegrationError/SendToJira";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    let tmp = new JiraAPIErrorReport({
      integrationError: error,
      titleMessage: shortMsg
    });

    return this.http
      .post(this.Url, tmp, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }


  bulkUpdateIntegrationErrors(updateScope: BulkErrorUpdateScope): Observable<any> {
    //TODO - Implement new statuses
    this.actionUrl = "IntegrationErrors/Update";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, updateScope, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }


  // POST Support Send Help Desk
  sendHelpDesk(error: IntegrationError) {
    this.actionUrl = "Support/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, JSON.stringify(error), { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Integration Metrics ------------------------------------------//

  // GET Active Integration Metrics
  getActiveMetric(integrationId: string, integrationTypeId: string): Observable<IntegrationMetrics> {
    //console.log("getActiveMetric | integrationId: " + integrationId + " | integrationTypeId: " + integrationTypeId);
    this.actionUrl = "IntegrationMetrics/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      integrationId,
      "/",
      integrationTypeId
    );
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationMetrics>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration Metrics Lifetime
  getLifetimeMetrics(integrationId: string): Observable<IntegrationMetrics[]> {
    this.actionUrl = "IntegrationMetrics/Lifetime/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration Metrics All
  getAllIntegrationMetrics(integrationId: string): Observable<IntegrationMetrics[]> {
    this.actionUrl = "IntegrationMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Integration Logs ------------------------------------------//

  // GET Integration Logs
  getIntegrationLogs(integrationId: string): Observable<IntegrationLog[]> {
    this.actionUrl = "IntegrationLog/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationLog[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Error Filters ------------------------------------------//

  // GET Error Filter
  getAllCommonErrorFilters(integrationId: string): Observable<CommonErrorFilter[]> {
    this.actionUrl = "CommonErrorFilter/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<CommonErrorFilter[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Save Error Filter - ?? is update or insert ??
  saveErrorFilter(ef: CommonErrorFilter): Observable<string> {
    this.actionUrl = "CommonErrorFilter/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<string>(this.Url, JSON.stringify(ef), { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // DELETE Error Filter
  deleteErrorFilter(id: string): Observable<string> {
    this.actionUrl = "CommonErrorFilter/";
    this.Url = this.baseUrl.concat(this.actionUrl, id);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<string>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Error Status ------------------------------------------//

  // GET - Get All Error Statuses
  getAllErrorStatuses(): Observable<ErrorStatus[]> {
    this.actionUrl = "IntegrationErrorMessages/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ErrorStatus[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Loan Metrics ------------------------------------------//

  // GET Integration Metrics Lifetime
  getAllLoanMetrics(integrationId: string): Observable<LoanMetrics[]> {
    this.actionUrl = "LoanMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<LoanMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- CSV Summary ------------------------------------------//

  // GET CSV Blob File
  //getCSV(details: CsvSummary): Observable<any> {
  //  this.actionUrl = "CSVExport/";
  //  this.Url = this.baseUrl.concat(this.actionUrl);
  //  this.headers.append("Content-Type", "application/json");
  //  this.headers.append("Accept", "application/octet-stream");

  //  return this.http
  //    .post<any>(this.Url, JSON.stringify(details), { headers: this.headers })
  //    .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  //}

  //--------------------------- Error Categories ------------------------------------------//

  getAllErrorCategories(): Observable<ErrorCategory[]> {
    this.actionUrl = "ErrorCategories/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<ErrorCategory[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getErrorCategoryById(errorCategoryId: string): Observable<ErrorCategory> {
    this.actionUrl = "ErrorCategory/";
    this.Url = this.baseUrl.concat(this.actionUrl, errorCategoryId);
    return this.http
      .get<ErrorCategory>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateErrorCategory(errorCategory: ErrorCategory): Observable<any> {
    this.actionUrl = "ErrorCategory/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, errorCategory, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertErrorCategory(errorCategory: ErrorCategory): Observable<ErrorCategory> {
    this.actionUrl = "ErrorCategory/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<ErrorCategory>(this.Url, errorCategory, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
  //--------------------------- Error Categories ------------------------------------------//

  getAllCurrentStatuses(): Observable<CurrentStatus[]> {
    this.actionUrl = "CurrentStatuses/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<CurrentStatus[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
  //--------------------------- Service Errors ------------------------------------------//
  getAllServiceErrorsByServerId(serverId: string): Observable<ServiceError[]> {
    this.actionUrl = "ServiceErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl, serverId);
    return this.http
      .get<ServiceError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllServiceErrors(): Observable<ServiceError[]> {
    this.actionUrl = "ServiceErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<ServiceError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getServiceErrorById(serviceErrorId: string): Observable<ServiceError> {
    this.actionUrl = "ServiceError/";
    this.Url = this.baseUrl.concat(this.actionUrl, serviceErrorId);
    return this.http
      .get<ServiceError>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateServiceError(error: ServiceError): Observable<any> {
    this.actionUrl = "ServiceError/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, error, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertServiceError(error: ServiceError): Observable<ServiceError> {
    this.actionUrl = "ServiceError/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<ServiceError>(this.Url, error, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
