import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { ToastService } from '../../services/toast.service';
import { IconType, Toast, ToastType } from '../../models/toast';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'toast',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  //providers: [ToastService],
  encapsulation: ViewEncapsulation.None
})


export class ToastMessageComponent implements OnInit, OnDestroy {
  @Input() id = "default-toast";
  //@Input() messageToShow : string = "";
  //@Input() messageType: string = "";
  //@Input() messageDissmissable: boolean = true;
  @Input() fade: boolean = true;

  //toastService: ToastService;
  toastSub: Subscription;
  routeSub: Subscription;

  toasts: Toast[] = [];
  styleToastMap: Map<string, string>;

  constructor(private router: Router, private toastService: ToastService) { }

  ngOnInit(): void {
    this.loadStyleDict();

    this.toastSub = this.toastService.onStart(this.id)
      .subscribe(toast => {
        // console.log("ngOnInit");
        // console.log("ngOnInit | toast");
        // console.log(toast);
        // console.log("ngOnInit | this.toasts 1");
        // console.log(this.toasts);

        if (!toast.message) {
          //console.log("ngOnInit | !toast.message : " + this.toasts.length);
          this.toasts = this.toasts.filter(t => t.keepAfterRouteChange);
          this.toasts.forEach(t => delete t.keepAfterRouteChange);
          //this.toasts = [];
          return;
        }

        if (environment.production || !toast.ignoreInDevEnvironment) {
          this.toasts.push(toast);
          // console.log("ngOnInit | this.toasts after push: " + this.toasts.length);
          // console.log(this.toasts);

          if (toast.autoClose) {
            //this was commented out, but seems to work?
            setTimeout(() => this.closeToast(toast), 3000);
          }
        }
      }
    );

    this.routeSub = this.router.events.subscribe(event => {
      //console.log("this.routeSub | event | " + event);
      if (event instanceof NavigationStart) {
        //console.log("this.routeSub - NavigationStart");
        this.toastService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    this.toastSub.unsubscribe();
    this.routeSub.unsubscribe();
  }

  closeToast(toast: Toast) {
    if (!this.toasts.includes(toast)) {
      return;
    }

    if (this.fade) {
      this.toasts.find(t => t === toast).fade = true;

      setTimeout(() => {
        this.toasts = this.toasts.filter(t => t !== toast);
      }, 250);
    }
    else {
      this.toasts = this.toasts.filter(t => t !== toast)
    }
  }

  setStyle(toast: Toast) {
    if (!toast) {
      return;
    }

    var s: string[] = [];
    s.push(this.styleToastMap.get("toast-base"));
    s.push(this.styleToastMap.get(toast.toastType));

    if (toast.fade) {
      s.push(this.styleToastMap.get("can-fade"));
    }
    return s.join(' ');
  }

  setIconClass(toast: Toast) {

    switch(toast.icon)
    {
      case IconType.Warning: {
        return 'bi-exclamation-triangle-fill';
      }
      case IconType.Info: {
        return 'bi-info-circle-fill';
      }
      case IconType.Unlock: {
        return 'bi-unlock-fill';
      }
      case IconType.Lock: {
        return 'bi-lock-fill';
      }
      case IconType.Check: {
        return 'bi-check-circle-fill';
      }
      default: {
        //Defer to toast type if icon not defined
      }
    }
    
    switch(toast.toastType) {
      
      case ToastType.Warning:
      case ToastType.Danger: {
        return 'bi-exclamation-triangle-fill';
      }
      
      case ToastType.Success: {
        return 'bi-check-circle-fill';
      }

      //case ToastType.Info:
      default: {
        return 'bi-info-circle-fill';
      }

    };

  }

  loadStyleDict(): void {
    this.styleToastMap = new Map<string, string>();
    this.styleToastMap.set(ToastType.Danger, "alert-danger bg-danger text-white");
    this.styleToastMap.set(ToastType.Info, "alert-info bg-info text-white");
    this.styleToastMap.set(ToastType.Success, "alert-success bg-success text-white");
    this.styleToastMap.set(ToastType.Warning, "alert-warning bg-warning text-dark");
    this.styleToastMap.set(ToastType.Primary, "alert-primary bg-primary text-white");
    this.styleToastMap.set(ToastType.Secondary, "alert-secondary bg-secondary text-white");
    this.styleToastMap.set(ToastType.Light, "alert-light bg-light text-dark");
    this.styleToastMap.set(ToastType.Dark, "alert-dark bg-dark text-white");
    this.styleToastMap.set("toast-base", "alert alert-dismissable");
    this.styleToastMap.set("can-fade", "fade");
  }
  clearAllMessages() {
    while(this.toasts.length > 0)
    {
      this.toasts.pop();
    }
  }

}
