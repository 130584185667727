import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  appComponent: AppComponent;

  constructor(root: AppComponent) {
    this.appComponent = root;
  }

  ngOnInit(): void {
  }


  login(): void {
    this.appComponent.login();
  }

}
