<div class="container">
  <div class="row g-1">

    <!-- destination -->
    <div class="col-2 align-self-center">
      <app-destinations-dropdown-view #destinationView
        (itemSelected)="getDestinationFromView($event)"
        (loadingFailed)="setFailedLoading($event)"
        [labelName]="'Application'"
        [overrideEndpointId]="overrideDestinationId"
        (itemSelectedFromOverride)="updateDestinationFromOverride($event)">
      </app-destinations-dropdown-view>
    </div>

    <!-- contract button or contract input view -->
    <div *ngIf="selectedDestination" class="col-3 align-self-center">
      <ng-container *ngIf="!selectedContract">
        <button mat-raised-button (click)="openContractTableDialog()" class="ms-2">Select Contract</button>
      </ng-container>
      <ng-container *ngIf="selectedContract">
        <mat-form-field appearance="fill" >
          <mat-label>Client Contract</mat-label>
          <input matInput disabled
            value="{{selectedContract.clientFullName + ' - ' + selectedContract.clientShortName}}"
            matTooltip="{{selectedContract.clientFullName + ' - ' + selectedContract.clientShortName}}">
          <button mat-icon-button matSuffix
            (click)="openContractTableDialog()"
            matTooltip="Change Contract"
            [attr.aria-label]="'Change Contract'"
            [attr.aria-pressed]="change">
          <mat-icon>close</mat-icon>
        </button>
        </mat-form-field>
      </ng-container>
    </div>

    <!-- funded logic -->
    <div *ngIf="selectedDestination && selectedContract && showFundedLogic" class="col-3 text-center align-self-center pb-2">
      <div class="text-center">
        Funded Completed Date: {{completedDateLogicString}}
      </div>
    </div>

    <!-- go to metrics button -->
    <div *ngIf="selectedContract && showGoToMetrics" class="col-2 align-self-center pb-2">
      <button mat-raised-button (click)="openGoToMetrics()" class="mx-2">
        Go to Metrics
      </button>
    </div>

    <!-- go to integrations buttons -->
    <div class="col-2 align-self-center" *ngIf="selectedContract && haveIntegrations && selectedContract.integrationMappings.length > 0 && checkAccess(['LOSTalker.DevOps'])">
        <div class="row-cols-1">
          <button
              *ngFor="let i of mappedIntegrations"
              mat-raised-button
              (click)="goToIntegration(i)"
              class="mx-2 mb-1">{{i.name}}</button>
        </div>
    </div>

  </div>
</div>

<ng-template #contractTableDialog>
  <h2 matDialogTitle>Select a Client Contract:</h2>
  <mat-dialog-content>

    <app-client-contract-view #contractView
        [selectedDestination]="selectedDestination"
        (itemSelected)="saveContractDialogSelection($event)"
        [selectThisContract]="overrideContract"
        (itemSelectedFromOverride)="updateContractFromOverride($event)">
    </app-client-contract-view>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose (click)="submitContractDialogSelect()">Submit</button>
    <button mat-button matDialogClose color="primary" (click)="closeDialog()">Cancel</button>
  </mat-dialog-actions>
</ng-template>

