import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin, Subscription } from 'rxjs';
import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State, DataResult, DataSourceRequestState, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ClientIntegrationService } from '../../../services/client-integration.service';
import { ToastService } from '../../../services/toast.service';
import { ReportService } from '../../../services/report.service';
import { Report } from '../../../models/report';
import { Server } from '../../../models/server';
import { DestinationEndpoint } from '../../../models/destination-endpoint';
import { ImportType } from '../../../models/import-type';
import { DataSourceType } from '../../../models/data-source-type';
import { IntegrationType } from '../../../models/integration-type';
import { Integration } from '../../../models/integration';
import { Toast, ToastType } from '../../../models/toast';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent, RowArgs, ExcelModule, SelectionEvent } from '@progress/kendo-angular-grid';
import { IntegrationErrorMessage } from 'src/app/models/integration-error-message';
import { ErrorLoggingService } from 'src/app/services/logging.service';
import { IntegrationErrorMessageFilter } from 'src/app/models/integration-error-message-filter';
import { LOSTalkerTitleService } from '../../../services/lostalker-title-service.service';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss'],
  providers: [ClientIntegrationService, ReportService, ToastService, LOSTalkerTitleService]
})
export class ErrorReportComponent implements OnInit {
  @Input() reportId = "";
  @Input() currentReportToShow: Report;

  @ViewChild("grid") public grid: GridComponent;

  //Constants
  errorGroupSize: number = 100;
  _currReport: Report;
  type: 'numeric' | 'input' = 'input';
  errorStatuses: string[] = ["Unresolved", "InProgress", "NotImportant", "Resolved"];
  isInTab: boolean;
  excelFileName: string = "Errors.xlsx";

  //Database Filter Variables
  integrationErrorMessageFilter: IntegrationErrorMessageFilter = new IntegrationErrorMessageFilter();

  //Table Filter Variables
  statusSelectedFilter: any;
  hasStatusFilter: boolean = false;
  public statusFilter: CompositeFilterDescriptor;
  public state: DataSourceRequestState = {
    skip: 0,
    take: 10,
    sort: []
  };
  totalIntegrationErrorMessages: number;
  rowSelected: RowArgs;
  selectedError: IntegrationErrorMessage;
  gridViewIsLoading = true;
  gridView: GridDataResult;
  errorsList: IntegrationErrorMessage[];
  filter: CompositeFilterDescriptor;
  filteredGroup: IntegrationErrorMessage[];
  filteredData: IntegrationErrorMessage[];

  private serviceSubscription: Subscription;

  constructor(
    protected _clientService: ClientIntegrationService,
    protected _reportService: ReportService,
    protected _toastService: ToastService,
    protected _errorLoggingService: ErrorLoggingService,
    private location: Location,
    private router: Router,
    private titleService: LOSTalkerTitleService) {

    this.filteredData = [];

    this.state.sort = [{ field: "name", dir: 'asc' }];
    this.errorsList = [];
  }

  ngOnInit(): void {

    this.integrationErrorMessageFilter.skip = 0;
    this.integrationErrorMessageFilter.take = this.errorGroupSize;

    if (this.currentReportToShow) {
      this.titleService.setUpTitle('Report - ' + this.currentReportToShow.title);

      this._currReport = this.currentReportToShow;

      if (this._currReport.exportFileName !== ""
        && this._currReport.exportFileName !== undefined) {
        this.excelFileName = this._currReport.exportFileName;
      }
    }

    if (this._currReport.dataSproc !== "" && this._currReport.dataSproc !== undefined) {
      this.updateFilteredData(true);
    }

  }

  public updateFilteredData(newFilter: boolean) {
    this.gridViewIsLoading = true;
    this.gridView = {
      total: 0,
      data: []
    }

    this._reportService.getIntegrationErrorMessageTypeQueryData(
      this._currReport.reportId, this.integrationErrorMessageFilter).subscribe(
        (data) => {
          if(newFilter) {
            this._reportService.getIntegrationErrorMessageTypeQueryDataCount(
              this._currReport.reportId, this.integrationErrorMessageFilter).subscribe(
                (count) => {

                  this.totalIntegrationErrorMessages = count;

                  this.errorsList = data;

                  if(this.filter == null) {
                    this.loadDataIntoGridView();
                  }
                  else {
                    this.filterChange(this.filter);
                  }

                  this.gridViewIsLoading = false;
                }, error => {
                  this.gridViewIsLoading = false;
                }
              )
          }
          else {
            this.errorsList = data;

            if(this.filter == null) {
              this.loadDataIntoGridView();
            }
            else {
              this.filterChange(this.filter);
            }
            this.gridViewIsLoading = false;
          }

        },
        error => {
          this.gridViewIsLoading = false;
        }
    );
  }

  public goBack(e: Event) {
    this.location.back();
  }

  // reset filters
  public resetFilters(): void {
    this.integrationErrorMessageFilter.stackTraceContains = undefined;
    this.integrationErrorMessageFilter.skip = 0;
    this.integrationErrorMessageFilter.minDate = undefined;
    this.integrationErrorMessageFilter.maxDate = undefined;
    this.integrationErrorMessageFilter.logMessageContains = undefined;

    this.updateFilteredData(true);
  }

  // apply filters
  public applyFilters(): void {
    this.integrationErrorMessageFilter.skip = 0;
    this.updateFilteredData(true);
  }

  public pageBack(): void {
    this.integrationErrorMessageFilter.skip -= this.errorGroupSize;
    this.updateFilteredData(false);
  }

  public pageForward(): void {
    this.integrationErrorMessageFilter.skip += this.errorGroupSize;
    this.updateFilteredData(false);
  }

  public firstPage(): void {
    this.integrationErrorMessageFilter.skip = 0;
    this.updateFilteredData(false);
  }

  public lastPage(): void {
    this.integrationErrorMessageFilter.skip = Math.floor(this.totalIntegrationErrorMessages/100) * 100;
    this.updateFilteredData(false);
  }

  public clearMinDateSearch() {
    this.integrationErrorMessageFilter.minDate = undefined;
  }

  public clearMaxDateSearch() {
    this.integrationErrorMessageFilter.maxDate = undefined;
  }

  public clearStackTraceSearch() {
    this.integrationErrorMessageFilter.stackTraceContains = undefined;
  }

  public clearLogMessageSearch() {
    this.integrationErrorMessageFilter.logMessageContains = undefined;
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
  }

  expand(event: SelectionEvent) {
    if (this.rowSelected !== undefined) {
        this.grid.collapseRow(this.rowSelected.index);
    }
    this.rowSelected = event.selectedRows[0];
    this.grid.expandRow(event.selectedRows[0].index);
  }

  navigateToClient(dataItem: IntegrationErrorMessage) {
    this.router.navigate(['/editConfig', dataItem.clientName, dataItem.integrationName]);
  }

  formatDate(error: IntegrationErrorMessage): string {
    var tmp = new Date(error.receivedDate);
    tmp.setMinutes(tmp.getMinutes() - tmp.getTimezoneOffset());
    return tmp.toLocaleString();
  }

  helpDesk(error: IntegrationErrorMessage): void {
    this._errorLoggingService.sendHelpDesk(error);
  }

  updateError(error: IntegrationErrorMessage, errorStatusId: string) {
    this.selectedError = error;
    this.selectedError.errorStatusId = errorStatusId;
    if(errorStatusId == "Resolved")
    {
        this.selectedError.integrationStopped = false;
    }
    this._errorLoggingService.updateError(this.selectedError).subscribe(value => {
        this.updateFilteredData(false);
    });
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  loadDataIntoGridView() {
    this.gridView = {
        data: this.errorsList.slice(this.state.skip, this.state.skip + this.state.take),
        total: this.errorsList.length
    };
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.errorsList, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  public saveData(component: any): void {
    component.save();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.filter = filter;
    this.filteredGroup = filterBy(this.errorsList, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
        data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
        total: orderedData.length
    };
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;

    this.updateGrid();
  }

  clearStatusFilter(): void {
    this.statusFilter = undefined;
    this.hasStatusFilter = false;
    this.statusSelectedFilter = undefined;

    const root = { logic: "and", filters: [], ...this.filter };

    for(let i=0; i<root.filters.length; i++)
    {
        let filter = root.filters[i] as FilterDescriptor;
        if(filter.field == "errorStatusId")
        {
            root.filters.splice(i, 1);
            i--;
        }
    }

    this.filterChange(root);
  }

  statusFilterChange(checked: string): void {
    this.hasStatusFilter = true;

    const root = { logic: "and", filters: [], ...this.filter };

    const [filter] = flatten(root).filter((x) => x.field === "errorStatusId");

    if (!filter) {
      root.filters.push({
        field: "errorStatusId",
        operator: "eq",
        value: checked,
      });
    } else {
      filter.value = checked;
    }
    this.filterChange(root);
  }


}
