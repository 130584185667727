import { Component, OnInit, OnChanges, OnDestroy, Input, Output, ViewChild, TemplateRef, EventEmitter } from '@angular/core';
import { State, DataSourceRequestState, orderBy, CompositeFilterDescriptor, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, SelectionEvent, RowArgs, DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { Observable, Subscription, Subject } from 'rxjs';
import { takeUntil, take, map, startWith } from "rxjs/operators";
import { ToastService } from '../../services/toast.service';
import { LosTokenService } from 'src/app/services/los-token.service';
import { LOSToken } from 'src/app/models/los-token';
import { LOSTokenData } from 'src/app/models/los-token-data';
import { MatDialog } from '@angular/material/dialog';
import { Integration } from 'src/app/models/integration';
import { FormGroup } from "@angular/forms";
import { UntypedFormControl } from '@angular/forms';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';
import { MultiColumnComboBoxComponent } from '@progress/kendo-angular-dropdowns';
@Component({
  selector: 'app-los-token-mapping-view',
  templateUrl: './los-token-mapping-view.component.html',
  styleUrls: ['./los-token-mapping-view.component.scss']
})
export class LosTokenMappingViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() data: LOSToken;

  public addForm: FormGroup = new FormGroup({
    integration: new UntypedFormControl(),
  });

  @Output() mappingDataChanged = new EventEmitter<string>();

  @ViewChild('confirmDeleteDialog', { static: true }) confirmDialog: TemplateRef<any>;
  @ViewChild('confirmDeleteTokenDialog', { static: true }) confirmTokenDialog: TemplateRef<any>;
  @ViewChild('confirmDeleteAllDialog', { static: true }) confirmAllDialog: TemplateRef<any>;
  @ViewChild('addMappingDialog', { static: true }) addDialog: TemplateRef<any>;
  @ViewChild("list", {static: false})
  public list: MultiColumnComboBoxComponent;

  public itemToRemove: LOSTokenData = null;
  public itemToAdd: LOSToken = null;

  gridData: LOSTokenData[] = [];

  gettingData: boolean = false;
  hasData: boolean = false;
  gettingIntegrations: boolean = false;
  haveIntegrations: boolean = false;

  allGroup: LOSTokenData[] = [];
  filteredGroup: LOSTokenData[] = [];

  gridView: GridDataResult;
  gridViewIsLoading: boolean = true;

  type: 'numeric' | 'input' = 'input';
  public pageSizes: number[] = [10, 25, 50, 100];
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  public pageSize = 10;

  allIntegrations: Integration[] = [];
  filteredIntegrations: Integration[] = [];
  availableIntegrations: Integration[] = [];
  selectedIntegration: Integration = null;

  clientId: number = null;

  validateClientId() {
    if (this.allGroup.length > 0) {
      let cIds = this.allGroup.map(x => x.clientId);
      let unique = cIds.filter((value, index, self) => self.indexOf(value) === index);
      if (unique.length == 1 && unique[0]) {
        this.clientId = unique[0];
      }
      else {
        this.toastService.toastCreate("Multiple Client Ids found for token.", "Warning");
      }
    }
  }

  constructor(
    private toastService: ToastService,
    private lTService: LosTokenService,
    private clientService: ClientIntegrationService,
    private dialog: MatDialog
  ) {
    this.state.sort = [{field: 'integrationName', dir: 'desc'}];
    this.filteredGroup = [];
  }

  ngOnInit(): void {
    if (this.data && this.data.losTokenId != "") {
      this.getMappings();
    }
    else {
      this.gridViewIsLoading = false;
      this.toastService.toastCreate("Passed in LOSToken or LOSTokenId is null.", "Warning");
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getMappings(): void {
    this.gettingData = true;
    this.allGroup = [];
    this.filteredGroup = [];

    this.lTService.getLOSTokenMappingDataForTokenIdWithoutTokenValue(this.data.losTokenId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (data) => {

        if (data != null && data.length > 0) {
          data.forEach(x => {
            this.allGroup.push(new LOSTokenData(x));
          });
          this.filteredGroup = this.allGroup;

          // sort and setup grid view
          let orderedData = orderBy(this.allGroup, this.state.sort);
          this.gridView = {
            data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
            total: orderedData.length
          };
          this.gridViewIsLoading = false;

          this.validateClientId();
        }
        else {
          this.gridView = { data: [], total: 0};
          this.gridViewIsLoading = false;
        }

      }
    );
  }

  reloadData(parentReload: boolean = false): void {
    this.selectedIntegration = null;
    this.itemToAdd = null;
    this.itemToRemove = null;
    if (parentReload == true) {
      this.mappingDataChanged.emit(this.data.losTokenId);
    }
    else {
      this.getMappings();
    }
  }

  //--------------------------- Dialog Functions ------------------------------------------//
  closeDialog(): void {
    this.dialog.closeAll();
  }

  //--------------------------- DELETE ------------------------------------------//

  startDeleteAll() {
    this.itemToRemove = new LOSTokenData(this.data);
    this.dialog.open(this.confirmAllDialog);
  }

  confirmDelete(x?: LOSTokenData) {
    this.itemToRemove = x;
    this.dialog.open(this.confirmDialog);
  }

  checkDeleteMapping() {
    this.dialog.closeAll();
    if (this.allGroup.length <= 1) {
      this.dialog.open(this.confirmTokenDialog);
    }
    else {
      this.deleteMapping();
    }
  }

  private deleteMapping() {
    if (this.itemToRemove != null && this.itemToRemove.losTokenMappingId != -1) {

      this.lTService.deleteLOSTokenMapping(this.itemToRemove.losTokenMappingId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (value) => {
          // success
          this.toastService.toastCreate("Successfully deleted mapping", "Success", {
            autoClose: false
          });
          this.reloadData();
        },
        (error) => {
          this.toastService.toastCreate("Error deleting mapping", "Warning", {
            autoClose: false
          });
        }
      );
    }
  }

  deleteToken() {
    this.dialog.closeAll();
    if (this.itemToRemove != null) {

      this.lTService.deleteLOSToken(this.itemToRemove)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (value) => {
          this.toastService.toastCreate("Successfully deleted token and mapping", "Success", {
            autoClose: false
          });
          this.reloadData(true);
        },
        (error) => {
          this.toastService.toastCreate("Error deleting token and mapping", "Warning", {
            autoClose: false
          });
        }
      );
    }
  }

  //--------------------------- INSERT ------------------------------------------//

  startAdd() {
    this.itemToAdd = null;
    this.selectedIntegration = null;
    this.getIntegrationsForClient();
    this.dialog.open(this.addDialog);
  }

  addNewMapping() {
    this.dialog.closeAll();
    if (this.selectedIntegration != null) {
      this.itemToAdd = new LOSToken(this.data);
      this.itemToAdd.integrationId = this.selectedIntegration.integrationId;
      this.lTService.insertLOSTokenMapping(this.itemToAdd)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          // success
          this.toastService.toastCreate("Successfully added mapping.", "Success", {
            autoClose: false
          });
          this.reloadData();
        },
        (error) => {
          this.toastService.toastCreate("Error adding mapping.", "Warning", {
            autoClose: false
          });
        }
      );
    }
  }

  //--------------------------- Integration Functions ------------------------------------------//
  getIntegrationsForClient(): void {
    this.gettingIntegrations = true;
    if (this.clientId != null) {
      this.clientService.getIntegrationsByClientId(this.clientId, true)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            this.haveIntegrations = true;
            this.gettingIntegrations = false;

            // filter by data source but group togerher encompass and encompass api
            if (this.data.dataSourceTypeId == "DynamicEncompass" || this.data.dataSourceTypeId == "DynamicEncompassAPI") {
              this.allIntegrations = data.filter(x => x.dataSourceTypeId == "DynamicEncompass" || x.dataSourceTypeId == "DynamicEncompassAPI");
            }
            else {
              this.allIntegrations = data.filter(x => x.dataSourceTypeId == this.data.dataSourceTypeId);
            }

            // filter out integrations that are already linked to the token
            let activeIntegrationIds = this.allGroup.map(x => x.integrationId);
            this.availableIntegrations = this.allIntegrations.filter(
              x => activeIntegrationIds.indexOf(x.integrationId) == -1
            );
          }
        },
        (error) => {
          this.gettingIntegrations = false;
          this.toastService.toastCreate("Error getting integrations.", "Warning", {
            autoClose: false
          });
        }
      );
    }

  }

  public handleFilterChange(searchTerm: string): void {
    const normalizedQuery = searchTerm.toLowerCase();

    // search in all three fields diplayed in the popup table
    const filterExpression = (i: Integration) =>
      i.dataSourceTypeId.toLowerCase().includes(normalizedQuery) ||
      i.name.toLowerCase().includes(normalizedQuery) ||
      i.integrationId.toLowerCase().includes(normalizedQuery);

    this.availableIntegrations = this.availableIntegrations.filter(filterExpression);
  }


  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  sortChange(sort: SortDescriptor[]) {
    this.state.sort = sort;
    this.updateGrid();
  }




}
