<ng-container *ngIf="isInTab">
  <h3 class="title-center pt-2">Add Integration Identifier</h3>
  <div class="pb-2 ps-2" style="float: right;">
    <button mat-raised-button (click)="toggleCancelEditIdentifier()">Cancel</button>
  </div>
</ng-container>

<ng-container *ngIf="!isInTab">
  <h1 class="title-center pb-2">Add Integration Identifier</h1>
</ng-container>

<div *ngIf="isInTab && inAddMode && integration">
  <div *ngIf="!destination">
    <mat-form-field appearance="fill" class="med-wide-text-box">
      <mat-label>Destination</mat-label>
      <input type="text" matInput [matAutocomplete]="auto" [formControl]="destinationFormControl">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
        (optionSelected)="selectDestination($event.option.value)" [displayWith]="displayFnDestination">
        <mat-option *ngFor="let option of filteredDestinations | async" [value]="option">
          {{option.endpointName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div *ngIf="destination" class="flexWrapTabDest">
    <h5>Destination: {{destination.endpointName}}</h5>
    <div class="pb-2 pl-2">
      <button mat-raised-button (click)="editDestination()">Change</button>
    </div>
  </div>
</div>

<div class="flexWrap">

  <div class="flexLeftCol" *ngIf="!isInTab">
    <ng-container *ngIf="!client">
      <mat-form-field appearance="fill" class="med-wide-text-box">
        <mat-label>Client</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" [formControl]="clientFormControl">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
          (optionSelected)="selectClient($event.option.value)" [displayWith]="displayFnClient">
          <mat-option *ngFor="let option of filteredClients | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <!-- <ng-container *ngIf="hasClient && !isInTab"> -->
    <ng-container *ngIf="client && !isInTab">
      <div class="flexFormLine">
        <ng-container *ngIf="isInTab">
          <h4>Client: {{client.name}}</h4>
        </ng-container>

        <ng-container *ngIf="!isInTab">
          <h2>Client: {{client.name}}</h2>
        </ng-container>

        <div class="pb-2">
          <button mat-raised-button (click)="editClient()">Change</button>
        </div>
      </div>
      <div *ngIf="!hasIntegration">
        <mat-form-field appearance="fill" class="med-wide-text-box">
          <mat-label>Integration</mat-label>
          <input type="text" matInput [matAutocomplete]="auto" [formControl]="integrationFormControl">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
            (optionSelected)="selectIntegration($event.option.value)" [displayWith]="displayFnIntegration">
            <mat-option *ngFor="let option of filteredIntegrations | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </ng-container>

    <div *ngIf="hasIntegration && !isInTab" class="flexFormLine">
      <h5>Integration: {{integration.name}}</h5>
      <div class="pb-2">
        <button mat-raised-button (click)="editIntegration()">Change</button>
      </div>
    </div>

    <div *ngIf="hasIntegration && !hasDestination">
      <mat-form-field appearance="fill" class="med-wide-text-box">
        <mat-label>Destination</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" [formControl]="destinationFormControl">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
          (optionSelected)="selectDestination($event.option.value)" [displayWith]="displayFnDestination">
          <mat-option *ngFor="let option of filteredDestinations | async" [value]="option">
            {{option.endpointName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div *ngIf="hasIntegration && hasDestination" class="flexFormLine">
      <h5>Destination: {{destination.endpointName}}</h5>
      <div class="pb-2">
        <button mat-raised-button (click)="editDestination()">Change</button>
      </div>
    </div>
  </div>

  <div [ngClass]="{'flexRightCol': !isInTab, 'flexWrapTabInner': isInTab}" *ngIf="destination">
    <div class="flexCol" [ngClass]="{'flexLeftColTab': isInTab}">
      <ng-container *ngIf="editingIdentifier && selectedIdentifier">

        <!-- <div class="pb-2" *ngIf="hasIdentifier && !editing"> -->
        <!-- <div class="pb-2" *ngIf="identifiersExist && !editing"> -->
        <div class="pb-2" *ngIf="hasIdentifier && !editing && !inAddMode">
          <h6 class="pb-2">Identifier Id: {{selectedIdentifier.identifierIdValue}}</h6>
        </div>
        <div class="pb-2" *ngIf="editing || inAddMode">
          <!-- <h6 *ngIf="!isInTab || (isInTab && !inAddMode)">Identifier Id: {{selectedIdentifier.identifierIdValue}}</h6> -->
          <h6 *ngIf="!inAddMode">Identifier Id: {{selectedIdentifier.identifierIdValue}}</h6>
          <mat-form-field appearance="fill" hintLabel="CompanyId, OrgId, ..." class="med-wide-text-box">
            <mat-label>Identifier Id</mat-label>
            <input matInput [(ngModel)]="editingIdentifier.identifierIdValue" name="editingIdentifier.identifierIdValue"
              id="editingIdentifier.identifierIdValue" maxlength="200" #identifierIdVal>
            <mat-hint align="end">{{identifierIdVal.value?.length || 0}}/200</mat-hint>
          </mat-form-field>
        </div>

        <div class="pb-2" *ngIf="hasIdentifier && !editing && !inAddMode">
          <h6 class="pb-2">Identifier Name: {{selectedIdentifier.identifierNameValue}}</h6>
        </div>
        <div class="pb-2" *ngIf="editing || inAddMode">
          <h6 *ngIf="!inAddMode">Identifier Name: {{selectedIdentifier.identifierNameValue}}</h6>
          <mat-form-field appearance="fill" hintLabel="LimeGearCompany, CSDataSwapCompany, MappingTag, ..."
            class="med-wide-text-box">
            <mat-label>Identifier Name</mat-label>
            <input matInput [(ngModel)]="editingIdentifier.identifierNameValue"
              name="editingIdentifier.identifierNameValue" id="editingIdentifier.identifierNameValue" maxlength="200"
              #identifierNameVal>
            <mat-hint align="end">{{identifierNameVal.value?.length || 0}}/200</mat-hint>
          </mat-form-field>
        </div>

        <div class="pb-2" *ngIf="hasIdentifier && !editing && !inAddMode">
          <h6 class="pb-2">Identifier Key: {{selectedIdentifier.identifierKeyValue}}</h6>
        </div>
        <div class="pb-2" *ngIf="editing || inAddMode">
          <h6 *ngIf="!inAddMode">Identifier Key: {{selectedIdentifier.identifierKeyValue}}</h6>
          <mat-form-field appearance="fill" hintLabel="LimeGearKey, CSDataSwapKey, ApiKey, ..."
            class="med-wide-text-box">
            <mat-label>Identifier Key</mat-label>
            <input matInput [(ngModel)]="editingIdentifier.identifierKeyValue"
              name="editingIdentifier.identifierKeyValue" id="editingIdentifier.identifierKeyValue" maxlength="200"
              #identifierKeyVal>
            <mat-hint align="end">{{identifierKeyVal.value?.length || 0}}/200</mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
    <div class="flexCol" [ngClass]="{'flexRightColTab': isInTab}">

      <ng-container *ngIf="editingIdentifier && selectedIdentifier">
        <div class="pb-2" *ngIf="hasIdentifier && !editing && !inAddMode">
          <mat-checkbox [checked]="editingIdentifier.canFastFetch" [disabled]="true" class="checkbox-padding">
            Identifier can be used to Fast Fetch loans 2.
          </mat-checkbox>
        </div>
        <div class="pb-2" *ngIf="editing || inAddMode">
          <mat-checkbox [checked]="editingIdentifier.canFastFetch" (change)="changeCanFastFetch($event.checked)"
            class="checkbox-padding">
            Identifier can be used to Fast Fetch loans.
          </mat-checkbox>
        </div>
        <div class="pb-2" *ngIf="hasIdentifier && !editing && !inAddMode">
          <mat-checkbox [checked]="editingIdentifier.canDataSwap" [disabled]="true" class="checkbox-padding">
            Identifier can be used for Data Swap functions 2.
          </mat-checkbox>
        </div>
        <div class="pb-2" *ngIf="editing || inAddMode">
          <mat-checkbox [checked]="editingIdentifier.canDataSwap" (change)="changeCanDataSwap($event.checked)"
            class="checkbox-padding">
            Identifier can be used for Data Swap functions.
          </mat-checkbox>
        </div>
        <div class="pb-2" *ngIf="hasIdentifier && !editing">
          <h6 class="pb-2">Comments: {{selectedIdentifier.comments}}</h6>
        </div>
        <div class="pb-2" *ngIf="editing || inAddMode">
          <h6 *ngIf="!isInTab || (isInTab && !inAddMode)">Comments: {{selectedIdentifier.comments}}</h6>
          <mat-form-field appearance="fill" hintLabel="Note for identifier" class="med-wide-text-box">
            <mat-label>Comments</mat-label>
            <input matInput [(ngModel)]="editingIdentifier.comments" name="editingIdentifier.comments"
              id="editingIdentifier.comments" maxlength="150" #identifierComments>
            <mat-hint align="end">{{identifierComments.value?.length || 0}}/150</mat-hint>
          </mat-form-field>
        </div>
        <h6 class="pb-2" *ngIf="hasIdentifier && !isInTab && !editing">Created Date: {{selectedIdentifier.createdDate}}
        </h6>
        <h6 class="pb-2" *ngIf="hasIdentifier && !isInTab && !editing">Updated Date: {{selectedIdentifier.updatedDate}}
        </h6>

        <div class="form-buttons-row">
          <button mat-raised-button *ngIf="identifiersExist && inAddMode"
            (click)="insertIntegrationIdentifier()" [disabled]="savingIdentifier">Create</button>
          <button mat-raised-button *ngIf="hasIdentifier && !editing && !inAddMode"
            (click)="toggleEditIdentifier()">Edit</button>
          <button mat-raised-button *ngIf="hasIdentifier && !isInTab && editing"
            (click)="toggleCancelEditIdentifier()">Cancel</button>
          <button mat-raised-button *ngIf="hasIdentifier && isInTab && !inAddMode"
            (click)="toggleCancelEditIdentifier()">Cancel</button>
          <button mat-raised-button *ngIf="hasIdentifier && editing"
            (click)="updateIntegrationIdentifier()">Submit</button>
          
          <div class="k-i-loading" *ngIf="savingIdentifier"></div>
        </div>
      </ng-container>
    </div>
  </div>

</div>

<div style="margin: auto; margin-top:0%; text-align: center;" *ngIf="pageLoading">
  <div class="k-i-loading"></div>
</div> 

<div style="margin: auto; margin-top:0%; text-align: center;" *ngIf="!pageLoading && !destinationList">
  <div class="no-records-message">Loading failed.</div>
</div> 