export class SearchTerm {
  searchValue: string;
  currentSearchValue: string;
  hasSearchTerm: boolean;

  defaultText: string = '';
  searchField: string;
  searchList: Array<any>;

  constructor(field: string = '', placeholder: string = 'Search...' ) {
    this.searchValue = '';
    this.currentSearchValue = '';
    this.searchField = field;
    this.defaultText = placeholder;
    this.hasSearchTerm = false;
  }

  public clear() {
    this.searchValue = "";
    this.currentSearchValue = "";
    this.hasSearchTerm = false;
  }

  public save() {
    if (this.searchValue != null || this.searchValue != "") {
      this.currentSearchValue = this.searchValue;
      this.searchValue = "";
      this.hasSearchTerm = true;
    }
    else {
      this.searchValue = "";
      this.currentSearchValue = "";
      this.hasSearchTerm = false;
    }
  }

  public setSearchList(newList: Array<any>) {
    this.searchList = newList;
  }

  public searchInList(newList?: Array<any>) {
    if (newList != null) {
      this.searchList = newList;
    }

    return this.searchList
      .filter(x =>
        x[this.searchField].toLowerCase()
          .indexOf(this.currentSearchValue.toLowerCase()
          ) !== -1);
  }
}
