import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';
const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const day = today.getDate();

@Component({
  selector: 'app-date-range-view',
  templateUrl: './date-range-view.component.html',
  styleUrls: ['./date-range-view.component.scss']
})
export class DateRangeViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  @Input() monthYearOnly: boolean = false;
  @Input() showThisMonthButton: boolean = true;
  @Input() dateStringPrefix: string = "";
  @Output() updatedRange = new EventEmitter<[Date,Date]>();
  @Input() initDateRange: [Date,Date] = null;

  hasDateRangeSelected: boolean = false;

  currentStartDate: Date = null;
  currentEndDate: Date = null;

  // loan
  public range = new FormGroup({
    start: new FormControl<Date | null>(new Date(year, month, 1)),
    end: new FormControl<Date | null>(new Date(year, month, day)),
  });

  // metric
  public metricRange = new FormGroup({
    start: new FormControl<Date | null>(new Date(year, month-1, 1)),
    end: new FormControl<Date | null>(new Date(year, month, 0)),
  });

  constructor(protected toastService: ToastService) { }

  ngOnInit(): void {
    this.currentEndDate = null;
    this.currentStartDate = null;
    this.hasDateRangeSelected = false;

    // loan
    this.range.setValue({
      start: new Date(year, month, 1),
      end: new Date(year, month, day),
    });

    // metric
    this.metricRange.setValue({
      start: new Date(year, month-1, 1),
      end: new Date(year, month, 0),
    });

    if (this.initDateRange != null && this.initDateRange != undefined) {
      this.sendDateRange(this.initDateRange[0], this.initDateRange[1]);

      // test if this sends output twice

      if (this.monthYearOnly) {
        this.metricRange.setValue({
          start: this.initDateRange[0],
          end: this.initDateRange[1],
        });
      }
      else {
        this.range.setValue({
          start: this.initDateRange[0],
          end: this.initDateRange[1],
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  clear(): void {
    this.currentEndDate = null;
    this.currentStartDate = null;
    this.hasDateRangeSelected = false;

    // loan
    this.range.setValue({
      start: new Date(year, month, 1),
      end: new Date(year, month, day),
    });

    // metric
    this.metricRange.setValue({
      start: new Date(year, month-1, 1),
      end: new Date(year, month, 0),
    });
  }

  submitDateRange(event: any) {
    this.hasDateRangeSelected = false;
    var startDate: Date = null;
    let endDate: Date = null;

    if (this.monthYearOnly) {
      if (this.metricRange.get('start').status == 'VALID') {
        startDate = new Date(this.metricRange.get('start').value);

      }

      if (this.metricRange.get('end').status == 'VALID') {
        endDate = new Date(this.metricRange.get('end').value);
      }
    }
    else {
      if (this.range.get('start').status == 'VALID') {
        startDate = new Date(this.range.get('start').value);
      }

      if (this.range.get('end').status == 'VALID') {
        endDate = new Date(this.range.get('end').value);
      }
    }

    if (startDate == null || endDate == null) {
      this.toastService.toastCreate("Date range invalid", "Warning");
    }
    else {
      this.sendDateRange(startDate, endDate);
    }
  }

  sendDateRange(start: Date, end: Date): void {
    this.currentStartDate = start;
    this.currentEndDate = end;
    this.hasDateRangeSelected = true;
    this.updatedRange.emit([this.currentStartDate, this.currentEndDate]);
  }

  submitDefaultRange(event: any) {
    this.hasDateRangeSelected = false;
    var startDate: Date = null;
    var lastDayOfLastMonth: Date = new Date(today.getFullYear(), today.getMonth(), 0);

    if (event == 'yearToDate') {
      startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
      this.sendDateRange(startDate, today);
    }
    else if (event == 'thisYear') {
      startDate = new Date(new Date().getUTCFullYear(), 0, 1, 1);
      this.sendDateRange(startDate, today);
    }
    else if (event == 'past3Months') {
      if (this.monthYearOnly) {
        startDate = new Date(today.getFullYear(), today.getMonth() - 4, 1);
        this.sendDateRange(startDate, lastDayOfLastMonth);
      }
      else {
        startDate = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        this.sendDateRange(startDate, today);
      }
    }
    else if (event == 'lastMonth') {
      if (this.monthYearOnly) {
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        this.sendDateRange(startDate, lastDayOfLastMonth);
      }
      else {
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        this.sendDateRange(startDate, today);
      }
    }
    else if (event == 'thisMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      var endDate: Date = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      this.sendDateRange(startDate, endDate);
    }
  }

}
