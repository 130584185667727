<!-- <div class="container" style="height: 500px"> -->
<div class="container d-grid gap-3 p-2">
  <h1 class="text-center my-2 py-2">LOS Tokens and Mappings</h1>

  <div class="grid-container">

    <kendo-grid
      [loading]="gridViewIsLoading"
      [data]="gridView"
      [scrollable]="'scrollable'"
      [resizable]="true"
      [sortable]="true"
      [sort]="state.sort"
      [pageable]="true"
      [pageSize]="state.take"
      [skip]="state.skip"
      [filterable]="true"
      [filter]="state.filter"
      [selectable]="true"
      [kendoGridExpandDetailsBy]="expandDetailsBy"
      [(expandedDetailKeys)]="expandedDetailKeys"
      (selectionChange)="selectionChange($event)"
      (sortChange)="sortChange($event)"
      (pageChange)="pageChange($event)"
      (filterChange)="filterChange($event)">

      <kendo-grid-column field="dataSourceTypeId" title="DataSourceType" [width]="250"></kendo-grid-column>
      <kendo-grid-column field="losServerName" title="LOSServerName"></kendo-grid-column>
      <kendo-grid-column field="losUserName" title="LOSUserName"></kendo-grid-column>
      <kendo-grid-column field="tokenCreatedDate" title="Token Created Date" [width]="250"
          format="MM/dd/yyyy h:mm a"
          [filterable]="false"></kendo-grid-column>

      <ng-template kendoGridDetailTemplate let-dataItem>
        <app-los-token-mapping-view
          [data]="dataItem"
          (mappingDataChanged)="reloadActiveToken($event)">
        </app-los-token-mapping-view>
      </ng-template>

      <ng-template kendoGridNoRecordsTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
        </div>
      </ng-template>

      <ng-template kendoGridViewLoadingTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading"></div>
        </div>
      </ng-template>

      <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  </div>
</div>
