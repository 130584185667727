<h1 style="text-align:center;">LOS Talker Clients</h1>
<br />

<ng-container>
  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="{{aliasSearchTerm.defaultText}}" [(ngModel)]="aliasSearchTerm.searchValue" (keydown.enter)="updateSearch(aliasSearchTerm)" (input)="updateSearch(aliasSearchTerm)">
          <div class="input-group-append">
            <button mat-stroked-button (click)="updateSearch(aliasSearchTerm)" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">search</mat-icon>
            </button>
            <button mat-stroked-button (click)="clearSearch(aliasSearchTerm)" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="aliasSearchTerm.hasSearchTerm" class="card-footer card-current-search">
        Current Search: {{aliasSearchTerm.currentSearchValue | json}}
      </div>
    </div>
  </div>
</ng-container>

<kendo-grid [data]="gridView"
            [pageSize]="state.take"
            [skip]="state.skip"
            [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
            [sortable]="{mode: 'single',allowUnsort: false}"
            [sort]="state.sort"
            [scrollable]="'scrollable'"
            [selectable]="true"
            [filter]="filter"
            [filterable]="true"
            (sortChange)="sortChange($event)"
            (selectionChange)="selectionChangeHandler($event)"
            (pageChange)="pageChange($event)"
            (filterChange)="filterChange($event)">

  <kendo-grid-column field="clientId" title="Client ID" [width]="100">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="name" title="Name" [width]="300" id="colID">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <!-- <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell> -->
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="dataSourceTypeAgg" title="Data Source Types" [width]="400" >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column field="compensafeInternalName" title="Short Name" [width]="100">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="aliases" title="Aliases" [width]="200">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <!-- <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell> -->
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="clientId" title="Go To" [width]="100" [filterable]="false" [sortable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [routerLink]="['/editConfig', dataItem.name]" target="_blank">
        <button mat-flat-button color="primary" (click)="stopPropagation($event)">Navigate</button>
      </a>
    </ng-template>
  </kendo-grid-column>


  <ng-template kendoPagerTemplate>
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes [pageSizes]="[5, 10, 25, 50, 100]"></kendo-pager-page-sizes>
  </ng-template>

  <ng-template kendoGridNoRecordsTemplate>
    <div class="empty_spacing_for_norecords">
      <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && gridView">No records found.</div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridView">Loading failed.</div>
    </div>
  </ng-template>

</kendo-grid>
