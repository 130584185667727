<div class="page-half">
  <div id="marketing-info">
    <div id="los-info">
      <h1>Welcome to the LOS Talker configuration Portal</h1>
      <span>LOS Talker works with several industry-leading Loan Origination Systems</span>
      <ul>
  
        <li>ICE Technology: Encompass SDK\API</li>
        <li>Byte Software:  BytePro API\SQL</li>
        <li>Calyx Software: CalyxPoint CSV flat file</li>
        <li>Calyx Software: CalyxPath CSV flat file</li>
        <li>MeridianLink: LendingQB API</li>
        <li>FISERV: PCLender\MortgageDirector API</li>
        <li>OpenClose: OpenClose CSV flat file</li>
        <li>Integra: EPIC API</li>
        <li>Finastra: MortgageBot SQL</li>
  
      </ul>
    </div>
    <!-- <div id="sn-info">
      <span>
        SimpleNexus builds software that aim to maintain accuracy and cut costs for its subscribers.
        LOS Talker focuses on opening communication from the LOS system to integrate throughout your company's other
        portals such as Surefire and our own software <a href="https://www.simplenexus.com/compensafe/">Compensafe</a>.
      </span>
    </div>
    <div id="config-portal-info">
      <span>
        LOS Talker is fully customizable in respect to the data that you want to integrate.
        This portal will allow you to customize:
      </span>
      <ul>
        <li>The custom fields from your Loan Origination System.</li>
        <li>The channels to include.</li>
        <li>The relevant loan folders that need to be sent.</li>
        <li>Include or exclude specific loan officers.</li>
        <li>Convert the data so that it is accepted by the receiving software.</li>
        <li>You can monitor the integrations to see where a loan went missing in the integration pipeline.</li>
      </ul>
    </div> -->
    <div id="links">
      <span>Important links and references</span>
      <ul>
        <li><a href="https://lbaware.atlassian.net/wiki/spaces/SWIT/overview">Confluence Page</a></li>
      </ul>
    </div>
  </div>

  <span>For SN team members that have signed in through your Office 365 account for the first time:</span>
  <ul>
    <li>You have read-only access. Please submit a helpDesk ticket if you need additional security rights.</li>
  </ul>

</div>
<div class="page-half" *ngIf="this.appComponent.loggedIn">
  <img class="diagram" src="..\..\..\assets\images\LOS_Talker_for_Technology_Vendor_Client.jpeg" />
</div>