<h1 class="title-center my-2">Change Logs Report</h1>

<div class="match-form-field title-center my-2">
  <mat-button-toggle-group
      #group="matButtonToggleGroup"
      [multiple]="false"
      [hideSingleSelectionIndicator]="true"
      [disabled]="gettingData"
      (change)="switchChangeLogReportType($event.value)">
      <mat-button-toggle
        [checked]="byIntegration == false"
        value="date"
        aria-label="By Date">
        By Date
      </mat-button-toggle>
      <mat-button-toggle
        [checked]="byIntegration == true"
        value="integration"
        aria-label="By Integration">
        By Integration
      </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="row row-cols-1 row-cols-md-2 row-cols-sm-2 pb-3 g-3">

  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Min Date</span>
          </div>
          <input type="date" class="form-control" [formControl]="minDateFormControl" (ngModelChange)="updateDate()" max="{{maxDateFormControl.value}}">
          <div class="input-group-append">
            <button mat-stroked-button (click)="clearMinDateSearch()" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">clear</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Max Date</span>
          </div>
          <input type="date" class="form-control" [formControl]="maxDateFormControl" (ngModelChange)="updateDate()" min="{{minDateFormControl.value}}" max="{{maxDate | date:'yyyy-MM-dd'}}">
          <div class="input-group-append">
            <button mat-stroked-button (click)="clearMaxDateSearch()" class="px-1 search-icon-btn">
              <mat-icon class="material-icons-outlined search-icon">clear</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-container *ngIf="byIntegration">
  <ng-container *ngIf="!client">
    <ng-container *ngIf="!gettingClients"> 
      <mat-form-field appearance="fill" class="med-wide-text-box" style="width:300px">
        <mat-label>Client</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" [formControl]="clientFormControl" >
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
          (optionSelected)="selectClient($event.option.value)" [displayWith]="displayFnClient">
          <mat-option *ngFor="let option of filteredClients | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="gettingClients"> 
      <div class="empty_spacing_for_norecords">
        <div class="k-i-loading"></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="client">
    <div class="flexFormLine">
      <h4>Client: {{client.name}}  |  <button mat-raised-button (click)="editClient()">Change</button></h4>
    </div>
    <div *ngIf="!integration">
      <ng-container *ngIf="!gettingIntegrations"> 
        <mat-form-field appearance="fill" class="med-wide-text-box" style="width:300px">
          <mat-label>Integration</mat-label>
          <input type="text" matInput [matAutocomplete]="auto" [formControl]="integrationFormControl">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
            (optionSelected)="selectIntegration($event.option.value)" [displayWith]="displayFnIntegration">
            <mat-option *ngFor="let option of filteredIntegrations | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="gettingIntegrations"> 
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div *ngIf="integration" class="flexFormLine">
    <h4>Integration: {{integration.name}}  |  <button mat-raised-button (click)="editIntegration()">Change</button></h4>
  </div>

</ng-container>


<ng-container *ngIf="integration || !byIntegration">
  <ng-container *ngIf="haveData">
    <div class="data-grid-container">
      <app-view-generic-grid #genericGrid
      [data]="filteredData"
      [columns]="reportColumns"
      [exportFileName]="'ChangeLogs.xlsx'"
      [isResizeable]="true"
      [showExportToolbar]="true"
      [loading]="gettingData"
      [enableAutoFit]="true"
      >
      </app-view-generic-grid>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!haveData && gettingData">
    <div class="empty_spacing_for_norecords">
      <div class="k-i-loading"></div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!haveData && !gettingData">
    <div class="empty_spacing_for_norecords">
      <div class="no-records-message">Loading failed.</div>
    </div>
  </ng-container>
</ng-container>
