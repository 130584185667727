<ng-container *ngIf="isInTab == false">
  <h1 style="text-align:center;">Push Back</h1>
</ng-container>
<ng-container *ngIf="isInTab == true">
  <h4 style="text-align:center;">Push Back</h4>
</ng-container>
<div class="inline" *ngIf="integrationRunTimes && integrationRunTimes.length == 0">
  <h1 style="text-align:center;">No Run Times</h1>
</div>
<div *ngIf="addingIntegrationPushBack == false">

  <div *ngIf="selectedRunTime">
    <button mat-flat-button class="inline add-pushback-btn" style="background-color: #ff9933; color: white;" *ngIf="!hasActivePushBack"
      (click)="setAddingIntegrationPushBack()" [disabled]="savingIntegrationRuntime">Add Push Back</button>
    <div id="kendo-identifier-grid" style="padding: 10px;">
      <kendo-grid [data]="gridView" [reorderable]="false" [pageSize]="state.take" [skip]="state.skip" [sortable]="false"
        [pageable]="true" [scrollable]="'scrollable'" [filterable]="false" [selectable]="true"
        (selectionChange)="selectionChange($event)" (dataStateChange)="dataStateChange($event)"
        (edit)="editHandler($event)" (add)="addHandler($event)">
        <kendo-grid-column field="integrationRunTimeId" title="RunTime" [width]="180" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{integrationRunTimesDictionary[dataItem.integrationRunTimeId].importTypeDescription}} - {{integrationRunTimesDictionary[dataItem.integrationRunTimeId].destinationEndpointName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="pushBackLastRunTimeDate" title="Last Run Time" [width]="180" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.pushBackLastRunTimeDate | date:'yyyy-MM-dd hh:mm:ss a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="triggerDate" title="Trigger Date" [width]="180" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.triggerDate | date:'yyyy-MM-dd hh:mm:ss a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="actualStartDate" title="Start Date" [width]="180" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.actualStartDate | date:'yyyy-MM-dd hh:mm:ss a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="pushBackCompleted" title="Completed Date" [width]="180" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.pushBackCompleted | date:'yyyy-MM-dd hh:mm:ss a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="active" title="Active" [width]="70" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.active.toString() | uppercase}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="jiraStoryURL" title="Jira Story URL" [width]="180" [filterable]="false">
        </kendo-grid-column>
        <kendo-grid-column field="comment" title="Comment" [width]="180" [filterable]="false">
        </kendo-grid-column>
        <kendo-grid-command-column *ngIf="checkAccess('Admin')" title="Delete" [width]="100" [sticky]="true">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button mat-stroked-button *ngIf="checkAccess(['LOSTalker.Admin']) && dataItem.active" [disabled]="savingIntegrationRuntime" style="float:right; background-color: #d40404; color: #FFFFFF"
            (click)="setDeletingIntegrationRunTime(dataItem)">Delete</button>
          </ng-template>
        </kendo-grid-command-column>
  
        <ng-template kendoGridNoRecordsTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
            <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
          </div>
        </ng-template>
  
        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-info></kendo-pager-info>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
        </ng-template>
  
      </kendo-grid>
    </div>
  </div>
</div>

<div *ngIf="addingIntegrationPushBack == true">
  <button mat-stroked-button *ngIf="integrationPushBacks.length != 0" class="inline" style="background-color: #ff9933; color: white;"
    (click)="goBack()">Back</button>

  <br />
  <br />

  <mat-form-field appearance="fill">
    <mat-label>Integration Run Time</mat-label>
    <mat-select (selectionChange)="selectIntegrationRunTime($event)">
      <mat-option *ngFor="let r of integrationRunTimes" [value]="r">
        {{r.importTypeDescription}} - {{r.destinationEndpointName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />

  <mat-form-field appearance="fill" class="med-wide-text-box">
    <mat-label>Comments</mat-label>
    <input matInput [(ngModel)]="commentInput" name="commentInput"
      id="commentInput" maxlength="150" #identifierComments>
  </mat-form-field>
  <br />

  <mat-form-field appearance="fill" class="med-wide-text-box">
    <mat-label>Jira Story URL</mat-label>
    <input matInput [(ngModel)]="jiraStoryURLInput" name="jiraStoryURLInput"
      id="jiraStoryURLInput" maxlength="150" #identifierJiraStoryURL>
  </mat-form-field>

  <div *ngIf="checkAccess(['LOSTalker.Admin'])">
    <br />
    <br />
    <p><b>Date for LastRunTime to be set to for PushBack: </b></p>
    <kendo-datetimepicker style="width: 210px" placeholder="Enter a date and time" format="MM/dd/yyyy hh:mm:ss a"
      [(value)]="pushBackLastRunTimeDateInput">
    </kendo-datetimepicker>
    <br />
    <br />
    <p><b>Date for Integration to start PushBack: </b></p>
    <kendo-datetimepicker style="width: 210px" placeholder="Enter a date and time" format="MM/dd/yyyy hh:mm:ss a"
      [(value)]="triggerDateInput">
    </kendo-datetimepicker>
    <br />
    <br />
    <br />
    <br />
    <button mat-stroked-button [disabled]="savingIntegrationPushBack" style="float:left; background-color: #ff9933; color: #FFFFFF" (click)="addIntegrationPushBack()">Add PushBack</button>
  </div>
</div> 

<ng-template #deletePushBackDialog>
  <h2 matDialogTitle>Confirm Deleting PushBack</h2>
  <mat-dialog-content>
    <span>The PushBack for {{selectedRunTime.importTypeDescription}} - {{selectedRunTime.destinationEndpointName}} RunTime will be deleted.</span>
    <br/>
    <span>TriggerDate: {{selectedPushBack.triggerDate | date:'yyyy-MM-dd hh:mm:ss a'}}</span>
    <br/>
    <span>LastRunTime: {{selectedPushBack.pushBackLastRunTimeDate | date:'yyyy-MM-dd hh:mm:ss a'}}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose (click)="deleteIntegrationPushBack()">Delete</button>
    <button mat-button matDialogClose>Cancel</button>
  </mat-dialog-actions>
</ng-template>
