import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { EncompassAPIFieldPath } from '../models/los-field-path';
import { protectedResources } from '../auth-config';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class FieldPathService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  getAllFieldPaths(): Observable<EncompassAPIFieldPath[]> {
    this.actionUrl = "EncompassAPIFieldPaths/Get";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<EncompassAPIFieldPath[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getEncompassAPIFieldPathByFieldId(fieldId: string): Observable<EncompassAPIFieldPath> {
    this.actionUrl = "EncompassAPIFieldPath";
    this.Url = this.baseUrl.concat(this.actionUrl, fieldId);

    return this.http
      .get<EncompassAPIFieldPath>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteEncompassAPIFieldPath(field: EncompassAPIFieldPath): Observable<any> {
    this.actionUrl = "EncompassAPIFieldPath/";
    this.Url = this.baseUrl.concat(this.actionUrl, field.encompassAPIFieldPathId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<any>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertEncompassAPIFieldPath(field: EncompassAPIFieldPath): Observable<any> {
    this.actionUrl = "EncompassAPIFieldPath/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, field, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateEncompassAPIFieldPath(field: EncompassAPIFieldPath): Observable<any> {
    this.actionUrl = "EncompassAPIFieldPath/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put(this.Url, field, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
