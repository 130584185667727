import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Location } from '@angular/common';
import { Observable, Subscription, Subject, forkJoin } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MonacoPageComponent } from '../monaco-editor/monaco-editor.component';
import { User } from '../../models/user';

import { DefaultConfigurationPlaceholder, DefaultConfigUtils } from '../../models/default-config-placeholder';
import { DataSourceType } from '../../models/data-source-type';
import { ImportType } from '../../models/import-type';
import { DestinationEndpoint } from '../../models/destination-endpoint';


import { DefaultConfigPlaceholderService } from '../../services/default-config-placeholder.service';
import { ClientIntegrationService } from '../../services/client-integration.service';
import { ToastService } from '../../services/toast.service';
import { checkNotInList } from '../../directives/check-not-in-list.directive';
import { TypeConstantService } from 'src/app/services/type-constant.service';

@Component({
  selector: 'app-add-default-configs',
  templateUrl: './add-default-configs.component.html',
  styleUrls: ['./add-default-configs.component.scss'],
  providers: [DefaultConfigPlaceholderService, ClientIntegrationService]
})
export class AddDefaultConfigsComponent implements OnInit, OnDestroy, AfterViewInit {

  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild('monaco') public child: MonacoPageComponent;
  monacoData: string = '<xml></xml>';
  sub: any;

  allData: DefaultConfigurationPlaceholder[];
  filteredGroup: DefaultConfigurationPlaceholder[];
  selectedConfig: DefaultConfigurationPlaceholder;
  configNames: string[] = [];
  name: string = "";
  nameFormControl = new UntypedFormControl('', {validators: [Validators.required], updateOn: 'blur'});
//   integrationNameFormControl = new FormControl('', {validators: [Validators.required, checkNotInList(this.integrationNames)], updateOn: 'blur'});


selectedParentConfig: DefaultConfigurationPlaceholder;
  importTypes: ImportType[] = [];
  selectedImportType: ImportType;
  destinationEndpoints: DestinationEndpoint[] = [];
  selectedDestinationEndpoint: DestinationEndpoint;
  dataSourceTypes: DataSourceType[] = [];
  selectedDataSourceType: DataSourceType;
  isGenericIntegration = false;

  savingDefaultConfig: boolean = false;

  constructor(
    protected configService: DefaultConfigPlaceholderService,
    protected clientService: ClientIntegrationService,
    protected typeConstantService: TypeConstantService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private user: User
  ) {
    this.selectedConfig = new DefaultConfigurationPlaceholder();
  }

  ngOnInit(): void {

    forkJoin({
      data1: this.typeConstantService.getAllDataSourceTypes(),
      data2: this.typeConstantService.getAllDestinationEndpoints(),
      data3: this.typeConstantService.getAllImportTypes()
    })
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(({ data1, data2, data3 }) => {
      this.dataSourceTypes = data1;
      this.destinationEndpoints = data2;
      this.importTypes = data3;

      this.configService.getAllDefaultConfigPlaceholders()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
          this.allData = data;
          this.configNames = this.allData.map(i => i.name.toLowerCase());
          this.nameFormControl.setValidators([
              checkNotInList(this.configNames),
              Validators.required,
              Validators.maxLength(100),
              Validators.pattern('^[a-zA-Z0-9_-]*$')
            ]);
        });
    });

  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngAfterViewInit(): void {

  }


  goBack(): void {
    this.location.back();
  }

  canDeactivate(): boolean {
    if (this.child.hasBeenEdited()) {
      return false;
    }
    return true;
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  saveDefaultConfig(): void {
    this.savingDefaultConfig = true;
    if (this.nameFormControl.status == 'VALID') {
      this.selectedConfig.name = this.nameFormControl.value;

      if (this.monacoData == '' || this.monacoData == null) {
        this.toastService.toastCreate(
            "XML Config Data in Monaco Editor for Config cannot be null or empty.",
            "Warning",
            { autoClose: true }
        );
        this.savingDefaultConfig = false;
      }
      else {
        this.selectedConfig.xmlElementConfig = this.monacoData;

        this.configService.insertDefaultConfigPlaceholder(this.selectedConfig)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (value) => {
            this.toastService.toastCreate(
              "Successfully added the default config: " + this.selectedConfig.name,
              "Success",
              { keepAfterRouteChange: true }
            );
            this.router.navigate(['editDefaultConfigs', this.selectedConfig.name]);
          }
          ,(error) => {
            this.savingDefaultConfig = false;
          }
        );
      }

    }
    else {
      this.toastService.toastCreate(
            "Default Config Name has errors. Fix and resubmit.",
            "Warning",
            { autoClose: true }
        );
      this.savingDefaultConfig = false;
    }
  }

  parentConfigChange(event: any): void {
    if (event.value == null) {
      this.selectedParentConfig = null;
      this.selectedConfig.parentId = null;
    }
    else {
      this.selectedParentConfig = event.value;
      this.selectedConfig.parentId = event.value.defaultConfigurationPlaceholderId;
    }
  }

  dataSourceTypeChange(event: any): void {
    this.selectedDataSourceType = event.value;
    this.selectedConfig.dataSourceTypeId = event.value.dataSourceTypeId;
  }

  destinationEndpointChange(event: any): void {
    this.selectedDestinationEndpoint = event.value;
    this.selectedConfig.destinationEndpointId = event.value.destinationEndpointId;
    this.selectedConfig.endpointName = event.value.endpointName;
  }

  importTypeChange(event: any): void {
    this.selectedImportType = event.value;
    this.selectedConfig.importTypeId = event.value.importTypeId;
    this.selectedConfig.typeDescription = event.value.typeDescription;
  }

  onToggleIsGenericChange(value: any): void {
    if (value === 'true') {
      this.isGenericIntegration = true;
    } else if (value === 'false') {
      this.isGenericIntegration = false;
    } else {
      this.isGenericIntegration = null;
    }
  }

}
