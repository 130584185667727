import { Component, OnInit, OnChanges, OnDestroy, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { State, DataSourceRequestState, orderBy, CompositeFilterDescriptor, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, SelectionEvent, RowArgs } from '@progress/kendo-angular-grid';
import { Observable, Subscription, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { Integration } from '../../models/integration';
import { SearchTerm } from '../../models/search-term';
import { IntegrationErrorMessage } from '../../models/integration-error-message';
import { IntegrationInformation } from '../../models/integration-information';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { Client } from '../../models/client';
import { ClientIntegrationService } from "../../services/client-integration.service";
import { ErrorLoggingService } from 'src/app/services/logging.service';
import { ToastService } from '../../services/toast.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { User } from '../../models/user';
import * as internal from 'stream';
import { IntegrationType } from 'src/app/models/integration-type';

const flatten = (filter) => {
    const filters = filter.filters;
    if (filters) {
        return filters.reduce(
            (acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]),
            []
        );
    }
    return [];
};

@Component({
  selector: 'app-error-list-grid',
  templateUrl: './error-list-grid.component.html',
  styleUrls: ['./error-list-grid.component.scss'],
  providers: [ClientIntegrationService, TypeConstantService],
})
export class ErrorListGridComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild("errorGrid") public errorGrid: GridComponent;
  hasData = false;

  @Input() data: IntegrationErrorMessage[] = [];
  @Input() clients: Client[];
  @Input() loading: boolean; // show loading icon from parent
  @Input() integrationsMap: Map<string, Integration>;
  @Input() destinationsMap: Map<string, DestinationEndpoint>;
  @Output() getUpdated = new EventEmitter<string>(); // ask parent for updated data

  selectedError: IntegrationErrorMessage;
  selectedRow: RowArgs;
  selectedRows: RowArgs[] = [];
  public expandedDetailKeys: any[] = [];
  public expandDetailsBy = (dataItem: any): any => {
    return dataItem.integrationErrorMessageId;
  };

  gridView: GridDataResult;
  gridViewIsLoading = true;
  currentFiltersFromGrid: CompositeFilterDescriptor;
  public state: DataSourceRequestState = {
      skip: 0,
      take: 10,
      sort: []
  };

  filteredDestinations: DestinationEndpoint[];
  public selectedDestination: DestinationEndpoint;
  destinationsFiltered: Observable<DestinationEndpoint[]>;
  pageLoading = true;
  activeUser: User;

  filteredData: IntegrationErrorMessage[];
  // search vars
  public stackSearchTerm: SearchTerm;
  public logSearchTerm: SearchTerm;

  // error status vars
  public statusSearchTerm: string = "";
  hasStatusFilter = false;
  statusSelectedFilter: any;
  errorStatuses: string[] = ["Unresolved", "InProgress", "NotImportant", "Resolved"];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientIntegrationService,
    private errorService: ErrorLoggingService,
    private toastService: ToastService,
    protected typeService: TypeConstantService,
    private user: User
  ) {
    this.state.sort = [{ field: "errorStatusId", dir: 'desc' }, { field: "receivedDate", dir: 'desc' }];
    this.filteredData = [];
    this.stackSearchTerm = new SearchTerm();
    this.logSearchTerm = new SearchTerm();
  }

  ngOnInit(): void {

    if (this.clients == null) {
      this.getClients();
    }

    this.refresh();

  }

  public refresh(): void {
    if (this.data != null) {
      if (this.data.length > 0) {
        this.data.forEach(x => x.receivedDate = new Date(x.receivedDate));
        this.filteredData = this.data;
        this.state.skip = 0;
        this.filteredData = filterBy(this.data, this.currentFiltersFromGrid);
        this.mapData();
      }
      else
      {
        this.gridView = {data: [], total: 0};
        this.gridViewIsLoading = false;
      }
    }
  }

  public getDestinationName(intTypeId: string) {
    var rc = "NoDestination";
    if (this.destinationsMap != undefined && this.destinationsMap.has(intTypeId)) {
      rc = this.destinationsMap.get(intTypeId).endpointName;
    }

    return rc;
  }

  public mapData(): void{
    if (this.data.length > 0) {
      this.data.forEach(x => x.receivedDate = new Date(x.receivedDate));
      // if integrations finished before errors
      if (this.integrationsMap != null && this.integrationsMap.size > 0 && this.destinationsMap.size > 0) {
        this.data.forEach(x => {
          let i = this.integrationsMap.get(x.integrationId);
          if (i != null) {
            // x.integrationMod = i;
            x.dataSourceTypeId = i.dataSourceTypeId;
            x.integrationServerId = i.serverId;
            x.integrationName = i.name;
            let tmp = this.destinationsMap.get(x.integrationTypeId);
            if (tmp != null && tmp != undefined) {
              x.destinationId = tmp.destinationEndpointId;
              x.destinationName = tmp.endpointName
            } else {
              let defaultDest = this.destinationsMap.get("LOSTalkerThread");
              x.destinationId = defaultDest.destinationEndpointId;
              x.destinationName = defaultDest.endpointName;
            }

            x.clientId = i.clientId;
            if (x.locked == false && i.locked == true) {
              x.locked = true;
            }
          }
        });
      }
    }
    this.updateGrid();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.updateGrid();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  sortChange(sort: SortDescriptor[]) {
    this.state.sort = sort;
    this.updateGrid();
  }

  public updateFilteredData(filterFromGrid: CompositeFilterDescriptor): void {
    if (this.data != null) {
      this.state.skip = 0;
      this.currentFiltersFromGrid = filterFromGrid;

      this.updateGrid();
    }
    else {
      this.gridViewIsLoading = false;
    }
  }

  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }

  statusFilterChange(checked: string): void {
    this.state.skip = 0;
    this.hasStatusFilter = true;
    this.statusSearchTerm = checked;
    this.updateGrid();
  }

  clearStatusFilter(): void {
    this.statusSearchTerm = null;
    this.hasStatusFilter = false;
    this.updateGrid();
  }

  clearFilters() {
    this.currentFiltersFromGrid = null;
    this.stackSearchTerm.clear();
    this.logSearchTerm.clear();
    this.statusSearchTerm = null;
    this.hasStatusFilter = false;
    this.updateGrid();
  }

  updateGrid() {
    this.filteredData = this.data;

    if (this.stackSearchTerm.hasSearchTerm) {
      this.filteredData = filterBy(this.filteredData, {
        logic: 'and',
        filters: [
          { field: "stackTrace", operator: "contains", value: this.stackSearchTerm.currentSearchValue, ignoreCase: true }
        ]
      });
    }

    if (this.logSearchTerm.hasSearchTerm) {
      this.filteredData = filterBy(this.filteredData, {
        logic: 'and',
        filters: [
          { field: "logMessage", operator: "contains", value: this.logSearchTerm.currentSearchValue, ignoreCase: true }
        ]
      });
    }

    if (this.hasStatusFilter) {
      this.filteredData = filterBy(this.filteredData, {
        logic: 'and',
        filters: [
          { field: "errorStatusId", operator: "eq", value: this.statusSearchTerm, ignoreCase: true }
        ]
      });
    }

    if (this.currentFiltersFromGrid != null) {
      this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
    }


    let orderedData = orderBy(this.filteredData.length > 0 ? this.filteredData : this.data, this.state.sort);
    this.gridView = {
        data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
        total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  updateError(error: IntegrationErrorMessage, errorStatusId: string) {
    this.selectedError = error;
    this.selectedError.errorStatusId = errorStatusId;
    if (errorStatusId == "Resolved") {
        this.selectedError.integrationStopped = false;
    }
    this.errorService.updateError(this.selectedError)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (value) => {
        this.getUpdated.emit('true');
        this.updateGrid();
      });
  }

  selectionChange(event: SelectionEvent) {
    if (event.selectedRows.length > 0) {
      this.expandedDetailKeys = [];
      this.expandedDetailKeys.push(event.selectedRows[0].dataItem?.integrationErrorMessageId);
    }
  }

  clearSearch(item: SearchTerm) {
    item.clear();
    this.state.skip = 0;
    this.updateGrid();
  }

  updateSearch(item: SearchTerm) {
    item.save();
    this.state.skip = 0;
    this.updateGrid();
  }

  formatDate(error: IntegrationErrorMessage): string {
    var tmp = new Date(error.receivedDate);
    return tmp.toLocaleString();
  }

  helpDesk(error: IntegrationErrorMessage): void {
    this.errorService.sendHelpDesk(error);
  }

  navigateToClient(dataItem: IntegrationErrorMessage) {
    if (this.clients != null && ( dataItem.clientName == "" || dataItem.clientName == null)) {
      dataItem.clientName = this.clients.filter(c => c.clientId == dataItem.clientId)[0].name;
    }
    if (dataItem.clientName != null && dataItem.clientName != "") {
      this.router.navigate(['/editConfig', dataItem.clientName, dataItem.integrationName]);
    }
  }

  getClients(): void {
    this.clientService.getAllClients()
      .subscribe(clients => this.clients = clients,
        error => {
        this.gridViewIsLoading = false;
      });
  }

  public saveData(component: any): void {
    component.save();
  }

}
