<div class="container-fluid">
  <div class="row align-items-center my-2" *ngIf="selectedContract && hasContract && hasPeriods">
    <ng-container *ngIf="allInvoicePeriods && hasPeriods">
      <div class="col-3 text-center col-sm-4 col-lg-3" *ngIf="hasMultiPreviousPeriods">
        <mat-form-field appearance="fill" class="previous-periods-drop-down">
          <mat-label>Previous Invoice Periods</mat-label>
          <mat-select (selectionChange)="selectPreviousPeriod($event)" [formControl]="previousPeriodsFormControl">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let option of otherPreviousPeriods" [value]="option">
              {{option.dateEnd | date:'LLLL'}} | {{option.dateStart | date:'MM/dd/YYYY'}} - {{option.dateEnd | date:'MM/dd/YYYY'}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="bottom-buttons" [ngClass]="{'col-9 mb-2 col-sm-8 col-lg-9': hasMultiPreviousPeriods}">
        <button mat-raised-button (click)="selectPeriod(previousBilledPeriod)" *ngIf="previousBilledPeriod"
          [ngClass]="{'period-button-selected': selectedItem == previousBilledPeriod}" >
          Previous Billed (
            {{previousBilledPeriod.dateEnd | date:'LLLL'}}
          ) <br />Invoice Period
        </button>
        <button mat-raised-button (click)="selectPeriod(lastBilledPeriod)" *ngIf="lastBilledPeriod"
          [ngClass]="{'period-button-selected': selectedItem == lastBilledPeriod}">
          Last Billed (
            {{lastBilledPeriod.dateEnd | date:'LLLL'}}
          ) <br />Invoice Period
        </button>
        <button mat-raised-button (click)="selectCompletedLoansPeriod(completedPeriod)" *ngIf="completedPeriod"
          [ngClass]="{'period-button-selected': selectedItem == completedPeriod}">
          Current (
            {{completedPeriod.dateEnd | date:'LLLL'}}
          ) <br />Completed Loans
        </button>
      </div>

    </ng-container>

  </div>
  <div class="row align-items-center my-2" *ngIf="selectedContract && hasContract && !hasPeriods && !gettingPeriods">
    <div class="col">
      <h5 class="text-center my-2">No Invoice Periods Found.</h5>
    </div>
  </div>

  <div class="row align-items-center my-2" *ngIf="selectedContract && hasContract && !hasPeriods && gettingPeriods">
    <div class="col">
      <h5 class="text-center my-2">Loading Invoice Periods...</h5>
    </div>
  </div>

</div>
