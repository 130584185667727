<div class="fastfetch-container" style="height: 100%;">
  <div class="fastfetch-container-title" style="height: 5%;">
    <ng-container *ngIf="isInTab == false">
      <h1 style="text-align:center;">FastFetch</h1>
    </ng-container>
    <ng-container *ngIf="isInTab == true">
      <h4 style="text-align:center;">FastFetch</h4>
    </ng-container>
  </div>
  <div *ngIf="isInTab == false">
    <br />
    <br />
  </div>

  <ng-container *ngIf="hasDestinations && !isInTab">
    <!-- <ng-container *ngIf="!selectedDestination"> -->
    <div class="dest-switch">
      <mat-button-toggle-group (change)="selectDestination($event.value)" [hideSingleSelectionIndicator]="true">
        <mat-button-toggle *ngFor="let d of filteredDestinations" [value]="d" [checked]="d == selectedDestination">
          {{ formatDestinationName(d.endpointName) }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-container>


  <ng-container *ngIf="gridView && (selectedDestination || isInTab)">
    <div class="fastfetch-container-main" style="height: 95%;">

      <kendo-grid [data]="gridView" [pageSize]="state.take" [skip]="state.skip"
        [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
        [sortable]="{mode: 'single',allowUnsort: false}" [sort]="state.sort" [scrollable]="true" [selectable]="true"
        [filter]="filter" [filterable]="true" (sortChange)="sortChange($event)"
        (selectionChange)="selectionChangeHandler($event)" (pageChange)="pageChange($event)"
        (filterChange)="filterChange($event)">

        <kendo-grid-column field="integrationId" title="Selected" width="100" [style]="{'text-align': 'center'}"
          [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="selectedIntegration">
              <span
                *ngIf="dataItem.integrationId == selectedIntegration.integrationId && dataItem.destinationName == selectedIntegration.destinationName"
                style="text-align: center;">
                <i class="bi bi-star-fill"></i>
              </span>
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="identifierIdValue" title="Company ID" width="100">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="identifierNameValue" title="Company Name" width="300" id="colID">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="integrationName" title="Integration Name" width="300">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="dataSourceType" title="Data Source Type" width="400">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
            </kendo-grid-string-filter-cell>
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="destinationName" title="Destination" width="100">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="serverId" title="Server" width="100">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-input></kendo-pager-input>
          <kendo-pager-info></kendo-pager-info>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
        </ng-template>

      </kendo-grid>

      <div *ngIf="selectedIntegration" class="ff-main-form-area">
        <br />
        <div style="display: flex; align-items: center; justify-content: space-between; width: 60%;">
          <ng-container *ngIf="isInTab == false">
            <h2>Client: {{selectedIntegration.clientName}}</h2>
            <button class="btn" style="padding-left: 10px;" (click)="changeClient()"><i class="bi bi-pencil"></i></button>
          </ng-container>
          <ng-container *ngIf="isInTab == true">
            <h5>Client: {{selectedIntegration.clientName}}</h5>
          </ng-container>
        </div>

        <ng-container *ngIf="isInTab == true">
          <div *ngIf="selectedIntegration">
            <h5>Integration: {{selectedIntegration.identifierNameValue}}</h5>
          </div>
        </ng-container>

        <div style="display: flex; align-items: center;">
          <h5 style="padding-right: 15px;">Currently sending to: {{getSNEmail(activeUser.userName)}}</h5>
          <mat-checkbox [checked]="overrideEmail" (change)="changeOverrideEmailCheck($event.checked)">
            Override Email Address
          </mat-checkbox>
        </div>

        <ng-container *ngIf="overrideEmail">
          <!-- <kendo-label [for]="emailOverrideForUser" text="Email to send update to (overrides default of user's username):"></kendo-label>
          <input #emailOverrideForUser [(ngModel)]="userEmailOverride" name="emailOverrideForUser"/> -->

          <mat-form-field appearance="fill" class="ff-dropdown-has-client">
            <mat-label>Email to send FastFetch update to:</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. test@example.com">
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </ng-container>

        <div style="display: flex; flex-direction: column; width: 80%;">
          <div style="display: block;">
            <button class="btn" style="float: right;" (click)="openLoanInfoDialog()">
              <span style="padding-right:10px;">Help </span><i class="bi bi-info-circle"></i>
            </button>
          </div>

          <div class="add-fastfetch-loan-group">
            <kendo-grid (excelPaste)="onExcelPaste($event)" [kendoGridBinding]="gridData"
              [kendoGridReactiveEditing]="createLoanFormGroup" [pageSize]="10" [pageable]="true">
              <ng-template kendoGridToolbarTemplate>
                <button kendoGridAddCommand [disabled]="gridData.length >= 300">Add new</button>
              </ng-template>
              <kendo-grid-column field="loan" title="Loan" width="100" editor="text"></kendo-grid-column>
              <kendo-grid-command-column title="Commands" width="220">
                <ng-template kendoGridCellTemplate let-isNew="isNew">
                  <button kendoGridEditCommand type="button" [primary]="true">Edit</button>
                  <button kendoGridRemoveCommand type="button">Remove</button>
                  <button kendoGridSaveCommand type="button" [disabled]="loanFormGroup?.invalid">
                    {{ isNew ? "Add" : "Update" }}
                  </button>
                  <button kendoGridCancelCommand type="button">
                    {{ isNew ? "Discard changes" : "Cancel" }}
                  </button>
                </ng-template>
              </kendo-grid-command-column>

              <ng-template kendoGridNoRecordsTemplate>
                <div class="empty_spacing_for_norecords">
                  <div class="no-records-message">Click in grid and use CTRL+V to paste loans.</div>
                </div>
              </ng-template>

            </kendo-grid>

          </div>
        </div>

        <div class="submit-fastfetch-buttons" style="margin-bottom: 60px;">
          <button type="button" class="btn btn-outline-dark" (click)="clearFastFetchFormData($event)">Clear
            Form</button>
          <button type="button" class="btn btn-outline-dark" (click)="submitFastFetchRequest($event)" [disabled]="gridData.length > 300">Submit</button>
          <button *ngIf="gridData.length > 0" type="button" class="btn btn-outline-dark"
            (click)="clearFastFetchLoanGrid($event)">Clear Loan Grid</button>
            <span style="padding-right:10px;float: right;color: red;" class="mx-2">Max Loan Count Per Request: 300 loans. Current Count: {{gridData.length}}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="pageLoading">
    <div class="k-i-loading"></div>
  </div>

  <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!pageLoading && !allDestinations">
    <div class="no-records-message">Loading failed.</div>
  </div>
</div>

<ng-template #fastFetchInfoDialog>
  <h2 matDialogTitle>FastFetch - Pasting Loans into Grid</h2>
  <mat-dialog-content>
    <h6>Click in grid and use CTRL+V to paste loans.</h6>
    <h6> You can paste loans from an array of different sources as long as there is a character separating the loan numbers</h6>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Close</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #loanProgressDialog>
  <h2 matDialogTitle></h2>
  <mat-dialog-content>
    <ng-container *ngIf="loansBeingProcessed">
      <section class="progress-bar-section progress-custom">
        <mat-progress-bar class="progress-bar-margin" [color]="'primary'" [mode]="'buffer'" [value]="progressBarValue"
          [bufferValue]="progressBarBufferValue">
        </mat-progress-bar>
        <mat-label>Loans being sent to FastFetch Queue</mat-label>
      </section>
    </ng-container>
    <ng-container *ngIf="!loansBeingProcessed">
      <h6>Loans have been sent to FastFetch queue.</h6>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Close</button>
  </mat-dialog-actions>
</ng-template>
