export class ErrorSearchFilter {

    constructor(logMessage: string, errorCategoryId: number, numDays: number){
        this.logMessage = logMessage,
        this.errorCategoryId = errorCategoryId,
        this.numberOfDays = numDays
    }

    logMessage: string;
    errorCategoryId: number;
    numberOfDays: number;
}
