import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';
import { CustomSQLQuery } from '../models/sql-query';
import { QueryType } from '../models/query-type';
import { CsvSummary } from '../models/csv-summary';
import { Integration, IntegrationResponseMessage, IntegrationFlags } from '../models/integration';
import { ErrorStatus } from '../models/error-status';
import { IntegrationType } from '../models/integration-type';
import { IntegrationJavaScript } from '../models/integration-javascript';
import { IntegrationLog } from '../models/integration-log';
import { IntegrationErrorMessage } from '../models/integration-error-message';
import { IntegrationMetrics } from '../models/integration-metrics';
import { LoanMetrics } from '../models/loan.metrics';
import { CommonErrorFilter } from '../models/common-error-filter';
import { Server } from '../models/server';
import { DataSourceType } from '../models/data-source-type';
import { DestinationEndpoint } from '../models/destination-endpoint';
import { IntegrationIdentifier } from '../models/integration-identifier';
import { protectedResources } from '../auth-config';
import { DataResponse } from '../models/data-response';
import { ImportType } from '../models/import-type';
import { IntegrationInformation } from '../models/integration-information';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomSQLQueryService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- CustomSQLQueries ------------------------------------------//

  getAllCustomQueries(): Observable<CustomSQLQuery[]> {
    this.actionUrl = "CustomSQLQueries";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<CustomSQLQuery[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllCustomQueriesByIntegration(id: string): Observable<CustomSQLQuery[]> {
    this.actionUrl = "CustomSQLQueries/";
    this.Url = this.baseUrl.concat(this.actionUrl, id);

    return this.http
      .get<CustomSQLQuery[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getCustomQueryById(id: string): Observable<CustomSQLQuery> {
    this.actionUrl = "CustomSQLQuery/";
    this.Url = this.baseUrl.concat(this.actionUrl, id);

    return this.http
      .get<CustomSQLQuery[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  addCustomQuery(query: CustomSQLQuery): Observable<any> {
    this.actionUrl = "CustomSQLQuery";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, query, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateCustomQuery(query: CustomSQLQuery): Observable<any> {
    this.actionUrl = "CustomSQLQuery";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, query, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteCustomQuery(query: CustomSQLQuery): Observable<any> {
    this.actionUrl = "CustomSQLQuery/";
    this.Url = this.baseUrl.concat(this.actionUrl, query.customSQLQueryId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- QueryTypes ------------------------------------------//

  getAllQueryTypes(): Observable<QueryType[]> {
    this.actionUrl = "QueryTypes";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<QueryType[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
