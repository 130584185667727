<div class="container-fluid h-100 d-flex flex-column">
    <ng-container *ngIf="integrationsMap != undefined && clients != undefined && !gettingClients">
            <app-report-error-search-filter #reportErrorsFilter [reportConstants]="reportConstants"
                [errorSearchFilter]="errorSearchFilter" (updateFilter)="filterErrorsReport($event)"
                (clearGridFilters)="clearFilters($event)" (reloadCurrentList)="reloadGridForFullPopulation($event)">
            </app-report-error-search-filter>

        <div class="row">
            <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.errorsPopulated">
                <div class="k-i-loading"></div>
            </div>
            <div class="mt-2" *ngIf="this.errorsPopulated && this.firstGroupReturned">
                <app-error-list-grid-v2 #reportErrorsGridComponent [data]="fullErrorsList"
                    [destinations]="reportConstants.destinationEndpoints" [integrationsMap]="integrationsMap"
                    [categories]="reportConstants.errorCategories" [importTypes]="reportConstants.importTypes"
                    [statuses]="reportConstants.currentStatuses" [loading]="dataLoading" [clients]="clients"
                    [sorting]="defaultErrorSorting">
                </app-error-list-grid-v2>
            </div>
        </div>
    </ng-container>
</div>
