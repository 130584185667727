<div *ngIf="baseMapping">
  <h1>Edit Base Mapping</h1>
  <div class="inline">
    <span class="text-large inline">{{baseMapping.name}}  </span>
    <kendo-switch
      [(ngModel)]="checked"
      onLabel="XML"
      offLabel="JS"
      (valueChange)="toggleDataXMLJS($event)">
    </kendo-switch>
  </div>
  <br />

  <app-monaco-editor #monaco
    (textChange)="monacoData = $event"
    [editorData]="monacoData"
    [lang]="'xml'" 
    [editAccess]="checkAccess(['LOSTalker.DevOps'])">
  </app-monaco-editor>

  <br />
  <button kendoButton (click)="goBack()">Go Back</button>
  <button kendoButton [disabled]="updateInitiated" *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="updateBaseMapping()">Save Changes</button>
  <br />
  <br />

  <div *ngIf="checkAccess(['LOSTalker.DevOps'])">
    <span class="text-large inline">Enable Generic Conversion</span>
    <kendo-switch
      [(ngModel)]="enableGenericConversion"
      onLabel="Yes"
      offLabel="No">
    </kendo-switch>
  </div>

  <br />
  <div *ngIf="enableGenericConversion">
    <mat-form-field appearance="fill">
      <mat-label>Generic Name</mat-label>
      <input matInput [(ngModel)]="genericName">
    </mat-form-field>
    <button kendoButton [disabled]="convertingToGeneric" *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="createGenericEndpoint()">Convert to Generic</button>
  
  </div>

  <div *ngIf="showHistory">
    <h3>Recent history changes:</h3>

    <kendo-dropdownlist 
      [data]="historyNames" 
      (selectionChange)="selectBaseHistory($event)">
    </kendo-dropdownlist>

    <kendo-switch
      [(ngModel)]="historyChecked"
      (valueChange)="toggleDataHistoryXMLJS($event)"
      onLabel="XML"
      offLabel="JS">
    </kendo-switch>

  </div>
  <br />
  <div *ngIf="selectedHistory != undefined">

    <app-monaco-diff-editor #monacoHistory
      [currentData]="monacoOriginalData"
      [historyData]="monacoHistoryData"
      [lang]="monacoHistoryLanguage"
      [historyTitle]="historySideTitle"
      [currentTitle]="currentSideTitle">
    </app-monaco-diff-editor>

    <button kendoButton [disabled]="updateInitiated" *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="updateBaseMappingFromHistory()">Revert to Selected History</button>

  </div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="pageLoading">
  <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!pageLoading && !baseMapping">
  <div class="no-records-message">Loading failed.</div>
</div>