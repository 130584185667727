import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-page-loading-view',
  templateUrl: './page-loading-view.component.html',
  styleUrls: ['./page-loading-view.component.scss']
})
export class PageLoadingViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  @Input() loading: boolean = true;
  @Input() loadingFailed: boolean = false;
  @Input() loadingFailedMessage: string = "Loading failed.";

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.loading?.currentValue != null && changes?.loading?.firstChange != true) {
       this.loading = changes?.loading?.currentValue;
    }
    if (changes?.loadingFailed?.currentValue != null && changes?.loadingFailed?.firstChange != true) {
      this.loadingFailed = changes?.loadingFailed?.currentValue;
   }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

}
