<div *ngIf="genericEndpoint">
  <h1>Edit Base Mapping</h1>
  <div class="inline">
      <span class="text-large inline">{{genericEndpoint.name}}  </span>
      <kendo-switch
        [(ngModel)]="checked"
        [onLabel]="'XML'"
        [offLabel]="'JS'"
        (valueChange)="toggleDataXMLJS($event)"
        ></kendo-switch>
  </div>
  <br />
  <app-monaco-editor #monaco
      (textChange)="monacoData = $event"
      [editorData]="monacoData"
      [lang]="'xml'"
      [editAccess]="checkAccess(['LOSTalker.DevOps'])"
      >
  </app-monaco-editor>
  <br />
  <div class="row">
    <div class="col-sm-11">
      <button kendoButton (click)="goBack()">Go Back</button>
      <button kendoButton *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="updateGenericEndpoint()">Save Changes</button>
    </div>
    <div class="col-sm-1 text-end">
      <button style="float:right; background-color: #d40404; color: #FFFFFF" kendoButton *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="confirmDelete()">Delete</button>
    </div>
  </div>

  <div *ngIf="checkAccess(['LOSTalker.DevOps']) && isSDKEndpoint()">
    <span class="text-large inline">Enable API Conversion</span>
    <kendo-switch
      [(ngModel)]="enableAPIConversion"
      onLabel="Yes"
      offLabel="No">
    </kendo-switch>
  </div>

  <br />
  <div *ngIf="enableAPIConversion">
    <mat-form-field appearance="fill">
      <mat-label>API Name</mat-label>
      <input matInput [(ngModel)]="apiName">
    </mat-form-field>
    <button kendoButton [disabled]="convertingToAPI" *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="createAPIGenericEndpoint()">Convert to API</button>
  </div>

  <div *ngIf="showHistory">
    <br>
    <kendo-switch
      [(ngModel)]="historyChecked"
      (valueChange)="toggleDataHistoryXMLJS($event)"
      onLabel="XML"
      offLabel="JS">
    </kendo-switch>

    <br>
    <br>

    <ng-container *ngIf="historyChecked == true">
      <mat-form-field appearance="fill" class="dropdown-med-width">
        <mat-label>Generic Endpoint History</mat-label>
        <mat-select name="i" (selectionChange)="selectBaseHistory($event.value)">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let i of baseHistory" [value]="i">
            {{i.updatedDate}}-{{i.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="historyChecked == false">
      <mat-form-field appearance="fill" class="dropdown-med-width">
        <mat-label>Generic Endpoint History</mat-label>
        <mat-select name="ij1" (selectionChange)="selectBaseJavaScriptHistory($event.value)">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let ij1 of javascriptList" [value]="ij1">
            {{ ij1.javaScriptId }} | {{ ij1.updatedDate | date: 'MM/dd/yyyy h:mm a' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      Compare to:

      <mat-form-field appearance="fill" class="dropdown-med-width">
        <mat-label>Javascript History Compare</mat-label>
        <mat-select name="i" (selectionChange)="selectJsGenericEndpointCompare($event.value)">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let i of javascriptBaseHistory" [value]="i">
            {{ i.javaScriptId }} | {{ i.updatedDate | date: 'MM/dd/yyyy h:mm a' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </ng-container>

    <ng-template *ngIf="historyChecked; then int1 else int2"></ng-template>

    <ng-template #int1>
      <div *ngIf="selectedHistory"  class="monaco-outer">
        <div>
          <app-monaco-diff-editor #monacoHistory
                      [currentData]="historyCurrentData"
                      [historyData]="historyData"
                      [lang]="monacoLangHtml"
                      [historyTitle]="historySideTitle"
                      [currentTitle]="currentSideTitle">
          </app-monaco-diff-editor>
        </div>
        <div class="center-button">
          <button mat-raised-button
            *ngIf="checkAccess(['LOSTalker.DevOps.Write'])"
            (click)="updateGenericEndpointFromHistory('xml')">
            Revert to Selected History
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #int2>
      <div *ngIf="selectedJavascript && javascriptSelectedHistory" class="monaco-outer">
        <div>
          <app-monaco-diff-editor #monacoJSHistory
                      [currentData]="historyCurrentData"
                      [historyData]="historyData"
                      [lang]="monacoLangJs"
                      [historyTitle]="historySideTitle"
                      [currentTitle]="currentSideTitle">
          </app-monaco-diff-editor>
        </div>
        <div class="center-button">
          <button mat-raised-button
            *ngIf="checkAccess(['LOSTalker.DevOps.Write'])"
            (click)="updateGenericEndpointFromHistory('js')">
            Revert to Selected History
          </button>
        </div>
      </div>
    </ng-template>






  </div>
  <br />
  <div *ngIf="selectedHistory != undefined && false">


    <app-monaco-diff-editor #monacoHistory
      [currentData]="monacoOriginalData"
      [historyData]="monacoHistoryData"
      [lang]="monacoHistoryLanguage"
      [historyTitle]="historySideTitle"
      [currentTitle]="currentSideTitle">
    </app-monaco-diff-editor>

    <button kendoButton *ngIf="checkAccess(['LOSTalker.DevOps'])" (click)="updateBaseMappingFromHistory()">Revert to Selected History</button>

  </div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="pageLoading">
  <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!pageLoading && !genericEndpoint">
  <div class="no-records-message">Loading failed.</div>
</div>

<ng-template #confirmDeleteDialog>
  <mat-dialog-content>
    <h3>Do you wish to delete generic endpoint:  {{genericEndpoint.name}}?</h3>
    <button mat-flat-button color="primary" style="margin:5px" (click)="deleteGenericEndpoint()" >Yes</button>
    <button mat-flat-button color="primary" style="margin:5px" (click)="closeDialog()">No</button>
  </mat-dialog-content>
</ng-template>
