<ng-container *ngIf="this.destinationsMap != undefined">
  <ng-container *ngIf="isInTab == false">
    <h1 class="mb-4" style="text-align:center;">Integration Errors</h1>
  </ng-container>
  <ng-container *ngIf="isInTab == true">
    <h4 class="mt-1 mb-3" style="text-align:center;">Integration Errors</h4>
  </ng-container>

  <kendo-tabstrip height="100%" #tabstrip (tabSelect)="onTabSelect($event)"> 

    <kendo-tabstrip-tab [title]="'Integration Stopped Errors'" [selected]="true">
      <ng-template kendoTabContent>
          <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.stoppedPopulated">
            <div class="k-i-loading"></div>
          </div>
        <div class="mt-2" *ngIf="this.stoppedPopulated">
          <app-error-list-grid
                #stoppedListComponent
                [data]="stoppedErrorsList"
                [integrationsMap]="integrationsMap"
                [destinationsMap]="destinationsMap"
                [loading]="dataLoading"
                (getUpdated)="sendUpdatedDataToTable($event)">
          </app-error-list-grid>         
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Integration Maintenance Errors'">
      <ng-template kendoTabContent>
          <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.maintenancePopulated">
            <div class="k-i-loading"></div>
          </div>
        <div class="mt-2" *ngIf="this.maintenancePopulated">
          <app-error-list-grid
                #maintenanceListComponent
                [data]="maintenanceErrorsList"
                [integrationsMap]="integrationsMap"
                [destinationsMap]="destinationsMap"
                [loading]="dataLoading"
                [clients]="clients"
                (getUpdated)="sendUpdatedDataToTable($event)">
          </app-error-list-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Service Errors'">
      <ng-template kendoTabContent>
          <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.serviceErrorsPopulated">
            <div class="k-i-loading"></div>
          </div>
        <div class="mt-2" *ngIf="this.serviceErrorsPopulated">
          <app-service-error-list-grid
                #serviceErrorListComponent
                [data]="serviceErrorList"
                [integrationsMap]="integrationsMap"
                [loading]="dataLoading"
                [clients]="clients"
                (getUpdated)="sendUpdatedDataToTable($event)">
          </app-service-error-list-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'All Other Errors'">
      <ng-template kendoTabContent>
        <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.otherPopulated">
          <div class="k-i-loading"></div>
        </div>
        <div class="mt-2" *ngIf="this.otherPopulated">
          <app-error-list-grid
                #otherListSection
                [data]="otherErrorsList"
                [integrationsMap]="integrationsMap"
                [destinationsMap]="destinationsMap"
                [loading]="dataLoading"
                (getUpdated)="sendUpdatedDataToTable($event)">
          </app-error-list-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</ng-container>
