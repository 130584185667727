import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DataSwapParams } from "../models/dataswap-params";
import { DataSwapResponse } from "../models/dataswap-response";
import { protectedResources } from '../auth-config';
import { environment } from 'src/environments/environment';
import { ToastService } from "./toast.service";
@Injectable()
export class LBADataswapService {
    //private baseUrl: string = "api/";
    private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
    private actionUrl: string = "";
    private Url: string = "";
    private headers = new HttpHeaders();
  
    private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
      ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
        keepAfterRouteChange: true
      });
      
      if (environment.enableDebuggingTools) {
        console.error("An HTTP transport error occured: " + error.message);
      }
  
      return throwError(error);
    }
  
    constructor(private http: HttpClient, private toastService: ToastService) { }

    //--------------------------- Compensafe functions ------------------------------------------//

    compensafeGetFile(dataswapParams: DataSwapParams) {
        this.actionUrl = "DataSwap/CompenSafeGetFile";
        this.Url = this.baseUrl.concat(this.actionUrl);
        //this.headers.append("Content-Type", "application/json");

        return this.http
            .post<DataSwapResponse>(this.Url, dataswapParams, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    compensafeGetFileByInfo(company: string, swapKey: string, fileType: string, latestNo: string) {
        this.actionUrl = "DataSwap/CompenSafeGetFile/";
        this.Url = this.baseUrl.concat(this.actionUrl, company, "/", swapKey, "/", fileType, "/", latestNo);
        //this.headers.append("Content-Type", "application/json");

        return this.http
            .post<DataSwapResponse>(this.Url, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));

    }

    compensafeGetLoan(dataswapParams: DataSwapParams) {
        this.actionUrl = "DataSwap/CompenSafeGetLoan";
        this.Url = this.baseUrl.concat(this.actionUrl);
        //this.headers.append("Content-Type", "application/json");

        return this.http
            .post<DataSwapResponse>(this.Url, dataswapParams, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    compensafeGetLoanByInfo(company: string, swapKey: string, fileType: string, latestNo: string) {
        this.actionUrl = "DataSwap/CompenSafeGetLoan/";
        this.Url = this.baseUrl.concat(this.actionUrl, company, "/", swapKey, "/", fileType, "/", latestNo);
        //this.headers.append("Content-Type", "application/json");

        return this.http
            .post<DataSwapResponse>(this.Url, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    //--------------------------- LimeGear functions ------------------------------------------//

    limegearGetFile(dataswapParams: DataSwapParams) {
        this.actionUrl = "DataSwap/LimeGearGetFile";
        this.Url = this.baseUrl.concat(this.actionUrl);
        //this.headers.append("Content-Type", "application/json");
        return this.http
            .post<DataSwapResponse>(this.Url, dataswapParams, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
        // return this.http.post<DataSwapResponse>(this.Url, dataswapParams, { headers: this.headers })
        //     .pipe(
        //         catchError(this.handleError)
        //     )
    }

    limegearGetFileByInfo(company: string , swapKey: string , fileType: string  = "applic", latestNo: string  = "1") {
        this.actionUrl = "DataSwap/LimeGearGetFile/";
        this.Url = this.baseUrl.concat(this.actionUrl, company, "/", swapKey, "/", fileType, "/", latestNo);
        //this.headers.append("Content-Type", "application/json");

        return this.http
            .post<DataSwapResponse>(this.Url, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    limegearGetLoan(dataswapParams: DataSwapParams) {
        this.actionUrl = "DataSwap/LimeGearGetLoan";
        this.Url = this.baseUrl.concat(this.actionUrl);
        //this.headers.append("Content-Type", "application/json");

        return this.http
            .post<DataSwapResponse>(this.Url, dataswapParams, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));
    }

    limegearGetLoanByInfo(company: string, swapKey: string, loanNo: string, latestNo: string) {
        this.actionUrl = "DataSwap/LimeGearGetLoan/";
        this.Url = this.baseUrl.concat(this.actionUrl, company, "/", swapKey, "/", loanNo, "/", latestNo);
        //this.headers.append("Content-Type", "application/json");

        return this.http
            .post<DataSwapResponse>(this.Url, { headers: this.headers })
            .pipe(catchError(err => this.errorHandler(err, this.toastService)));

    }

}
