import { InteractionType } from "@azure/msal-browser";

export class Toast {
  id: string;
  toastType: ToastType;
  message: string;

  autoClose: boolean;
  fade: boolean;
  ignoreInDevEnvironment: boolean;
  options: any;
  keepAfterRouteChange: boolean;

  icon: IconType;

  constructor(init?: Partial<Toast>) {
    Object.assign(this, init);
  }
}

export enum ToastType {
  Success = 'Success',
  Warning = 'Warning',
  Danger = 'Danger',
  Info = 'Info',
  Primary = 'primary',
  Secondary = 'secondary',
  Light = 'light',
  Dark = 'dark'
}

export enum IconType {
  Check = 'check',
  Info = 'info',
  Warning = 'warning',
  Lock = 'lock',
  Unlock = 'unlock'
}
