<ng-container>
    <div class="loans-grid-container">
      <kendo-grid
        #genericGrid
        autosize="true"
        [kendoGridBinding]="allGroup"
        [columnMenu]="true"
        [reorderable]="true"
        [resizable]="true"
        [scrollable]="'scrollable'"
        [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
        [sortable]="{mode: 'single',allowUnsort: false}"
        [pageSize]="state.take"
        [skip]="state.skip"
        [filterable]="true"
        [selectable]="false">
      <ng-container *ngIf="showExportToolbar">
        <ng-template kendoGridToolbarTemplate>
          <button type="button" kendoGridExcelCommand icon="file-excel">
            Export to Excel
          </button>
        </ng-template>
      </ng-container>
      <kendo-grid-column *ngFor="let columnInfo of cols"
            field="{{ columnInfo.field }}"
            title="{{ columnInfo.title }}"
            format="{{ columnInfo.format }}"
            filter="{{ columnInfo.type }}"
            [filterable]="columnInfo.filterable"
            [hidden]="columnInfo.hidden"
            [width]="200"
      >
        <ng-container *ngIf="columnFieldInfoMap.has(columnInfo.field)">
            <ng-template kendoGridHeaderTemplate let-column>
                <span matTooltip='{{getHeaderTooltipText(columnInfo.title,columnFieldInfoMap.get(columnInfo.field))}}'
                    matTooltipPosition="above">
                    {{columnInfo.title}}
                </span>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="!columnFieldInfoMap.has(columnInfo.field) && columnFieldInfoMap.has(columnInfo.prettyField)">
          <ng-template kendoGridHeaderTemplate let-column>
              <span matTooltip='{{getHeaderTooltipText(columnInfo.title,columnFieldInfoMap.get(columnInfo.prettyField))}}'
                  matTooltipPosition="above">
                  {{columnInfo.title}}
              </span>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="!columnFieldInfoMap.has(columnInfo.field) && !columnFieldInfoMap.has(columnInfo.prettyField)">
            <ng-template kendoGridHeaderTemplate let-column>
                <span matTooltip="{{columnInfo.title}}" matTooltipPosition="above">{{columnInfo.title}}</span>
            </ng-template>
        </ng-container>

      </kendo-grid-column>
      <ng-template kendoGridNoRecordsTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
        </div>
      </ng-template>
      <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
      </ng-template>
      <kendo-grid-excel [fetchData]="allData" fileName="{{exportFileName}}"></kendo-grid-excel>
    </kendo-grid>

  </div>
</ng-container>
