<h1 class="title-center">Add Client</h1>
<br>
<br>

<div *ngIf="!clientsLoading && clients">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Client Name</mat-label>
        <input matInput [(ngModel)]="client.name">
    </mat-form-field>
    <br>
    
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Integration Abbreviated Name</mat-label>
        <input matInput [(ngModel)]="client.clientIntegrationAbbrName">
    </mat-form-field>
    <br>

    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>CompenSafe Internal Name</mat-label>
        <input matInput [(ngModel)]="client.compensafeInternalName">
    </mat-form-field>
    <br>
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Aliases</mat-label>
        <input matInput [(ngModel)]="client.aliases" />
    </mat-form-field>
    <br>

    Add Client Information
    <kendo-switch
        [(ngModel)]="clientInfoAdd"
    ></kendo-switch>
    <br><br>

    <div class="submit">
        <button id="btnSaveIntegration"
          mat-raised-button (click)="saveClient()" [disabled] = "savingClient">
          Add Client
        </button>
        <div class="k-i-loading" *ngIf="savingClient"></div>
    </div>
</div>


<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="clientsLoading">
    <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!clientsLoading && !clients">
    <div class="no-records-message">Loading failed.</div>
</div>