export class IntegrationIdentifier {
    integrationId: string;
    destinationId: number;
    destinationName: string;
    identifierIdValue: string;
    identifierNameValue: string;
    identifierKeyValue: string;
    createdDate: Date;
    updatedDate: Date;
    canFastFetch: boolean;
    canDataSwap: boolean;
    subscriptionName: string;
    comments?: string;
    integrationName?: string;
}