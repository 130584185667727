import { ClientContract } from "./client-contract";
import { InvoicePeriod } from "./invoice-period";
import { ColumnSettings } from "./column-settings";
import { InvoicingLoanType } from "./invoicing-loan-type";
import { InvoicePeriodMetrics } from "./invoice-period-metrics";

export class InvoicingReportLoan {
    loanNumber: string;
    loanGuid: string;
    channel: string;
    completedDate: Date;
    jsonFieldCollection: Map<string,string>;
    lastLTSModifiedDate: Date;
    postedAsOf?: Date;
    loanAmount: number;

    invoicePeriodId: string;
    invoicePeriod?: InvoicePeriod = null;

    clientContractId?: string;

    clientContract?: ClientContract = null;

    actionTaken: string;
    actionTakenDate?: Date;
    actualClosingDate?: Date;
    applicationDate?: Date;
    approvedDate?: Date;
    companyNMLSNumber: string;
    createdDate?: Date;
    disbursementDate?: Date;
    estClosingDate?: Date;
    lienPosition: string;
    loanOfficerNMLS: string;
    loanOfficerEmail: string;
    loanPurpose: string;
    loanSource: string;
    startedDate?: Date;
    signedDate?: Date;
    lostAccess: boolean;
    losUpdatedDate: Date;
    auditComment?: string;

    // InvoicedLoan Specific
    invoicedLoanId: string;
    completedDateRemoved?: boolean;
    invoiceCreditDate?: Date;
    invoicedAsOf?: Date;
    completedDateAfterInvoice?: Date;

    // CompletedLoan Specific
    completedLoanId: string;

    // Base Specifig
    loanId?: string;
    invoicingLoanType?: InvoicingLoanType;
    jsonDictionaryKeys: string[] = [];

    constructor(init?: Partial<InvoicingReportLoan>, c?: ClientContract, p?: InvoicePeriod) {
        this.invoicingLoanType = init.invoicingLoanType;
        this.loanId = init.loanId;
        this.completedLoanId = init.completedLoanId;
        this.invoicedLoanId = init.invoicedLoanId;

        this.loanNumber = init.loanNumber;
        this.loanGuid = init.loanGuid;
        this.channel = init.channel;
        this.jsonFieldCollection = init.jsonFieldCollection;
        this.invoicePeriodId = init.invoicePeriodId;
        this.loanAmount = init.loanAmount;

        this.actionTaken = init.actionTaken;
        this.companyNMLSNumber = init.companyNMLSNumber;
        this.lienPosition = init.lienPosition;
        this.loanOfficerNMLS = init.loanOfficerNMLS;
        this.loanOfficerEmail = init.loanOfficerEmail;
        this.loanPurpose = init.loanPurpose;
        this.loanSource = init.loanSource;
        this.auditComment = init.auditComment;
        this.lostAccess = init.lostAccess;

        if (init?.invoicePeriod != null) {
            this.invoicePeriod = init.invoicePeriod;
        }
        else if (p != null) {
            this.invoicePeriod = p;
        }

        if (init?.clientContract != null) {
            this.clientContract = init.clientContract;
        }
        else if (c != null) {
            this.clientContract = c;
        }

        // Date setting

        if (init?.completedDate != null) {
            this.completedDate = new Date(init.completedDate)
        }
        else {
            this.completedDate = null;
        }

        if (init?.lastLTSModifiedDate != null) {
            this.lastLTSModifiedDate = new Date(init.lastLTSModifiedDate)
        }
        else {
            this.lastLTSModifiedDate = null;
        }

        if (init?.postedAsOf != null) {
            this.postedAsOf = new Date(init.postedAsOf)
        }
        else {
            this.postedAsOf = null;
        }

        if (init?.losUpdatedDate != null) {
            this.losUpdatedDate = new Date(init.losUpdatedDate)
        }
        else {
            this.losUpdatedDate = null;
        }

        if (init?.invoicedAsOf != null) {
            this.invoicedAsOf = new Date(init.invoicedAsOf)
        }
        else {
            this.invoicedAsOf = null;
        }

        if (init?.invoiceCreditDate != null) {
            this.invoiceCreditDate = new Date(init.invoiceCreditDate)
        }
        else {
            this.invoiceCreditDate = null;
        }

        if (init?.completedDateAfterInvoice != null) {
            this.completedDateAfterInvoice = new Date(init.completedDateAfterInvoice)
        }
        else {
            this.completedDateAfterInvoice = null;
        }

        // Boolean setting up
        if (init?.completedDateRemoved != null) {
            this.completedDateRemoved = init.completedDateRemoved;
        }
        else {
            this.completedDateRemoved = false;
        }

        if (init.jsonFieldCollection != null) {
            let newKeys = Object.keys(init.jsonFieldCollection);
            if (newKeys != null) {
                this.jsonDictionaryKeys = newKeys;
            }
        }

        if (init.actionTakenDate != null) {
            this.actionTakenDate = new Date(init.actionTakenDate)
          }
          else {
            this.actionTakenDate = null;
          }

          if (init.actualClosingDate != null) {
            this.actualClosingDate = new Date(init.actualClosingDate)
          }
          else {
            this.actualClosingDate = null;
          }

          if (init.applicationDate != null) {
            this.applicationDate = new Date(init.applicationDate)
          }
          else {
            this.applicationDate = null;
          }

          if (init.approvedDate != null) {
            this.approvedDate = new Date(init.approvedDate)
          }
          else {
            this.approvedDate = null;
          }

          if (init.createdDate != null) {
            this.createdDate = new Date(init.createdDate)
          }
          else {
            this.createdDate = null;
          }

          if (init.disbursementDate != null) {
            this.disbursementDate = new Date(init.disbursementDate)
          }
          else {
            this.disbursementDate = null;
          }

          if (init.estClosingDate != null) {
            this.estClosingDate = new Date(init.estClosingDate)
          }
          else {
            this.estClosingDate = null;
          }

          if (init.startedDate != null) {
            this.startedDate = new Date(init.startedDate)
          }
          else {
            this.startedDate = null;
          }

          if (init.signedDate != null) {
            this.signedDate = new Date(init.signedDate)
          }
          else {
            this.signedDate = null;
          }

    }
}
