import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentStatus } from 'src/app/models/currentStatus';
import { ErrorCategory } from 'src/app/models/error-category';
import { ErrorSearchFilter } from 'src/app/models/error-search-filter';
import { ImportType } from 'src/app/models/import-type';
import { ReportConstants } from 'src/app/models/report-constants';
import { ReportErrorSearchFilter } from 'src/app/models/report-error-search-filter';


@Component({
  selector: 'app-report-error-search-filter',
  templateUrl: './report-error-search-filter.component.html',
  styleUrls: ['./report-error-search-filter.component.scss']
})
export class ReportErrorSearchFilterComponent implements OnInit {

  @Input() reportConstants: ReportConstants;
  @Input() errorSearchFilter: ReportErrorSearchFilter;

  @Output() updateFilter = new EventEmitter<ReportErrorSearchFilter>();
  @Output() clearGridFilters = new EventEmitter<boolean>();
  @Output() reloadCurrentList = new EventEmitter<boolean>();

  allErrorsReturned: boolean = false;
  allErrorsDisplayed: boolean = false;
  tabIndex: number = 0;
  origErrorSearchFilter: ReportErrorSearchFilter;
  //Category Filter
  selectedCategory: ErrorCategory = {
    errorCategoryId: 0,
    categoryName: "Select a Category",
    categoryDescription: "Select a Category",
    categoryPriority: 0
  };

  constructor() {
  }

  ngOnInit(): void {
    this.origErrorSearchFilter = Object.assign({}, this.errorSearchFilter);
    if (this.reportConstants.errorCategories != undefined) {
      this.reportConstants.errorCategories?.push(this.selectedCategory);
    }
  }


  updateFilters(): void {
    if (this.errorSearchFilter.startReceivedDate != undefined)
      this.errorSearchFilter.startReceivedDate = new Date(this.errorSearchFilter.startReceivedDate.toLocaleDateString());
    if (this.errorSearchFilter.endReceivedDate != undefined)
      this.errorSearchFilter.endReceivedDate = new Date(this.errorSearchFilter.endReceivedDate.toLocaleDateString());
    if (this.errorSearchFilter.startInitialErrorDate != undefined)
      this.errorSearchFilter.startInitialErrorDate = new Date(this.errorSearchFilter.startInitialErrorDate.toLocaleDateString());
    if (this.errorSearchFilter.endInitialErrorDate != undefined)
      this.errorSearchFilter.endInitialErrorDate = new Date(this.errorSearchFilter.endInitialErrorDate.toLocaleDateString());
    if (this.errorSearchFilter.startResolutionDate != undefined)
      this.errorSearchFilter.startResolutionDate = new Date(this.errorSearchFilter.startResolutionDate.toLocaleDateString());
    if (this.errorSearchFilter.endResolutionDate != undefined)
      this.errorSearchFilter.endResolutionDate = new Date(this.errorSearchFilter.endResolutionDate.toLocaleDateString());

    this.updateFilter.emit(this.errorSearchFilter)
  }

  formatDateSearchField(event: string): Date {
    let rc: Date = undefined;
    if (event != undefined && event != '') {
      rc = new Date(event);
      if(isNaN(rc.getTime())){
        rc = undefined
      }
    }
    return rc;
  }

  clearFilters(): void {
    this.selectedCategory = this.reportConstants.errorCategories.find(c => c.errorCategoryId == 0);
    this.errorSearchFilter = Object.assign({}, this.origErrorSearchFilter);
    this.clearGridFilters.emit(true);
    this.updateFilters();
  }

  onTabSelect(event: any): void {
    this.tabIndex = event.index;
  }

  setAllErrorsReturned(): void {
    this.allErrorsReturned = true;
  }

  setAllErrorsDisplayed(): void {
    this.allErrorsDisplayed = true;
  }


  reloadErrorData() {
    this.reloadCurrentList.emit(true);
  }
}
