import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  versionString: string;
  constructor() { }

  ngOnInit(): void {
    var versionDom = document.getElementById("version");
        if (versionDom) {
            this.versionString = versionDom.innerHTML;
        }
  }

}
