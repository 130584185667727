<h1 style="text-align:center;">Custom SQL Queries</h1>
<br />

<ng-container *ngIf="!integration">
  <div class="col">
    <div class="card search-card h-100">
      <div class="card-body">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="{{aliasSearchTerm.defaultText}}" [(ngModel)]="aliasSearchTerm.searchValue" (keydown.enter)="updateSearch(aliasSearchTerm)" (input)="updateSearch(aliasSearchTerm)">
          <div class="input-group-append">
            <button mat-stroked-button (click)="updateSearch(aliasSearchTerm)" class="px-1">
              <mat-icon class="material-icons-outlined">search</mat-icon>
            </button>
            <button mat-stroked-button (click)="clearSearch(aliasSearchTerm)" class="px-1">
              <mat-icon class="material-icons-outlined">close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="aliasSearchTerm.hasSearchTerm" class="card-footer card-current-search">
        Current Search: {{aliasSearchTerm.currentSearchValue | json}}
      </div>
    </div>
  </div>
</ng-container>

<kendo-grid [data]="gridView"
            [pageSize]="state.take"
            [skip]="state.skip"
            [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
            [sortable]="{mode: 'single',allowUnsort: false}"
            [sort]="state.sort"
            [scrollable]="'scrollable'"
            [selectable]="true"
            [filter]="filter"
            [filterable]="true"
            (sortChange)="sortChange($event)"
            (selectionChange)="selectionChangeHandler($event)"
            (pageChange)="pageChange($event)"
            (filterChange)="filterChange($event)"
            (add)="goToAdd($event)">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand [disabled]="!checkAccess(['LOSTalker.Admin'])">Add New Query</button>
  </ng-template>

  <kendo-grid-column field="queryName" title="Query Name" [width]="200">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="queryTypeName" title="Query Type" [width]="100">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="integrationSourceTypeId" title="Integration Type" [width]="100" *ngIf="!integration">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="integrationName" title="Integration" [width]="400" *ngIf="!integration">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true"
                                     [column]="column"
                                     [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <ng-template kendoPagerTemplate>
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes [pageSizes]="[5, 10, 25, 50, 100]"></kendo-pager-page-sizes>
  </ng-template>

  <ng-template kendoGridNoRecordsTemplate>
    <div class="empty_spacing_for_norecords">
      <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridLoadError">No records found.</div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && gridLoadError">Loading failed.</div>
    </div>
  </ng-template>

</kendo-grid>
