<ng-container *ngIf="isInTab == false">
  <h1 style="text-align:center;">Integration Identifiers</h1>
</ng-container>
<ng-container *ngIf="isInTab == true">
  <h4 style="text-align:center;">Integration Identifiers</h4>
</ng-container>
<br/>
<div *ngIf="integration">
  <div id="kendo-identifier-grid" style="padding: 10px;">
    <kendo-grid [data]="gridView" [reorderable]="true" [pageSize]="state.take" [skip]="state.skip" [sortable]="false"
      [pageable]="true" [scrollable]="'scrollable'" [filterable]="false" [selectable]="true"
      (selectionChange)="selectionChange($event)" (dataStateChange)="dataStateChange($event)"
      (edit)="editHandler($event)" (add)="addHandler($event)">
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand [disabled]="!checkAccess(['LOSTalker.Admin'])">Add Identifier</button>
      </ng-template>
      <kendo-grid-column field="destinationName" title="Destination" [width]="130" [filterable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="identifierIdValue" title="Id Value" [width]="150" [filterable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="identifierNameValue" title="Name Value" [width]="150" [filterable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="createdDate" title="Created" [width]="180" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.createdDate | date:'yyyy-MM-dd hh:mm:ss a'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="updatedDate" title="Updated" [width]="180" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.updatedDate | date:'yyyy-MM-dd hh:mm:ss a'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="canFastFetch" title="Can FastFetch" [width]="130" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.canFastFetch.toString() | uppercase}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="canFastFetch" title="Can DataSwap" [width]="130" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.canDataSwap.toString() | uppercase}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="comments" title="Comments" [width]="180" [filterable]="false">
      </kendo-grid-column>
      <kendo-grid-command-column *ngIf="checkAccess('Admin')" title="Update" [width]="150" [sticky]="true">
        <ng-template kendoGridCellTemplate>
          <button kendoGridEditCommand [primary]="true" [disabled]="!checkAccess(['LOSTalker.Admin'])">Edit</button>
        </ng-template>
      </kendo-grid-command-column>

      <ng-template kendoGridNoRecordsTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
        </div>
      </ng-template>

      <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
      </ng-template>

    </kendo-grid>
  </div>

  <button mat-raised-button (click)="generateIdentifier()" [disabled]="!checkAccess(['LOSTalker.Admin'])">Generate Identifier</button>
</div>

<ng-container *ngIf="showAddUpdateForm && checkAccess(['LOSTalker.Admin'])">
  <app-add-integration-identifier [isInTab]="true" 
    [client]="client" 
    [integration]="integration"
    [selectedIdentifier]="selectedIdentifier" 
    [inAddMode]="inAddMode" 
    [populatedAdd]="generatedNewIdentifier"
    (finished)="addUpdateFormFinished($event)"
    (outputIdentifier)="outputIdentifier($event)">
  </app-add-integration-identifier>
</ng-container>
