<ng-container *ngIf="isInTab == false">
  <h1 style="text-align:center;">Run Time</h1>
</ng-container>
<ng-container *ngIf="isInTab == true">
  <h4 style="text-align:center;">Run Time</h4>
</ng-container>
<div class="inline" *ngIf="integrationRunTimeSelected == false && addingIntegrationRunTime == false">
  <button mat-flat-button class="inline" style="background-color: #ff9933; color: white;"
    (click)="addIntegrationRunTimeSwitch()">+</button>
  <br />
</div>
<div *ngIf="integrationRunTimeSelected == true && addingIntegrationRunTime == false">
  <p><b>Select a RunTime to view: </b></p>

  <mat-button-toggle-group (change)="selectIntegrationRunTime($event.value)" [hideSingleSelectionIndicator]="true">
    <mat-button-toggle *ngFor="let r of integrationRunTimes" [value]="r" [checked]="r == integrationRunTime">{{r.importTypeDescription}} - {{r.destinationEndpointName}}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <button mat-flat-button class="inline add-runtime-btn" style="background-color: #ff9933; color: white;"
    (click)="addIntegrationRunTimeSwitch()">+</button>
  <br />

  <h3 style="float: left; margin-bottom: 0;">{{integrationRunTime.importTypeDescription}} - {{integrationRunTime.destinationEndpointName}} RunTimes</h3> 

  <button *ngIf="integrationRunTime.activePushBack" matTooltip="RunTime has active PushBack, RunTime may be pushed back automatically"
    class=" inline btn pushback-btn" style="color: white;">
    <i style="color: red; " class="bi bi-exclamation-circle-fill"></i>
  </button>

  <br/>
  <br/>

  <h5>LastRunTime: {{ integrationRunTime.lastRunTime | date: 'EEEE, MMMM d, y, h:mm:ss a ZZZZ'}}</h5>
  <h5>NextRunTime: {{ integrationRunTime.nextRunTime | date: 'EEEE, MMMM d, y, h:mm:ss a ZZZZ'}}</h5>
  <div *ngIf="integrationRunTime.lastDataSent != undefined;then lds else emptyLDS"></div>
  <ng-template #lds>
    <h5>LastDataSent: {{ integrationRunTime.lastDataSent | date: 'EEEE, MMMM d, y, h:mm:ss a ZZZZ'}}</h5>
  </ng-template>
  <ng-template #emptyLDS>
    <h5>LastDataSent: Not Populated</h5>
  </ng-template>
  <div *ngIf="checkAccess(['LOSTalker.Admin'])" class="runtime-button-row">
    <button mat-stroked-button (click)="updateIntegrationRunTime(1, 'hours')">Rollback 1 Hour</button>
    <button mat-stroked-button (click)="updateIntegrationRunTime(3, 'hours')">Rollback 3 Hours</button>
    <button mat-stroked-button (click)="updateIntegrationRunTime(12, 'hours')">Rollback 12 Hours</button>
    <button mat-stroked-button (click)="rollbackIntegrationRuntime('StartOfDay')">Start of Day</button>
    <button mat-stroked-button (click)="rollbackIntegrationRuntime('StartOfMonth')">Start of Month</button>
    <br />
    <br />
    <p><b>Select a date and time for LastRunTime: </b></p>
    <kendo-datetimepicker style="width: 210px" placeholder="Enter a date and time" format="MM/dd/yyyy hh:mm:ss a"
      [(value)]="lastRunTimeDateInput">
    </kendo-datetimepicker>
    <br />
    <br />
    <p><b>Select a date and time for NextRunTime: </b></p>
    <kendo-datetimepicker style="width: 210px" placeholder="Enter a date and time" format="MM/dd/yyyy hh:mm:ss a"
      [(value)]="nextRunTimeDateInput">
    </kendo-datetimepicker>
    <br />
    <br />
  </div>
  <button mat-stroked-button [disabled]="savingIntegrationRuntime" style="float:left; background-color: #ff9933; color: #FFFFFF"
      (click)="updateIntegrationRunTime()" *ngIf="checkAccess(['LOSTalker.Admin'])">Set RunTime</button>

    <button mat-stroked-button *ngIf="checkAccess(['LOSTalker.Admin'])" [disabled]="savingIntegrationRuntime" style="float:right; background-color: #d40404; color: #FFFFFF"
    (click)="setDeletingIntegrationRunTime()">Delete RunTime</button>
</div>

<div *ngIf="addingIntegrationRunTime == true">
  <button mat-stroked-button *ngIf="integrationRunTimes.length != 0" class="inline" style="background-color: #ff9933; color: white;"
    (click)="selectIntegrationRunTime(integrationRunTimes[0])">Back</button>

  <br />
  <br />

  <mat-form-field appearance="fill">
    <mat-label>Destination Endpoint</mat-label>
    <mat-select (selectionChange)="selectDestinationEndpoint($event)">
      <mat-option *ngFor="let dest of destinationEndpoints" [value]="dest">
        {{dest.endpointName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <br />
  <br />

  <mat-form-field appearance="fill">
    <mat-label>Import Type</mat-label>
    <mat-select (selectionChange)="selectImportType($event)">
      <mat-option *ngFor="let imp of importTypes" [value]="imp">
        {{imp.typeDescription}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="checkAccess(['LOSTalker.Admin'])">
    <br />
    <br />
    <p><b>Select a date and time for LastRunTime: </b></p>
    <kendo-datetimepicker style="width: 210px" placeholder="Enter a date and time" format="MM/dd/yyyy hh:mm:ss a"
      [(value)]="lastRunTimeDateInput">
    </kendo-datetimepicker>
    <br />
    <br />
    <p><b>Select a date and time for NextRunTime: </b></p>
    <kendo-datetimepicker style="width: 210px" placeholder="Enter a date and time" format="MM/dd/yyyy hh:mm:ss a"
      [(value)]="nextRunTimeDateInput">
    </kendo-datetimepicker>
    <br />
    <br />
    <br />
    <br />
    <button mat-stroked-button [disabled]="savingIntegrationRuntime" style="float:left; background-color: #ff9933; color: #FFFFFF" (click)="addIntegrationRunTime()">Add
      RunTime</button>
  </div>
</div>

<ng-template #deleteRunTimeDialog>
  <h2 matDialogTitle>Confirm Deleting RunTime</h2>
  <mat-dialog-content>
    The {{integrationRunTime.importTypeDescription}} - {{integrationRunTime.destinationEndpointName}} runtime will be deleted.
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose (click)="deleteIntegrationRunTime()">Delete</button>
    <button mat-button matDialogClose>Cancel</button>
  </mat-dialog-actions>
</ng-template>
