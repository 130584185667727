import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { State, DataSourceRequestState, SortDescriptor } from '@progress/kendo-data-query';
import { Observable, Subscription, Subject, forkJoin, iif } from 'rxjs';
import { takeUntil, mergeMap } from "rxjs/operators";
import { Integration } from '../../models/integration';
import { Client } from 'src/app/models/client';
import { IntegrationError } from '../../models/integration-error';
import { IntegrationErrorResponse } from '../../models/integration-error-response';
import { ClientIntegrationService } from "../../services/client-integration.service";
import { IntegrationErrorsService } from '../../services/integration-errors.service';
import { ErrorListGridV2Component } from '../error-list-grid-v2/error-list-grid-v2.component';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ToastService } from '../../services/toast.service';
import { Server } from 'src/app/models/server';
import { ServerService } from 'src/app/services/server.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { ServiceErrorListGridComponent } from '../service-error-list-grid/service-error-list-grid.component';
import { ServiceError } from 'src/app/models/service-error';
import { ErrorCategory } from 'src/app/models/error-category';
import { CurrentStatus } from 'src/app/models/currentStatus';
import { ImportType } from 'src/app/models/import-type';
import { ErrorSearchFilter } from 'src/app/models/error-search-filter';
@Component({
  selector: 'app-error-messages-v2',
  templateUrl: './error-messages-v2.component.html',
  styleUrls: ['./error-messages-v2.component.scss'],
  providers: [ClientIntegrationService, IntegrationErrorsService]
})
export class ErrorMessagesv2Component implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() integration: Integration = null;
  @Input() isInTab: boolean = false;
  @Input() currentClient: Client = null;

  @ViewChild("tabstrip") public tabstrip: TabStripComponent;
  @ViewChild("allListComponent") public allListComponent: ErrorListGridV2Component;
  @ViewChild("serviceErrorListComponent") public serviceErrorListComponent: ServiceErrorListGridComponent;
  @ViewChild("criticalErrorListComponent") public criticalErrorListComponent: ErrorListGridV2Component;
  tabIndex: number = 0;
  dataLoading: boolean = true;
  allPopulated: boolean = false;
  serviceErrorsPopulated: boolean = false;
  criticalPopulated: boolean = false;

  allErrorsList: IntegrationError[];
  serviceErrorList: ServiceError[];
  criticalErrorsList: IntegrationError[];
  public allDestinations: DestinationEndpoint[];
  public categories: ErrorCategory[];
  public statuses: CurrentStatus[];
  public importTypes: ImportType[];

  integrations: Integration[];
  clients: Client[];
  servers: Server[];
  integrationsMap: Map<string, Integration>;

  critErrorSearchFilter: ErrorSearchFilter = new ErrorSearchFilter(null, null, 45);
  allErrorSearchFilter: ErrorSearchFilter = new ErrorSearchFilter(null, null, 30);

  critErrorSorting: SortDescriptor[] = [{ field: "currentStatusId", dir: 'desc' }, { field: "receivedDate", dir: 'desc' }];
  allErrorSorting: SortDescriptor[] = [{ field: "receivedDate", dir: 'desc' }];

  hasDestinations: boolean = false;
  hasCategories: boolean = false;
  hasStatuses: boolean = false;
  hasImportTypes: boolean = false;
  gettingClients: boolean = false;

  errorsSub: Subscription;

  constructor(
    protected typeService: TypeConstantService,
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientIntegrationService,
    private serverService: ServerService,
    private toastService: ToastService,
    private errorLoggingService: IntegrationErrorsService) {
    this.allErrorsList = [];
    this.serviceErrorList = [];
    this.criticalErrorsList = [];
    this.integrations = [];
    this.allDestinations = [];
  }

  ngOnInit() {
    this.resetAll();
    this.getTypesAndDestinations();

    if (this.integration != null && this.integration != undefined && this.integration.integrationId != "") {
      this.integrations = [this.integration];
      this.integrationsMap = new Map(this.integrations.map(k => [k.integrationId, k] as [string, Integration]));
      if(this.currentClient != null && this.currentClient != undefined){
        this.clients = [this.currentClient];
      }
      this.getErrors();
    }
    else {
      this.getClients();
      this.getIntegrations();
      this.getErrors();
    }
  }

  resetAll(): void {
    this.tabIndex = 0;
    this.tabstrip?.selectTab(0);
    this.dataLoading = true;
    this.allPopulated = false;
    this.serviceErrorsPopulated = false;
    this.criticalPopulated = false;
    this.allErrorsList = [];
    this.serviceErrorList = [];
    this.criticalErrorsList = [];
    this.integrations = [];

    if (this.integrationsMap != undefined && this.integrationsMap != null) {
      this.integrationsMap.clear();
    }

    this.gettingClients = false;
    this.clients = [];

    this.servers = [];
    this.hasDestinations = false;
    this.allDestinations = [];
    this.hasCategories = false;
    this.categories = [];
    this.hasStatuses = false;
    this.statuses = [];
    this.hasImportTypes = false;
    this.importTypes = [];

    if (this.errorsSub != undefined && this.errorsSub != null) {
      this.errorsSub.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.errorsSub.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getErrors(forceRefresh: Boolean = false) {
    this.dataLoading = true;
    if ((!this.allPopulated || forceRefresh) && this.isInTab){
      this.getAllErrors(false);
    }
    else if (this.tabIndex == 0 && (!this.criticalPopulated || forceRefresh) && !this.isInTab) {
      this.dataLoading = true;
      this.getCriticalErrors();
    }
    else if (this.tabIndex == 2 && (!this.serviceErrorsPopulated || forceRefresh) && !this.isInTab) {
      this.dataLoading = true;
      this.getServiceErrors();
    }
    else if (this.tabIndex == 1 && (!this.allPopulated || forceRefresh) && !this.isInTab) {
        this.dataLoading = true;
        this.getAllErrors();
    }
    else {
      this.dataLoading = false;
    }
  }

  updateAllErrors(): void {
    this.dataLoading = true;
    this.allPopulated = false;
    this.getAllErrors();
  }

  getErrorsInBackground() {
    if (this.gettingClients == false && (this.clients == null || this.clients.length == 0)) {
      this.getClients();
    }

    if(this.servers == null){
      this.servers = [];
      this.checkAllServersLastDataSent();
    }

    if (this.tabIndex !== 0 && !this.criticalPopulated && !this.isInTab) {
      this.getCriticalErrors();
    }

    if (this.tabIndex !== 2 && !this.serviceErrorsPopulated && !this.isInTab) {
      this.getServiceErrors();
    }

    if (this.tabIndex !== 1 && !this.allPopulated && !this.isInTab) {
      this.getAllErrors();
    }
  }

  sendUpdatedDataToTable(e: any) {
    this.getErrors(true);
  }

  filterAllErrors(filter: ErrorSearchFilter) {
    this.allErrorSearchFilter = filter;
    this.allPopulated = false;
    this.getAllErrors();
  }

  filterCriticalErrors(filter: ErrorSearchFilter) {
    this.critErrorSearchFilter = filter;
    this.criticalPopulated = false;
    this.getCriticalErrors();
  }

  onTabSelect(event: any) {
    this.tabIndex = event.index;
    this.getErrors();
  }

  getIntegrations(): void {
    this.clientService.getAllIntegrations()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (g) => {
          this.integrationsMap = new Map(g.map(k => [k.integrationId, k] as [string, Integration]));
          this.integrations = g;
        });
  }

  getClients(): void {
    this.gettingClients = true;
    if (this.isInTab) {
      this.clients = [this.currentClient];
      this.gettingClients = false;
    }
    else {
      this.clientService.getAllClients().subscribe(clients => {
        this.clients = clients;
        this.gettingClients = false;
      },
      error =>{
        this.gettingClients = false;
      });
    }
  }

  getTypesAndDestinations(): void {
    this.typeService.getAllDestinationEndpoints()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
          this.allDestinations = r;
          this.hasDestinations = true;
        },
        (error) => {
          this.hasDestinations = false;
        });

    this.typeService.getAllImportTypes()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.importTypes = r;
        this.hasImportTypes = true;
        },
        (error) => {
          this.hasImportTypes = false;
        });

    this.errorLoggingService.getAllErrorCategories()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.categories = r;
        this.hasCategories = true;
        },
        (error) => {
          this.hasCategories = false;
        });

    this.errorLoggingService.getAllCurrentStatuses()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.statuses = r;
        this.hasStatuses = true;
        },
        (error) => {
          this.hasStatuses = false;
        });
  }

  getAllErrorsForInTab(loadInBackground: boolean = true): void {
    var eObs = null;
    if (this.integration == null){
      eObs = this.errorLoggingService.getAllErrors(this.allErrorSearchFilter);
    }
    else {
      eObs = this.errorLoggingService.getAllErrorsById(this.integration.integrationId);
    }

    if (eObs != null) {
      this.errorsSub = eObs.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.criticalErrorsList = data.filter(x => x.currentStatusId > 2);
          this.criticalPopulated = true;
          this.allErrorsList = data;
          this.dataLoading = false;
          this.allPopulated = true;

          if (this.tabIndex == 0) {
            this.criticalErrorListComponent?.refreshFromParent();
          }
          else if (this.tabIndex == 1) {
            this.allListComponent?.refreshFromParent();
          }

          if (loadInBackground) {
            this.getErrorsInBackground();
          }
        },
        (error) => {
          this.dataLoading = false;
          this.allPopulated = true;
          this.criticalPopulated = true;
          this.toastService.toastCreate("Loading Integration Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
        }
      );
    }
  }

  getAllErrors(loadInBackground: boolean = true): void {
    var eObs = null;
    if (this.integration == null){
      eObs = this.errorLoggingService.getAllErrors(this.allErrorSearchFilter);
    }
    else {
      eObs = this.errorLoggingService.getAllErrorsById(this.integration.integrationId);
    }

    if (eObs != null) {
      this.errorsSub = eObs.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.allErrorsList = data;
          this.allListComponent?.refresh();
          this.dataLoading = false;
          this.allPopulated = true;
          if (loadInBackground) {
            this.getErrorsInBackground();
          }
        },
        (error) => {
          this.dataLoading = false;
          this.allPopulated = true;
          this.toastService.toastCreate("Loading Integration Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
        }
      );
    }
  }

  getCriticalErrors(): void {
    this.errorsSub = this.errorLoggingService.getSnapshot(this.critErrorSearchFilter)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (g) => {
        this.criticalErrorsList = g;
        this.criticalErrorListComponent?.refresh();
        this.dataLoading = false;
        this.criticalPopulated = true;
        this.getErrorsInBackground();
      },
      (error) => {
        this.dataLoading = false;
        this.criticalPopulated = true;
        this.toastService.toastCreate("Loading Critical Integration Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
      }
    );
  }

  getServiceErrors(loadInBackground: boolean = true): void {
    var seObs = null;

    if (this.integration == null) {
      seObs = this.errorLoggingService.getAllServiceErrors();
    }
    else {
      seObs = this.errorLoggingService.getAllServiceErrorsByServerId(this.integration.serverId);
    }

    if (seObs != null) {
      this.errorsSub = seObs.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.serviceErrorList = data;
          this.serviceErrorListComponent?.refresh();
          this.dataLoading = false;
          this.serviceErrorsPopulated = true;
          if (loadInBackground) {
            this.getErrorsInBackground();
          }
        },
        (error) => {
          this.dataLoading = false;
          this.serviceErrorsPopulated = true;
          this.toastService.toastCreate("Loading Service Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
        });
    }
  }

  public checkAllServersLastDataSent() {
    this.serverService.getAllServersInformation()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (s) => {
        this.servers = s;
        s.forEach(server => {
          if (server.serverId !== '0' && server.testServer == false) {
            if (server.lastDataSentServer !== null) {
              let lds = new Date(server.lastDataSentServer);

              if (lds !== null) {
                if (lds.getTime() < Date.now() - (server.serverAlertTimespan * 60 * 1000)) {
                  this.toastService.toastCreate("Last Data Sent Error. Server " + server.serverId + ": " + lds.toLocaleString(), "Warning", { autoClose: true, keepAfterRouteChange: false, ignoreInDevEnvironment: true });
                }
              }
            }
            else if (server.integrationCount > 0) {
              this.toastService.toastCreate("Last Data Sent not populated for Server " + server.serverId, "Warning", { autoClose: true, keepAfterRouteChange: false, ignoreInDevEnvironment: true });
            }
          }
        });
      },
      (error) => {
        this.dataLoading = false;
      }
    );
  }
}
