import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SortDescriptor, orderBy, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent, MultipleSortSettings, GridComponent } from '@progress/kendo-angular-grid';
import { DestinationEndpoint } from '../../../models/destination-endpoint';
import { ImportType } from '../../../models/import-type';
import { SearchTerm } from '../../../models/search-term';
import { Report } from 'src/app/models/report';
import { ReportService } from 'src/app/services/report.service';
import { ToastService } from 'src/app/services/toast.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { LOSTalkerTitleService } from '../../../services/lostalker-title-service.service';
import { ReportConstants } from 'src/app/models/report-constants';
import { ReportTabData } from 'src/app/models/report-tab-data';
import { ReportingUtils } from 'src/app/utilities/reporting-utilities';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { BaseEndpointMapping } from 'src/app/models/base-endpoint-mapping';
import { BaseEndpointMappingType } from 'src/app/models/base-endpoint-mapping-type';
import { ColumnSettings } from 'src/app/models/column-settings';
import { BaseMapping } from 'src/app/models/base-mapping';
import { GenericEndpoint } from 'src/app/models/generic-endpoint';
import { ViewGenericGridComponent } from '../../view-generic-grid/view-generic-grid.component';
const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-mappings-report-view',
  templateUrl: './mappings-report-view.component.html',
  styleUrls: ['./mappings-report-view.component.scss'],
  providers: [ReportService, TypeConstantService]
})
export class MappingsReportViewComponent implements OnInit, OnDestroy {
  @ViewChild('viewGenericGrid') public viewGenericGrid: ViewGenericGridComponent;

  componentDestroyed$: Subject<boolean> = new Subject();
  reportingUtils: ReportingUtils;
  reportConstants: ReportConstants;

  allBaseMappingData: BaseMapping[] = [];
  allGenericEndpointData: GenericEndpoint[] = [];
  allBaseEndpointMappingData: BaseEndpointMapping[] = [];

  filteredBaseMappingData: BaseMapping[] = [];
  filteredGenericEndpointData: GenericEndpoint[] = [];
  filteredBaseEndpointMappingData: BaseEndpointMapping[] = [];

  @Input() reportId = "";
  @Input() currentReportToShow: Report;
  @Input() destinationEndpointItems: DestinationEndpoint[] = [];
  @Input() importTypeItems: ImportType[] = [];

  currReport: Report;

  // current mapping type for report and data
  currMappingType: BaseEndpointMappingType = BaseEndpointMappingType.BaseEndpointMapping;

  public mappingType = BaseEndpointMappingType;

  reportColumns: ColumnSettings[] = [];
  reportMappingData: any;
  reportSort: SortDescriptor = null;

  // filter and search variables
  filtersChanged = false;
  xmlSearchTerm: SearchTerm;
  jsSearchTerm: SearchTerm;

  // filter cards data
  destinationEndpointItemsSelected: DestinationEndpoint[] = []; // csd.. lg..
  importTypeItemsSelected: ImportType[] = [];

  showMappingContainer: boolean = false;
  haveMappings: boolean = false;
  gettingMappings: boolean = false;

  constructor(
    protected reportService: ReportService,
    protected toastService: ToastService,
    protected typeService: TypeConstantService,
    private location: Location,
    private router: Router,
    private titleService: LOSTalkerTitleService
  ) {
    this.reportingUtils = new ReportingUtils();
  }

  ngOnInit(): void {
    if (this.currentReportToShow) {
      this.currReport = new Report(this.currentReportToShow);
      this.setUpReportData();
    }
  }

  setUpReportData(): void {
    if (this.currReport != null && this.currReport != undefined) {
      this.titleService.setUpTitle('Report - ' + this.currentReportToShow.title);
      this.xmlSearchTerm = new SearchTerm('xmlConfig', 'Search XML Config...');
      this.jsSearchTerm = new SearchTerm('', 'Search JS...');
      this.currMappingType = this.reportingUtils.getBaseEndpointMappingTypeByString(this.currReport.reportTypeName);
      this.reportColumns = this.reportingUtils.getDefaultColumns(this.currMappingType);

      if (this.currReport.dataSproc !== "" && this.currReport.dataSproc !== undefined) {
        if (this.currMappingType == BaseEndpointMappingType.BaseMapping) {
          this.getBaseMappingDataForReport();
        }
        else if (this.currMappingType == BaseEndpointMappingType.GenericEndpoint){
          this.getGenericEndpointDataForReport();
        }
        else if (this.currMappingType == BaseEndpointMappingType.BaseEndpointMapping){
          this.getBaseEndpointMappingDataForReport();
        }
      }
      else {
        this.toastService.toastCreate("Error in Report Properties.", "Warning");
      }
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public getBaseMappingDataForReport() : void {
    this.allBaseMappingData = [];
    this.showMappingContainer = true;
    this.haveMappings = false;
    this.gettingMappings = true;
    if (this.currReport != null
      && this.currReport.dataSproc !== ""
      && this.currReport.dataSproc !== undefined
      && this.currMappingType == BaseEndpointMappingType.BaseMapping) {

        this.reportService
          .getBaseMappingTypeQueryData(this.currReport.reportId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (data) => {
              if (data != null && data.length > 0) {
                this.allBaseMappingData = data;
                this.filteredBaseMappingData = data;
                this.gettingMappings = false;
                this.haveMappings = true;
              }
              else {
                this.gettingMappings = false;
                this.haveMappings = false;
              }
            }, (error) => {
              // console.log(error);
              this.toastService.toastCreate("Error getting mappings.", "Warning");
            }
          );
    }
    else {
      this.toastService.toastCreate("Error in Report Properties.", "Warning");
      this.gettingMappings = false;
    }


  }

  public getGenericEndpointDataForReport() : void {
    this.allGenericEndpointData = [];
    this.showMappingContainer = true;
    this.haveMappings = false;
    this.gettingMappings = true;

    if (this.currReport != null
      && this.currReport.dataSproc !== ""
      && this.currReport.dataSproc !== undefined
      && this.currMappingType == BaseEndpointMappingType.GenericEndpoint) {

        this.reportService
          .getGenericEndpointTypeQueryData(this.currReport.reportId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (data) => {
              if (data != null && data.length > 0) {
                this.allGenericEndpointData = data;
                this.filteredGenericEndpointData = data;
                this.gettingMappings = false;
                this.haveMappings = true;
              }
              else {
                this.gettingMappings = false;
                this.haveMappings = false;
              }
            }, (error) => {
              // console.log(error);
              this.toastService.toastCreate("Error getting mappings.", "Warning");
            }
          );
    }
    else {
      this.toastService.toastCreate("Error in Report Properties.", "Warning");
      this.gettingMappings = false;
    }
  }

  public getBaseEndpointMappingDataForReport() : void {
    this.allBaseEndpointMappingData = [];
    this.showMappingContainer = true;
    this.haveMappings = false;
    this.gettingMappings = true;

    if (this.currReport != null
          && this.currReport.dataSproc !== ""
          && this.currReport.dataSproc !== undefined
          && this.currMappingType == BaseEndpointMappingType.BaseEndpointMapping) {

            this.reportService
              .getBaseEndpointMappingTypeQueryData(this.currReport.reportId)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                (data) => {
                  if (data != null && data.length > 0) {
                    var upd: BaseEndpointMapping[] = data.map(x => new BaseEndpointMapping(x));
                    this.allBaseEndpointMappingData = upd;
                    this.filteredBaseEndpointMappingData = upd;
                    this.gettingMappings = false;
                    this.haveMappings = true;
                  }
                  else {
                    this.gettingMappings = false;
                    this.haveMappings = false;
                  }
                }, (error) => {
                  // console.log(error);
                  this.toastService.toastCreate("Error getting mappings.", "Warning");
                }
              );
    }
    else {
      this.toastService.toastCreate("Error in Report Properties.", "Warning");
      this.gettingMappings = false;
    }
  }

  // reset filters
  public resetFilters(e: Event): void {
    this.destinationEndpointItemsSelected = [];
    this.importTypeItemsSelected = [];
    this.updateFilteredData();
  }

  // apply filters
  public applyFilters(e: Event): void {
    this.updateFilteredData();
  }

  // update Filtered Data - Base Mapping
  public updateFilteredDataForBaseMapping() {
    if (this.currMappingType == BaseEndpointMappingType.BaseMapping) {
      this.filteredBaseMappingData = [];
      this.filteredBaseMappingData = this.allBaseMappingData;

      // filter by search
      if (this.currReport.hasSearch) {
        if (this.xmlSearchTerm.hasSearchTerm) {
          this.filteredBaseMappingData = filterBy(
            this.filteredBaseMappingData,
            {
              logic: 'and',
              filters: [
                { field: "xmlConfig", operator: "contains", value: this.xmlSearchTerm.currentSearchValue, ignoreCase: true }
              ]
            }
          );
        }
        if (this.jsSearchTerm.hasSearchTerm) {
          this.filteredBaseMappingData = filterBy(
            this.filteredBaseMappingData,
            {
              logic: 'and',
              filters: [
                { field: "javaScript", operator: "contains", value: this.jsSearchTerm.currentSearchValue, ignoreCase: true }
              ]
            }
          );
        }
      }

      // filter by filter cards
      if (this.currReport.hasFilterCards) {
        // destination endpoint
        if (this.destinationEndpointItemsSelected.length > 0
          && this.destinationEndpointItemsSelected.length != this.destinationEndpointItems.length) {
            let ids = this.destinationEndpointItemsSelected.map(i => i.destinationEndpointId);
            this.filteredBaseMappingData = this.filteredBaseMappingData.filter(
              i => ids.indexOf(i.destinationEndpointId) != -1
            );
        }
        // import type ids
        if (this.importTypeItemsSelected.length > 0
          && this.importTypeItemsSelected.length != this.importTypeItems.length) {
          let ids = this.importTypeItemsSelected.map(i => i.importTypeId);
          this.filteredBaseMappingData = this.filteredBaseMappingData.filter(
            i => ids.indexOf(i.importTypeId) != -1
          );
        }
      }
    }
  }

  // update Filtered Data - Generic Endpoint
  public updateFilteredDataForGenericEndpoint() {
    if (this.currMappingType == BaseEndpointMappingType.GenericEndpoint){
      this.filteredGenericEndpointData = [];
      this.filteredGenericEndpointData = this.allGenericEndpointData;

      // filter by search
      if (this.currReport.hasSearch) {
        if (this.xmlSearchTerm.hasSearchTerm) {
          this.filteredGenericEndpointData = filterBy(
            this.filteredGenericEndpointData,
            {
              logic: 'and',
              filters: [
                { field: "xmlConfiguration", operator: "contains", value: this.xmlSearchTerm.currentSearchValue, ignoreCase: true }
              ]
            }
          );
        }
        if (this.jsSearchTerm.hasSearchTerm) {
          this.filteredGenericEndpointData = filterBy(
            this.filteredGenericEndpointData,
            {
              logic: 'and',
              filters: [
                { field: "javaScript", operator: "contains", value: this.jsSearchTerm.currentSearchValue, ignoreCase: true }
              ]
            }
          );
        }
      }

      // filter by filter cards
      if (this.currReport.hasFilterCards) {
        // destination endpoint
        if (this.destinationEndpointItemsSelected.length > 0
          && this.destinationEndpointItemsSelected.length != this.destinationEndpointItems.length) {
            let ids = this.destinationEndpointItemsSelected.map(i => i.destinationEndpointId);
            this.filteredGenericEndpointData = this.filteredGenericEndpointData.filter(
              i => ids.indexOf(i.destinationId) != -1
            );
        }
        // import type ids
        if (this.importTypeItemsSelected.length > 0
          && this.importTypeItemsSelected.length != this.importTypeItems.length) {
          let ids = this.importTypeItemsSelected.map(i => i.importTypeId);
          this.filteredGenericEndpointData = this.filteredGenericEndpointData.filter(
            i => ids.indexOf(i.importTypeId) != -1
          );
        }
      }
    }
  }

  // update Filtered Data - Base Endpoint Mapping
  public updateFilteredDataForBaseEndpointMapping() {
    if (this.currMappingType == BaseEndpointMappingType.BaseEndpointMapping){
      // reset filtered data
      this.filteredBaseEndpointMappingData = [];
      this.filteredBaseEndpointMappingData = this.allBaseEndpointMappingData;

      // filter by search
      if (this.currReport.hasSearch) {
        if (this.xmlSearchTerm.hasSearchTerm) {
          this.filteredBaseEndpointMappingData = filterBy(
            this.filteredBaseEndpointMappingData,
            {
              logic: 'and',
              filters: [
                { field: "xmlConfig", operator: "contains", value: this.xmlSearchTerm.currentSearchValue, ignoreCase: true }
              ]
            }
          );
        }
        if (this.jsSearchTerm.hasSearchTerm) {
          this.filteredBaseEndpointMappingData = filterBy(
            this.filteredBaseEndpointMappingData,
            {
              logic: 'and',
              filters: [
                { field: "javaScript", operator: "contains", value: this.jsSearchTerm.currentSearchValue, ignoreCase: true }
              ]
            }
          );
        }
      }

      // filter by filter cards
      if (this.currReport.hasFilterCards) {
        // destination endpoint
        if (this.destinationEndpointItemsSelected.length > 0
          && this.destinationEndpointItemsSelected.length != this.destinationEndpointItems.length) {
            let ids = this.destinationEndpointItemsSelected.map(i => i.destinationEndpointId);
            this.filteredBaseEndpointMappingData = this.filteredBaseEndpointMappingData.filter(
              i => ids.indexOf(i.destinationEndpointId) != -1
            );
        }
        // import type ids
        if (this.importTypeItemsSelected.length > 0
          && this.importTypeItemsSelected.length != this.importTypeItems.length) {
          let ids = this.importTypeItemsSelected.map(i => i.importTypeId);
          this.filteredBaseEndpointMappingData = this.filteredBaseEndpointMappingData.filter(
            i => ids.indexOf(i.importTypeId) != -1
          );
        }
      }
    }
  }

  // update Filtered Data - All
  public updateFilteredData() {
    if (this.currMappingType == BaseEndpointMappingType.BaseMapping) {
      this.updateFilteredDataForBaseMapping();
    }
    else if (this.currMappingType == BaseEndpointMappingType.GenericEndpoint){
      this.updateFilteredDataForGenericEndpoint();
    }
    else if (this.currMappingType == BaseEndpointMappingType.BaseEndpointMapping){
      this.updateFilteredDataForBaseEndpointMapping();
    }
  }

  // SEARCH TERM FUNCTIONS
  clearSearch(item: SearchTerm) {
    item.clear();
    this.updateFilteredData();
    this.viewGenericGrid.resetSkipIndex();
  }

  updateSearch(item: SearchTerm) {
    item.save();
    this.updateFilteredData();
    this.viewGenericGrid.resetSkipIndex();
  }

  public externalFilterChange(value: any): void {
    //console.log("externalFilterChange", value);
  }

}
