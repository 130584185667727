import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Client } from '../../models/client';
import { CustomSQLQueryService } from '../../services/sql-queries.service';
import { Router } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Observable, Subject } from 'rxjs';
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
  PageChangeEvent
} from '@progress/kendo-angular-grid';
import { User } from '../../models/user';
import { SearchTerm } from 'src/app/models/search-term';
import { CustomSQLQuery } from 'src/app/models/sql-query';
import { Integration } from '../../models/integration';


const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-sql-queries',
  templateUrl: './sql-queries.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sql-queries.component.scss']
})
export class SQLQueriesComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() integration: Integration;

  // Constants
  type: 'numeric' | 'input' = 'input';
  allGroup: CustomSQLQuery[];

  // Dynamic
  gridView: GridDataResult;
  filteredGroup: CustomSQLQuery[];
  filter: CompositeFilterDescriptor;
  gridViewIsLoading = true;
  gridLoadError = false;
  public state: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  aliasSearchTerm: SearchTerm;

  constructor(protected sqlService: CustomSQLQueryService, private router: Router, private user: User) {
  }

  ngOnInit(): void {
    this.state.sort = [{ field: "name", dir: 'asc' }];
    if (this.integration == null) {
      this.sqlService.getAllCustomQueries()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        if (r != null) {
          this.allGroup = r;
          this.loadDataIntoGridView();
        }
        else {
          this.gridViewIsLoading = false;
        }
        this.gridLoadError = false;
      },
      error => {
        this.gridViewIsLoading = false;
        this.gridLoadError = true;
      });
      this.aliasSearchTerm = new SearchTerm('', 'Search by Integration Name');
    }
    else {
      this.sqlService.getAllCustomQueriesByIntegration(this.integration.integrationId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        if (r != null) {
          this.allGroup = r;
          this.loadDataIntoGridView();
        }
        else {
          this.gridViewIsLoading = false;
        }
        this.gridLoadError = false;
      },
      error => {
        this.gridViewIsLoading = false;
        this.gridLoadError = true;
      });
      this.aliasSearchTerm = new SearchTerm('', 'Search by Integration Name');
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  selectionChangeHandler(event: any): void {
    let selectedQuery = event.selectedRows[0].dataItem;
    if (this.checkAccess(["LOSTalker.Admin"])) {
      this.router.navigate(['/editQuery', selectedQuery.customSQLQueryId]);
    }
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  loadDataIntoGridView() {
    let orderedData = orderBy(this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };

  }

  clearSearch(item: SearchTerm) {
    item.clear();
    this.updateFilteredData();
  }

  updateSearch(item: SearchTerm) {
    item.save();
    this.updateFilteredData();
  }

  // update filters without the column filters
  public updateFilteredData(filterFromGrid: CompositeFilterDescriptor = null) {
    this.state.skip = 0;
    // filter clients by client name or alias
    this.filteredGroup = filterBy(this.allGroup, {
      logic: 'or',
      filters: [
        // {field: "name", operator:"contains", value: this.aliasSearchTerm.currentSearchValue, ignoreCase: true},
        {field: "integrationId", operator:"contains", value: this.aliasSearchTerm.currentSearchValue, ignoreCase: true}
      ]
    });

    this.updateGrid();
  }

  // Sort

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  public goToAdd(event: any) {
    if (this.checkAccess(["LOSTalker.Admin"])) {
      this.router.navigate(['/addQuery']);
    }
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
