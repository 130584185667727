<h1 style="text-align:center;">Reports</h1>
<br />
<ng-container *ngIf="!haveReports || !reportList">
  <div class="empty_spacing_for_norecords">
    <div class="k-i-loading" *ngIf="gettingReports"></div>
    <div class="no-records-message" *ngIf="!gettingReports && !reportList">No records found.</div>
  </div>
</ng-container>

<ng-container *ngIf="reportList && haveReports">
  <div *ngIf="reportList != undefined" class="row row-cols-1 row-cols-lg-2">
    <div *ngFor="let r of reportList" class="col mb-4" (click)="selectReport(r)">
      <div class="card">
        <div class="card-header bg-secondary text-white">{{r.title}}</div>
        <div class="card-body w-75">{{r.description}}</div>
      </div>
    </div>
  </div>
</ng-container>

