<div>
  <h4 class="title-center mb-2">Failed Loans</h4>

  <ng-container *ngIf="!haveData">
    <ng-container *ngIf="!gettingData">
      <div class="title-center mt-5">
        <div class="no-records-message">Loading failed.</div>
      </div>
    </ng-container>
    <ng-container *ngIf="gettingData">
      <div class="title-center mt-5">
        <div class="k-i-loading"></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="haveData">

    <ng-container *ngIf="allData">
      <app-view-generic-grid #genericGrid
        [data]="allData"
        [columns]="allColumns"
        [exportFileName]="exportFileName"
        [isResizeable]="true"
        [showExportToolbar]="true"
        [sortDescriptions]="sortDescs"
        [loading]="gettingData"
        >
      </app-view-generic-grid>
    </ng-container>

  </ng-container>
</div>
