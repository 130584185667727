<h1 class="title-center py-3">Admin Dashboard</h1>

<ng-container *ngIf="!haveReports || !reportList">
  <div class="empty_spacing_for_norecords">
    <div class="k-i-loading" *ngIf="gettingReports"></div>
    <div class="no-records-message" *ngIf="!gettingReports && !reportList">No reports found.</div>
  </div>
</ng-container>

<ng-container *ngIf="reportList && haveReports">
  <div class="container-fluid">
    <div *ngIf="reportList != undefined" class="row row-cols-1 row-cols-lg-2">
      <div *ngFor="let r of reportList" class="col mb-4" (click)="selectReport(r)">
        <div class="card">
          <div class="card-header bg-secondary text-white">{{r.title}}</div>
          <div class="card-body w-75">{{r.description}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="container-fluid">
  <div class="row row-cols-1 row-cols-lg-2">
    <div class="col mb-4" (click)="goToCustomSQL()">
      <div class="card">
        <div class="card-header bg-secondary text-white">Custom SQL Query Mappings</div>
        <div class="card-body w-75">Get Custom SQL Query Mappings</div>
      </div>
    </div>
  </div>
</div>