import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Server } from '../models/server';
import { protectedResources } from '../auth-config';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';

@Injectable()
export class ServerService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  // Used to calculate last runtime
  getAllServersInformation(): Observable<Server[]> {
    this.actionUrl = "Servers/GetAllServerInformation/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<Server[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateServer(server: Server): Observable<Server> {
    this.actionUrl = "Server/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put<Server>(this.Url, server, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllServers(): Observable<Server[]> {
    this.actionUrl = "Servers/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<Server[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateRunningServer(
    integrationId: string,
    serverId: string
  ): Observable<Server> {
    this.actionUrl = "Integration/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      integrationId,
      "/",
      serverId,
      "/false/0"
    );

    return this.http
      .patch<Server>(this.Url, null)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
  
  //--------------------------- Last Data Sent ------------------------------------------//

  // getAllServersLastDataSent(): Observable<Map<string, Date>> {
  //   this.actionUrl = "Servers/ServersLastDataSent";
  //   this.Url = this.baseUrl.concat(this.actionUrl);
  //   return this.http.get<Map<string, Date>>(this.Url);
  // }

}
