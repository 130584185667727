import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { Report } from '../../models/report';
import { ReportService } from '../../services/report.service';
import { ToastService } from '../../services/toast.service';
import { Toast, ToastType } from '../../models/toast';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
  providers: [ReportService]
})
export class AdminDashboardComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  reportList: Report[] = [];

  gettingReports: boolean = false;
  haveReports: boolean = false;

  constructor(
    protected reportService: ReportService,
    protected toastService: ToastService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.gettingReports = true;
    this.haveReports = false;

    this.reportService.getAdminReports()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          if (data != null) {
            this.reportList = data;
            this.haveReports = true;
          }
          else {
            this.toastService.toastCreate('No Reports Returned.', ToastType.Warning);
          }
          this.gettingReports == false;
        },
        (error) => {
          this.toastService.toastCreate('Reports failed to return.', ToastType.Danger);
          this.gettingReports == false;
        }
      );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public selectReport(r: Report): void {
    if (r.isCustomReport) {
      if (r?.routerUrl == '' || r?.routerUrl == undefined) {
        this.toastService.toastCreate("Report is Custom Report but Router URL not setup.", "Warning");
      }
      else {
        this.router.navigate([r?.routerUrl]);
      }
    }
    else {
      this.router.navigate(['/report-view/', r.reportId]);
    }
  }

  public goToCustomSQL(): void {
    this.router.navigate(['/sql-queries']);
  }
}
