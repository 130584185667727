import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Client } from '../models/client';
import { protectedResources } from '../auth-config';
import { ClientInformation } from '../models/client-information';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';

@Injectable()
export class ClientInformationService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Client Information ------------------------------------------//

  getAllClientInformation(): Observable<ClientInformation[]> {
    this.actionUrl = "ClientInformations";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<ClientInformation[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllDeletedClientInformation(): Observable<ClientInformation[]> {
    this.actionUrl = "ClientInformations/Deleted";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<ClientInformation[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientInformationById(clientInformationId: string): Observable<ClientInformation> {
    this.actionUrl = "ClientInformation/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientInformationId);

    return this.http
      .get<ClientInformation>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getClientInformationByClientId(clientId: number): Observable<ClientInformation> {
    this.actionUrl = "ClientInformation/Client/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientId.toString());

    return this.http
      .get<ClientInformation>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateClientInformation(clientInformation: ClientInformation): Observable<any> {
    this.actionUrl = "ClientInformation/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .put(this.Url, clientInformation, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertClientInformation(clientInformation: ClientInformation): Observable<any> {
    this.actionUrl = "ClientInformation/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, clientInformation, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  undeleteClientInformation(clientInformation: ClientInformation): Observable<any> {
    this.actionUrl = "ClientInformation/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientInformation.clientInformationId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .patch<ClientInformation>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  deleteClientInformation(clientInformation: ClientInformation): Observable<ClientInformation> {
    this.actionUrl = "ClientInformation/";
    this.Url = this.baseUrl.concat(this.actionUrl, clientInformation.clientInformationId.toString());
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<ClientInformation>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
