import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import { GenericEndpoint } from '../models/generic-endpoint';
import { BaseMapping } from '../models/base-mapping';
import { GenericEndpointJavaScript } from '../models/generic-endpoint-javascript';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseMappingService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Nongeneric Loan Mappings ------------------------------------------//

  getAllBaseMappings(): Observable<BaseMapping[]> {
    this.actionUrl = "BaseMappings/all";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<BaseMapping[]>(this.Url) 
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getBaseMappingsByDestination(dest: number): Observable<BaseMapping[]> {
    this.actionUrl = "BaseMappings/";
    this.Url = this.baseUrl.concat(this.actionUrl, dest.toString());

    return this.http
      .get<BaseMapping[]>(this.Url) 
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getBaseMappingsByDestinationAndImportType(dest: number, impType: number): Observable<BaseMapping[]> {
    this.actionUrl = "BaseMappings/";
    this.Url = this.baseUrl.concat(this.actionUrl, dest.toString(), "/", impType.toString());

    return this.http
      .get<BaseMapping[]>(this.Url) 
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getBaseMappingById(id: string): Observable<BaseMapping> {
    this.actionUrl = "BaseMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl, id);

    return this.http
      .get<BaseMapping>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getBaseMappingHistory(name: string, destinationEndpointId: number, importTypeId: number): Observable<BaseMapping[]> {
    this.actionUrl = "BaseMappingHistory/";
    this.Url = this.baseUrl.concat(this.actionUrl, name, "/", destinationEndpointId.toString(), "/", importTypeId.toString());

    return this.http
      .get<BaseMapping[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST
  insertBaseMapping(baseMapping: BaseMapping): Observable<string> {
    this.actionUrl = "BaseMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<string>(this.Url, baseMapping, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateBaseMapping(baseMapping: BaseMapping): Observable<any> {
    this.actionUrl = "BaseMapping/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, baseMapping, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //----------------------------Generic Endpoint---------------------------------//

  // GET
  getGenericEndpointById(endpointId: string): Observable<GenericEndpoint> {
    this.actionUrl = "GenericEndpoint/";
    this.Url = this.baseUrl.concat(this.actionUrl, endpointId);

    return this.http
      .get(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getGenericEndpoints(): Observable<GenericEndpoint[]> {
    this.actionUrl = "GenericEndpoints/all";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<GenericEndpoint[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST
  insertGenericEndpoint(endpoint: GenericEndpoint): Observable<string> {
    this.actionUrl = "GenericEndpoint";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");
    
    return this.http
      .post<string>(this.Url, endpoint, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PUT
  updateGenericEndpoint(endpoint: GenericEndpoint): Observable<any> {
    this.actionUrl = "GenericEndpoint";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, endpoint, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // DELETE

  deleteGenericEndpoint(endpoint: GenericEndpoint): Observable<any> {
    this.actionUrl = "GenericEndpoint/" + endpoint.genericEndpointId;
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete(this.Url, { headers: this.headers } )
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //----------------------------Generic Endpoint History---------------------------------//

  getGenericEndpointHistory(name: string): Observable<GenericEndpoint[]> {
    this.actionUrl = "GenericEndpointHistory/";
    this.Url = this.baseUrl.concat(this.actionUrl, name);

    return this.http
      .get<GenericEndpoint[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getGenericEndpointJavaScriptHistory(name: string): Observable<GenericEndpointJavaScript[]> {
    this.actionUrl = "GenericEndpointHistory/JavaScript/";
    this.Url = this.baseUrl.concat(this.actionUrl, name);

    return this.http
      .get<GenericEndpointJavaScript[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
  
  //----------------------------Generic Endpoint Conversion---------------------------------//

  convertBaseMappingToGeneric(baseMapping: BaseMapping, baseMappingName: string): Observable<string> {
    this.actionUrl = "GenericEndpoint/Convert/";
    this.Url = this.baseUrl.concat(this.actionUrl, baseMappingName);
    this.headers.append("Content-Type", "application/json");
    
    return this.http
      .post<string>(this.Url, baseMapping, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  convertEndpointToAPI(genericEndpoint: GenericEndpoint, genericEndpointName: string): Observable<string> {
    this.actionUrl = "GenericEndpoint/APIConvert/";
    this.Url = this.baseUrl.concat(this.actionUrl, genericEndpointName);
    this.headers.append("Content-Type", "application/json");
    
    return this.http
      .post<string>(this.Url, genericEndpoint, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
