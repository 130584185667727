import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, AfterViewChecked, AfterContentChecked, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

import { HttpResponse } from "@angular/common/http";
import { filter, take, map, tap } from "rxjs/operators";
import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { Client } from "../../models/client";
import { CsvSummary } from "../../models/csv-summary";
import { Integration } from "../../models/integration";
import { IntegrationJavaScript } from "../../models/integration-javascript";
import { IntegrationMetrics } from "../../models/integration-metrics";
import { Server } from "../../models/server";
import { User } from "../../models/user";

import { MonacoPageComponent } from "../monaco-editor/monaco-editor.component";
import { ClientIntegrationService } from "../../services/client-integration.service";
import { Observable, Subscription } from 'rxjs';
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
  PageChangeEvent,
  SelectionEvent
} from '@progress/kendo-angular-grid';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Title } from '@angular/platform-browser';
import { LOSTalkerTitleService } from "../../services/lostalker-title-service.service";

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'serverintegrations',
  templateUrl: './serverintegrations.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./serverintegrations.component.scss'],
  providers: [ ClientIntegrationService, LOSTalkerTitleService ],
})
export class ServerIntegrationsComponent implements OnInit {
  public state: DataSourceRequestState = {
    skip: 0,
    take: 10,
    sort: []
  };
  selectedIntegration: Integration;
  gridIntegrations: GridDataResult;
  // V2
  gridView: GridDataResult;
  type: 'numeric' | 'input' = 'input';
  searchTerm: string;
  filteredGroup: Integration[];
  listSettings: any;
  allGroup: Integration[];
  filter: CompositeFilterDescriptor;
  gridViewIsLoading = true;
  private sub: any;

  constructor(
      protected clients: ClientIntegrationService, 
      private router: Router, 
      private route: ActivatedRoute,
      private titleService: LOSTalkerTitleService
  ) {
    this.state.sort = [{ field: "integrationId", dir: 'asc' }];
    this.listSettings = {
      singleSelection: true,
      enableSearchFilter: true,
      showCheckbox: false,
      labelKey: 'clientId',
      primaryKey: 'identifier',
      position: top,
      escapeToClose: true
    };
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.titleService.setUpTitle('App Server ' + params['name']);
      
      this.clients.getAllIntegrationsByServer(params['name']).subscribe(r => {
        this.allGroup = r;
        this.loadDataIntoGridView();
      });
    });
  }
  
  ngOnDestroy(): void {
    
  }

  selectionChangeHandler(selectedRows: SelectionEvent) {
    //get the selected item
    const { dataItem, index } = selectedRows.selectedRows[0];
    this.selectedIntegration = dataItem;
    this.router.navigate(['/editConfig', this.selectedIntegration.clientName, this.selectedIntegration.name]);
  }

  loadDataIntoGridView() {
    let orderedData = orderBy(this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.allGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.updateGrid();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.filter = filter;
    this.filteredGroup = filterBy(this.allGroup, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };

  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
