import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { EditDefaultConfigsComponent } from '../../app/components/edit-default-configs/edit-default-configs.component';

@Injectable()
export class EditDefaultConfigGuard  {

    canDeactivate(component: EditDefaultConfigsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
          return component.canDeactivate() ? true :
            window.confirm("You are about to leave the page without saving your changes. Are you sure you want to leave this page?");
    }

}
