import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ClientInformation } from 'src/app/models/client-information';
import { ClientContract } from 'src/app/models/client-contract';
import { ClientContractService } from 'src/app/services/client-contract.service';
import { InvoicePeriod } from '../../models/invoice-period';
import { InvoicingService } from 'src/app/services/invoicing.service';
import { ToastService } from '../../services/toast.service';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { SortDescriptor } from '@progress/kendo-data-query';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { ColumnSettings } from 'src/app/models/column-settings';
import { Client } from 'src/app/models/client';
import { User } from '../../models/user';

@Component({
  selector: 'app-client-contract-view',
  templateUrl: './client-contract-view.component.html',
  styleUrls: ['./client-contract-view.component.scss'],
  providers: [ClientContractService, InvoicingService]
})
export class ClientContractViewComponent implements OnInit, OnDestroy, OnChanges {
  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() selectedClient: ClientInformation = null;
  @Input() selectedDestination: DestinationEndpoint = null;
  @Input() showExport: boolean = false;
  @Output() itemSelected = new EventEmitter<any>();

  @Input() selectThisContract: ClientContract = null;
  public gridSelectedItem: ClientContract = null;
  @Output() itemSelectedFromOverride = new EventEmitter<any>();

  gettingContracts: boolean = false;
  hasContracts: boolean = false;
  allGroup: ClientContract[];
  filteredGroup: ClientContract[];
  selectedItem: ClientContract;
  contractSort: SortDescriptor[] =  [{ field: "clientFullName", dir: 'asc' }];

  public contractColumns: ColumnSettings[] = [
    { field: 'clientFullName', title: 'Full Name', type: 'text',},
    { field: 'clientShortName', title: 'Short Name', type: 'text',},
    { field: 'identifierIdValue', title: 'Identifier Id Value', type: 'text',},
    { field: 'identifierNameValue', title: 'Identifier Name Value', type: 'text',},
    //{ field: 'identifierKeyValue', title: 'Identifier Key Value', type: 'text',},
    { field: 'losTalkerClientId', title: 'LOSTalker Client Id', type: 'text',},
    //{ field: 'comments', title: 'Comments', type: 'text',},
    { field: 'disabled', title: 'Disabled', type: 'text',},
    { field: 'recreateInvoicesForAuditPeriod', title: 'Recreate Invoices For Audit Period', type: 'text',},
    { field: 'productionInvoiceReport', title: 'Production Invoice Report', type: 'text'},
    { field: 'clientContractId', title: 'Client Contract Id', hidden: true },
    { field: 'currentCompletedField', title: 'Completed Field', type: 'text'},
    { field: 'salesForceId', title: 'Salesforce Id', type: 'text'},
    { field: 'sfCustomerNumber', title: 'SF Customer Number', type: 'text'},
    // { field: 'clientInformationId', title: 'Client Information Id', hidden: true },
    // { field: 'contractStartDate', title: 'Contract Start Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm:ss a}', hidden: true },
    // { field: 'contractTermDate', title: 'Contract Term Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm:ss a}', hidden: true },
    // { field: 'billingStartDate', title: 'Billing Start Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm:ss a}', hidden: true },
    // { field: 'destinationEndpointId', title: 'destinationEndpointId', hidden: true },
    // { field: 'updatedDate', title: 'Updated Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm:ss a}', hidden: true },
    // { field: 'deleted', title: 'deleted', type: 'boolean', hidden: true },
    // { field: 'invoiceDayOfMonth', title: 'invoiceDayOfMonth', type: 'text', hidden: true },
    // { field: 'useFirstBusinessDay', title: 'useFirstBusinessDay', type: 'boolean', hidden: true },
  ];

  constructor(
    protected invoicingService: InvoicingService,
    protected clientContractService: ClientContractService,
    private toastService: ToastService,
    private user: User
  ) { }

  showProdClientSwitch: boolean = false;
  showProdContractsOnly: boolean = true;

  ngOnInit(): void {
    this.getContracts();

    if (this.checkAccess() == true) {
      this.showProdClientSwitch = true;
    }
  }

  updateShowProdContracts(value: boolean): void {
    this.showProdContractsOnly = value;
    this.filterContracts();
  }

  checkAccess(): boolean {
    return this.user.checkAccess(["LOSTalker.Admin"]);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if((changes?.selectedClent?.currentValue != null && changes?.selectedClient?.firstChange != true)
      || (changes?.selectedDestination?.currentValue != null && changes?.selectedDestination?.firstChange != true)) {
        this.getContracts();
      }
    if(changes?.selectThisContract?.currentValue != null && changes?.selectThisContract?.currentValue != undefined
      && (changes?.selectThisContract?.previousValue == undefined || changes?.selectThisContract?.previousValue == null )) {
          if (this.hasContracts && this.filteredGroup != null && this.filteredGroup.length > 0) {
            var tmp = this.filteredGroup.find(x => x.clientContractId == this.selectThisContract.clientContractId);
            if (tmp != null) {
              this.selectedItem = tmp;
              this.gridSelectedItem = tmp;
              this.itemSelectedFromOverride.emit(this.selectedItem);
            }

          }
      }
  }

  getContracts(): void {
    this.gettingContracts = true;
    this.allGroup = [];
    this.filteredGroup = [];

    this.clientContractService.getAllClientContractWithInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((r) => {
        if (r != null && r.length > 0) {
          this.allGroup = r;
          this.gettingContracts = false;
          this.hasContracts = true;

          this.filterContracts();
        }
        else {
          this.gettingContracts = false;
          this.toastService.toastCreate("No contracts returned.", "Warning");
        }
      },
      (error) => {
        this.gettingContracts = false;
        this.toastService.toastCreate("Error returning contracts.", "Warning");
      });
  }

  filterContracts(): void {
    this.filteredGroup = [];

    if (this.allGroup.length > 0) {
      if (this.showProdContractsOnly) {
        this.filteredGroup = this.allGroup.filter(c => c.productionInvoiceReport == true);
      }
      else {
        this.filteredGroup = this.allGroup;
      }

      if (this.selectedDestination != null) {
        this.filteredGroup = this.filteredGroup.filter(c => c.destinationEndpointId == this.selectedDestination.destinationEndpointId);
      }

      if (this.selectThisContract != null && this.selectThisContract != undefined) {
        var tmp = this.filteredGroup.find(x => x.clientContractId == this.selectThisContract.clientContractId);
        if (tmp != null) {
          this.selectedItem = this.selectThisContract;
          this.gridSelectedItem = this.selectThisContract;
          this.itemSelectedFromOverride.emit(this.selectedItem);
        }
      }
    }
    else if (this.gettingContracts == false) {
      this.getContracts();
    }
  }

  getItemFromGenericGrid(event: any): void {
    this.selectedItem = event;
    this.itemSelected.emit(this.selectedItem);
  }
}
