import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../models/user';

@Injectable()
export class RoleGuard  {
    constructor(private user: User, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let customRedirect = next.data['roleGuardRedirect'];
        let requiredRoles = next.data['requiredRoles'];
        let allowedUser = false;

        if (this.user != undefined) {
            allowedUser = this.user.checkAccess(requiredRoles);
        }

        // if we want to redirect based on a role, we can do that here as well

        if (!allowedUser) {
            let redirect = customRedirect ? customRedirect : '/home';
            this.router.navigate([redirect]);
        }

        return allowedUser;
    }
}
