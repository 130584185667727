<div *ngIf="!currReport">
  <h1 class="report-title">
    Report : Integration Bulk Action Report
  </h1>
  <div class="k-i-loading" *ngIf="pageLoading"></div>
</div>
<div *ngIf="currReport">

  <h1 class="report-title">
    Report <span *ngIf="currReport">: {{currReport.title}}</span>
  </h1>

  <hr class="hr-solid">

  <!-- Action Buttons -->
  <ng-container>
    <div class="match-form-field title-center mb-3">
      <mat-button-toggle-group
          #group="matButtonToggleGroup"
          [multiple]="false"
          [hideSingleSelectionIndicator]="true"
          (change)="onToggleActionChange($event.value)">
          <mat-button-toggle
            [checked]="bulkActionType === bulkIntegActionType.LockUnlock"
            value="LockUnlock"
            aria-label="Lock Unlock">
            Lock / Unlock Integrations
          </mat-button-toggle>
          <mat-button-toggle
            [checked]="bulkActionType === bulkIntegActionType.MoveServerIntegrations"
            value="MoveServerIntegrations"
            aria-label="Move Server Integrations">
            Move Server Integrations
          </mat-button-toggle>
          <mat-button-toggle
            [checked]="bulkActionType === bulkIntegActionType.UpdateRunTimes"
            value="UpdateRunTimes"
            aria-label="Update RunTimes">
            Update RunTimes
          </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-container>

  <ng-container *ngIf="bulkActionSelected">

    <!-- Action Area -->
    <ng-container *ngIf="bulkActionType == bulkIntegActionType.LockUnlock">
      <div class="report-switch">
        <div class="btn-group btn-group-lg btn-group-toggle" role="group" aria-label="Lock Group" data-toggle="buttons">
          <input type="radio"
                class="btn-check"
                id="report-switch-locked"
                [value]="false"
                [ngModel]="!switchLocked" (ngModelChange)="switchLocked = $event"
                data-ng-model="!switchLocked"
                (click)="onLockSwitchChange($event)">
              <label class="btn" [ngClass]="{'btn-danger': !switchLocked, 'btn-secondary': switchLocked}" for="report-switch-locked">
                <span class="k-icon k-i-close-circle"></span> Locked
              </label>

          <input type="radio"
                class="btn-check"
                id="report-switch-unlocked"
                [value]="true"
                [(ngModel)]="switchLocked"
                data-ng-model="switchLocked"
                (click)="onLockSwitchChange($event)">
              <label class="btn" [ngClass]="{'btn-secondary': !switchLocked, 'btn-success': switchLocked}" for="report-switch-unlocked">
                <span class="k-icon k-i-check-circle"></span> Unlocked
            </label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="bulkActionType == bulkIntegActionType.MoveServerIntegrations">
      <div class="report-switch">
        <div class="d-flex flex-row justify-content-center align-items-center align-self-center gap-3">
          <div>
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Select a server</mat-label>
              <mat-select [(value)]="selectedServer" (selectionChange)="changeServer($event)">
                <mat-option *ngFor="let server of serverItems" [value]="server">
                  {{server.description}} - Count: {{server.integrationCount}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="pb-3">
            <button mat-raised-button (click)="openServerInfoDialog()" class="px-2">
              <mat-icon class="material-icons-outlined">help_outline</mat-icon>
              See Server Information
            </button>
          </div>
        </div>

      </div>

    </ng-container>

    <ng-container *ngIf="bulkActionType == bulkIntegActionType.UpdateRunTimes">
      <div class="mt-2 mb-2">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>RunTime Update Fields</mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="runTimeUpdateFormGroup" role="group" >
            <div class="report-switch">
              <div class="report-filter-cards">

                <div class="row row-cols-2 row-cols-md-2 row-cols-sm-2 g-4 pb-1">


                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header d-flex justify-content-between">
                        <div class="align-self-center">LastRunTime</div>
                        <mat-checkbox
                          [checked]="enableLastRunTime"
                          (change)="changeEnableRunTimeChecks('LAST', $event.checked)"
                          labelPosition="before">
                          Enable
                        </mat-checkbox>
                      </div>
                      <div class="card-body">
                        <div class="card-title"></div>
                        <div class="example-wrapper">
                            <kendo-form-field showErrors="initial">
                              <kendo-datetimepicker
                                formControlName="lastDate"
                                [calendarType]="'infinite'"
                                [animateCalendarNavigation]="true"
                                [navigation]="false"
                                [required]="enableLastRunTime"
                                [disabled]="!enableLastRunTime"
                                format="MM/dd/yyyy hh:mm:ss a"></kendo-datetimepicker>
                              <kendo-formerror *ngIf="
                              (runTimeUpdateFormGroup.get('lastDate').touched ||
                              runTimeUpdateFormGroup.get('lastDate').dirty) && enableLastRunTime &&
                              runTimeUpdateFormGroup.get('lastDate')?.errors?.required">
                                Date is required if enabled.
                              </kendo-formerror>
                              <kendo-formerror *ngIf="
                              lastDateFormControl.valid && enableLastRunTime
                              && nextDateFormControl.valid && enableNextRunTime
                              && lastDateFormControl.value > nextDateFormControl.value">
                                Last Run Time Date must before or equal to the Next Run Time Date if both are enabled.
                              </kendo-formerror>
                              <kendo-formerror *ngIf="!enableLastRunTime && !enableNextRunTime">
                                One of LastRunTime or NextRunTime Dates must be enabled.
                              </kendo-formerror>
                            </kendo-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header d-flex justify-content-between">
                        <div class="align-self-center">NextRunTime</div>
                        <mat-checkbox
                          [checked]="enableNextRunTime"
                          (change)="changeEnableRunTimeChecks('NEXT', $event.checked)"
                          labelPosition="before">
                          Enable
                        </mat-checkbox>
                      </div>
                      <div class="card-body text-center">
                        <div class="example-wrapper">

                            <kendo-form-field showErrors="initial">
                              <kendo-datetimepicker
                                formControlName="nextDate"
                                [calendarType]="'infinite'"
                                [animateCalendarNavigation]="true"
                                [navigation]="false"
                                [required]="enableNextRunTime"
                                [disabled]="!enableNextRunTime"
                                format="MM/dd/yyyy hh:mm:ss a"></kendo-datetimepicker>
                              <kendo-formerror *ngIf="
                              (runTimeUpdateFormGroup.get('nextDate').touched ||
                              runTimeUpdateFormGroup.get('nextDate').dirty) && enableNextRunTime &&
                              runTimeUpdateFormGroup.get('nextDate')?.errors?.required">
                                Date is required if enabled.
                              </kendo-formerror>
                              <kendo-formerror *ngIf="!enableLastRunTime && !enableNextRunTime">
                                One of LastRunTime or NextRunTime Dates must be enabled.
                              </kendo-formerror>
                            </kendo-form-field>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header">
                        <div>Destination Endpoint for Integration RunTimes</div>
                      </div>
                      <div class="card-body">
                        <div class="example-wrapper">
                          <mat-form-field>
                            <mat-label>Destination Endpoints</mat-label>
                            <mat-select [formControl]="destinationsControl" name="destinationEndpoints" required multiple disableOptionCentering panelClass="rTPanelClass">
                              <mat-select-trigger>
                                <mat-chip-list>
                                  <mat-chip
                                    *ngFor="let x of destinationsControl.value"
                                    [removable]="true"
                                    (removed)="onRTSelectionRemoved('DESTINATION', x)">
                                    {{x.endpointName}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                  </mat-chip>
                                </mat-chip-list>
                              </mat-select-trigger>
                              <mat-option *ngFor="let x of destinationEndpointItems" [value]="x">{{x.endpointName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="destinationsControl.hasError('required')">Must have at least one selected.</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card h-100">
                      <div class="card-header">
                        <div>Import Types for Integration RunTimes</div>
                      </div>
                      <div class="card-body">
                        <div class="example-wrapper">
                          <mat-form-field>
                            <mat-label>Import Types</mat-label>
                            <mat-select [formControl]="importTypesControl" name="importTypes" required multiple disableOptionCentering panelClass="rTPanelClass">
                              <mat-select-trigger>
                                <mat-chip-list>
                                  <mat-chip
                                    *ngFor="let x of importTypesControl.value"
                                    [removable]="true"
                                    (removed)="onRTSelectionRemoved('IMPORTTYPE', x)">
                                    {{x.typeDescription}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                  </mat-chip>
                                </mat-chip-list>
                              </mat-select-trigger>
                              <mat-option *ngFor="let i of importTypeItems" [value]="i">{{i.typeDescription}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="importTypesControl.hasError('required')">Must have at least one selected.</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    </ng-container>

    <!-- filter cards -->
    <div class="report-filter-cards">
      <div class="row row-cols-1 row-cols-md-4 row-cols-sm-3 g-4 pb-1">

        <div class="col">
          <div class="card h-100">
            <div class="card-body">
              <div class="card-title">DataSource Type:</div>
              <div class="example-wrapper">
                <kendo-multiselect [checkboxes]="true"
                                  [data]="dataSourceTypeItems"
                                  [textField]="'description'"
                                  [valueField]="'dataSourceTypeId'"
                                  [placeholder]="'Select Data Source Type...'"
                                  [clearButton]="true"
                                  [autoClose]="false"
                                  [filterable]="true"
                                  (valueChange)="externalFilterChange($event)"
                                  [(ngModel)]="dataSourceItemsSelected"></kendo-multiselect>
              </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">Destination Endpoints</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="destinationEndpointItems"
                                [textField]="'endpointName'"
                                [valueField]="'destinationEndpointId'"
                                [placeholder]="'Select Destination Endpoint...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="destinationEndpointItemsSelected"></kendo-multiselect>
          </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="card-title">Import Type (RunTime V2)</div>
            <div class="example-wrapper">
              <kendo-multiselect [checkboxes]="true"
                                [data]="importTypeItems"
                                [textField]="'typeDescription'"
                                [valueField]="'importTypeId'"
                                [placeholder]="'Select Import Type...'"
                                [clearButton]="true"
                                [autoClose]="false"
                                [filterable]="true"
                                (valueChange)="externalFilterChange($event)"
                                [(ngModel)]="importTypeItemsSelected"></kendo-multiselect>
          </div>
          </div>
        </div>
      </div>

        <div class="col">
          <div class="card h-100">
            <div class="card-body">
              <div class="card-title">Server:</div>
              <div class="example-wrapper">
                <kendo-multiselect [checkboxes]="true"
                                  [data]="serverItems"
                                  [textField]="'description'"
                                  [valueField]="'serverId'"
                                  [placeholder]="'Select Server...'"
                                  [clearButton]="true"
                                  [autoClose]="false"
                                  [filterable]="true"
                                  (valueChange)="externalFilterChange($event)"
                                  [(ngModel)]="serverItemsSelected"></kendo-multiselect>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="report-command-button-group">
      <button class="btn btn-outline-secondary" (click)="resetFilters($event)">
      Reset Filters
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="applyFilters($event)" >
      Apply Filters
      </button>

      <button type="button" class="btn btn-outline-secondary" (click)="unSelectAll($event)" [disabled]="selectedIds.length == 0">
          Deselect All
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="selectAll($event)">
          Select All
      </button>

      <ng-container *ngIf="bulkActionType == bulkIntegActionType.LockUnlock">
        <button type="button" class="btn btn-outline-secondary" (click)="lockIntegrations($event)" [disabled]="selectedIds.length == 0">
          <span *ngIf="switchLocked">Lock</span><span *ngIf="!switchLocked">Unlock</span> Selected Integrations
        </button>
      </ng-container>
      <ng-container *ngIf="bulkActionType == bulkIntegActionType.MoveServerIntegrations">
        <button type="button" class="btn btn-outline-secondary" (click)="moveIntegrations($event)" [disabled]="selectedIds.length == 0">
          Move Selected Server Integrations
        </button>
      </ng-container>
      <ng-container *ngIf="bulkActionType == bulkIntegActionType.UpdateRunTimes">
        <button type="button" class="btn btn-primary lts-btn-primary" (click)="updateIntegrationRunTimes($event)" [disabled]="selectedIds.length == 0">
          Update RunTimes
        </button>
      </ng-container>
    </div>

    <hr class="hr-solid">

    <div class="report-kendo-grid-wrapper">
      <ng-container *ngIf="bulkActionType == bulkIntegActionType.UpdateRunTimes">
        <div>
          <h5 class="report-title important-red-text">
            Warning: Integration RunTimes may not get updated if integration is currently running.
          </h5>
        </div></ng-container>
      <ng-container>
        <div class="m-1">
          <div class="report-grid-wrapper">
            <kendo-grid #reportGrid
                        autosize="true"
                        [kendoGridBinding]="gridData"
                        [kendoGridExpandDetailsBy]="expandDetailsBy"
                        [(expandedDetailKeys)]="expandedDetailKeys"
                        [navigable]="false"
                        [columnMenu]="true"
                        [reorderable]="true"
                        [resizable]="true"
                        [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
                        [sortable]="{mode: 'single', allowUnsort: false}"
                        [sort]="state.sort"
                        [pageSize]="state.take"
                        [skip]="state.skip"
                        [filter]="tmpFilter"
                        filterable="menu, row"
                        [selectable]="{enabled: true, checkboxOnly: true, mode: 'multiple'}"
                        kendoGridSelectBy="integrationId"
                        [(selectedKeys)]="selectedIds"
                        [rowSelected]="isRowSelected"
                        (sortChange)="sortChange($event)"
                        (pageChange)="pageChange($event)"
                        [scrollable]="'none'"
                        (filterChange)="filterChange($event)"
                        (cellClick)="onCellClick($event)">
              <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel">
                  Export to Excel
                </button>
                <div class="report-item-count">
                  {{selectedIds.length}} Items Selected
                </div>
              </ng-template>
              <ng-template kendoGridNoRecordsTemplate>
                <div class="empty_spacing_for_norecords">
                  <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
                  <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
                </div>
              </ng-template>

              <kendo-grid-checkbox-column title="" [showSelectAll]="true" [width]="30" [columnMenu]="false"></kendo-grid-checkbox-column>
              <kendo-grid-column
                          *ngFor="let column of cols"
                          field="{{ column.field }}"
                          title="{{ column.title }}"
                          format="{{ column.format }}"
                          filter="{{ column.type }}"
                          [filterable]="column.filterable"
                          [hidden]="column.hidden"
                          [width]="column.width">
                  <ng-container *ngIf="column.type == 'boolean'">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      {{dataItem[column.field] ? "True" : "False"}}
                    </ng-template>
                  </ng-container>
                  <ng-container *ngIf="column.type == 'date' && column.usePipeFormat == true">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      {{dataItem[column.field] | date :column.pipeFormat}}
                    </ng-template>
                  </ng-container>
                  <ng-container *ngIf="column.field == 'serverId'">
                    <ng-template kendoGridFilterCellTemplate let-filter>
                      <dropdown-filter-cell
                        [filter]="filter"
                        [data]="serverItems"
                        textField="serverId"
                        valueField="serverId"></dropdown-filter-cell>

                    </ng-template>
                  </ng-container>
              </kendo-grid-column>
              <ng-template kendoGridDetailTemplate let-dataItem *ngIf="bulkActionType == bulkIntegActionType.UpdateRunTimes">
                <section>
                  <app-view-generic-grid
                      [data]="dataItem.integrationRunTimes"
                      [columns]="integrationRunTimeCols"
                      [isResizeable]="true"
                      [isFilterable]="false"
                      [isSelectable]="false"
                      [showExportToolbar]="false"
                      [loading]="gettingData">
                    </app-view-generic-grid>
                </section>
              </ng-template>
              <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
              </ng-template>
              <kendo-grid-excel [fetchData]="selectedData" fileName="{{exportFileName}}"></kendo-grid-excel>
            </kendo-grid>
          </div>
        </div>
      </ng-container>
    </div>

  </ng-container>
</div>

<!-- Report Back Button -->
<ng-container>
  <div class="report-command-button-group">
    <button type="button" class="btn btn-outline-secondary" (click)="goBack($event)">
      Go Back
    </button>
  </div>
</ng-container>

<ng-template #serverInfoDialog>
  <h2 matDialogTitle>Server Information</h2>
  <mat-dialog-content>
    <div>
      <app-view-generic-grid
        [data]="serverItems"
        [columns]="serverInfoCols"
        [isResizeable]="true"
        [isSelectable]="false"
        [showExportToolbar]="false"
        [loading]="gettingServerItems">
      </app-view-generic-grid>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Close</button>
  </mat-dialog-actions>
</ng-template>
