import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { EditConfigComponent } from '../components/edit-config/edit-config.component';

@Injectable()
export class EditConfigGuard  {

    canDeactivate(component: EditConfigComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
          return component.canDeactivate() ? true :
            window.confirm("You are about to leave the page without saving your changes. Are you sure you want to leave this page?");
    }

}
