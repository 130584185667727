<div class="metrics-grid-container">
  <kendo-grid
      #metricGrid
      autosize="true"
      [kendoGridBinding]="allGroup"
      [sortable]="{mode: 'multiple',allowUnsort: true}"
      [sort]="state.sort"
      [pageable]="{info:true,type:type,pageSizes:pageSizes,previousNext:true,buttonCount:5}"
      [pageSize]="state.take"
      [skip]="state.skip"
      [filterable]="true"
      [selectable]="false"
      [columnMenu]="{sort: true, stick: true, filter: true}"
      [resizable]="true"
      [scrollable]="'scrollable'"
      (excelExport)="onExcelExport($event)"
      >
    <ng-template kendoGridToolbarTemplate>
      <button type="button" kendoGridExcelCommand icon="file-excel">
        Export to Excel
      </button>
    </ng-template>
    <ng-template kendoGridColumnMenuTemplate let-service="service" let-column="column">
      <kendo-grid-columnmenu-item text="Metrics Column Chooser" icon="columns" [expanded]="false">

          <ng-template kendoGridColumnMenuItemContentTemplate >
            <div class="k-column-list-wrapper">
              <div class="k-column-list">
                <ng-container *ngFor="let col of allColumns">
                  <div class="k-column-list-item">
                    <input type="checkbox" class="k-checkbox" [ngModel]="!col.hidden" (ngModelChange)="col.hidden = $event" (change)="hideCol(col)">
                    <span class="k-checkbox-label">{{ col.title || col.field }}</span>
                  </div>

                  <ng-container *ngIf="col.hasSubColumns">
                    <div class="k-column-list-item metric-sub-column-item-chooser" *ngFor="let subCol of col.subColumns">
                      <input type="checkbox" class="k-checkbox" [ngModel]="!subCol.hidden" (ngModelChange)="subCol.hidden = $event" (change)="hideCol(subCol)">
                      <span class="k-checkbox-label">{{ subCol.title || subCol.field }}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="k-columnmenu-actions">
                <button type="button" class="k-button" (click)="unHideAllColumns()">UnHide All</button>
              </div>
            </div>
          </ng-template>

      </kendo-grid-columnmenu-item>
      <kendo-grid-columnmenu-sort [service]="service">
      </kendo-grid-columnmenu-sort>
      <kendo-grid-columnmenu-filter [service]="service">
      </kendo-grid-columnmenu-filter>
      <kendo-grid-columnmenu-stick [service]="service">
      </kendo-grid-columnmenu-stick>
      <kendo-grid-columnmenu-item icon="arrows-resizing" text="Fit column"
          (itemClick)="grid.autoFitColumn(column); service.close()">
      </kendo-grid-columnmenu-item>
    </ng-template>
    <ng-container *ngIf="generalGroupedColumns">
      <kendo-grid-column-group
          *ngFor="let columnInfo of generalGroupedColumns"
          title="{{ columnInfo.title }}"
          [includeInChooser]="true"
          >
        <ng-template kendoGridHeaderTemplate let-column="column">
          <span matTooltip="{{columnInfo.title}}" matTooltipPosition="above">{{columnInfo.title}}</span>
        </ng-template>
        <kendo-grid-column *ngFor="let subColumnInfo of columnInfo.subColumns"
                field="{{subColumnInfo.fullField}}"
                title="{{ subColumnInfo.title }}"
                [columnMenu]="true"
                [filterable]="false"
                [hidden]="subColumnInfo.hidden"
                [resizable]="true"
                [width]="150"
            >
            <ng-container *ngIf="columnInfo.field == 'dateEnd'">
              <ng-template kendoGridFooterTemplate>
                {{total["dateEnd"].count | number: '1.0-0'}}
              </ng-template>
            </ng-container>

            <ng-container *ngIf="columnInfo.field == 'dateStart'">
              <ng-template kendoGridFooterTemplate>
                {{total["dateStart"].count | number: '1.0-0'}}
              </ng-template>
            </ng-container>

            <ng-container *ngIf="columnInfo.field == 'dateEndMonth'">
              <ng-template kendoGridFooterTemplate>
                {{total["dateEnd"].count | number: '1.0-0'}}
              </ng-template>
            </ng-container>

            <ng-container *ngIf="columnInfo.field == 'dateEndYear'">
              <ng-template kendoGridFooterTemplate>
                {{total["dateEnd"].count | number: '1.0-0'}}
              </ng-template>
            </ng-container>
          </kendo-grid-column>
      </kendo-grid-column-group>
    </ng-container>
    <ng-container *ngIf="generalColumns">
      <kendo-grid-column *ngFor="let columnInfo of generalColumns"
          field="{{ columnInfo.field }}"
          title="{{ columnInfo.title }}"
          format="{{ columnInfo.format }}"
          filter="{{ columnInfo.type }}"
          [filterable]="false"
          [hidden]="columnInfo.hidden"
          [width]="150"
      >
      <ng-container *ngIf="columnInfo.field == 'contract.clientFullName'">
        <ng-template kendoGridFooterTemplate>
          {{total["clientFullName"].count | number: '1.0-0'}}
        </ng-template>
      </ng-container>

      <ng-container *ngIf="columnInfo.field == 'totalLoanCount'">
        <ng-template kendoGridFooterTemplate>
          {{total["totalLoanCount"].sum | number: '1.0-0'}}
        </ng-template>
      </ng-container>
      <ng-container *ngIf="columnInfo.field == 'loanVolume'">
        <ng-template kendoGridCellTemplate let-dataItem let-column="column">
          {{dataItem.loanVolume | currency}}
        </ng-template>

        <ng-template kendoGridFooterTemplate>
          {{total["loanVolume"].sum | currency}}
        </ng-template>
      </ng-container>
    </kendo-grid-column>
    </ng-container>
    <ng-container *ngIf="hasExtraColumns">
        <kendo-grid-column-group *ngFor="let columnInfo of columns" title="{{ columnInfo.title }}" [includeInChooser]="true">
          <ng-template kendoGridHeaderTemplate let-column="column">
            <span matTooltip="{{columnInfo.title}}" matTooltipPosition="above">{{columnInfo.title}}</span>
          </ng-template>
          <kendo-grid-column *ngFor="let subColumnInfo of columnInfo.subColumns"
              field="{{subColumnInfo.fullField}}"
              title="{{ subColumnInfo.title }}"
              [columnMenu]="true"
              [filterable]="false"
              [hidden]="subColumnInfo.hidden"
              [resizable]="true"
              [width]="150"
          >
            <ng-template kendoGridHeaderTemplate let-column>
              <span matTooltip="{{subColumnInfo.title}}" matTooltipPosition="above">{{subColumnInfo.title}}</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-column="column">
              {{getAggregateSubKeyValue(dataItem, columnInfo, subColumnInfo)}}
            </ng-template>
            <ng-template kendoGridFooterTemplate let-dataItem>
              {{getAggregateTotalSubKeyValue(dataItem, columnInfo, subColumnInfo)}}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>
    </ng-container>
    <ng-template kendoGridNoRecordsTemplate>
      <div class="empty_spacing_for_norecords">
        <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
        <div class="no-records-message" *ngIf="!gridViewIsLoading">No records found.</div>
      </div>
    </ng-template>

    <kendo-grid-excel fileName="{{exportFileName}}" [fetchData]="allData"></kendo-grid-excel>
  </kendo-grid>

</div>
