<ng-container *ngIf="allDestinations && hasDestinations">
    <ng-container *ngIf="!selectedItem">
      <mat-form-field appearance="fill">
        <mat-label>{{labelName}}</mat-label>
        <input type="text"
          matInput
          [matAutocomplete]="auto"
          [formControl]="destinationFormControl">
        <mat-autocomplete autoActiveFirstOption
            #auto="matAutocomplete"
            (optionSelected)="selectDestination($event.option.value)"
            [displayWith]="displayFnDestination">
          <mat-option *ngFor="let option of destinationsFiltered | async" [value]="option" >
            {{option.endpointName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="selectedItem">
      <mat-form-field appearance="fill" class="small-width">
        <mat-label>{{labelName}}</mat-label>
        <input type="text" matInput disabled value="{{selectedItem.endpointName}}" >
        <button mat-icon-button matSuffix
            (click)="editDestination()"
            matTooltip="Change Destination"
            [attr.aria-label]="'Change Destination'"
            [attr.aria-pressed]="change">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </ng-container>

  </ng-container>
