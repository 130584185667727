<h1 class="title-center">Add Integration</h1>
<br>
<br>

<div *ngIf="!pageLoading && dataSourceTypes">
  <div *ngIf="!hasClient">
    <mat-form-field appearance="fill" style="min-width: 325px;">
      <mat-label>Client</mat-label>
      <input type="text"
             matInput
             [matAutocomplete]="auto"
             [formControl]="clientFormControl">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="setClient($event.option.value)"
                        [displayWith]="displayClientName">
        <mat-option *ngFor="let client of filteredClients | async"
                    [value]="client">
          {{client.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <br>
  </div>

  <div *ngIf="hasClient">
    <div class="container flexWrap">
      <div class="row">
        <div class="col-md-6">

          <h2>Client: {{client.name}}</h2>

          <button mat-raised-button (click)="editClient()" >Change</button>
          <br>
          <br />
          <h3>Current integrations for {{client.name}}</h3>
          <div class="inline pe-1" *ngFor="let i of integrations">
            <button mat-raised-button (click)="goToIntegration(i.name)">{{i.name}}</button>
          </div>

          <div class="pt-3">
            <mat-form-field appearance="fill">
              <mat-label>New Integration Name</mat-label>
              <input matInput [formControl]="integrationNameFormControl">
              <mat-error *ngIf="integrationNameFormControl.hasError('checkNotInList')">Name must not already exist.</mat-error>
              <mat-error *ngIf="integrationNameFormControl.hasError('required')">Required.</mat-error>
            </mat-form-field>
          </div>


        </div>
        <div class="col-md-4">

          <ng-container>
            <mat-form-field appearance="fill">
              <mat-label>Data Source Type</mat-label>
              <mat-select
                [(value)]="selectedDataSourceType"
                (selectionChange)="dataSourceTypeChange($event)">
                <!-- <mat-option>All</mat-option> -->
                <mat-option *ngFor="let option of dataSourceTypes" [value]="option">
                  {{option.dataSourceTypeId}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container>
            <mat-form-field appearance="fill">
              <mat-label>Destination</mat-label>
              <mat-select
                [(value)]="selectedDestinationEndpoint"
                (selectionChange)="destinationEndpointChange($event)">
                <mat-option>Any</mat-option>
                <mat-option *ngFor="let option of destinationEndpoints" [value]="option">
                  {{option.endpointName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container>
            <mat-form-field appearance="fill">
              <mat-label>Import Type</mat-label>
              <!-- [disabled]="!inEditMode" -->
              <mat-select
                [(value)]="selectedImportType"
                (selectionChange)="importTypeChange($event)">
                <mat-option>Any</mat-option>
                <mat-option *ngFor="let option of importTypes" [value]="option">
                  {{option.typeDescription}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <br />

          <ng-container>
            <div class="match-form-field">
              <mat-button-toggle-group
                  #group="matButtonToggleGroup"
                  [multiple]="false"
                  [hideSingleSelectionIndicator]="true"
                  (change)="onToggleIsGenericChange($event.value)">
                  <mat-button-toggle
                    [checked]="isGenericIntegration == false"
                    value="false"
                    aria-label="Not Generic ( Default )">
                      Not Generic
                  </mat-button-toggle>
                  <mat-button-toggle
                  [checked]="isGenericIntegration == true"
                    value="true"
                    aria-label="Generic">
                      Generic
                  </mat-button-toggle>
                  <mat-button-toggle
                  [checked]="isGenericIntegration == null"
                    value="null"
                    aria-label="Generic">
                      Either / Neither
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </ng-container>

          <div>
            <button mat-raised-button (click)="getDefaultMappings()">Refresh Default Mapping</button>
          </div>

        </div>
      </div>
    </div>

    <br />
    <div>

      <br>

      <span><b>Please Enter the XML Configuration for the client below.</b></span>
      <br />

      <div class="inline">
        <span class="text-large inline">{{integration.name}}</span>
      </div>
      <br />
      <div>
        <app-monaco-editor #monaco
                           (textChange)="monacoData = $event"
                           [editorData]="monacoData"
                           [lang]="'xml'"
                           [editAccess]="checkAccess(['LOSTalker.Admin'])"></app-monaco-editor>
        <br />
        <button id="btnSaveIntegration"
          mat-raised-button (click)="saveIntegration()" [disabled]="savingIntegration"
          primary="true">
          Add Integration
        </button>
        <div class="k-i-loading" *ngIf="savingIntegration"></div>
      </div>
    </div>
    <br />
    <br />
  </div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="pageLoading">
  <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!pageLoading && !dataSourceTypes">
  <div class="no-records-message">Loading failed.</div>
</div>
