<ng-container>
  <div class="generic-grid-container" kendoTooltip showOn="none" [tooltipTemplate]="ltErrorGridToolTip"
  filter=".k-grid td" (mouseover)="showTooltip($event)">
    <!-- [loading]="loading" -->
    <kendo-grid
    #genericGrid
    autosize="true"
    [data]="gridView"

    [columnMenu]="showColumnMenu"
    [reorderable]="isReorderable"
    [resizable]="isResizeable"
    [pageable]="{info: true,type: type,pageSizes: true,previousNext: true}"
    [sortable]="{mode: 'single',allowUnsort: false}"
    [sort]="state.sort"
    [pageSize]="state.take"
    [skip]="state.skip"
    [filterable]="isFilterable"
    [filter]="state.filter"
    [selectable]="isSelectable"
    [scrollable]="'scrollable'"
    (selectionChange)="selectionChange($event)"
    (sortChange)="sortChange($event)"
    (pageChange)="pageChange($event)"
    (filterChange)="filterChange($event)">
    <ng-container *ngIf="showExportToolbar">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridExcelCommand icon="file-excel">
          Export to Excel
        </button>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="showSelectionColumn">
      <kendo-grid-column
        field="{{ selectionfield }}"
        title="{{selectionColumnHeader}}"
        width="{{selectionColumnWidth}}"
        [style]="{'text-align': 'center'}"
        [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="showSelectDeselectButtons">
            <button *ngIf="!selectedItem || (selectedItem && dataItem[selectionfield] != selectedItem[selectionfield])" (click)="selectItem(dataItem)" mat-flat-button color="primary">Select</button>
            <button *ngIf="selectedItem && dataItem[selectionfield] == selectedItem[selectionfield]" (click)="deselectItem()" mat-flat-button>Deselect</button>
          </ng-container>
          <ng-container *ngIf="!showSelectDeselectButtons">
            <span *ngIf="selectedItem">
              <span
                *ngIf="dataItem[selectionfield] == selectedItem[selectionfield]"
                style="text-align: center;">
                <i class="bi bi-star-fill"></i>
              </span>
            </span>
          </ng-container>

        </ng-template>
      </kendo-grid-column>
    </ng-container>
    <kendo-grid-column
            *ngFor="let column of cols"
            field="{{ column.field }}"
            title="{{ column.title }}"
            format="{{ column.format }}"
            filter="{{ column.type }}"
            [filterable]="column.filterable"
            [hidden]="column.hidden"
            [width]="column.width">
          <ng-container *ngIf="column.type == 'date' && column.usePipeFormat == true">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem[column.field] | date :column.pipeFormat}}
            </ng-template>
          </ng-container>
      </kendo-grid-column>
      <ng-container *ngIf="hasActionGoToColumn">
        <kendo-grid-column field="{{actionGoToField}}" title="Go To" [width]="40" [filterable]="false" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="actionGoToUseAddlField">
              <a [routerLink]="getRouterLink(dataItem)" target="_blank">
                <button mat-flat-button color="primary">Navigate</button>
              </a>
            </ng-container>
            <ng-container *ngIf="!actionGoToUseAddlField">
              <ng-container *ngIf="actionGoToUsePrefix">
                <a [routerLink]="[actionGoToPrefix, dataItem[actionGoToField]]" target="_blank">
                  <button mat-flat-button color="primary">Navigate</button>
                </a>
              </ng-container>
              <ng-container *ngIf="!actionGoToUsePrefix">
                <a [routerLink]="[dataItem[actionGoToField]]" target="_blank">
                  <button mat-flat-button color="primary">Navigate</button>
                </a>
              </ng-container>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
      <ng-template kendoGridNoRecordsTemplate>
        <div class="empty_spacing_for_norecords">
          <div class="k-i-loading" *ngIf="gridViewIsLoading || loading"></div>
          <div class="no-records-message" *ngIf="!gridViewIsLoading && !loading">No records found.</div>
        </div>
      </ng-template>
      <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
      </ng-template>
      <kendo-grid-excel [fetchData]="allData" fileName="{{exportFileName}}"></kendo-grid-excel>
    </kendo-grid>
  </div>
  <div *ngIf="showChangeSelection && selectedItem">
    <button mat-raised-button (click)="changeSelectionView()" >Change</button>
  </div>
</ng-container>


<ng-template #ltErrorGridToolTip let-anchor>
  <span>{{anchor.nativeElement.innerText}}</span>
</ng-template>