<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="this.integrationsMap == undefined">
  <div class="k-i-loading"></div>
</div>
<ng-container *ngIf="this.integrationsMap != undefined && this.clients != undefined && !this.gettingClients">
  <ng-container *ngIf="isInTab == false">
    <h3 style="text-align:center;">Integration Errors</h3>
  </ng-container>
  <ng-container *ngIf="isInTab == true">
    <h4 class="mt-1 mb-3" style="text-align:center;">Integration Errors</h4>
  </ng-container>

  <kendo-tabstrip height="100%" #tabstrip (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab *ngIf="isInTab == false" [title]="'Critical Errors'" [selected]="!isInTab">
      <ng-template kendoTabContent>
        <basic-error-search-filter [isSnapShot]="true" [categories]="categories"
          [errorSearchFilter]="critErrorSearchFilter" (updateFilter)="filterCriticalErrors($event)">
        </basic-error-search-filter>
        <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.criticalPopulated">
          <div class="k-i-loading"></div>
        </div>
        <div class="mt-2" *ngIf="this.criticalPopulated">
          <app-error-list-grid-v2 #allListComponent [data]="criticalErrorsList" [integrationsMap]="integrationsMap"
            [categories]="categories" [importTypes]="importTypes" [statuses]="statuses" [clients]="clients"
            [sorting]="critErrorSorting" [destinations]="allDestinations" [loading]="dataLoading">
          </app-error-list-grid-v2>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'All Errors'" [selected]="isInTab">
      <ng-template kendoTabContent>
        <div *ngIf="integration == null">
          <basic-error-search-filter [isSnapShot]="false" [categories]="categories"
            [errorSearchFilter]="allErrorSearchFilter" (updateFilter)="filterAllErrors($event)">
          </basic-error-search-filter>
        </div>
        <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.allPopulated">
          <div class="k-i-loading"></div>
        </div>
        <div class="mt-2" *ngIf="this.allPopulated">
          <app-error-list-grid-v2 #allListComponent [data]="allErrorsList" [integrationsMap]="integrationsMap"
            [categories]="categories" [importTypes]="importTypes" [statuses]="statuses" [loading]="dataLoading"
            [clients]="clients" [destinations]="allDestinations" [sorting]="allErrorSorting">
          </app-error-list-grid-v2>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <ng-container *ngIf="isInTab == false">
      <kendo-tabstrip-tab [title]="'Service Errors'">
        <ng-template kendoTabContent>
          <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!this.serviceErrorsPopulated">
            <div class="k-i-loading"></div>
          </div>
          <div class="mt-2" *ngIf="this.serviceErrorsPopulated">
            <app-service-error-list-grid #serviceErrorListComponent [data]="serviceErrorList"
              [integrationsMap]="integrationsMap" [categories]="categories" [importTypes]="importTypes"
              [statuses]="statuses" [destinations]="allDestinations" [loading]="dataLoading" [clients]="clients">
            </app-service-error-list-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </ng-container>
  </kendo-tabstrip>
</ng-container>