import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IntegrationType } from '../models/integration-type';
import { DataSourceType } from '../models/data-source-type';
import { DestinationEndpoint } from '../models/destination-endpoint';
import { protectedResources } from '../auth-config';
import { ImportType } from '../models/import-type';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';
import { ReportConstants } from '../models/report-constants';

@Injectable()
export class TypeConstantService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();

  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.error, "Warning", {
      keepAfterRouteChange: true
    });

    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Import Types ------------------------------------------//

  // Get Import Types Observable
  getAllImportTypes(): Observable<ImportType[]> {
    this.actionUrl = "ImportTypes/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ImportType[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getImportType(importTypeId: number): Observable<ImportType> {
    this.actionUrl = "ImportType/";
    this.Url = this.baseUrl.concat(this.actionUrl + importTypeId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ImportType>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Destination Endpoint ------------------------------------------//

  // Get Destination Endpoints
  getAllDestinationEndpoints(): Observable<DestinationEndpoint[]> {
    this.actionUrl = "DestinationEndpoints/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<DestinationEndpoint[]>(this.Url)
       .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get by id
  getDestinationEndpointById(destinationEndpointId: number): Observable<DestinationEndpoint> {
    this.actionUrl = "DestinationEndpoint/";
    this.Url = this.baseUrl.concat(this.actionUrl + destinationEndpointId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<DestinationEndpoint>(this.Url)
       .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Integration Types ------------------------------------------//

  // GET Integration Types
  getAllIntegrationTypes(): Observable<IntegrationType[]> {
    this.actionUrl = "IntegrationTypes/";
    this.Url = this.baseUrl.concat(this.actionUrl);

    return this.http
      .get<IntegrationType[]>(this.Url)
       .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET
  getIntegrationType(typeId: string): Observable<IntegrationType> {
      this.actionUrl = "RunTime/GetIntegrationType/";
      this.Url = this.baseUrl.concat(this.actionUrl, typeId);

      return this.http
        .get<IntegrationType>(this.Url)
         .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // DELETE
  deleteIntegrationType(intType: IntegrationType): Observable<IntegrationType> {
      this.actionUrl = "RunTime/DeleteIntegrationType/"
      this.Url = this.baseUrl.concat(this.actionUrl);
      this.headers.append('Content-Type', 'application/json');

      return this.http
          .post<IntegrationType>(this.Url, JSON.stringify(intType), { headers: this.headers })
           .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST
  addIntegrationType(intType: IntegrationType): Observable<IntegrationType> {
      this.actionUrl = "IntegrationType/";
      this.Url = this.baseUrl.concat(this.actionUrl);
      this.headers.append('Content-Type', 'application/json');

      return this.http
          .post<IntegrationType>(this.Url, JSON.stringify(intType), { headers: this.headers })
           .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Data Source Types ------------------------------------------//

  // Get DataSource Types
  getAllDataSourceTypes(): Observable<DataSourceType[]> {
    this.actionUrl = "DataSourceTypes/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<DataSourceType[]>(this.Url)
       .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Report constants ------------------------------------------//

  // Get All Default Report Contstants
  getAllReportViewConstants(): Observable<ReportConstants> {
    this.actionUrl = "ReportData/constants/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ReportConstants>(this.Url)
       .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // Get Report Contstants by Report Type
  getReportViewConstantsForReportType(rTId: number): Observable<ReportConstants> {
    this.actionUrl = "ReportData/constants/";
    this.Url = this.baseUrl.concat(this.actionUrl + rTId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ReportConstants>(this.Url)
       .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }
}
