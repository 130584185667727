import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, Subject, of} from 'rxjs';
import { InvoicingService } from '../../services/invoicing.service';
import { InvoicePeriod } from '../../models/invoice-period';
import { ToastService } from '../../services/toast.service';
import { ToastType } from 'src/app/models/toast';
import { ClientContract } from '../../models/client-contract';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const day = today.getDate();

@Component({
  selector: 'app-invoicing-select-date-view',
  templateUrl: './invoicing-select-date-view.component.html',
  styleUrls: ['./invoicing-select-date-view.component.scss']
})
export class InvoicingSelectDateViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  @Input() showDatePeriodSwitch: boolean = true;
  @Input() useMonthYearOnly: boolean = false;
  @Input() dateStringPrefix: string = "";

  @Input() selectedContract: ClientContract = null;
  @Input() overrideDateRange: [Date,Date] = null;
  @Input() overrideInvoicePeriod: InvoicePeriod = null;

  pageReady: boolean = false;
  showPeriodSelectView: boolean = true;

  selectedInvoicePeriod: InvoicePeriod = null;

  @Output() updatedRange = new EventEmitter<[Date,Date]>();
  @Output() updatedInvoicePeriod = new EventEmitter<InvoicePeriod>();
  @Output() completedLoansPeriodSelected = new EventEmitter<InvoicePeriod>();
  @Output() updatedInvoicePeriodFromOverride = new EventEmitter<InvoicePeriod>();
  @Output() returnAllInvoicePeriods = new EventEmitter<InvoicePeriod[]>(); // return InvoicePeriod List from InvoicePeriodView
  constructor() { }

  ngOnInit(): void {
    this.pageReady = false;
    if (this.showDatePeriodSwitch == false) {
      this.showPeriodSelectView = false;
    }
    this.pageReady = true;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
  }

  // Toggle between Date Range and Invoice Period Selection
  onToggleShowPeriodSelectViewChange(value: any): void {
    if (value === 'true') {
      this.showPeriodSelectView = true;
    } else if (value === 'false') {
      this.showPeriodSelectView = false;
    }
  }

  // get Completed Loans Period from Period Select View
  getCompletedLoansInvoicePeriodFromView(event: InvoicePeriod) {
    this.selectedInvoicePeriod = null;

    if (event != null && event != undefined) {
      this.selectedInvoicePeriod = event;
      this.completedLoansPeriodSelected.emit(event);
    }
  }

  // get non Completed Loans Period from Period Select View
  getInvoicePeriodFromOverrideView(event: InvoicePeriod) {
    this.selectedInvoicePeriod = null;

    if (event != null && event != undefined) {
      this.selectedInvoicePeriod = event;
      this.updatedInvoicePeriodFromOverride.emit(event);
    }
  }

  getInvoicePeriodFromView(event: InvoicePeriod) {
    this.selectedInvoicePeriod = null;

    if (event != null && event != undefined) {
      this.selectedInvoicePeriod = event;
      this.updatedInvoicePeriod.emit(event);
    }
  }

  // get date range from date range select
  submitDateRange(event: [Date,Date]) : void {
    this.updatedRange.emit(event);
  }

  formatDateEnd(day: number) {
    var rc = day.toString();
    if (day == 31) {
      rc += "st";
    }
    else {
      rc += "th";
    }

    return rc;
  }

  returnInvoicePeriodsList(periods: InvoicePeriod[]): void {
    this.returnAllInvoicePeriods.emit(periods);
  }
}
