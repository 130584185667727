import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class User {
  userId: string;
  email: string;
  fullName: string;
  userName: string;
  disabled: boolean;
  private userRoles: string[];
  enableImpersonation: boolean = false;
  claims: object;

  getRoles(): string[] {
    return this.userRoles;
  }

  setRoles(roles: string[]) {
    this.userRoles = roles;
  }

  setImpersonation(testRoles: string[]) {
    if (this.userRoles.includes("LOSTalker.Admin")) {
      this.enableImpersonation = true;
      this.userRoles = testRoles;
    }
  }

  endImpersonation() {
    if (this.claims["roles"] != undefined) {
      this.userRoles = this.claims["roles"];
      this.enableImpersonation = false;
    }
  }

  checkAccess(aL: string[]): boolean {

    var hasAccess = false;

    try {
      if (this.userRoles !== undefined && this.userRoles.length > 0) {

        if (!aL.includes('LOSTalker.Admin') && this.userRoles.includes('LOSTalker.Admin')) {
          hasAccess = true;
        }
        else if (!aL.includes('LOSTalker.Admin') && this.userRoles.includes('LOSTalker.DevOps')) {
          hasAccess = true;
        }
        else {
          for (let al of aL) {
            hasAccess = this.userRoles.includes(al);
            if (hasAccess) {
              break;
            }
            else {
              if (al.indexOf("LOSTalker.Integration.") == 0) {
                if (this.userRoles.includes("LOSTalker.Integration")){
                  hasAccess = true;
                }
                else {
                  for (let r of this.userRoles) {
                    if (al.indexOf(r) == 0) {
                      hasAccess = true;
                      break;
                    }
                  }
                }
              }
              else if (al.indexOf("LOSTalker.Client.") == 0) {
                if (this.userRoles.includes("LOSTalker.Client")){
                  hasAccess = true;
                }
                else {
                  for (let r of this.userRoles) {
                    if (al.indexOf(r) == 0) {
                      hasAccess = true;
                      break;
                    }
                  }
                }
              }
              else if (al == 'LOSTalker.BaseMapping') {
                hasAccess = this.userRoles.includes('LOSTalker.Integration')
                  || this.userRoles.includes('LOSTalker.Client');
              }
              else if (al == 'LOSTalker.Client') {
                hasAccess = this.userRoles.includes('LOSTalker.Integration');
              }

              if (hasAccess) {
                break;
              }
            }
          }
        }
      }
    }
    catch (error) {
      console.log(error);
    }


    return hasAccess;
  }
}
