<div *ngIf="customSQLQuery">
  <h1>Edit Custom SQL Query</h1>
  <div class="inline">
    <span class="text-large inline">{{customSQLQuery.name}}  </span>
  </div>
  <br />

  <app-monaco-editor #monaco
    (textChange)="monacoData = $event"
    [editorData]="monacoData"
    [lang]="'sql'" 
    [editAccess]="checkAccess(['LOSTalker.Admin'])">
  </app-monaco-editor>

  <br />
  <button kendoButton (click)="goBack()">Go Back</button>
  <button kendoButton *ngIf="checkAccess(['LOSTalker.Admin'])" [disabled]="savingInformation" (click)="updateCustomQuery()">Save Changes</button>
  <button kendoButton *ngIf="checkAccess(['LOSTalker.Admin'])" [disabled]="savingInformation" (click)="deleteCustomQuery()">Delete</button>
  <br />
  <br />
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="pageLoading">
  <div class="k-i-loading"></div>
</div>

<div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!pageLoading && !customSQLQuery">
  <div class="no-records-message">Loading failed.</div>
</div>