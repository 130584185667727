import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable, Subscription, forkJoin, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ClientIntegrationService } from '../../../services/client-integration.service';
import { ToastService } from '../../../services/toast.service';
import { Toast, ToastType } from '../../../models/toast';
import { Client } from '../../../models/client';
import { Integration } from '../../../models/integration';
import { IntegrationType } from '../../../models/integration-type';

import { State, DataSourceRequestState } from '@progress/kendo-data-query';
import { SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent, RowArgs, ExcelModule } from '@progress/kendo-angular-grid';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-undelete',
  templateUrl: './undelete.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./undelete.component.scss'],
  providers: [ClientIntegrationService]
})
export class UndeleteComponent implements OnInit, OnDestroy {

  showActiveClients = true;
  hasNoIntegrations = false;
  showGoToRevisionHistory = false;

  activeClients: Client[];
  inactiveClients: Client[];
  selectedClient: Client;
  clientSelected: boolean = false;
  clientsFiltered: Observable<Client[]>;
  inactiveClientsFiltered: Observable<Client[]>;
  clientFormControl = new UntypedFormControl();
  inactiveClientFormControl = new UntypedFormControl();

  integrations: Integration[];
  activeIntegrations: Integration[];
  inactiveIntegrations: Integration[];

  componentDestroyed$: Subject<boolean> = new Subject();
  gridView: GridDataResult;
  sortDesc: SortDescriptor[] = [{ field: "updatedDate", dir: "asc", },];
  selectedIntegration: Integration;
  baseData: Integration[] = [];
  filteredData: Integration[];
  hasIntegrations = false;
  excelFileName: string = "Deleted-Integrations.xlsx";

  pageLoading: boolean = true;



  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    protected clientService: ClientIntegrationService,
    protected toastService: ToastService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {
    this.baseData = [];
    this.filteredData = [];
    this.integrations = [];
    this.activeIntegrations = [];
    this.inactiveIntegrations = [];
    this.activeClients = [];
    this.inactiveClients = [];
    // this.clients = [];
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sortDesc = sort;
    this.updateGrid();
  }

  ngOnInit(): void {
    this.getAllClients();
  }

  getAllClients(): void {
    forkJoin({
      data1: this.clientService.getAllClients(),
      data2: this.clientService.getAllDeletedClients()
    })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data1, data2 }) => {
        this.activeClients = data1;
        this.inactiveClients = data2;
        this.clientsFiltered = this.clientFormControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this.filterClients(name) : this.activeClients.slice())
        );
        this.inactiveClientsFiltered = this.inactiveClientFormControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this.filterInactiveClients(name) : this.inactiveClients.slice())
        );
        this.pageLoading = false;
      }, error => {
        this.pageLoading = false;
      });
  }

  clearBasics(): void {
    this.selectedIntegration = null;
    this.hasIntegrations = false;
    this.selectedClient = null;
    this.clientSelected = false;
    this.filteredData = [];
    this.baseData = [];
    this.clientFormControl.setValue('');
    this.inactiveClientFormControl.setValue('');
    this.hasNoIntegrations = false;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  onToggleActiveClientChange(value: any): void {
    if (value === 'true') {
      this.showActiveClients = true;
    } else {
      this.showActiveClients = false;
    }
    this.clearBasics();
  }

  editClient(): void {
    this.clearBasics();
  }

  selectClient(c: Client): void {
    this.pageLoading = true;
    this.selectedClient = c;
    this.clientSelected = true;
    this.getIntegrations();
  }

  displayFnClient(x: Client): string {
    return x && x.name ? x.name : '';
  }

  displayFnInactiveClient(x: Client): string {
    return x && x.name ? x.name : '';
  }

  private filterClients(name: string): Client[] {
    const filterValue = name.toLowerCase();
    return this.activeClients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private filterInactiveClients(name: string): Client[] {
    const filterValue = name.toLowerCase();
    return this.inactiveClients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  selectionChangeHandler(event: any): void {
    this.selectedIntegration = event.selectedRows[0].dataItem;
  }

  clickUndeleteButton(event: any): void {
    if (!this.selectedIntegration) {
      this.toastService.toastCreate("Integration not selected", "Warning", this.options);
    }
    else {
      if (!this.showActiveClients) {
        this.clientService.undeleteClient(this.selectedClient).subscribe(
          value => {
            this.toastService.toastCreate("Client Undeleted.", "Success", this.options);
          });
      }

      if (this.selectedIntegration.deleted == true) {

        this.clientService.undeleteIntegration(this.selectedIntegration).subscribe(
          value => {
            this.toastService.toastCreate("Integration Undeleted.", "Success", this.options);
            this.router.navigate(['editConfig', this.selectedClient.name, this.selectedIntegration.name]);
          });

      }
      else {
        this.toastService.toastCreate("Error undeleting integration.", "Warning", this.options);
      }
    }
  }

  clickUndeleteClientButton(event: any): void {
    this.clientService.undeleteClient(this.selectedClient).subscribe(
      value => {
        this.toastService.toastCreate("Client Undeleted.", "Success", this.options);
        this.router.navigate(['/addIntegration', this.selectedClient.name]);
      });
  }

  clickAddIntegration(event: any): void {
    this.router.navigate(['/addIntegration', this.selectedClient.name]);
  }

  clickGoToClient(event: any): void {
    this.router.navigate(['/editConfig', this.selectedClient.name]);
  }

  getIntegrations(): void {
    if (this.selectedClient) {
      this.clientService.getUniqueIntegrationsByClient(this.selectedClient.name)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(d => {
          if (d.length > 0) {
            let aIntegrations = d.filter(i => i.deleted == false);
            if (aIntegrations.length > 0) {
              let aN = aIntegrations.map(i => i.name);
              let inactive = d.filter(i => aN.indexOf(i.name) == -1);
              if (inactive.length > 0) {
                this.integrations = inactive;
                this.baseData = inactive;
                this.hasIntegrations = true;
                this.showGoToRevisionHistory = false;
                if (inactive.length === 1) {
                  this.selectedIntegration = inactive[0];
                  this.loadDataIntoGridView();
                }
                else {
                  this.loadDataIntoGridView();
                }
              }
              else {
                this.showGoToRevisionHistory = true;
                this.hasNoIntegrations = true;
              }
            }
            else {
              this.integrations = d;
              this.baseData = d;
              this.hasIntegrations = true;
              if (d.length === 1) {
                this.selectedIntegration = d[0];
                this.loadDataIntoGridView();
              }
              else {
                this.loadDataIntoGridView();
              }
            }
          }
          else {
            this.hasNoIntegrations = true;
          }
          this.pageLoading = false;
        }, error => {
          this.pageLoading = false;
        });
    }
  }

  loadDataIntoGridView(): void {
    const d = orderBy(this.baseData, this.sortDesc);
    this.gridView = {
      data: d,
      total: d.length
    };
  }

  updateGrid(): void {
    const d = orderBy(this.baseData, this.sortDesc);
    this.gridView = {
      data: d,
      total: d.length
    };
  }

  isDisabled(args) {
    return {
      'k-disabled': args.dataItem.deleted === false
    };
  }

}
