import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { ClientIntegrationService } from '../../services/client-integration.service';
import { ReportService } from '../../services/report.service';
import { Report, ReportData } from '../../models/report';
import { ReportType } from 'src/app/models/report-type';
import { Server } from '../../models/server';
import { Client } from '../../models/client';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { ImportType } from '../../models/import-type';
import { DataSourceType } from '../../models/data-source-type';
import { IntegrationType } from '../../models/integration-type';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../models/toast';
import { TypeConstantService } from '../../services/type-constant.service';
import { LOSTalkerTitleService } from '../../services/lostalker-title-service.service';
import { IntegrationRunTime } from 'src/app/models/integration-runtime';
import { ReportConstants } from 'src/app/models/report-constants';
@Component({
  selector: 'report-view',
  templateUrl: './report-view.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./report-view.component.scss'],
  providers: [ReportService, LOSTalkerTitleService, TypeConstantService]
})
export class ReportViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  reportList: Report[];
  public currentReport: Report;
  currentReportType: ReportType;
  reportConstants: ReportConstants;

  public reportType = ReportType;

  customReport: Boolean = false;
  sub: any;

  // items to filter from
  serverItems: Server[] = [];
  dataSourceTypeItems: DataSourceType[] = [];
  destinationEndpointItems: DestinationEndpoint[] = [];
  importTypeItems: ImportType[] = [];
  clientItems: Client[] = [];
  integrationRunTimeItems: IntegrationRunTime[] = [];

  constructor(
    protected typeConstantService: TypeConstantService,
    protected reportService: ReportService,
    protected toastService: ToastService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private titleService: LOSTalkerTitleService
  ) {

  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {

      this.reportService.getReportById(params["reportId"])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {

        if (data != null) {
          this.currentReport = new Report(data);
          this.customReport = this.currentReport.isCustomReport;
          if (this.customReport) {
            this.router.navigate([this.currentReport?.routerUrl]);
          }
          else {
            this.titleService.setUpTitle('Report - ' + data.title);
            if (this.currentReport.reportTypeId != null && this.currentReport.reportTypeName != null) {
              this.currentReportType = new ReportType({
                reportTypeId: this.currentReport.reportTypeId,
                reportTypeName: this.currentReport.reportTypeName
              });
            }
            this.getReportConstants();
          }
        }
        else {
          this.toastService.toastCreate("Report failed to return", "Danger");
        }

      },
      (error) => {
        this.toastService.toastCreate("Report failed to return", "Danger");
      });

    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public goBack(e: Event): void {
    this.location.back();
  }

  public getReportConstants(): void {
    if (this.currentReport != null && this.currentReportType != null) {
      this.typeConstantService.getReportViewConstantsForReportType(this.currentReportType.reportTypeId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          if (data != null) {
            this.reportConstants = data;
            this.dataSourceTypeItems = data.dataSourceTypes;
            this.serverItems = data.servers;
            this.destinationEndpointItems = data.destinationEndpoints;
            this.importTypeItems = data.importTypes;
            this.integrationRunTimeItems = data.integrationRunTimes;
          }
        },
        (error) => {
          // console.log(error);
          this.toastService.toastCreate("Report Constants failed to return", "Danger");
        }
      );
    }
    else {
      this.typeConstantService.getAllReportViewConstants()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          if (data != null) {
            this.dataSourceTypeItems = data.dataSourceTypes;
            this.serverItems = data.servers;
            this.destinationEndpointItems = data.destinationEndpoints;
            this.importTypeItems = data.importTypes;
            this.integrationRunTimeItems = data.integrationRunTimes;
          }
        },
        (error) => {
          // console.log(error);
          this.toastService.toastCreate("Report Constants failed to return", "Danger");
        }
      );
    }
  }
}
